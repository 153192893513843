import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import AnalysisPageVM from 'src/viewModels/ProjectAnalysis';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProjectHeader from 'src/components/project/ProjectHeader';
import Analysis from './Analysis';
import Schedule from './Schedule';

@observer
class AnalysisPage extends React.Component {

  constructor(props) {
    super(props);
    this.vm = new AnalysisPageVM(this.props.router.params.id);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <ProjectHeader />
        <Tabs
          size="small"
          activeKey={this.vm.currentTabKey}
          onTabClick={this.vm.onTabChange}
        >
          <Tabs.TabPane
            tab="專案分析紀錄"
            key="analysis"
          >
            <Analysis
              features={this.vm.features}
              channelStat={this.vm.channelStat}
              searchStat={this.vm.searchStat}
              searchAngle={this.vm.searchAngle}
              analysisPageVM={this.vm}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="資料起訖區間（含排程）"
            key="schedule"
          >
            <Schedule
              features={this.vm.features}
              channelStat={this.vm.channelStat}
              searchStat={this.vm.searchStat}
              searchAngle={this.vm.searchAngle}
              changeTabAndRefresh={this.vm.changeTabAndRefresh}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }
}

AnalysisPage.propTypes = {
  router: PropTypes.object
};

AnalysisPage.defaultProps = {
  router: null
};

export default withProfile(withRouter(AnalysisPage), true);
