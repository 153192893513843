import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Button } from 'antd';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProjectHeader from 'src/components/project/ProjectHeader';
import ChannelPane from './ChannelPane';
import SearchPane from './SearchPane';
import FeaturePane from './FeaturePane';

const AllProjectsSettingsPage = observer((props) => {

  return (
    <>
      <ProjectHeader projectId={props.projectId} />

      <Tabs
        size="small"
        defaultActiveKey={props.defaultActiveKey || 'channel'}
      >
        <Tabs.TabPane tab="頻道組合" key="channel">
          <ChannelPane projectId={props.projectId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="搜尋關鍵字" key="search">
          <SearchPane projectId={props.projectId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="特性關鍵字" key="feature">
          <FeaturePane projectId={props.projectId} onFeatureTabChange={props.onFeatureTabChange} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
});

AllProjectsSettingsPage.propTypes = {
  router: PropTypes.shape({}),
  projectId: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  onFeatureTabChange: PropTypes.func
};

AllProjectsSettingsPage.defaultProps = {
  router: null,
  projectId: '',
  defaultActiveKey: '',
  onFeatureTabChange: () => {}
};

export default withProfile(withRouter(AllProjectsSettingsPage), false);
