import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import SystemService from 'src/services/system';
import { errorModal } from 'src/utils';

export default class SystemLoading {

  @observable indicator = ''; // enum: [light, medium, heavy]
  @observable pending = [];
  @observable running = [];
  @observable stats = {
    pending: 0,
    running: 0
  };

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.getData();
  }

  getData = async () => {
    try {
      const res = await SystemService.get();

      runInAction(() => {
        this.indicator = res.indicator;
        this.pending = res.pending;
        this.running = res.running;
        this.stats = res.stats;
      });

    } catch {
      errorModal('發生錯誤，無法取得資料');
    }
  }
}
