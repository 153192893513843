import { Button, Row, Tag, Tooltip } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { countryCodeToName } from 'src/utils';

const makeTag = (tag) => {
  // attr: 屬性, subAttr子屬性, theme: 內容主題, subTheme: 次要主題

  // 內容主題
  if (
    tag.type === '受眾媒體'
    || tag.attr === '消費品牌自營頻道'
    || tag.attr === '通路自營頻道'
    || tag.attr === '媒體自營頻道'
    || tag.attr === '其他組織自營頻道'
  ) {
    return tag.themes;
  }

  let results;

  switch (tag.subAttr) {
    // 內容主題
    case '網紅':
      results = tag.themes;
      break;
    // 子屬性
    case '藝人明星':
    case '高階經理人':
    case '主播/主持人':
    case '模特兒/直播主':
    case '非營利組織自營頻道':
      results = [tag.subAttr];
      break;
    // 子屬性_次要主題
    case '政治人物':
    case '名嘴':
    case '政黨自營頻道':
    case '側翼粉專自營頻道':
      results = tag.subThemes.length > 0
        ? tag.subThemes.map((subTheme) => `${tag.subAttr}_${subTheme}`)
        : [tag.subAttr];
      break;
    // 子屬性_內容主題
    case '政府機關自營頻道':
      results = tag.themes.length > 0
        ? tag.themes.map((theme) => `${tag.subAttr}_${theme}`)
        : [tag.subAttr];
      break;
    default: results = [];
  }

  return results;
};

const makeTagArray = (rawTags, type) => {
  const tags = rawTags
    .filter((tag) => tag.type === type)
    .flatMap((tag) => makeTag(tag));

  return tags;
};

const columns = [
  {
    title: <Row justify="center">頻道ID</Row>,
    key: 'id',
    width: 240,
    render: (data) => (<div>{data.channelId || data.id}</div>)
  },
  {
    title: <Row justify="center">國家</Row>,
    key: 'country',
    width: 160,
    render: (data) => (<div>{data.country ? countryCodeToName(data.country) : ''}</div>)
  },
  {
    title: <Row justify="center">頻道名稱</Row>,
    key: 'channelDisplayName',
    render: (data) => (
      <div style={{ width: '100%' }}>
        {data.channelDisplayName}
      </div>
    )
  },
  {
    title: <Row justify="center">來源類型</Row>,
    key: 'mediaType',
    width: 140,
    render: (data) => (<div>{data.mediaType}</div>)
  },
  {
    title: '網站',
    key: 'url',
    align: 'center',
    width: 100,
    render: (data) => (
      <Button
        type="link"
        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        href={data.url}
        target="_blank"
      >
        <LinkOutlined />
      </Button>
    )
  },
  {
    title: '狀態',
    key: 'isValid',
    align: 'center',
    width: 100,
    render: (data) => (
      <Row justify="center">
        <div style={{ textAlign: 'center' }}>{data.isValid ? 'true' : 'false'}</div>
      </Row>
    )
  },
  {
    title: 'is_Kol',
    key: 'isKol',
    align: 'center',
    width: 100,
    render: (data) => (
      <>
        {data.isKol === true && (<div style={{ textAlign: 'center' }}>網紅</div>)}
        {data.isKol === false && (<div style={{ textAlign: 'center' }}>非網紅</div>)}
        {(data.isKol !== true && data.isKol !== false) && (<div style={{ textAlign: 'center' }}>-</div>)}
      </>
    )
  },
  {
    title: <Row justify="center">資料起始時間</Row>,
    key: 'earliestDate',
    width: 150,
    render: (data) => (
      <div>
        {data.earliestDate ? dayjs(data.earliestDate).format('YYYY-MM-DD HH:mm') : '-'}
      </div>
    )
  },
  {
    title: <Row justify="center">近半年總發文量</Row>,
    key: 'count',
    width: 150,
    sorter: true,
    showSorterTooltip: false,
    render: (data) => (<div>{data.count}</div>)
  },
  {
    title: <Row justify="center">頻道標籤</Row>,
    key: 'count',
    width: 250,
    render: (data) => {
      const audienceTags = makeTagArray(data.tags, '受眾媒體');
      const officialTags = makeTagArray(data.tags, '官方媒體');
      const hasTags = audienceTags.length > 0 && officialTags.length > 0;

      return (
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', maxHeight: 50, overflow: 'hidden' }}>
            {audienceTags.map((tag) => <Tag color="orange" style={{ marginBottom: 3 }}>{tag}</Tag>)}
            {officialTags.map((tag) => <Tag color="green" style={{ marginBottom: 3 }}>{tag}</Tag>)}

          </div>

          {hasTags && (
            <Tooltip
              trigger={['click']}
              title={(
                <div>
                  {audienceTags.map((tag) => <Tag color="orange">{tag}</Tag>)}
                  {officialTags.map((tag) => <Tag color="green">{tag}</Tag>)}
                </div>
              )}
            >
              <Button
                type="link"
                style={{ padding: 0, margin: 0, height: 22 }}
              >
                {'> more'}
              </Button>
            </Tooltip>
          )}
        </div>
      );
    }
  }
];

export default columns;
