import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { Modal } from 'antd';
import { errorModal } from 'src/utils';
import ProjectService from 'src/services/project';
import ChannelSetService from 'src/services/channelSet';

export default class DisableSOP {

  @observable modalVisible = false;
  projectId = '';
  storedChannel = null;

  constructor(projectId, storedChannel) {
    makeObservable(this);
    this.projectId = projectId;
    this.storedChannel = storedChannel;
  }

  @action
  end = () => {
    this.modalVisible = false;
    this.storedChannel.selectedIdSet.clear();
  }

  @action
  cancel = () => {
    this.modalVisible = false;
  }

  start = async () => {
    try {
      // get project status
      const res = await ProjectService.getProject(this.projectId);
      const isRuning = res.meta.isRun;
      const { beginAt, endAt } = res.progress;


      // project is not running
      if (!isRuning) {
        Modal.warning({ title: '專案未啟用，無法使用永久停用功能', okText: '確認' });
        return;
      }


      // project is running and backtracking
      if (beginAt && !endAt) {
        errorModal(
          '停用失敗！',
          '此專案有一個以上的資料分析工作正在進行，為避免分析結果錯亂，目前無法執行資料停用工作。'
        );
        return;
      }


      // project is running but not backtracking
      runInAction(() => { this.modalVisible = true; });

    } catch {
      errorModal('發生錯誤，請稍後再試');
    }
  }


  disableChannels = async (date) => {
    try {
      const ids = Array.from(this.storedChannel.selectedIdSet);

      await ChannelSetService.disableChannel(this.projectId, ids, date);

      this.storedChannel._list
        .filter((channel) => this.storedChannel.selectedIdSet.has(channel.channelId))
        .forEach((channel) => channel.disable(date));

      Modal.success({ title: '頻道設定已停用。', okText: '關閉' });


    } catch (err) {
      errorModal('發生錯誤。無法停用');

    } finally {
      this.end();
    }
  }

}
