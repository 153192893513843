import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { Modal } from 'antd';
import { errorModal } from 'src/utils';
import ProjectService from 'src/services/project';
import SearchSetService from 'src/services/searchSet';

export default class DisableSOP {

  @observable modalVisible = false;

  keywordPaneVM = null;
  projectId = '';

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  @action
  end = () => {
    this.modalVisible = false;
    this.keywordPaneVM.resetSelectedKeyword();
  }

  @action
  cancel = () => {
    this.modalVisible = false;
  }

  start = async (keywordPaneVM) => {
    // get project status
    const res = await ProjectService.getProject(this.projectId);
    const isRuning = res.meta.isRun;
    const { beginAt, endAt } = res.progress;
    this.keywordPaneVM = keywordPaneVM;

    // project is not running
    if (!isRuning) {
      return Modal.warning({ title: '專案未啟用，無法使用永久停用功能', okText: '確認' });
    }


    // project is running and backtracking
    if (beginAt && !endAt) {
      return errorModal(
        '停用失敗！',
        '此專案有一個以上的資料分析工作正在進行，為避免分析結果錯亂，目前無法執行資料停用工作。'
      );
    }


    // project is running but not backtracking
    runInAction(() => { this.modalVisible = true; });
    return null;
  }

  disable = async (date) => {
    try {
      const keywordPool = this.keywordPaneVM.searchVM.keywordPool;
      const ids = Array.from(this.keywordPaneVM.selectedIdSet);


      await Promise.all(ids.map(async (id) => {
        const keyword = keywordPool.get(id);
        const { sid, subSid, sub2Sid } = keyword._getIds();
        await SearchSetService.disableKeyword(date, this.projectId, sid, subSid, sub2Sid, this.keywordPaneVM.level);
        keyword.disable(date);
      }));


      Modal.success({
        title: '搜尋關鍵字設定已停用。',
        okText: '關閉'
      });


      this.keywordPaneVM.searchVM.informDisable(this.keywordPaneVM.level, ids, date);

    } catch (err) {
      errorModal('發生錯誤，無法停用。');

    } finally {
      this.end();
    }
  }

}
