/* eslint no-param-reassign: ["error", { "props": false }] */

import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import { Modal } from 'antd';
import { NAME_ORDER } from 'src/constants';
import Keyword from './Keyword';
import Tag from './Tag';
import EditDrawer from './EditDrawer';

export default class ImportDrawerViewModel {

  @observable visible = false;
  @observable keywords = [];
  @observable tags = [];
  @observable searchAngle = null;
  @observable edit = null;
  @observable name = '';
  @observable reduceKeyword = '';

  @observable L1QueryData = null;
  @observable L2QueryData = null;
  @observable L3QueryData = null;

  @observable L1Order = NAME_ORDER.ASC;
  @observable L2Order = NAME_ORDER.ASC;
  @observable L3Order = NAME_ORDER.ASC;

  constructor(searchAngle) {
    makeObservable(this);
    runInAction(() => {
      this.searchAngle = searchAngle;
    });
  }

  @action
  closeDrawer = () => {
    this.visible = false;
    this.keywords = [];
    this.tags = [];
    this.edit = null;
    this.name = '';
    this.reduceKeyword = '';
    this.L1QueryData = null;
    this.L2QueryData = null;
    this.L3QueryData = null;
  }

  @action
  setL1QueryData = (data) => {
    this.L1QueryData = data;
  }
  @action
  setL2QueryData = (data) => {
    this.L2QueryData = data;
  }
  @action
  setL3QueryData = (data) => {
    this.L3QueryData = data;
  }
  @action
  onL1OrderChange = (order) => {
    if (this.L1Order === NAME_ORDER.DESC) {
      runInAction(() => { this.L1Order = NAME_ORDER.ASC; });
    } else {
      runInAction(() => { this.L1Order = NAME_ORDER.DESC; });
    }
  }
  @action
  onL2OrderChange = (order) => {
    if (this.L2Order === NAME_ORDER.DESC) {
      runInAction(() => { this.L2Order = NAME_ORDER.ASC; });
    } else {
      runInAction(() => { this.L2Order = NAME_ORDER.DESC; });
    }
  }
  @action
  onL3OrderChange = (order) => {
    if (this.L3Order === NAME_ORDER.DESC) {
      runInAction(() => { this.L3Order = NAME_ORDER.ASC; });
    } else {
      runInAction(() => { this.L3Order = NAME_ORDER.DESC; });
    }
  }

  @action
  uploadData = (data) => {
    const { name, reduceKeyword, rows } = data;

    // set meta info
    this.name = name;
    this.reduceKeyword = reduceKeyword;

    // to get all tag keys
    const tags = Object.keys(rows[0].tags);
    const map = new Map();
    tags.forEach((tag) => map.set(tag, new Set()));

    this.keywords = rows.map((r) => {

      // to get all tag values
      Object.entries(r.tags).forEach(([key, value]) => {
        map.get(key)?.add(value);
      });
      return new Keyword(r, this);
    });

    this.tags = tags.map((tag) => new Tag(tag, Array.from(map.get(tag)), this));
    this.keywords.forEach((keyword) => keyword.keywordResult);
  }
  @action
  onNameChange = (e) => {
    this.name = e.target.value;
  }
  @action
  onReduceKeywordChange = (e) => {
    this.reduceKeyword = e.target.value;
  }
  @computed
  get canExport() {
    return this.keywords.every((keyword) => !keyword.keywordResult.hasError);
  }

  updateKeyowrd = (level, oldName, newName, level1, level2) => {
    if (level === 'level1') {
      this.keywords
        .forEach((k) => k.updateName(level, oldName, newName));
    } else if (level === 'level2') {
      this.keywords
        .filter((k) => k.level1 === level1)
        .forEach((k) => k.updateName(level, oldName, newName));
    } else {
      this.keywords
        .filter((k) => k.level1 === level1 && k.level2 === level2)
        .forEach((k) => k.updateName(level, oldName, newName));
    }
  }

  deleteKeyword = (level, name) => {
    Modal.confirm({
      title: `是否刪除 ${name}`,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        const newKeywords = this.keywords.filter((k) => k[level] !== name);
        runInAction(() => { this.keywords = newKeywords; });
      }
    });
  }

  @action
  deleteTag = (tagName) => {
    this.keywords.forEach((keyword) => {
      delete keyword.tags[tagName];
    });
    this.tags = this.tags.filter((tag) => tag.fullName !== tagName);
  }
  @action
  updateTag = (oldName, newName) => {
    this.keywords.forEach((keyword) => {
      const value = keyword.tags[oldName];
      delete keyword.tags[oldName];
      keyword.tags[newName] = value;
    });
  }
  @action
  deleteSubTag = (tagKey, tagValue) => {
    this.keywords.forEach((keyword) => {
      if (keyword.tags[tagKey] && keyword.tags[tagKey] === tagValue) {
        keyword.tags[tagKey] = '其他';
      }
    });
  }
  @action
  updateSubTag = (oldName, newName, tagKey) => {
    this.keywords.forEach((keyword) => {
      if (keyword.tags[tagKey] && keyword.tags[tagKey] === oldName) {
        keyword.tags[tagKey] = newName;
      }
    });
  }
  @computed
  get L1Keywords() {
    let keywords;

    if (!this.L1QueryData) {
      keywords = this.keywords.filter((k) => k.level === 'level1');

    } else {
      keywords = this.keywords.filter((k) => {
        const { isError, keyword } = this.L1QueryData;
        const _isError = isError ? k.keywordResult.hasError : true;
        const _keyword = keyword ? (k.synonym.includes(keyword) || k.level1.includes(keyword)) : true;
        return k.level === 'level1' && _isError && _keyword;
      });
    }

    if (this.L1Order === NAME_ORDER.ASC) return keywords;
    return keywords.reverse();
  }
  @computed
  get L2Keywords() {
    let keywords;

    if (!this.L2QueryData) {
      keywords = this.keywords.filter((k) => k.level === 'level2');

    } else {
      keywords = this.keywords.filter((k) => {
        const { isError, keyword, sids, tagKeyId, tagValueId } = this.L2QueryData;
        const _isError = isError ? k.keywordResult.hasError : true;
        const _keyword = keyword ? (k.synonym.includes(keyword) || k.level2.includes(keyword)) : true;
        const _sids = (sids?.length > 0) ? sids.includes(k.level1) : true;
        const _tag = tagKeyId ? k.tags[tagKeyId] === tagValueId : true;
        return k.level === 'level2' && _isError && _keyword && _sids && _tag;
      });
    }

    if (this.L2Order === NAME_ORDER.ASC) return keywords;
    return keywords.reverse();
  }
  @computed
  get L3Keywords() {
    let keywords;

    if (!this.L3QueryData) {
      keywords = this.keywords.filter((k) => k.level === 'level3');

    } else {
      keywords = this.keywords.filter((k) => {
        const { isError, keyword, sids, tagKeyId, tagValueId } = this.L3QueryData;
        const _isError = isError ? k.keywordResult.hasError : true;
        const _keyword = keyword ? (k.synonym.includes(keyword) || k.level3.includes(keyword)) : true;
        const _sids = (sids?.length > 0) ? (sids.includes(k.level1) || sids.includes(`${k.level1}\v${k.level2}`)) : true;
        const _tag = tagKeyId ? k.tags[tagKeyId] === tagValueId : true;
        return k.level === 'level3' && _isError && _keyword && _sids && _tag;
      });
    }

    if (this.L3Order === NAME_ORDER.ASC) return keywords;
    return keywords.reverse();
  }
  @computed
  get L2Tags() {
    return this.tags.filter((tag) => tag.scope !== 'l3');
  }
  @computed
  get L3Tags() {
    return this.tags.filter((tag) => tag.scope !== 'l2');
  }
  @computed
  get L1KeywordOption() {
    const keywords = this.keywords.filter((k) => k.level === 'level1');
    return keywords;
  }
  @computed
  get L2KeywordOption() {
    const keywords = this.keywords.filter((k) => k.level === 'level2');
    return keywords;
  }

  //  --------- edit drawer --------

  @action
  showEditDrawer = (targetKeyword, level) => {
    this.edit = new EditDrawer(this, targetKeyword, level);
  }
  @action
  hideEditDrawer = () => {
    this.edit = null;
  }

}
