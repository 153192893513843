import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { CHANNEL_SORT } from 'src/constants';

export default class BaseChannelList {

  projectId = '';

  sort = CHANNEL_SORT.DEFAULT;

  @observable _list = [];
  @observable filterCondition = null;
  @observable anchor = null;
  @observable selectedIdSet = new Set();
  @observable isLodaing = false;
  @observable isSelectAll = false;

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  static addChannelFrom = (target, source) => {
    const ids = Array.from(source.selectedIdSet);
    source.selectedIdSet.clear();
    target.add(ids);
  }

  getChannels = () => {}

  deleteChannels = () => {}

  add = () => {}

  reset = () => {
    runInAction(() => {
      this.anchor = null;
      this.filterCondition = null;
      this.isSelectAll = false;
      this.isLodaing = false;
      this._list = [];
    });
  }

  @computed
  get list() {
    return this._list;
  }

  @computed
  get hasNextPage() {
    return !!this.anchor;
  }

  @computed
  get isEmpty() {
    return this._list.length === 0;
  }

  @computed
  get noSeletedIds() {
    return Array.from(this.selectedIdSet).length === 0;
  }

  @action
  channelSelect = () => {
    this.selectedIdSet.clear();
    this.isSelectAll = false;
  }

  toggleSelectAll = () => {
    runInAction(() => { this.isSelectAll = !this.isSelectAll; });

    if (this.isSelectAll) {
      this.list.forEach((channel) => { channel.toggleIsSelected(true); });

    } else {
      this.list.forEach((channel) => { channel.toggleIsSelected(false); });
    }
  }

  queryNextPage = () => {
    if (this.hasNextPage) {
      this.getChannels();
    }
  }

  @action
  filterList = (ids) => {
    // ids is a Set instance
    this._list = this._list.filter((channel) => !ids.has(channel.channelId));
  }

  onSortChange = (direction) => {
    switch (direction) {
      case 'ascend':
        this.sort = CHANNEL_SORT.ASC;
        break;

      case 'descend':
        this.sort = CHANNEL_SORT.DESC;
        break;

      default:
        this.sort = CHANNEL_SORT.DEFAULT;
    }

    runInAction(() => {
      this.anchor = null;
      this.isSelectAll = false;
      this.isLodaing = false;
      this._list = [];
    });
    this.getChannels();
  }

}

