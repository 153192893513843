/* eslint-disable no-param-reassign */
import { message, Modal } from 'antd';
import { makeObservable, observable, action, runInAction } from 'mobx';
import dayjs from 'dayjs';
import ProjectService from 'src/services/project';
import ProjectBackTrackService from 'src/services/projectBackTrack';
import ProjectScheduleService from 'src/services/projectSchedule';
import ChannelSetService from 'src/services/channelSet';
import SearchSetService from 'src/services/searchSet';
import { errorModal } from 'src/utils';

export default class TabPane {
  projectId = '';
  featureOnly = true;
  close = null;
  project = null;

  @observable data = {
    startDate: null,
    endDate: null
  }
  @observable confirmInput = '';
  @observable isLoading = false;
  @observable isProcessingBacktrack = false;


  constructor({ projectId, featureOnly, close, askDate, refresh }) {
    makeObservable(this);
    this.projectId = projectId;
    this.featureOnly = featureOnly;
    this.close = close;
    this.askDate = askDate;
    this.refresh = refresh;
  }

  @action
  confirmInputChange = (e) => {
    this.confirmInput = e.target.value;
  }


  getProject = async () => {
    if (this.isLoading) return;

    try {
      runInAction(() => { this.isLoading = true; });
      const res = await ProjectService.getProject(this.projectId);
      runInAction(() => {
        this.project = res;
        this.isLoading = false;
      });

    } catch (err) {
      errorModal('發生錯誤，無法取得資訊');
      throw err;
    }
  }


  @action
  reset = () => {
    this.data.startDate = null;
    this.data.endDate = null;
  }

  @action
  onDateChange = (date) => {
    if (!date) {
      this.data.startDate = null;
      this.data.endDate = null;
    } else {
      this.data.startDate = date[0];
      this.data.endDate = date[1];
    }
  }


  onBackTrack = async () => {
    try {
      const data = {
        startDate: this.data.startDate?.startOf('day')?.toISOString() ?? undefined,
        finishDate: this.data.endDate?.endOf('day')?.toISOString() ?? undefined,
        featureOnly: this.featureOnly
      };
      await ProjectBackTrackService.create(this.projectId, data);
      message.success('已經設定進行回溯工作');

    } catch (err) {

      switch (err.response?.status) {
        case 400:
          errorModal(err.response?.data?.message ?? '無法回溯');
          break;

        default:
          errorModal(
            '開始回溯失敗！',
            '此專案有一個以上的資料分析工作正在進行，或尚未完全停止運行，請稍後再嘗試執行手動回溯工作。'
          );
      }

      throw err;

    } finally {
      // refresh list after drawer close
      this.close();
      this.refresh();
    }
  }

  askDisable = () => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: '是否要重新安排資料分析工作？',
        content: '因專案設定有停用狀況，回溯將影響專案現存口碑結果，確定要回溯嗎？',
        okText: '執行',
        cancelText: '取消',
        onCancel: () => reject(),
        onOk: () => resolve()
      });
    });
  }

  checkStatus = async () => {
    try {

      if (!this.data.startDate || !this.data.endDate) {
        return errorModal('請輸入日期');
      }

      runInAction(() => { this.isProcessingBacktrack = true; });

      await this.getProject();

      let needToUpdateProject = false;
      const projectStartDate = dayjs(this.project.autorun.startDate).valueOf();
      const projectEndDate = dayjs(this.project.autorun.endDate).valueOf();
      const backTrackStartDate = dayjs(this.data.startDate.startOf('day')).valueOf();
      const backTrackEndDate = dayjs(this.data.endDate.endOf('day')).valueOf();
      const isEarlier = backTrackStartDate < projectStartDate;
      const isLater = backTrackEndDate > projectEndDate;

      // 1. 回溯日期是否早於專案起訖區間
      if (isEarlier || isLater) {
        await this.askDate(this);
        needToUpdateProject = true;
      }

      // 2. 專案有無停用狀況
      const endDateString = dayjs(this.data.endDate.endOf('day')).toISOString();
      const hasDisabledChannel = await ChannelSetService.hasDisableItem(this.projectId, endDateString);
      const hasDisabledSearchKeyword = await SearchSetService.hasDisableItem(this.projectId, endDateString);
      if (hasDisabledChannel || hasDisabledSearchKeyword) {
        await this.askDisable();
      }

      // 3. 呼叫回溯
      await this.onBackTrack();

      // 4. 若回溯時間早於專案開始時間 或 晚於專案結束時間，更新專案起訖區間
      if (needToUpdateProject) {
        const startDate = isEarlier ? backTrackStartDate : projectStartDate;
        const endDate = isLater ? backTrackEndDate : projectEndDate;

        await ProjectScheduleService.update(this.projectId,
          {
            ...this.project.autorun,
            autoRun: this.project.autorun.enable,
            startDate: dayjs(startDate).toISOString(),
            endDate: dayjs(endDate).toISOString()
          });
      }

    } catch {
      // ignore
    } finally {
      runInAction(() => { this.isProcessingBacktrack = false; });
    }
    return null;
  }

}
