import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row, Button, Select, Input, Form, Drawer } from 'antd';
import SkipKeywordBtn from 'src/components/search/SkipKeywordBtn';
import { TEXT } from './constants';

import styles from './styles.module.css';

const EditDrawer = observer((props) => {
  const [text, setText] = useState('');

  useEffect(() => {
    switch (props.vm.level) {
      case 1:
        setText(TEXT[props.searchAngle]?.L1);
        break;
      case 2:
        setText(TEXT[props.searchAngle]?.L2);
        break;
      case 3:
        setText(TEXT[props.searchAngle]?.L3);
        break;
      default:
        setText('');
    }
  }, [props.vm.level, props.searchAngle]);

  const footer = () => (
    <>
      {props.vm.isEdit
        ? (
          <Button
            type="primary"
            disabled={props.vm.hasError}
            onClick={props.vm.onSave}
          >
            儲存
          </Button>
        )
        : <Button type="primary" onClick={props.vm.onEdit}>編輯</Button>}
    </>
  );

  return (
    <Drawer
      title="檢視與編輯搜尋關鍵字"
      width={600}
      onClose={props.vm.hide}
      visible={props.vm.isVisible}
      footer={footer()}
      footerStyle={{ display: 'flex', justifyContent: 'center' }}
      maskClosable={false}
    >
      <Form
        labelCol={{ span: 7 }}
        style={{
          padding: '0 10px',
          margin: 0
        }}
      >
        {(props.vm.level === 2 || props.vm.level === 3) && (
          <Form.Item label={`${TEXT[props.searchAngle]?.L1}名稱`}>
            <div>{props.keywordPool?.get(props.vm.targetKeyword?.sid)?.name ?? ''}</div>
          </Form.Item>
        )}

        {(props.vm.level === 3) && (
          <Form.Item label={`${TEXT[props.searchAngle]?.L2}名稱`}>
            <div>{props.keywordPool?.get(props.vm.targetKeyword?.subSid)?.name ?? '-'}</div>
          </Form.Item>
        )}

        <Form.Item
          label={`${text}名稱`}
          validateStatus={props.vm.name && !props.vm.isExist ? 'success' : 'error'}
          help={props.vm.helpTxt}
        >
          {props.vm.isEdit
            ? (
              <Input
                onChange={props.vm.onNameChange}
                value={props.vm.name}
              />
            )
            : (
              <div>{props.vm.name ?? ''}</div>
            )}
        </Form.Item>

        <Form.Item
          label={`${text}搜尋關鍵字`}
          validateStatus={props.vm.keywordResult.hasError ? 'error' : 'success'}
          help={(
            <div>
              {props.vm.keywordResult.reasons.map((r) => (
                <div key={r.id} className={styles[r.type]}>{r.message}</div>
              ))}
            </div>
          )}
        >
          {props.vm.isEdit
            ? (
              <>
                <Input.TextArea
                  rows={10}
                  onChange={props.vm.onKeywordChange}
                  value={props.vm.keyword}
                />
                <SkipKeywordBtn callback={props.vm.skipKeyword} />
              </>
            )
            : (
              <div>{props.vm.keyword ?? ''}</div>
            )}
        </Form.Item>

        {props.vm.tags && (
        <Form.Item label="標籤">
          {props.vm.tags.map((tagVM) => (
            <Row key={tagVM.tid} align="middle" style={{ marginBottom: 10 }}>
              <div>{`${tagVM.name}： `}</div>
              <Select
                value={tagVM.subTid}
                onSelect={tagVM.onSelect}
                style={{ width: '70%' }}
                disabled={!props.vm.isEdit}
                showSearch
                optionFilterProp="children"
              >
                {tagVM.subTags.map((subTag) => (
                  <Select.Option key={subTag.id} value={subTag.id}>
                    {subTag.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          ))}
        </Form.Item>
        )}

        <Form.Item label="同義詞">
          {props.vm.isEdit
            ? (
              <Input
                onChange={props.vm.onSynonymChange}
                value={props.vm.synonym}
              />
            )
            : (
              <div>{props.vm.synonym ?? ''}</div>
            )}
        </Form.Item>
      </Form>
    </Drawer>
  );
});

export default EditDrawer;
