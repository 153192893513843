import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ProjectService from 'src/services/project';

export default class ProjectReportsPage {
  projectId = '';

  @observable project = {};

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  didMount = async () => {
    try {
      const res = await ProjectService.getProject(this.projectId);
      runInAction(() => {
        this.project = res;
      });
    } catch {
      // ignore
    }
  }

}
