import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import Summary from 'src/components/channel/Summary';
import SearchBar from 'src/components/channel/SearchBar';
import commonColumns from 'src/components/channel/commonColumns';
import ChannelVM from 'src/viewModels/AllProjectsSettings/Channel';
import withRouter from 'src/components/withRouter';

@observer
class ChannelPane extends React.Component {

  constructor(props) {
    super(props);

    const projectId = this.props.projectId || this.props.router.params.id;

    this.vm = new ChannelVM(projectId);

  }

  componentDidMount() {
    this.vm.getList();
  }


  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 120px)' }}>
        <Summary
          projectId={this.props.projectId || this.props.router.params.id}
        />
        <SearchBar
          onQuery={this.vm.onSearch}
        />

        <div style={{ height: 'calc(100vh - 240px)' }}>
          <ReactResizeDetector handleHeight>
            {({ height = 0 }) => (
              <div
                style={{ height: '100%' }}
              >
                <Observer>
                  {() => (
                    <Spin
                      tip="正在加載資料"
                      spinning={this.vm.isLoading}
                    >
                      <Table
                        size="small"
                        rowKey="id"
                        onFetch={this.vm?.onBottom}
                        columns={commonColumns}
                        scroll={{ y: height, x: 1600 }}
                        dataSource={this.vm?.list ?? []}
                        lineCount={2}
                        onChange={(pagination, filters, sorter) => {
                          this.vm.onSortChange(sorter.order);
                        }}
                      />
                    </Spin>
                  )}

                </Observer>
              </div>
            )}
          </ReactResizeDetector>

        </div>
      </div>
    );
  }
}

ChannelPane.propTypes = {
  router: PropTypes.object.isRequired,
  projectId: PropTypes.string
};

ChannelPane.defaultProps = {
  projectId: ''
};

export default withRouter(ChannelPane);
