import { useState, useEffect } from 'react';
import { Modal, Input, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const ClearAllModal = (props) => {
  const [name, setName] = useState('');

  useEffect(() => {
    return function cleanup() {
      setName('');
    };
  }, []);

  return (
    <Modal
      maskClosable={false}
      closable={false}
      okText={props.actionTxt}
      cancelText="取消"
      visible={props.visible}
      title={(
        <div>
          <ExclamationCircleOutlined style={{ color: '#FB4747', marginRight: 10 }} />
          {`若要${props.actionTxt}現有設定，請輸入專案名稱`}
        </div>
      )}
      onOk={() => {
        if (props.projectName !== name) {
          return message.error('與專案名稱不符');
        }
        props.onOk();
        setName('');
        props.onCancel();
        return null;
      }}
      onCancel={() => {
        setName('');
        props.onCancel();
      }}
      okButtonProps={{
        disabled: !name,
        danger: true
      }}
    >
      <div style={{ marginBottom: 15 }}>
        {props.text}
      </div>
      <Input
        value={name}
        placeholder="請輸入"
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};

ClearAllModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  actionTxt: PropTypes.string
};

ClearAllModal.defaultProps = {
  actionTxt: '清空'
};


export default ClearAllModal;
