import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import ProjectReportService from 'src/services/projectReport';
import { errorModal } from 'src/utils';
import Query from './Query';

export default class Topics {
  projectId = '';
  LIMIT = 50;

  @observable currentTabKey = 'topic';

  @observable data = {
    startDate: null,
    endDate: null,
    keywords: [],
    featureFile: null
  };

  @observable rank = { // 專案全品牌及產品排行
    L1: [],
    L2: [],
    L3: []
  }

  @observable topTopics = { // 主題 TOP 100 話題
    data: [],
    searchId: '',
    isLoading: false
  };

  @observable chart = { // 主題口碑數每日折線圖
    data: [],
    rawData: [],
    isLoading: false
  }

  @observable topFeatures = { // Top100特性關鍵字
    data: [],
    isLoading: false
  };

  @observable featureTopics = { // 特性關鍵字話題列表
    meta: {
      categoryName: '',
      subCategoryName: '',
      keywordName: '',
      keywordId: ''
    },
    data: [],
    searchId: '',
    isVisible: false,
    isLoading: false,
    fid: '',
    hasNextPage: true,
    page: 2 // pagination start with page 2
  };

  @observable topicModal = { // 話題庫存頁面
    isVisible: false,
    isFeature: false,
    isLoading: false,
    mainId: '',
    main: {
      title: '',
      sentiment: 0,
      author: '',
      date: null,
      commentCount: 0,
      likeCount: 0,
      shareCount: 0,
      channelDisplayName: '',
      womCount: 0,
      replyCount: 0,
      womReplyCount: 0,
      content: '',
      negativeCount: 0,
      neutralCount: 0,
      positiveCount: 0,
      url: ''
    },
    reply: [],
    hasNextPage: true,
    replyPage: 1
  }

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
    this.query = new Query({ projectId, data: this.data });
  }

  didMount = async () => {
    try {
      Promise.all([
        this.query.getL1SearchKeywords(),
        this.query.getFeatures()
      ]);

    } catch {
      // ignore
    }
  }

  @computed
  get startDate() {
    return this.data.startDate.startOf('day').toISOString();
  }

  @computed
  get finishDate() {
    return this.data.endDate.endOf('day').toISOString();
  }

  onSearch = async () => {

    if (!this.data.startDate || !this.data.endDate) {
      return errorModal('請輸入日期');
    }

    await Promise.all([
      this.getRanks(),
      this.getTopTopics(),
      this.getChartData()
    ]);


    // get feature categories
    if (this.data.featureFile) {
      await this.query.getFeatureCategories(this.data.featureFile);
    } else {
      this.onTabChange('topic');
      this.query.resetFeatureCategories();
    }

    return null;
  }

  // 主題 TOP 100 話題
  getTopTopics = async () => {
    try {
      runInAction(() => {
        this.topTopics.isLoading = true;
      });

      const { result, searchId } = await ProjectReportService.topTopics(this.projectId, {
        keywords: this.data.keywords,
        startDate: this.startDate,
        finishDate: this.finishDate
      });

      runInAction(() => {
        this.topTopics.data = result;
        this.topTopics.searchId = searchId;
        this.topTopics.isLoading = false;
      });

    } catch {
      errorModal('發生錯誤，無法取得主題 TOP 100 話題');
      runInAction(() => { this.topTopics.isLoading = false; });
    }
  }

  // 主題口碑數每日折線圖
  getChartData = async () => {
    try {
      runInAction(() => {
        this.chart.isLoading = true;
      });

      const result = await ProjectReportService.chart(this.projectId, {
        keywords: this.data.keywords,
        startDate: this.startDate,
        finishDate: this.finishDate
      });

      const map = new Map();

      // transform data structure for LineChart
      result.forEach((item) => {
        item.data.forEach((data) => {
          if (map.has(data.date)) {
            map.get(data.date)[item.name] = data.count;
          } else {
            map.set(data.date, {
              date: data.date,
              [item.name]: data.count
            });
          }
        });
      });

      const data = Array.from(map)
        .map((item) => {
          const value = item[1];
          return { ...value, date: dayjs(value.date).format('YYYY-MM-DD') };
        })
        .sort((a, b) => {
          const dateA = a.date;
          const dateB = b.date;

          if (dateA < dateB) return -1;
          return 1;
        });

      runInAction(() => {
        this.chart.isLoading = false;
        this.chart.data = data;
        this.chart.rawData = result;
      });

    } catch {
      errorModal('發生錯誤，無法取得主題口碑數每日折線圖');
      runInAction(() => { this.chart.isLoading = false; });
    }
  }

  // Top 100 特性關鍵字
  getTopFeatures = async (categoryId, subCategoryId) => {

    try {
      runInAction(() => {
        this.topFeatures.isLoading = true;
      });

      const res = await ProjectReportService.topFeatures(this.projectId, {
        keywords: this.data.keywords,
        startDate: this.startDate,
        finishDate: this.finishDate,
        fid: this.data.featureFile,
        cid: categoryId || undefined,
        subCid: subCategoryId || undefined
      });

      runInAction(() => {
        this.topFeatures.data = res;
        this.topFeatures.isLoading = false;
      });

    } catch {
      errorModal('發生錯誤，無法取得Top100特性關鍵字');
      runInAction(() => { this.topFeatures.isLoading = false; });
    }
  }

  // 專案全品牌及產品排行
  getRanks = async () => {
    try {

      const [L1, L2, L3] = await Promise.all(
        Array
          .from({ length: 3 }, (v, i) => i)
          .map((level) => this.getOneRank(level + 1))
      );

      runInAction(() => {
        this.rank = { L1, L2, L3 };
      });

    } catch (err) {
      console.log(err);
      errorModal('發生錯誤，無法取得專案全品牌及產品排行');
    }
  }

  getOneRank = async (level) => {
    const res = await ProjectReportService.ranking(this.projectId, {
      startDate: this.startDate,
      finishDate: this.finishDate,
      level: level.toString()
    });
    return res;
  }

  showTopicModal = async (mainId, isFeature = false) => {
    runInAction(() => {
      this.topicModal.isVisible = true;
      this.topicModal.isFeature = isFeature;
      this.topicModal.mainId = mainId;
    });

    await Promise.all([
      this.getMain(),
      this.getReply()
    ]);
  }

  // 話題庫存頁面（主文）
  getMain = async () => {
    try {
      const resMian = await ProjectReportService.topicsMainContent({
        projectId: this.projectId,
        mainId: this.topicModal.mainId,
        searchId: this.topicModal.isFeature ? this.featureTopics.searchId : this.topTopics.searchId
      }, this.topicModal.isFeature);

      runInAction(() => {
        this.topicModal.main = resMian;
      });

    } catch {
      errorModal('發生錯誤，無法取得話題庫存資料');
    }
  }

  // 話題庫存頁面（回文）
  getReply = async () => {
    try {
      if (this.topicModal.isLoading || !this.topicModal.isVisible) return;

      runInAction(() => {
        this.topicModal.isLoading = true;
      });

      const resReply = await ProjectReportService.topicsReplyContent({
        projectId: this.projectId,
        mainId: this.topicModal.mainId,
        searchId: this.topicModal.isFeature ? this.featureTopics.searchId : this.topTopics.searchId,
        page: this.topicModal.replyPage,
        limit: this.LIMIT
      }, this.topicModal.isFeature);

      if (resReply.length < this.LIMIT) {
        runInAction(() => {
          this.topicModal.hasNextPage = false;
        });
      }

      runInAction(() => {
        this.topicModal.reply = [...this.topicModal.reply, ...resReply];
        this.topicModal.replyPage += 1;
        this.topicModal.isLoading = false;
      });

    } catch {
      runInAction(() => { this.topicModal.isLoading = false; });
      errorModal('發生錯誤，無法取得話題庫存回文資料');
    }
  }

  @action
  topicMadalOnBottom = () => {
    if (this.topicModal.hasNextPage) {
      this.getReply();
    }
  }

  @action
  hideTopicModal = () => {
    this.topicModal = {
      isVisible: false,
      isFeature: false,
      isLoading: false,
      mainId: '',
      main: {
        title: '',
        sentiment: 0,
        author: '',
        date: null,
        commentCount: 0,
        likeCount: 0,
        shareCount: 0,
        channelDisplayName: '',
        womCount: 0,
        replyCount: 0,
        womReplyCount: 0,
        content: '',
        negativeCount: 0,
        neutralCount: 0,
        positiveCount: 0,
        url: ''
      },
      reply: [],
      hasNextPage: true,
      replyPage: 1
    };
  }

  @action
  showFeatureTopicDrawer = (featureId) => {
    this.featureTopics.isVisible = true;
    this.getFeatureTopics(featureId);
  }

  getFeatureTopics = async (featureId) => {
    try {
      runInAction(() => {
        this.featureTopics.isLoading = true;
      });

      const { result, searchId, featureCategory, featureSubCategory, featureKeyword } = await ProjectReportService.featureTopics(
        this.projectId,
        {
          feature: featureId,
          keywords: this.data.keywords,
          startDate: this.startDate,
          finishDate: this.finishDate,
          limit: this.LIMIT
        }
      );

      runInAction(() => {
        this.featureTopics.data = result;
        this.featureTopics.searchId = searchId;
        this.featureTopics.meta = {
          categoryName: featureCategory?.name ?? '',
          subCategoryName: featureSubCategory?.name ?? '',
          keywordName: featureKeyword?.name ?? '',
          keywordId: featureKeyword?._id ?? ''
        };
        this.featureTopics.isLoading = false;
      });

    } catch {
      errorModal('發生錯誤，無法取得特性關鍵字話題列表');
      runInAction(() => { this.featureTopics.isLoading = false; });
    }
  }

  featureTopicOnBottom = async () => {
    if (this.featureTopics.isLoading
      || !this.featureTopics.hasNextPage
      || !this.featureTopics.isVisible) return;

    try {
      runInAction(() => {
        this.featureTopics.isLoading = true;
      });

      const result = await ProjectReportService.featureTopicsPaging(
        this.projectId,
        {
          searchId: this.featureTopics.searchId,
          page: this.featureTopics.page,
          limit: this.LIMIT
        }
      );

      if (result.length < this.LIMIT) {
        runInAction(() => {
          this.featureTopics.hasNextPage = false;
        });
      }

      runInAction(() => {
        this.featureTopics.data = [...this.featureTopics.data, ...result];
        this.featureTopics.page += 1;
        this.featureTopics.isLoading = false;
      });

    } catch {
      // ignore
      runInAction(() => { this.featureTopics.isLoading = false; });
    }

  }

  @action
  hideFeatureTopicDrawer = () => {
    this.featureTopics.isVisible = false;
    this.featureTopics.data = [];
    this.featureTopics.searchId = '';
    this.featureTopics.hasNextPage = true;
    this.featureTopics.page = 2;
    this.featureTopics.meta = {
      categoryName: '',
      subCategoryName: '',
      keywordName: '',
      keywordId: ''
    };
  }

  @action
  onTabChange = (newKey) => {
    this.currentTabKey = newKey;
  }


}
