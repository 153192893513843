import { Modal } from 'antd';
import axios from 'axios';
import Papa from 'papaparse';
import Config from 'src/config';

const numberFormat = new Intl.NumberFormat('en-IN');
const regionNamesInTraditionalChinese = new Intl.DisplayNames(['zh-Hant'], { type: 'region' });

export const noSearchKeyword = '這裡不搜不搜不搜';

export const noSearchCheck = (keyword) => {
  return keyword.includes('這裡不搜')
  || keyword.includes('不搜不搜')
  || keyword.includes('不搜部搜');
};

export async function request(options, preventToRedirect = false) {
  try {
    console.log(`[API] request: ${JSON.stringify(options, null, 2)}`);
    const result = await axios(options);
    console.log(`[API] status: ${result?.status}`);

    return result;
  } catch (err) {
    return new Promise((resolve, reject) => {
      if (err.response && err.response.status === 401 && !preventToRedirect) {
        window.location.href = '/login';
      } else {

        const status = err?.response?.status;
        // const data = err?.response?.data;
        const msg = err?.message ?? err;

        const log = [
          status ? `status: ${status}` : null,
          // data ? `data: ${JSON.stringify(data)}` : null,
          msg ? `msg: ${msg}` : null
        ]
          .filter((el) => !!el)
          .join(', ');

        console.log(`[API] error ${log}`);
        reject(err);
      }
    });
  }
}

export const getHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      return `${r[0]}:9000`;
      // TODO: updata url
    }
  }
  return '';
};

export const delay = (ms) => new Promise((r) => setTimeout(r, ms));

/**
 *
 * @param {string} value
 * @param {(string|RegExp)[]} patterns
 * @returns {boolean}
 */
export const some = (value, patterns = []) => {
  return patterns.some((p) => {
    const reg = Object.prototype.toString.call(value) === '[object String]' ? new RegExp(p) : p;
    return reg.test(value);
  });
};

/**
 * array 1D to 2D
 * @param {any[]} arr
 * @param {number} size group size
 * @returns
 */
export const group = (arr, size) => Array.from(
  { length: Math.ceil(arr.length / size) },
  (_, idx) => arr.slice(idx * size, (idx + 1) * size)
);


/**
 *
 * add thousands separator
 */
export const numberFormatter = (number) => {
  return numberFormat.format(number);
};


export const countryCodeToName = (countryCode) => {
  return regionNamesInTraditionalChinese.of(countryCode);
};


export const errorModal = (title, content = '') => {
  return Modal.error({
    title,
    content,
    okText: '確認'
  });
};


/**
 *
 * @param {Object[]} list
 * @param {string} fileName
 * @param {string} extraText
 */
export const downloadFile = (list, fileName = null, extraText) => {

  const downloadList = list.length === 0 ? [{ 尚無資料: '' }] : list;

  const csv = Papa.unparse(downloadList, Config.csv.export);
  const content = extraText ? `${extraText}\n${csv}` : csv;

  const element = document.createElement('a');
  const file = new Blob(['\ufeff', content], { type: 'text/csv' });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.csv` || 'export.csv';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};


/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject({ a: 1, b: { c: 2 } })
 * Returns:
 *   { a: 1, c: 2 }
 *
 * reference:
 * https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object/33037683#33037683
 */
export const flattenObject = (obj) => {
  const flattened = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};
