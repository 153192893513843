import { message, Modal } from 'antd';
import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ChannelSetService from 'src/services/channelSet';
import BaseChannelList from './BaseChannelList';
import ChannelItem from './ChannelItem';

export default class StoredChannelList extends BaseChannelList {

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  getChannels = async () => {
    try {
      if (this.isLodaing) return;
      runInAction(() => { this.isLodaing = true; });

      const { channels, anchor } = await ChannelSetService.getChannelOfProject(this.projectId, {
        ...this.filterCondition,
        sort: this.sort
      });
      const newChannels = channels.map((c) => new ChannelItem(c, this));

      runInAction(() => {
        this.anchor = anchor;
        this._list = [...this._list, ...newChannels];
        this.isLodaing = false;
      });

    } catch (err) {
      console.log(err);
      runInAction(() => { this.isLodaing = false; });
    }
  }


  add = async (ids) => {
    try {
      runInAction(() => { this.isLodaing = true; });
      await ChannelSetService.addChannel(this.projectId, ids);

    } catch (err) {
      switch (err.response?.status) {
        case 409:
          Modal.error({
            title: '頻道重複',
            okText: '確認'
          });
          break;

        default:
          Modal.error({ title: '發生錯誤', okText: '確認' });
      }
    } finally {
      runInAction(() => {
        this.anchor = null;
        this._list = [];
        this.isLodaing = false;
      });
      await this.getChannels();
    }

  }

  @action
  applyFilter = (newCondition) => {
    this.reset();
    this.filterCondition = newCondition;
    this.getChannels();
  }

  onButton = () => {
    if (this.anchor) this.getChannels();
  }

}
