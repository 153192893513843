import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { SEARCH_KEYWORD_TXT } from 'src/constants';
import Summary from 'src/components/search/Summary';
import withRouter from 'src/components/withRouter';
import SearchPaneVM from 'src/viewModels/AllProjectsSettings/Search';
import L1Pane from './L1Pane';
import L2Pane from './L2Pane';
import L3Pane from './L3Pane';
import TagPane from './TagPane';
import ViewDetailDrawer from './ViewDetailDrawer';

@observer
class SearchPane extends React.Component {

  constructor(props) {
    super(props);

    const projectId = this.props.projectId || this.props.router.params.id;

    this.vm = new SearchPaneVM(projectId);
  }

  componentDidMount() {
    this.vm.didMount();
  }


  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 120px)' }}>
        <Summary
          projectId={this.props.projectId}
          angle={this.vm.searchAngle}
        />

        <Tabs type="card">
          <Tabs.TabPane key={1} tab={SEARCH_KEYWORD_TXT[this.vm.searchAngle]?.L1 ?? ''}>
            <L1Pane vm={this.vm} projectId={this.props.projectId} />
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab={SEARCH_KEYWORD_TXT[this.vm.searchAngle]?.L2 ?? ''}>
            <L2Pane vm={this.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={3} tab={SEARCH_KEYWORD_TXT[this.vm.searchAngle]?.L3 ?? ''}>
            <L3Pane vm={this.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={4} tab="標籤">
            <TagPane vm={this.vm} />
          </Tabs.TabPane>
        </Tabs>


        <ViewDetailDrawer vm={this.vm} searchAngle={this.vm.searchAngle} />
      </div>
    );
  }
}

SearchPane.propTypes = {
  router: PropTypes.object.isRequired,
  projectId: PropTypes.string
};

SearchPane.defaultProps = {
  projectId: ''
};

export default withRouter(SearchPane);
