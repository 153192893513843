import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Input, Row, Select, DatePicker, Button, Spin, Col } from 'antd';
import { DownOutlined, UpOutlined, LinkOutlined } from '@ant-design/icons';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import { PROJECT_DOMAINS } from 'src/constants';
import EsVM from 'src/viewModels/Search/Es';

import styles from './styles.module.css';


@observer
class Es extends React.Component {

  constructor() {
    super();
    this.vm = new EsVM();
    this.column = [
      {
        title: <Row justify="center">發文ID</Row>,
        key: 'id',
        render: (data) => (<div className={styles.txt}>{data._id}</div>)
      },
      {
        title: <Row justify="center">時間</Row>,
        key: 'date',
        render: (data) => (<div>{dayjs(data.date).format('YYYY/MM/DD HH:mm:ss')}</div>)
      },
      {
        title: <Row justify="center">作者</Row>,
        key: 'author',
        render: (data) => (<div className={styles.txt}>{data.author}</div>)
      },
      {
        title: <Row justify="center">累積回文數</Row>,
        key: 'commentCount',
        width: 150,
        render: (data) => (<div>{data.commentCount}</div>)
      },
      {
        title: <Row justify="center">content</Row>,
        key: 'content',
        width: 450,
        render: (data) => (<div className={styles.txt}>{data.content}</div>)
      },
      {
        title: <Row justify="center">頻道</Row>,
        key: 'channelDisplayName',
        render: (data) => (<div className={styles.txt}>{data.channelDisplayName}</div>)
      },
      {
        title: <Row justify="center">6大類</Row>,
        key: 'channelCategory',
        width: 150,
        render: (data) => (<div>{data.channelCategory}</div>)
      },
      {
        title: <Row justify="center">連結</Row>,
        key: 'url',
        width: 100,
        render: (data) => (
          <Button
            href={data.url}
            type="link"
            target="_blank"
          >
            <LinkOutlined />
          </Button>
        )
      }
    ];
  }

  componentDidMount() {
    this.vm.didMount();
  }


  render() {
    return (
      <div className={styles.tab}>
        <div className={styles.box}>
          <div style={{ display: !this.vm.isExpand ? 'none' : 'inherit' }}>
            <div>
              搜尋關鍵字
              <span className={styles.red}>*</span>
              ：
            </div>
            <Input.TextArea
              rows={5}
              onChange={this.vm.onKeywordChange}
              value={this.vm.data.keyword}
              className={styles.mb}
            />

            <div className={styles.warnTxt}>
              {this.vm.keywordResult.reasons.map((r) => (
                <div key={r.id} className={styles[r.type]}>{r.message}</div>
              ))}
            </div>
          </div>

          <Row align="middle" className={styles.mb}>
            <div>
              資料起訖區間
              <span className={styles.red}>*</span>
              ：
            </div>
            <DatePicker.RangePicker
              onChange={this.vm.onDateChange}
              value={[this.vm.data.startDate, this.vm.data.endDate]}
            />
          </Row>



          <Row className={styles.mb}>
            <Col className={styles.title}>
              引用專案之頻道組合：
            </Col>


            <Col>
              <Row
                className={styles.mb}
                style={{ display: (!this.vm.isExpand && this.vm.data.inputProjectId) ? 'none' : 'inherit' }}
              >
                <Select
                  placeholder="搜尋或選擇負責人"
                  className={styles.select}
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  onChange={this.vm.onOwnerChange}
                  value={this.vm.data.ownerId}
                >
                  {this.vm.users.map((user) => (
                    <Select.Option value={user.id} key={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select>

                <Select
                  className={styles.select}
                  placeholder="搜尋或選擇所屬領域"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  onChange={this.vm.onDomainChange}
                  value={this.vm.data.domain}
                >
                  {PROJECT_DOMAINS.map((domain) => (
                    <Select.Option key={domain} value={domain}>
                      {domain}
                    </Select.Option>
                  ))}
                </Select>

                <Select
                  className={styles.select}
                  placeholder="搜尋或選擇專案名稱"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  onChange={this.vm.onSelectProjectIdChange}
                  value={this.vm.data.selectProjectId}
                >
                  {this.vm.projects.map((p) => (
                    <Select.Option value={p._id} key={p._id}>{p.meta.name}</Select.Option>
                  ))}
                </Select>
              </Row>



              <div
                style={{ display: !this.vm.isExpand ? 'none' : 'inherit' }}
                className={styles.mb}
              >
                或是
              </div>

              <Input
                placeholder="輸入專案ID"
                onChange={this.vm.onInputProjectIdChange}
                value={this.vm.data.inputProjectId}
                style={{ display: (!this.vm.isExpand && !this.vm.data.inputProjectId) ? 'none' : 'inherit' }}
                className={clsx([styles.input, styles.mb])}
              />


            </Col>

          </Row>





          <Row justify="end">
            <Button
              onClick={this.vm.reset}
              className={styles.mr}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              onClick={this.vm.onSearch}
              className={styles.mr}
              disabled={!this.vm.searchable}
            >
              套用搜尋
            </Button>
            <Button
              type="link"
              onClick={this.vm.toggleExpand}
            >
              {this.vm.isExpand
                ? (
                  <>
                    收起
                    <UpOutlined />
                  </>
                )
                : (
                  <>
                    展開
                    <DownOutlined />
                  </>
                )}
            </Button>
          </Row>
        </div>


        <div style={{ flex: 1, minHeight: 0 }}>
          <ReactResizeDetector handleHeight>
            {({ height = 0 }) => (
              <div
                style={{ height: '100%' }}
              >
                <Observer>
                  {() => (
                    <Spin
                      tip="正在加載資料"
                      spinning={this.vm.isLoading}
                    >
                      <Table
                        size="small"
                        rowKey="id"
                        onFetch={() => {}}
                        columns={this.column}
                        scroll={{ y: height, x: 1700 }}
                        dataSource={this.vm?.list ?? []}
                        lineCount={3}
                      />
                    </Spin>
                  )}

                </Observer>
              </div>
            )}
          </ReactResizeDetector>

        </div>
      </div>
    );
  }
}

export default Es;
