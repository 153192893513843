import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Button, Drawer, Empty } from 'antd';

import withRouter from 'src/components/withRouter';
import FeatureSetViewModel from 'src/viewModels/AllProjectsSettings/Feature';
import SubTabs from './SubTabs';

@observer
class FeatureSetPane extends React.Component {
  constructor(props) {
    super(props);
    const projectId = this.props.projectId || this.props.router.params.id;
    this.vm = new FeatureSetViewModel(projectId);
  }

  async componentDidMount() {
    await this.vm.getList();
    this.props.onFeatureTabChange(this.vm.subTabs[0].feature.fid);
  }

  render() {
    return (
      <>
        {this.vm.subTabs.length === 0 && <Empty style={{ marginTop: '30vh' }} />}
        <Tabs
          type="card"
          onChange={(fid) => this.props.onFeatureTabChange(fid)}
        >
          {this.vm.subTabs.map((subTab) => (
            <Tabs.TabPane key={subTab.feature.fid} tab={subTab.feature.name}>
              <SubTabs
                vm={subTab}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>

      </>
    );
  }
}

FeatureSetPane.propTypes = {
  router: PropTypes.objectOf(PropTypes.any),
  projectId: PropTypes.string,
  onFeatureTabChange: PropTypes.func
};

FeatureSetPane.defaultProps = {
  router: null,
  projectId: '',
  onFeatureTabChange: () => {}
};

export default withRouter(FeatureSetPane);
