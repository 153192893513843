import React, { useState, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Col, Input } from 'antd';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import SortButton from 'src/components/SortButton';
import ActionBar from 'src/components/search/L1ActionBar';
import { TEXT } from '../constants';

import styles from './styles.module.css';

const L1Pane = observer((props) => {
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    setColumns([
      {
        title: <Row justify="center">序號</Row>,
        width: 50,
        key: 'index',
        render: (_, __, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: (
          <Observer>
            {() => (
              <SortButton
                title={`${TEXT[props.vm.searchAngle]?.L1}名稱`}
                order={props.vm.L1Order}
                onOrderChange={props.vm.onL1OrderChange}
              />
            )}
          </Observer>
        ),
        width: 230,
        key: 'l1',
        render: (data) => (<Observer>{() => (<div className={styles.txt}>{data.name ?? ''}</div>)}</Observer>)
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L1}搜尋關鍵字`}
          </Row>
        ),
        key: 'keyword',
        render: (data) => (
          <Observer>
            {() => (
              <Tooltip
                title={data.keywordResult.reasons.length > 0 && (
                <div>
                  {data.keywordResult.reasons.map((r) => (
                    <div key={r.id} className={styles[r.type]}>{r.message}</div>
                  ))}
                </div>
                )}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  {data.keywordResult.hasError && <ExclamationCircleOutlined className={styles.exclamationIcon} />}
                  {data.keywordResult.hasWaran && <WarningOutlined className={styles.warningIcon} />}
                  <div className={styles.txt}>{data.keyword ?? ''}</div>
                </div>
              </Tooltip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">同義詞</Row>,
        key: 'synonym',
        render: (data) => (<Observer>{() => (<div className={styles.txt}>{data.synonym ?? '-'}</div>)}</Observer>
        )
      },
      {
        title: <Row justify="center">執行動作</Row>,
        width: 150,
        key: 'action',
        render: (data) => (
          <>
            <Button type="link" onClick={() => props.vm.showEditDrawer(data, 1)}>編輯</Button>
            <Button type="link" onClick={() => data.delete()}>刪除</Button>
          </>
        )
      }
    ]);
  }, [props.vm.searchAngle, props.vm]);
  return (
    <>
      <Row className={styles.marginBottom}>
        <Col flex={1} className={styles.marginRight}>
          <Row align="middle" className={styles.border} wrap={false}>
            <div className={styles.padding} style={{ width: '18ch' }}>分析主題名稱</div>
            <Input
              onChange={props.vm.onNameChange}
              value={props.vm.name}
            />
          </Row>
        </Col>

        <Col flex={2} className={styles.marginRight}>
          <Row align="middle" className={styles.border} wrap={false}>
            <div className={styles.padding} style={{ width: '26ch' }}>{`${TEXT[props.vm.searchAngle]?.L1}縮限搜尋關鍵字`}</div>
            <Input
              onChange={props.vm.onReduceKeywordChange}
              value={props.vm.reduceKeyword}
            />
          </Row>
        </Col>
      </Row>

      <ActionBar
        onQuery={props.vm.setL1QueryData}
        mode="importDrawer"
      />


      {columns && (
      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <div style={{ width: '100%', height: 'calc(100vh - 340px)' }}>
                <Table
                  tableKey={props.vm.L1TableKey}
                  onFetch={() => {}}
                  lineCount={2}
                  fixedWidthColCount={1}
                  dataSource={props.vm.L1Keywords}
                  columns={columns}
                  rowKey="id"
                  size="small"
                  pagination={false}
                  scroll={{
                    y: height
                  }}
                />
              </div>
            )}
          </Observer>
        )}
      </ReactResizeDetector>
      )}

    </>
  );
});

export default L1Pane;
