import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

export default class CategoryVM {

  // id equals to its name
  @observable id = '';
  @observable name = '';
  @observable subCategory = '';


  constructor(id, subCategory, level) {
    makeObservable(this);
    runInAction(() => {
      this.id = id;
      this.name = id;
      this.subCategory = subCategory?.map((sub) => new CategoryVM(sub, null, 2)) ?? null;
      if (level === 1) {
        this.cid = id;
      } else {
        this.subCid = id;
      }
    });
  }

  @action
  update = (name) => {
    this.name = name;
  }

  @action
  updateSubCategory = (name, subCid) => {
    this.subCategory.forEach((subCategory) => {
      if (subCategory.id === subCid) {
        subCategory.update(name);
      }
    });
  }
}
