import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ChangeLogService from 'src/services/changeLog';
import GetListVM from 'src/viewModels/components/GetListVM';
import LogItem from './LogItem';
import ModalViewModel from '../modal';

// for 異動紀錄 ＆ 引用通知
// ref = null    異動紀錄
// ref = 'myRef' 我引用別人
// ref = 'refMe' 別人引用我

export default class ProjectChangeLogVM {
  id = '';
  ref = null;

  @observable summary = [];
  @observable summaryStartDate = null;
  @observable summaryEndDate = null;

  channelSet = null;
  searchSet = null;
  featureSet = null;

  modal = new ModalViewModel();

  constructor(id, ref) {
    makeObservable(this);
    this.id = id;
    this.ref = ref;

    this.channelSet = new GetListVM(
      async (data) => {
        const { logs, anchor } = await ChangeLogService.getChangeLog(this.id, {
          ...data,
          ref: this.ref,
          type: 'channelSet'
        });
        const list = logs.map((log) => new LogItem(log, this.id));
        return { list, anchor };
      }
    );
    this.searchSet = new GetListVM(
      async (data) => {
        const { logs, anchor } = await ChangeLogService.getChangeLog(this.id, {
          ...data,
          ref: this.ref,
          type: 'searchSet'
        });
        const list = logs.map((log) => new LogItem(log, this.id));
        return { list, anchor };
      }
    );
    this.featureSet = new GetListVM(
      async (data) => {
        const { logs, anchor } = await ChangeLogService.getChangeLog(this.id, {
          ...data,
          ref: this.ref,
          type: 'featureSet'
        });
        const list = logs.map((log) => new LogItem(log, this.id));
        return { list, anchor };
      }
    );
  }

  didMount = () => {
    Promise.all([
      this.getSummary(),
      this.channelSet.getList(),
      this.searchSet.getList(),
      this.featureSet.getList()
    ]);
  }

  getSummary = async () => {
    try {
      const res = await ChangeLogService.getChangeLogSummary(this.id, {
        ref: this.ref,
        startDate: this.summaryStartDate?.toISOString() || undefined,
        endDate: this.summaryEndDate?.toISOString() || undefined
      });

      runInAction(() => { this.summary = res; });
    } catch (err) {
      console.log(err);
    }
  }

  @action
  onDateChange = (date) => {
    this.summaryStartDate = date?.[0]?.startOf('day') ?? null;
    this.summaryEndDate = date?.[1]?.endOf('day') ?? null;
    this.getSummary();
  }
}
