import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message } from 'antd';

import { errorModal } from 'src/utils';
import FeatureSetService from 'src/services/featureSet';
import DrawerVM from './DrawerVM';
import DeleteSOP from './DeleteSOP';

export default class SubTabsViewModel {

  projectId = null;
  featureId = null;
  originalFeature = null;
  onUpdateInfo = () => {};
  onDeleteFeatureFile = () => {};
  lastQueryData = null;
  @observable feature = {
    name: '',
    note: '',
    level: 2
  };
  @observable isEdit = false;
  @observable category = [];
  @observable keyword = [];
  @observable filteredKeyword = null;
  @observable filteredCategory = null;
  @observable selectedCategoryKey = [];
  @observable newTag = null;
  @observable importDrawer = null;
  @observable isProcessingData = false;
  @observable clearAllModalVisible = false;
  @observable deleteModalVisible = false;
  deleteSOP = null;

  constructor({ feature, onUpdateInfo, onDeleteFeatureFile }) {
    makeObservable(this);
    this.projectId = feature.projectId;
    this.featureId = feature.fid;
    this.originalFeature = feature;
    this.onUpdateInfo = onUpdateInfo;
    this.onDeleteFeatureFile = onDeleteFeatureFile;
    this.deleteSOP = new DeleteSOP(feature.projectId);
  }

  @computed
  get columnCount() {
    if (this.feature.level === 2) {
      return this.filteredCategory ? this.filteredCategory.length : this.category.length;
    }
    let count = 0;
    if (this.filteredCategory) {
      this.filteredCategory.forEach((c) => { count += c.subCategory.length; });
    } else {
      this.category.forEach((c) => { count += c.subCategory.length; });
    }
    return count;
  }

  @computed
  get tableData() {
    const array = [];
    const map = new Map();
    const category = this.filteredCategory || this.category;
    const keywords = this.filteredKeyword || this.keyword;

    if (this.feature.level === 3) {
      // set map keys with subcategoryId
      category.forEach((c) => {
        c.subCategory.forEach((subc) => { map.set(subc.id, []); });
      });
      // add data to the array according to subcategoryId
      keywords.forEach((data) => { map.get(data.subCid)?.push(data); });
    }

    if (this.feature.level === 2) {
      // set map keys with categoryId
      category.forEach((c) => { map.set(c.cid, []); });
      // add data to the array according to categoryId
      keywords.forEach((data) => { map.get(data.cid)?.push(data); });
    }

    // to get array of arrays grouping by subcategoryId or categoryId
    map.forEach((value, key) => array.push(value));


    let maxRowCount = 0;
    array.forEach((a) => {
      if (a.length > maxRowCount) { maxRowCount = a.length; }
    });
    return {
      array,
      maxRowCount
    };
  }

  onRef = async (data) => {
    try {
      const { refProjectId, refFid } = data;
      const refProjectTips = await FeatureSetService.getTip(refProjectId, refFid);

      if (refProjectTips.keywordCount === 0) {
        errorModal('特性關鍵字檔案內容為空，無法引用');
        return;
      }

      await FeatureSetService.ref(this.projectId, this.featureId, data);
      this.queryFeatureDetail();

      runInAction(() => {
        this.feature.refProject = refProjectId;
      });

    } catch {
      errorModal('發生錯誤，無法引用公版專案');
    }
  }


  @action
  showImportDrawer = (json) => {
    this.importDrawer = new DrawerVM(this.feature);
    this.importDrawer.getUploadData(json);
  }
  @action
  closeImportDrawer = () => {
    this.importDrawer = null;
  }

  clearFeature = async () => {
    try {
      await FeatureSetService.clearAllFeatureSet(this.projectId, this.featureId);
      this.queryFeatureDetail();

    } catch {
      errorModal('發生錯誤，無法清空現有設定');
    }
  }

  deleteFeature = async () => {
    try {
      await FeatureSetService.removeFeatureSet(this.projectId, this.featureId);
      this.onDeleteFeatureFile(this.featureId);
    } catch {
      errorModal('發生錯誤，無法刪除檔案');
    }
  }

  onSave = async () => {
    // no change
    if (this.originalFeature.name === this.feature.name && this.originalFeature.note === this.feature.note) {
      return runInAction(() => { this.isEdit = false; });
    }

    if (!this.feature.name) return message.error('請輸入檔案名稱');

    try {
      await FeatureSetService.updateFeatureSet(this.projectId, this.featureId, { name: this.feature.name, note: this.feature.note });
      this.onUpdateInfo(this.featureId, { name: this.feature.name, note: this.feature.note });

      // renew originalFeature
      this.originalFeature = { ...this.originalFeature, name: this.feature.name, note: this.feature.note };

    } catch {
      errorModal('發生錯誤，無法更新特性關鍵字基本資料');
      runInAction(() => {
        this.feature.name = this.originalFeature.name;
        this.feature.note = this.originalFeature.note;
      });
    } finally {
      runInAction(() => { this.isEdit = false; });

    }
    return null;
  }

  queryAllCatogory = async () => {
    try {
      const category = await FeatureSetService.queryAllCategory(this.projectId, this.featureId);
      runInAction(() => { this.category = category; });
      this.resetFilter();
    } catch (err) {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  queryFeatureDetail = async () => {
    try {
      const { featureSet, category, keyword } = await FeatureSetService.queryFeatureSetDetail(this.projectId, this.featureId);
      runInAction(() => {
        this.feature = featureSet;
        this.category = category;
        this.keyword = keyword;
      });
      this.resetFilter();
    } catch (err) {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  createCategory = async (name) => {
    try {
      const data = { names: [name] };
      await FeatureSetService.createCategory(this.projectId, this.featureId, data);
      this.resetCategoryInput();
      this.queryAllCatogory();
    } catch (err) {
      if (err.response.status === 409) {
        errorModal('名稱重複，無法新增群組');
      } else {
        errorModal('發生錯誤，無法新增群組');
      }
    }
  }

  deleteCategory = async () => {
    if (this.selectedCategoryKey.length === 0) {
      message.warn('請先勾選再使用刪除功能');
      return;
    }

    this.deleteSOP.start(this.selectedCategoryKey, async () => {
      try {
        await Promise.all(
          this.selectedCategoryKey.map((cid) => FeatureSetService.deleteCategory(this.projectId, this.featureId, cid))
        );
        this.queryFeatureDetail();
        runInAction(() => { this.selectedCategoryKey = []; });
      } catch (err) {
        errorModal('發生錯誤，無法刪除群組');
      }
    });
  }

  updateCategory = async (cid, name) => {
    try {
      await FeatureSetService.updateCategory(this.projectId, this.featureId, cid, { name });
      this.queryAllCatogory();
    } catch (err) {
      if (err.response.status === 409) {
        errorModal('名稱重複，無法更新群組');
      } else {
        errorModal('發生錯誤，無法更新群組');
      }
      throw err;
    }
  }

  createSubCategory = async (cid, name) => {
    try {
      const data = {
        names: [name],
        parentId: cid
      };
      await FeatureSetService.createCategory(this.projectId, this.featureId, data);
      this.queryAllCatogory();
    } catch (err) {
      if (err.response.status === 409) {
        errorModal('名稱重複，無法新增子群組');
      } else {
        errorModal('發生錯誤，無法新增子群組');
      }
    }
  }

  updateSubCategory = async (cid, subCid, name) => {
    try {
      await FeatureSetService.updateSubCategory(this.projectId, this.featureId, cid, subCid, { name });
      this.queryAllCatogory();
    } catch (err) {
      if (err.response.status === 409) {
        errorModal('名稱重複，無法更新子群組');
      } else {
        errorModal('發生錯誤，無法更新子群組');
      }
    }
  }

  deleteSubCategory = async (cid, subCid) => {
    await this.deleteSOP.start([subCid], async () => {
      try {
        await FeatureSetService.deleteSubCategory(this.projectId, this.featureId, cid, subCid);
        this.queryFeatureDetail();
      } catch (err) {
        errorModal('發生錯誤，無法刪除子群組');
      }
    }, true);
  }

  createKeyword = async (data) => {
    try {
      const { cid, subCid, name, keyword } = data;
      const dataToPass = { keywords: [{ name, keyword }], cid, subCid };

      const keywordArray = await FeatureSetService.createKeyword(this.projectId, this.featureId, dataToPass);
      runInAction(() => { this.keyword.unshift(keywordArray[0]); });
      if (this.lastQueryData) this.onSearch(this.lastQueryData);

      message.success('新增成功');

    } catch (err) {
      if (err.response.status === 409) {
        errorModal('名稱重複，無法新增關鍵字');
      } else {
        errorModal('發生錯誤，無法新增關鍵字');
      }
    }
  }

  updateKeyword = async (data) => {
    try {
      const { cid, kid, subCid, name, keyword } = data;

      if (this.feature.level === 2) {
        await FeatureSetService.updateKeyword(this.projectId, this.featureId, cid, kid, { name, keyword });
      } else {
        await FeatureSetService.updateKeywordWithSub(this.projectId, this.featureId, cid, subCid, kid, { name, keyword });
      }

      runInAction(() => {
        for (let index = 0; index < this.keyword.length; index += 1) {
          const k = this.keyword[index];
          if (k.kid === data.kid) {
            this.keyword[index] = { ...k, name: data.name, keyword: data.keyword };
            break;
          }
        }
      });
      if (this.lastQueryData) this.onSearch(this.lastQueryData);
    } catch (err) {
      if (err.response.status === 409) {
        errorModal('名稱重複，無法更新關鍵字');
      } else {
        errorModal('發生錯誤，無法更新關鍵字');
      }
    }
  }

  deleteKeyword = async (data) => {
    this.deleteSOP.start([data], async () => {
      try {
        const { cid, kid, subCid } = data;

        if (this.feature.level === 2) {
          await FeatureSetService.deleteKeyword(this.projectId, this.featureId, cid, kid);
        } else {
          await FeatureSetService.deleteKeywordWithSub(this.projectId, this.featureId, cid, subCid, kid);
        }

        runInAction(() => {
          const index = this.keyword.findIndex((k) => (k.kid === kid));
          this.keyword.splice(index, 1);
        });
        if (this.lastQueryData) this.onSearch(this.lastQueryData);
      } catch (err) {
        errorModal('發生錯誤，無法刪除關鍵字');
      }
    });
  }

  @action
  onSearch = (data) => {
    if (!data.keyword && !data.cid && !data.subCid) {
      this.filteredKeyword = null;
      this.filteredCategory = null;
      this.lastQueryData = null;
      return;
    }

    this.lastQueryData = data;

    this.filteredKeyword = this.keyword.filter((k) => {
      const searchTxt = data.keyword
        ? (k.keyword.includes(data.keyword) || k.kid.includes(data.keyword) || k.name.includes(data.keyword))
        : true;
      const category = data.cid ? (k.cid === data.cid) : true;
      const subCategory = data.subCid ? (k.subCid === data.subCid) : true;
      return searchTxt && category && subCategory;
    });

    // keep categories that contain filtered keyword
    const cidSet = new Set();
    const subCidSet = new Set();
    this.filteredKeyword.forEach((k) => {
      cidSet.add(k.cid);
      subCidSet.add(k.subCid);
    });

    if (this.feature.level === 2) {
      this.filteredCategory = data.cid
        ? this.category.filter((c) => cidSet.has(c.cid) || c.cid === data.cid)
        : this.category.filter((c) => cidSet.has(c.cid));

    } else {
      // this.feature.level === 3
      const array = data.cid
        ? this.category.filter((c) => cidSet.has(c.cid) || c.cid === data.cid)
        : this.category.filter((c) => cidSet.has(c.cid));

      if (data.subCid) {
        this.filteredCategory = array.map((c) => (
          { ...c, subCategory: c.subCategory.filter((sub) => sub.id === data.subCid || subCidSet.has(sub.id)) }
        ));

      } else if (data.keyword) {
        this.filteredCategory = array.map((c) => (
          { ...c, subCategory: c.subCategory.filter((sub) => subCidSet.has(sub.id)) }
        ));

      } else {
        this.filteredCategory = array;
      }
    }
  }

  @action
  resetFilter = () => {
    this.filteredCategory = null;
    this.filteredKeyword = null;
    this.lastQueryData = null;
  }

  @action
  onUpload = async (file) => {
    runInAction(() => { this.isProcessingData = true; });

    try {
      const json = await FeatureSetService.readUploadedFile(file, this.feature.level);
      runInAction(() => {
        this.showImportDrawer(json);
        this.isProcessingData = false;
      });

    } catch (e) {
      console.log(e);
      errorModal('匯入失敗', e.message);
      runInAction(() => { this.isProcessingData = false; });
    }
  }

  onImport = async () => {
    try {
      const { name, note } = this.importDrawer.feature;
      if (!name) return message.error('請輸入檔案名稱');

      runInAction(() => { this.isProcessingData = true; });

      const rows = this.importDrawer.keyword.map((keyword) => ({
        category: keyword.category,
        subCategory: keyword.subCategory,
        name: keyword.name,
        keyword: keyword.keyword
      }));

      // if name or note have change, update them
      if ((name !== this.originalFeature.name) || (note !== this.originalFeature.note)) {
        await FeatureSetService.updateFeatureSet(this.projectId, this.featureId, { name, note });
        this.onUpdateInfo(this.featureId, { name, note });
      }

      this.closeImportDrawer();

      // import and update
      await FeatureSetService.importFeatureSet(this.projectId, this.featureId, rows);
      runInAction(() => { this.isProcessingData = false; });
      this.queryFeatureDetail();

      message.success('匯入成功！');

    } catch (e) {
      errorModal('上傳失敗', e.message);
      runInAction(() => { this.isProcessingData = false; });
    }

    return null;
  }

  @action
  onDrawerClose = () => { this.importDrawer.closeDrawer(); }

  @action
  onEdit = () => { this.isEdit = true; }

  @action
  onNameChange = (e) => { this.feature = { ...this.feature, name: e.target.value }; }

  @action
  onNoteChange = (e) => { this.feature = { ...this.feature, note: e.target.value }; }

  @action
  resetCategoryInput = (e) => { this.categoryInput = null; }

  @action
  onCategprySelectChange = (selectedRowKeys) => { this.selectedCategoryKey = selectedRowKeys; }

  @action
  toggleClearAllModal = (bool) => { this.clearAllModalVisible = bool; }

  @action
  toggleDeleteModal = (bool) => { this.deleteModalVisible = bool; }

  @computed
  get canRef() {
    const result = (this.category.length === 0) && !this.feature.refProject;

    return !!result;
  }
}
