import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Select, Button } from 'antd';
import TeamService from 'src/services/team';
import { ACCOUNT_STATUS, USER_PERMISSION, USER_STATUS, CUSTOMER_DEPARTMENT_ID, NO_DEPARTMENT_ID } from 'src/constants';

import styles from './styles.module.css';

const ActionBar = (props) => {
  const [form] = Form.useForm();
  const [team, setTeam] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      (async () => {
        try {
          const res = await TeamService.get();
          const teams = res.filter((d) => d.id !== NO_DEPARTMENT_ID && d.id !== CUSTOMER_DEPARTMENT_ID);
          setTeam(teams);
        } catch (err) {
          console.log(err);
        }
      })();
    }

    return () => { mounted = false; };
  }, []);

  const onReset = () => {
    form.resetFields();
  };

  const onQuery = () => {
    const data = form.getFieldsValue();
    props.onQuery(data);
  };

  return (
    <div className={styles.box}>
      <Form form={form}>
        <Row wrap={false}>
          <Col style={{ marginRight: 30 }} flex={1}>
            <div>搜尋</div>
            <Form.Item name="keyword" className={styles.mb}>
              <Input
                placeholder="請輸入帳號或使用者姓名"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col style={{ marginRight: 30 }}>
            <div>資料篩選條件</div>
            <Row>

              <Form.Item name="team" className={styles.mb}>
                <Select
                  placeholder="部門"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 200, marginRight: 10 }}
                >
                  {team.map((d) => (
                    <Select.Option value={d.id} key={d.id}>{d.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="status" className={styles.mb}>
                <Select placeholder="帳號狀態" allowClear style={{ width: 120, marginRight: 10 }}>
                  {Object.keys(ACCOUNT_STATUS).map((a) => (
                    <Select.Option value={ACCOUNT_STATUS[a].value} key={ACCOUNT_STATUS[a].value}>
                      {ACCOUNT_STATUS[a].name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="pmStatus" className={styles.mb}>
                <Select placeholder="可登入/不可登入" allowClear style={{ width: 150, marginRight: 10 }}>
                  {Object.keys(USER_STATUS).map((a) => (
                    <Select.Option value={USER_STATUS[a].value} key={USER_STATUS[a].value}>
                      {USER_STATUS[a].name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="pmPermission" className={styles.mb}>
                <Select placeholder="權限設定" allowClear style={{ width: 120 }}>
                  {Object.keys(USER_PERMISSION).map((a) => (
                    <Select.Option value={USER_PERMISSION[a].value} key={USER_PERMISSION[a].value}>
                      {USER_PERMISSION[a].name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
          </Col>

          <Col>
            <div style={{ height: 22 }} />
            <Row>
              <Button onClick={onReset} style={{ marginRight: 10 }}>清空篩選條件</Button>
              <Button onClick={onQuery} type="primary">套用搜索</Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ActionBar.propTypes = {
  onQuery: PropTypes.func.isRequired
};

export default ActionBar;
