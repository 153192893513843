import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import SearchSetService from 'src/services/searchSet';
import { LIMIT, NAME_ORDER } from 'src/constants';
import KeywordItem from './KeywordItem';

export default class KeywordPaneVM {
  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;
  @observable searchConditions = null;
  @observable order = NAME_ORDER.ASC;

  level = '1';
  projectId = '';

  constructor(projectId, level) {
    makeObservable(this);
    this.projectId = projectId;
    this.level = level;
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { keywords, anchor } = await SearchSetService.queryKeyword(this.projectId, {
        ...this.searchConditions,
        limit: 100,
        anchor: this.anchor,
        level: this.level,
        order: this.order
      });

      const _keywords = keywords.map((k) => KeywordItem.fromRes(k.keyword, k.tags));

      runInAction(() => {
        this.list = [...this.list, ..._keywords];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      // TODO error message
    }
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  onBottom = () => {
    if (this.anchor) this.getList();
  }

  @action
  onOrderChange = () => {
    if (this.order === NAME_ORDER.DESC) {
      runInAction(() => { this.order = NAME_ORDER.ASC; });
    } else {
      runInAction(() => { this.order = NAME_ORDER.DESC; });
    }
    this.list = [];
    this.anchor = null;
    this.getList();
  }

}
