import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProjectHeader from 'src/components/project/ProjectHeader';
import ProjectReportsPage from 'src/viewModels/ProjectReports';
import DownloadData from './DownloadData';
import DownloadTopics from './DownloadTopics';
import Topics from './Topics';

@observer
class ReportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ProjectReportsPage(this.props.router.params.id);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <>
        <ProjectHeader />
        <Tabs defaultActiveKey="1" size="small">
          <Tabs.TabPane tab="下載話題列表" key="1">
            <DownloadTopics project={this.vm.project} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="下載數據列表" key="2">
            <DownloadData project={this.vm.project} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="快速查看口碑數據" key="3">
            <Topics project={this.vm.project} />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }
}

ReportsPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withProfile(withRouter(ReportsPage), true);
