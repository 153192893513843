import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import MyProjectVM from 'src/viewModels/MyProject/Main';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProjectItem from 'src/components/project/ProjectItem';
import CreateProjectDrawer from './CreateProjectDrawer';
import ActionBar from '../components/ActionBar';

// import styles from './styles.module.css';

@observer
class ProjectManagePage extends React.Component {
  constructor() {
    super();
    this.vm = new MyProjectVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }


  render() {
    return (
      <div style={{ height: '100%', overflowY: 'hidden' }}>
        <ActionBar
          onQuery={this.vm.onSearch}
          profile={this.props.profile}
          hasOrder
          useSessionStorage
        />

        <Row justify="end" style={{ marginBottom: 10 }}>
          <Button type="link">
            <Link to="/project/deleted">已刪除的專案</Link>
          </Button>
          <Button type="primary" onClick={this.vm.drawer.show}>
            ＋新增專案
          </Button>
        </Row>

        <Spin
          tip="正在加載資料"
          spinning={this.vm.isLoading}
        >
          <BottomScrollListener onBottom={this.vm.onBotton}>
            {(scrollRef) => (
              <div
                ref={scrollRef}
                style={{ overflowY: 'scroll', height: 'calc(100vh - 190px' }}
              >
                <Observer>
                  {() => (
                    <>
                      {this.vm.list.map((project) => (
                        <ProjectItem
                          key={project._id}
                          projectId={project._id}
                          project={project}
                          editable
                          exportable
                          canGoToRefNoticePage
                          infoButton={(
                            <>
                              <Button type="primary" style={{ flex: 1, marginRight: 5 }}>
                                <Link to={`/project/analysis/${project._id}`} state={{ activePan: '2' }}>跑專案</Link>
                              </Button>
                              <Button type="primary" style={{ flex: 1, marginRight: 5 }}>
                                <Link to={`/project/reports/${project._id}`}>報表檢視</Link>
                              </Button>
                              <Button type="primary" style={{ flex: 1, marginRight: 5 }}>
                                <Link to={`/project/records/${project._id}`}>異動紀錄</Link>
                              </Button>
                              <Button
                                type="primary"
                                danger
                                onClick={() => this.vm.deleteProject(project._id)}
                                style={{ flex: 1 }}
                              >
                                刪除專案
                              </Button>

                            </>
                          )}
                          summaryButton={(
                            <>
                              <Button type="primary" block>
                                <Link to={`/project/analysis/${project._id}`}>檢視分析紀錄</Link>
                              </Button>
                            </>
                          )}
                          settingButton={(
                            <>
                              <Button type="primary" block>
                                <Link to={`/project/settings/${project._id}`}>檢視／編輯</Link>
                              </Button>
                            </>
                          )}
                        />
                      ))}
                    </>
                  )}

                </Observer>
              </div>
            )}
          </BottomScrollListener>
        </Spin>

        <CreateProjectDrawer
          onCreate={this.vm.createProject}
          onClose={this.vm.drawer.hide}
          visible={this.vm.drawer.visible}
        />
      </div>
    );
  }
}

ProjectManagePage.propTypes = {
  profile: PropTypes.object.isRequired
};

export default withProfile(withRouter(ProjectManagePage), true);
