import React from 'react';

import MainLogo from 'src/assets/logo.svg';

import { MAINTENANCE_TIME } from 'src/constants';

import styles from './styles.module.css';

export default function MaintenancePage() {
  return (
    <div className={styles.pageContainer}>
      <img src={MainLogo} className={styles.logo} alt="logo" />
      <div className={styles.mainText}>
        系統維護中
      </div>
      <div className={styles.desc}>
        <div className={styles.subText}>
          系統維護時間：
        </div>
        <div className={styles.subText}>
          {`${MAINTENANCE_TIME[0]} ~ ${MAINTENANCE_TIME[1]} (UTC+8)`}
        </div>
      </div>
    </div>
  );
}
