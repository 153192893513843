import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { nanoid } from 'nanoid';
import debounce from 'lodash.debounce';
import { keywordParse } from 'src/services/keywordParser';
import SearchSetService from 'src/services/searchSet';
import { noSearchKeyword, noSearchCheck } from 'src/utils';
import TagOption from './TagOption';

export default class CreateItemViewModel {

  id = nanoid();
  @observable sid = null;
  @observable subSid = null;
  @observable name = '';
  @observable keyword = '';
  @observable synonym = '';

  @observable tags = null;

  @observable keywordVM = null;
  @observable L1 = [];
  @observable L2 = [];
  @observable searchAngle = '';
  @observable level = '';
  @observable keywordPool = '';
  @observable tagPool = '';
  @observable isExist = false;

  constructor(keywordVM, defaultSid = null, defaultSubSid = null) {
    makeObservable(this);

    if (defaultSid) this.onSidSelect(defaultSid);
    if (defaultSubSid) this.onSubSidSelect(defaultSubSid);

    runInAction(() => {
      this.keywordVM = keywordVM;
      this.L1 = this.keywordVM.L1KeywordOption;
      this.L2 = this.keywordVM.L2KeywordOption;
      this.level = this.keywordVM.level;
      this.searchAngle = this.keywordVM.searchVM.searchAngle;
      this.keywordPool = this.keywordVM.searchVM.keywordPool;
      this.tagPool = this.keywordVM.searchVM.tagPool;

      this.tags = this.keywordVM.tags?.map((tag) => new TagOption(
        tag.subTags.find((t) => t.default).id,
        tag
      )) ?? null;
    });

  }

  checkNameExist = async () => {
    try {
      const result = await SearchSetService.checkNameExist(this.name, this.keywordVM.searchVM.id, this.sid, this.subSid, this.level);
      runInAction(() => { this.isExist = result; });
    } catch {
      // ignore
    }
  }

  debounceCheck = debounce(this.checkNameExist, 300);

  @action
  onSidSelect = (value) => {
    this.sid = value;
    this.subSid = null;

    if (!this.name) return;
    this.debounceCheck();
  }

  @action
  onSubSidSelect = (value) => {
    this.subSid = value;

    if (!this.name) return;
    this.debounceCheck();
  }

  @action
  onNameChange = (e) => {
    this.name = e.target.value;
    this.keywordVM.updateNewKeywordsMap();

    if (!this.name) return;
    this.debounceCheck();
  }

  @action
  onKeywordChange = (e) => {
    this.keyword = e.target.value;
  }

  @action
  onSynonymChange = (e) => {
    this.synonym = e.target.value;
  }

  @action
  skipKeyword = () => {
    this.keyword = noSearchKeyword;
  }

  @computed
  get getData() {
    let keyword = this.keyword;
    let skipCount = 0;

    if (noSearchCheck(this.keyword)) {
      keyword = noSearchKeyword;
      skipCount += 1;
    }

    return {
      skipCount,
      keyword,
      name: this.name,
      synonym: this.synonym
    };
  }

  @computed
  get keywordResult() {
    const { result, reasons } = keywordParse(this.keyword);
    const hasError = (result === 'error') || (result === 'notComplete');
    const hasWaran = (result === 'warn');
    return { result, reasons, hasError, hasWaran };
  }

  @computed
  get hasDupName() {
    const key = `${this.sid || ''}_${this.subSid || ''}_${this.name}`;
    return this.keywordVM.newKeywordsMap.get(key) > 1;
  }

  @computed
  get hasError() {
    switch (this.level) {
      case 1:
        return !this.name || !this.keyword || this.keywordResult.hasError || this.isExist;
      case 2:
        return !this.sid || !this.name || !this.keyword || this.keywordResult.hasError || this.isExist;
      case 3:
        return !this.sid || !this.name || !this.keyword || this.keywordResult.hasError || this.isExist;
      default:
        return false;
    }
  }

  @computed
  get helpTxt() {
    if (!this.name) return '此欄必填';
    if (this.isExist) return '資料庫已存在此名稱';
    if (this.hasDupName) return '名稱重複';
    return '';
  }
}
