import React, { useCallback, useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MenuFoldOutlined, MenuUnfoldOutlined, DashboardOutlined, TableOutlined, UserOutlined, ProfileOutlined, CheckCircleOutlined, LogoutOutlined
} from '@ant-design/icons';
import withProfile from 'src/components/withProfile';
import AuthService from 'src/services/auth';
import logo from './logo192.png';

import styles from './styles.module.css';

const Sider = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [routeKey, setRouteKey] = useState([location.pathname.split('/')[1]]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(
    () => {
      if (routeKey[0] !== location.pathname.split('/')[1]) {
        setRouteKey([location.pathname.split('/')[1]]);
      }
    },
    [location, routeKey]
  );

  const onCollapse = useCallback(
    (c, type) => {
      setCollapsed(c);
    },
    []
  );

  const onToggleCollapse = useCallback(
    () => {
      setCollapsed((pre) => !pre);
    },
    []
  );

  const goTo = useCallback(
    ({ key }) => {
      if (key === 'logout') {
        AuthService.logout();
        navigate('/login');
        return;
      }
      navigate(`/${key}`);
      setRouteKey([key]);
    },
    [navigate]
  );

  return (
    <Layout.Sider
      className={styles.side}
      collapsible
      width={200}
      breakpoint="md"
      theme="dark"
      trigger={null}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >

      <div className={styles.logoBox}>
        <div>
          <span>專</span>
          <span className={collapsed ? styles.titleCollapsed : styles.title}>{ collapsed ? '' : '案管理工具'}</span>
        </div>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>

      <Menu
        className={styles.menu}
        theme="dark"
        mode="inline"
        selectedKeys={routeKey}
        onClick={goTo}
      >
        <Menu.Item key="project" icon={<DashboardOutlined />}>專案管理</Menu.Item>
        <Menu.Item key="analysis" icon={<TableOutlined />}>分析列表</Menu.Item>
        <Menu.Item key="allprojects" icon={<ProfileOutlined />}>公版列表</Menu.Item>
        <Menu.Item key="search" icon={<CheckCircleOutlined />}>系統查詢</Menu.Item>
        {/* only admin can see this link  */}
        {(props.profile.admin) && (
          <Menu.Item key="permission" icon={<UserOutlined />}>權限管理</Menu.Item>
        )}
        <Menu.Item key="logout" icon={<LogoutOutlined />}>登出</Menu.Item>
      </Menu>

      <div className={styles.userBox}>
        <div className={collapsed ? styles.userContentCollapsed : styles.userContent}>
          {`Hi，${props.profile.name}`}
        </div>
      </div>

      <div
        className={clsx(styles.collapseBtn, collapsed ? styles.center : null)}
        onClick={onToggleCollapse}
      >
        { collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined /> }
      </div>
    </Layout.Sider>
  );
};

Sider.propTypes = {
  profile: PropTypes.object.isRequired
};

export default withProfile(Sider);
