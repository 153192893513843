import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row, Button, Select, Input, Form, Drawer } from 'antd';
import SkipKeywordBtn from 'src/components/search/SkipKeywordBtn';
import { TEXT } from '../constants';

import styles from './styles.module.css';

const EditDrawer = observer((props) => {
  const [text, setText] = useState('');

  useEffect(() => {
    switch (props.vm.level) {
      case 1:
        setText(TEXT[props.vm.searchAngle]?.L1);
        break;
      case 2:
        setText(TEXT[props.vm.searchAngle]?.L2);
        break;
      case 3:
        setText(TEXT[props.vm.searchAngle]?.L3);
        break;
      default:
        setText('');
    }
  }, [props.vm.level, props.vm.searchAngle]);

  return (
    <Drawer
      title="檢視與編輯搜尋關鍵字"
      width={600}
      onClose={props.vm.onClose}
      visible
      maskClosable={false}
      footer={(
        <Button
          type="primary"
          disabled={props.vm.hasError}
          onClick={props.vm.onSave}
        >
          儲存
        </Button>
      )}
      footerStyle={{ display: 'flex', justifyContent: 'center' }}
    >
      <Form
        labelCol={{ span: 7 }}
        style={{
          padding: 10,
          margin: '10px 0'
        }}
      >
        {(props.vm.level === 2 || props.vm.level === 3) && (
          <Form.Item label={`${TEXT[props.vm.searchAngle]?.L1}名稱`}>
            <div>{props.vm.targetKeyword.level1 ?? '-'}</div>
          </Form.Item>
        )}

        {(props.vm.level === 3) && (
          <Form.Item label={`${TEXT[props.vm.searchAngle]?.L2}名稱`}>
            <div>{props.vm.targetKeyword.level2 ?? '-'}</div>
          </Form.Item>
        )}

        <Form.Item
          label={`${text}名稱`}
          validateStatus={(!props.vm.name || props.vm.nameRepeated) ? 'error' : 'success'}
          help={(
            <div>
              {props.vm.name ? '  ' : '此欄必填'}
              {props.vm.nameRepeated ? '名稱重複' : '  '}
            </div>
          )}
          rules={[{ required: true }]}
        >
          <Input
            onChange={props.vm.onNameChange}
            value={props.vm.name}
          />
        </Form.Item>

        <Form.Item
          label={`${text}搜尋關鍵字`}
          validateStatus={props.vm.keywordResult.hasError ? 'error' : 'success'}
          help={(
            <div>
              {props.vm.keywordResult.reasons.map((r) => (
                <div key={r.id} className={styles[r.type]}>{r.message}</div>
              ))}
            </div>
          )}
        >
          <Input.TextArea
            rows={10}
            onChange={props.vm.onKeywordChange}
            value={props.vm.keyword}
          />
          <SkipKeywordBtn callback={props.vm.skipKeyword} />
        </Form.Item>

        {props.vm.level !== 1 && props.vm.tags && (
        <Form.Item label="標籤">
          {props.vm.tags?.map((tagVM) => (
            <Row key={tagVM.fullName} align="middle" style={{ marginBottom: 10 }}>
              <div>{`${tagVM.name}： `}</div>
              <Select
                value={tagVM.subTag}
                onSelect={tagVM.onSelect}
                style={{ width: '70%' }}
              >
                {tagVM.subTags?.map((subTag) => (
                  <Select.Option key={subTag.id} value={subTag.name}>
                    {subTag.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          ))}
        </Form.Item>
        )}

        <Form.Item label="同義詞">
          <Input
            onChange={props.vm.onSynonymChange}
            value={props.vm.synonym}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
});

export default EditDrawer;
