import React from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Es from './Es';
import SystemLoading from './SystemLoading';


@observer
class SearchPage extends React.Component {
  render() {
    return (
      <div>
        <Tabs>
          <Tabs.TabPane tab="系統忙碌程度" key="1">
            <SystemLoading />
          </Tabs.TabPane>
          <Tabs.TabPane tab="ES資料查詢" key="2">
            <Es />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withProfile(withRouter(SearchPage), true);
