import { makeObservable, observable, action, runInAction } from 'mobx';
import UserService from 'src/services/user';
import { errorModal } from 'src/utils';

export default class UserItem {
  id = '';
  teamName = '';
  email = '';
  name = '';
  type = '';
  status = '';
  phone = '';
  @observable pmStatus = '';
  @observable pmPermission = '';


  @observable editedPmStatus = '';
  @observable editedPmPermission = '';
  @observable isEdit = false;

  constructor(data) {
    makeObservable(this);

    const phoneExt = data.phoneExt ? `#${data.phoneExt}` : '';

    runInAction(() => {
      this.id = data.id;
      this.teamName = data.teamName;
      this.email = data.email;
      this.name = data.name;
      this.type = data.type;
      this.status = data.status;
      this.phone = `${data.phone}${phoneExt}`;
      this.pmStatus = data.pmStatus;
      this.pmPermission = data.pmPermission;

      this.editedPmStatus = data.pmStatus;
      this.editedPmPermission = data.pmPermission;
    });
  }

  @action
  onEdit = () => {
    this.isEdit = true;
  }

  @action
  onCancel = () => {
    this.isEdit = false;
    this.editedPmStatus = this.pmStatus;
    this.editedPmPermission = this.pmPermission;
  }

  onSave = async () => {
    if (this.editedPmPermission === 'none') {
      this.onCancel();
      errorModal('權限設定不可為無');
      return;
    }

    try {
      await UserService.updateUser(this.id, {
        pmStatus: this.editedPmStatus,
        pmPermission: this.editedPmPermission
      });
      runInAction(() => {
        this.pmStatus = this.editedPmStatus;
        this.pmPermission = this.editedPmPermission;
        this.isEdit = false;
      });
    } catch (err) {
      console.log(err);
      this.onCancel();
      errorModal('發生錯誤，無法更新使用者權限');
    }
  }

  @action
  onPermissionChange = (newPermission) => {
    this.editedPmPermission = newPermission;
  }

  @action
  onStatusChange = (newStatus) => {
    this.editedPmStatus = newStatus;
  }
}
