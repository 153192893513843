import { Modal } from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';
import { errorModal } from 'src/utils';
import ProjectBackTrackService from 'src/services/projectBackTrack';
import ProjectSchedule from 'src/services/projectSchedule';

export default class LogItem {

  id = '';

  @observable log = {
    _id: '',
    projectId: '',
    startDate: '',
    finishDate: '',
    featureOnly: true,
    type: 'init',
    beginAt: '',
    endAt: '',
    cancellable: false,
    stage: 'WAITING',
    stageDesc: '等待分析中',
    progress: 0,
    createdAt: '',
    channelCount: 0,
    featureCount: 0,
    keywordCount: 0
  };


  constructor(log) {
    makeObservable(this);
    runInAction(() => {
      this.log = log;
    });
    this.id = log._id;
  }

  confirmBacktrack = () => {
    if (!this.log.cancellable) {
      return errorModal(`已進入 ${this.stageDesc} 階段，此分析無法中止。`);
    }

    return Modal.confirm({
      title: '是否要中止這個資料回溯程式？',
      onOk: this.deleteBacktrack,
      okText: '是',
      cancelText: '否'
    });
  }

  downloadBacktrackSetting = (anchor) => {
    ProjectSchedule.downloadProjectScheduleLogSetting(this.log.projectId, this.id, anchor);
  }

  deleteBacktrack = async () => {
    try {

      await ProjectBackTrackService.delete(this.log.projectId);
      runInAction(() => {
        this.log.cancellable = false;
        this.log.stage = 'CANCEL';
        this.log.stageDesc = '已取消';
      });

      Modal.success({
        title: '回溯程式已中止。',
        okText: '確定'
      });

    } catch (err) {
      switch (err.response?.status) {
        case 404:
          // ignore
          break;
        case 412:
          errorModal('已進入分析中/後段流程，此分析無法中止。');
          break;
        default:
          errorModal('發生錯誤，無法中止回溯');
      }
    }

    return null;
  }

}
