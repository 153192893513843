/* eslint-disable */
function id(x) { return x[0]; }
var grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "Main", "symbols": ["Unit"], "postprocess": id},
    {"name": "Main", "symbols": ["Unit", {"literal":"+","pos":12}, "Main"], "postprocess": function(d) {return { type: 'expression', op: '+', left:d[0], right:d[2] }}},
    {"name": "Main", "symbols": ["Unit", {"literal":"-","pos":22}, "Main"], "postprocess": function(d) {return { type: 'expression', op: '-', left:d[0], right:d[2] }}},
    {"name": "Main", "symbols": ["Unit", {"literal":"&","pos":32}, "Main"], "postprocess": function(d) {return { type: 'expression', op: '&', left:d[0], right:d[2] }}},
    {"name": "Unit", "symbols": ["String"], "postprocess": id},
    {"name": "Unit", "symbols": ["Near"], "postprocess": id},
    {"name": "Unit", "symbols": ["OrUnit"], "postprocess": id},
    {"name": "OrUnit", "symbols": [{"literal":"(","pos":62}, "KeywordList", {"literal":")","pos":66}], "postprocess": function(d) {return { type: 'orUnit', set:d[1].set }}},
    {"name": "KeywordList", "symbols": ["Keyword", {"literal":";","pos":76}, "Keyword"], "postprocess": function(d) {return { type: 'keywordList', set: [d[0], d[2]] }}},
    {"name": "KeywordList", "symbols": ["Keyword", {"literal":";","pos":86}, "KeywordList"], "postprocess": function(d) {return { type: 'keywordList', set: [d[0], ...d[2].set] }}},
    {"name": "Keyword", "symbols": ["String"], "postprocess": id},
    {"name": "Keyword", "symbols": ["Near"], "postprocess": id},
    {"name": "Near", "symbols": ["String", {"literal":"/","pos":112}, "Range", {"literal":"/","pos":116}, "String"], "postprocess": function(d) {return { type: 'near', left: d[0].d, range: d[2], right: d[4].d }}},
    {"name": "Range$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "Range$ebnf$1", "symbols": [/[0-9]/, "Range$ebnf$1"], "postprocess": function arrconcat(d) {return [d[0]].concat(d[1]);}},
    {"name": "Range", "symbols": ["Range$ebnf$1"], "postprocess": function(d) {return { type: 'range', d:d[0].join("") }}},
    {"name": "Range$ebnf$2", "symbols": [/[0-9]/]},
    {"name": "Range$ebnf$2", "symbols": [/[0-9]/, "Range$ebnf$2"], "postprocess": function arrconcat(d) {return [d[0]].concat(d[1]);}},
    {"name": "Range", "symbols": ["Range$ebnf$2", {"literal":"n","pos":136}], "postprocess": function(d) {return { type: 'range', d:d[0].join(""), n: true }}},
    {"name": "String$ebnf$1", "symbols": [/[^()+\-&\;/\xA0\x00-\x09\x0B\x0C\x0E-\x1F\x7F"]/]},
    {"name": "String$ebnf$1", "symbols": [/[^()+\-&\;/\xA0\x00-\x09\x0B\x0C\x0E-\x1F\x7F"]/, "String$ebnf$1"], "postprocess": function arrconcat(d) {return [d[0]].concat(d[1]);}},
    {"name": "String", "symbols": ["String$ebnf$1"], "postprocess": function(d, l, reject) { const v = d[0].join(''); if(v.trim().length !== v.length) { return reject; } return { type: 'string', d:v }}},
    {"name": "String", "symbols": [{"literal":"\"","pos":151}, "EscString", {"literal":"\"","pos":155}], "postprocess": function(d) {return { type: 'string', d:d[1].d }}},
    {"name": "EscString$ebnf$1", "symbols": [/[^"\xA0\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/]},
    {"name": "EscString$ebnf$1", "symbols": [/[^"\xA0\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/, "EscString$ebnf$1"], "postprocess": function arrconcat(d) {return [d[0]].concat(d[1]);}},
    {"name": "EscString", "symbols": ["EscString$ebnf$1"], "postprocess": function(d) {return { type: 'string', d:d[0].join('')}}}
  ]
  , ParserStart: "Main"
}

export default grammar;
