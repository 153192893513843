import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, Tooltip } from 'antd';
import { observer, Observer } from 'mobx-react';

import styles from './styles.module.css';

function VirtualTable(props) {
  // lineCount is how many lines in a row
  const { columns, scroll, lineCount } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [rowHight, setRowHight] = useState(0);
  const [mergedColumns, setMergedColumns] = useState([]);

  useEffect(() => {
    // number of columns without default width
    const countColWithoutWidth = columns.filter(({ width }) => !width).length;

    // calculate total width of columns with default width
    let existingWidth = 0;

    columns.forEach((c) => {
      if (c.width) {
        existingWidth += c.width;
      }
    });

    let totalWidth = 0;

    if (props?.scroll?.x && props.scroll.x > tableWidth) {
      totalWidth = props.scroll.x;
    } else {
      totalWidth = tableWidth;
    }

    // give width to columns without default width
    const _mergedColumns = columns.map((column) => {
      if (column.width) return column;
      return { ...column, width: Math.floor((totalWidth - (existingWidth)) / (countColWithoutWidth)) };
    });

    setMergedColumns(_mergedColumns);

  }, [tableWidth, columns, props?.scroll?.x]);

  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = { value: 0 };
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => obj.value,
      set: (scrollLeft) => {
        if (gridRef.current) {
          obj.value = scrollLeft;
          gridRef.current.scrollTo({
            scrollLeft
          });
        }
      }
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true
      });
    }
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  useEffect(() => {
    setRowHight(lineCount >= 2 ? (lineCount * 22 + 16) : 60);
  }, [lineCount]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = connectObject;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        style={{ ...props.gridTableStyle }}
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          return mergedColumns[index]?.width ?? 200;
        }}
        height={scroll.y - 39} // subtract height of table header
        rowCount={rawData.length}
        rowHeight={() => rowHight}
        width={tableWidth}
        onScroll={({ scrollLeft, scrollTop }) => {
          onScroll({
            scrollLeft,
            scrollTop
          });
          // call fetch when reach last row
          if ((props.dataSource.length * rowHight) - scrollTop - props.scroll.y < rowHight * 1) {
            console.log('Fetch!');
            props.onFetch();
          }
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const disableDate = rawData[rowIndex].disableDate
            ? dayjs(rawData[rowIndex].disableDate).format('YYYY-MM-DD')
            : null;

          return (
            <Observer>
              {() => (
                <div
                  style={{
                    ...style,
                    ...props.gridCellStyle,
                    borderBottom: '1px solid #f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  className={
                classNames(
                  'virtual-table-cell',
                  { 'virtual-table-cell-last': columnIndex === mergedColumns.length - 1 },
                  { [styles.disableRows]: rawData[rowIndex].enable === false }
                )
              }
                >
                  {disableDate
                    ? (
                      <Tooltip title={disableDate}>
                        {
                  rawData[rowIndex][mergedColumns[columnIndex].dataIndex] !== undefined
                    ? rawData[rowIndex][mergedColumns[columnIndex].dataIndex]
                    : mergedColumns[columnIndex].render(rawData[rowIndex], null, rowIndex)
                  }
                      </Tooltip>
                    ) : (
                      <>
                        {
                  rawData[rowIndex][mergedColumns[columnIndex].dataIndex] !== undefined
                    ? rawData[rowIndex][mergedColumns[columnIndex].dataIndex]
                    : mergedColumns[columnIndex].render(rawData[rowIndex], null, rowIndex)
                }
                      </>
                    )}
                </div>
              )}
            </Observer>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        key={`ss${tableWidth}`}
        {...props}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList
        }}
      />
    </ResizeObserver>
  );
}

VirtualTable.propTypes = {
  columns: PropTypes.array.isRequired,
  scroll: PropTypes.objectOf(PropTypes.any).isRequired,
  lineCount: PropTypes.number,
  onFetch: PropTypes.func.isRequired,
  dataSource: PropTypes.array.isRequired,
  gridCellStyle: PropTypes.object,
  gridTableStyle: PropTypes.object
};

VirtualTable.defaultProps = {
  lineCount: 2,
  gridCellStyle: {},
  gridTableStyle: {}
};

export default observer(VirtualTable);
