import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { keywordParse } from 'src/services/keywordParser';
import { noSearchKeyword, noSearchCheck } from 'src/utils';
import TagOption from './TagOption';

export default class EditDrawerViewModel {
  @observable name = '';
  @observable keyword = '';
  @observable synonym = '';
  @observable tags = null;

  @observable drawerVM = null;
  @observable searchAngle = '';
  @observable level = '';

  targetKeyword = null;

  constructor(drawerVM, targetKeyword, level) {
    makeObservable(this);

    this.targetKeyword = targetKeyword;
    this.level = level;

    runInAction(() => {
      this.drawerVM = drawerVM;
      this.searchAngle = this.drawerVM.searchAngle;

      this.name = targetKeyword.name;
      this.keyword = targetKeyword.keyword;
      this.synonym = targetKeyword.synonym;
      this.tags = this.drawerVM.tags
        .filter((tag) => tag.scope === 'all' || tag.scope === `l${this.level}`)
        .map((tag) => new TagOption(
          tag,
          targetKeyword.tags[tag.fullName]
        )) ?? null;
    });

  }

  @action
  onClose = () => {
    this.drawerVM.hideEditDrawer();
  }

  @action
  onSave = async () => {
    const keywordNoChange = (
      this.name === this.targetKeyword.name
      && this.keyword === this.targetKeyword.keyword
      && this.synonym === this.targetKeyword.synonym
    );

    const tagsNoChange = this.tags?.every((tag) => !tag.hasChanged);

    if ((this.level !== 1) && !tagsNoChange) {
      this.tags.forEach((tag) => {
        if (tag.hasChanged) {
          this.targetKeyword.updateTag(tag.fullName, tag.subTag);
        }
      });
    }

    if (!keywordNoChange) {
      const { skipCount, name, keyword, synonym } = this.getData;
      await this.targetKeyword.updateSelf({ name, keyword, synonym });
    }

    this.drawerVM.hideEditDrawer();
  }

  @action
  onNameChange = (e) => {
    this.name = e.target.value;
  }

  @action
  onKeywordChange = (e) => {
    this.keyword = e.target.value;
  }

  @action
  onSynonymChange = (e) => {
    this.synonym = e.target.value;
  }

  @action
  skipKeyword = () => {
    this.keyword = noSearchKeyword;
  }

  @computed
  get getData() {
    let keyword = this.keyword;
    let skipCount = 0;

    if (noSearchCheck(this.keyword)) {
      keyword = noSearchKeyword;
      skipCount += 1;
    }

    return {
      skipCount,
      keyword,
      name: this.name,
      synonym: this.synonym
    };
  }

  @computed
  get keywordResult() {
    const { result, reasons } = keywordParse(this.keyword);
    const hasError = (result === 'error') || (result === 'notComplete');
    const hasWaran = (result === 'warn');
    return { result, reasons, hasError, hasWaran };
  }

  @computed
  get nameRepeated() {
    if (this.level === 3) {
      return this.drawerVM.L3Keywords.some((k) =>
        k.id !== this.targetKeyword.id
        && k.name === this.name
        && k.level1 === this.targetKeyword.level1
        && k.level2 === this.targetKeyword.level2);
    }
    if (this.level === 2) {
      return this.drawerVM.L2Keywords.some((k) =>
        k.id !== this.targetKeyword.id
        && k.name === this.name
        && k.level1 === this.targetKeyword.level1);
    }
    return this.drawerVM.L1Keywords.some((k) =>
      k.id !== this.targetKeyword.id
      && k.name === this.name);
  }

  @computed
  get hasError() {
    return !this.name || !this.keyword || this.keywordResult.hasError || this.nameRepeated;
  }
}
