import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Input, Radio, Row } from 'antd';

const CreateFeatureModal = (props) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="新增特性關鍵字"
      footer={null}
      forceRender
      visible={props.visible}
      onCancel={() => {
        props.onCancel();
        form.resetFields();
      }}
    >
      <Form form={form} requiredMark={false}>
        <Form.Item
          name="name"
          label="檔案名稱："
          rules={[
            {
              required: true,
              message: '請輸入檔案名稱'
            }
          ]}
        >
          <Input placeholder="請輸入檔案名稱" />
        </Form.Item>

        <Form.Item
          name="level"
          label="群組階層數："
          rules={[
            {
              required: true,
              message: '請選擇群組階層數'
            }
          ]}
        >
          <Radio.Group>
            <Radio value={2}>1</Radio>
            <Radio value={3}>2</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.validateFields()
                  .then(async (values) => {
                    await props.onOk(values);
                    form.resetFields();
                    props.onCancel();
                  })
                  .catch((errorInfo) => {
                    // ignore
                  });
              }}
            >
              確認
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateFeatureModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default CreateFeatureModal;
