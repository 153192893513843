import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Drawer, Row } from 'antd';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

import styles from './styles.module.css';

const ViewDetailDrawer = observer((props) => {
  const [text, setText] = useState('');
  const { drawer, searchAngle, keywordPool, tagPool } = props.vm;
  const { config } = drawer;

  useEffect(() => {
    switch (config) {
      case 1:
        setText(SEARCH_KEYWORD_TXT[searchAngle]?.L1 ?? '');
        break;
      case 2:
        setText(SEARCH_KEYWORD_TXT[searchAngle]?.L2 ?? '');
        break;
      case 3:
        setText(SEARCH_KEYWORD_TXT[searchAngle]?.L3 ?? '');
        break;
      default:
        setText('');
    }
  }, [config, searchAngle]);

  return (
    <Drawer
      title="檢視搜尋關鍵字"
      width={600}
      onClose={drawer.hide}
      visible={drawer.visible}
      maskClosable={false}
    >

      {config && (
      <Form
        labelCol={{ span: 7 }}
        className={styles.formBody}
      >
        {(config.level === 2 || config.level === 3) && (
        <Form.Item label={`${SEARCH_KEYWORD_TXT[searchAngle]?.L1 ?? ' '}名稱`}>
          {keywordPool?.get(config.sid)?.name ?? '-'}
        </Form.Item>
        )}

        {(config.level === 3) && (
        <Form.Item label={`${SEARCH_KEYWORD_TXT[searchAngle]?.L2 ?? ' '}名稱`}>
          {keywordPool?.get(config.subSid)?.name ?? '-'}
        </Form.Item>
        )}

        <Form.Item label={`${text}名稱`}>
          <div>{config.name}</div>
        </Form.Item>

        <Form.Item label={`${text}搜尋關鍵字`}>
          <div>{config.keyword}</div>
        </Form.Item>

        {config.level !== 1 && (
        <Form.Item label="標籤">
          {config.tags.map((tag) => (
            <Row key={tag.id} align="middle">
              <div>{`${tagPool?.get(tag.key)?.name ?? ' '}： `}</div>
              <div>{tagPool?.get(tag.value)?.name ?? ' '}</div>
            </Row>
          ))}
        </Form.Item>
        )}

        <Form.Item label="同義詞">
          <div>{config.synonym ?? '-'}</div>
        </Form.Item>
      </Form>
      )}
    </Drawer>
  );
});

export default ViewDetailDrawer;
