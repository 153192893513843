import { Row } from 'antd';
import dayjs from 'dayjs';
import { numberFormatter } from 'src/utils';

export const summaryColumns = [
  {
    title: (<Row justify="center">異動日期</Row>),
    key: 'date',
    render: (data) => (<Row justify="center">{dayjs(data.date).format('YYYY-MM-DD')}</Row>)
  },
  {
    title: (<Row justify="center">頻道組合</Row>),
    key: 'date',
    render: (data) => (<Row justify="center">{numberFormatter(data.channelCount)}</Row>)
  },
  {
    title: (<Row justify="center">搜尋關鍵字</Row>),
    key: 'date',
    render: (data) => (<Row justify="center">{numberFormatter(data.searchCount)}</Row>)
  },
  {
    title: (<Row justify="center">特性關鍵字</Row>),
    key: 'date',
    render: (data) => (<Row justify="center">{numberFormatter(data.featureCount)}</Row>)
  }
];
