import { Modal } from 'antd';
import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { keywordParse } from 'src/services/keywordParser';

export default class KeywordVM {

  @observable cid = null;
  @observable subCid = null;
  @observable category = null;
  @observable subCategory = null;
  @observable name = null;
  @observable keyword = null;
  @observable kid = null;

  @observable drawerVM = null;

  constructor(keyword, drawerVM) {
    makeObservable(this);
    runInAction(() => {
      this.cid = keyword.category;
      this.category = keyword.category;
      this.subCid = keyword.subCategory;
      this.subCategory = keyword.subCategory;
      this.name = keyword.name;
      this.keyword = keyword.keyword;
      this.kid = keyword.kid;
      this.drawerVM = drawerVM;
    });
  }

  @computed
  get siblings() {
    const array = this.drawerVM.keyword.filter((keyword) => {
      if (this.subCid) {
        return keyword.cid === this.cid && keyword.subCid === this.subCid;
      }
      return keyword.cid === this.cid;
    });
    return array;
  }

  @computed
  get keywordResult() {
    const { result, reasons } = keywordParse(this.keyword);
    const hasError = (result === 'error') || (result === 'notComplete');
    const hasWaran = (result === 'warn');
    return { result, reasons, hasError, hasWaran };
  }

  @action
  update = (name, keyword) => {
    if (this.siblings.some((k) => k.name === name.trim() && k.kid !== this.kid)) {
      return Modal.error({
        title: '關鍵字更新失敗',
        content: '在同一群組中已有此關鍵字'
      });
    }
    this.name = name.trim();
    this.keyword = keyword.trim();
    return null;
  }

  @action
  updateCategory = (name) => {
    this.category = name;
  }

  @action
  updateSubCategory = (name) => {
    this.subCategory = name;
  }
}
