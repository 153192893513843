import { makeObservable, action } from 'mobx';
import { Modal } from 'antd';
import { errorModal } from 'src/utils';
import ProjectService from 'src/services/project';
import SearchSetService from 'src/services/searchSet';

export default class DeleteSOP {

  projectId = '';
  targetKeywordId = null;
  keywordPaneVM = null;

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  @action
  end = () => {
    this.keywordPaneVM.resetSelectedKeyword();
    this.targetKeywordId = null;
  }

  @action
  cancel = () => {
    this.targetKeywordId = null;
  }

  start = async (keywordPaneVM, targetKeywordId) => {
    // get project status
    const res = await ProjectService.getProject(this.projectId);
    const isRuning = res.meta.isRun;
    const { beginAt, endAt } = res.progress;

    this.keywordPaneVM = keywordPaneVM;
    this.targetKeywordId = targetKeywordId;

    // project is not running
    if (!isRuning) {
      return this.deleteAsProjectNotRuning();
    }

    // project is running and backtracking
    if (beginAt && !endAt) {
      return errorModal(
        '刪除失敗！',
        '此專案有一個以上的資料分析工作正在進行，為避免分析結果錯亂，目前無法執行資料刪除工作。'
      );
    }

    // project is running but not backtracking
    this.deleteAsProjectRuning();
    return null;
  }


  executeDelete = async (ids) => {
    const keywordPool = this.keywordPaneVM.searchVM.keywordPool;
    const _ids = this.targetKeywordId || ids;

    await Promise.all(_ids.map((id) => {
      const keyword = keywordPool.get(id);
      const { sid, subSid, sub2Sid } = keyword._getIds();
      return SearchSetService.deleteKeyword(this.projectId, sid, subSid, sub2Sid, keyword.level);
    }));

    this.keywordPaneVM.searchVM.informDelete(this.keywordPaneVM.level, ids);
    this.keywordPaneVM.filter(_ids);
  }


  deleteAsProjectNotRuning = async () => {
    const ids = Array.from(this.keywordPaneVM.selectedIdSet);

    Modal.confirm({
      title: `確定刪除共 ${ids.length} 筆搜尋關鍵字嗎？`,
      okText: '確認刪除',
      cancelText: '取消',
      okButtonProps: { danger: true },
      zIndex: 900,
      onOk: async () => {
        try {
          await this.executeDelete(ids);
          Modal.success({ title: '刪除成功。', okText: '關閉' });

        } catch {
          errorModal('發生錯誤，無法刪除搜尋關鍵字');

        } finally {
          this.end();
        }
      },
      onCancel: this.cancel
    });
  }

  deleteAsProjectRuning = async () => {
    const ids = Array.from(this.keywordPaneVM.selectedIdSet);

    Modal.confirm({
      title: `共 ${ids.length || 1} 筆搜尋關鍵字將被刪除`,
      content: '刪除搜尋關鍵字代表系統將刪除專案中這些搜尋關鍵字的資料(包含過往分析過的口碑)，並重新分析現存資料結果。資料一經刪除無法復原。此動作的處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。是否確認永久刪除這些搜尋關鍵字？如不想刪除資料，請改使用「永久停用功能」。',
      okText: '確認刪除',
      cancelText: '取消',
      okButtonProps: { danger: true },
      zIndex: 900,
      onOk: async () => {
        try {
          await this.executeDelete(ids);
          Modal.success({
            title: '搜尋關鍵字設定已刪除，並將資料刪除任務排入佇列，稍後可到分析列表中查詢進度。',
            okText: '關閉'
          });

        } catch {
          errorModal('發生錯誤，無法刪除搜尋關鍵字');

        } finally {
          this.end();
        }
      },
      onCancel: this.cancel
    });
  }


}
