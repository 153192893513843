import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { CHANNEL_SORT } from 'src/constants';
import ChannelSetService from 'src/services/channelSet';

export default class ChannelVM {

  sort = CHANNEL_SORT.DEFAULT;
  projectId = null;

  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;
  @observable searchConditions = null;

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { channels, anchor } = await ChannelSetService.getChannelOfProject(this.projectId, {
        ...this.searchConditions,
        anchor: this.anchor,
        sort: this.sort
      });

      runInAction(() => {
        this.list = [...this.list, ...channels];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      // TODO error message
    }
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  onBottom = () => {
    if (this.anchor) this.getList();
  }

  onSortChange = (direction) => {
    switch (direction) {
      case 'ascend':
        this.sort = CHANNEL_SORT.ASC;
        break;

      case 'descend':
        this.sort = CHANNEL_SORT.DESC;
        break;

      default:
        this.sort = CHANNEL_SORT.DEFAULT;
    }

    runInAction(() => {
      this.anchor = null;
      this.isLodaing = false;
      this.list = [];
    });
    this.getList();
  }

}
