import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Button, Drawer, Spin, Tag } from 'antd';

import withRouter from 'src/components/withRouter';
import DisableModal from 'src/components/modal/DisableModal';
import ClearAllModal from 'src/components/modal/ClearAllModal';
import ImportModal from 'src/components/modal/ImportModal';
import RootModal from 'src/components/modal/RootModal';
import SearchKeywordSummary from 'src/components/search/Summary';
import SearchSetViewModel from 'src/viewModels/ProjectSettings/Search/index';
import ModalViewModel from 'src/viewModels/modal';
import RefDrawer from 'src/components/RefDrawer';
import { TEXT } from './constants';
import L1Pane from './L1Pane';
import L2Pane from './L2Pane';
import L3Pane from './L3Pane';
import TagPane from './TagPane';
import ImportDrawer from './ImportDrawer';
import EditDrawer from './EditDrawer';

import styles from './styles.module.css';

@observer
class SearchSetPane extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new SearchSetViewModel(props.router.params.id, this.deleteModalContent());
    this.createModalVM = new ModalViewModel();
    this.importModalVM = new ModalViewModel();
    this.clearAllModalVM = new ModalViewModel();
    this.refDrawerVM = new ModalViewModel();
  }

  componentDidMount() {
    this.vm.refresh();
  }

  deleteModalContent = () => (
    <>
      <div style={{ marginBottom: 20 }}>刪除關鍵字代表系統將刪除此專案中所有跟此關鍵字有關的資料數據，並重新進行現有的自動化運算。一經刪除資料無法復原。</div>
      <div style={{ marginBottom: 20 }}>此動作的處理時間因專案大小而異，可能無法立即完成。</div>
      <div style={{ marginBottom: 20 }}>是否確認永久刪除這些關鍵字？如不想刪除資料，請改使用「永久停用功能」</div>
    </>
  )

  createButtons = () => (
    <Row justify="end">
      <Button
        type="primary"
        style={{ marginRight: 10 }}
        onClick={this.importModalVM.show}
      >
        首次匯入
      </Button>
      <Button
        type="primary"
        onClick={() => this.refDrawerVM.show()}
      >
        引用其他搜尋關鍵字
      </Button>
    </Row>
  )

  clearButtons = () => (
    <>

      {this.vm.searchSetRefProject && (
        <Tag
          color="red"
        >
          {`引用 ${this.vm.searchSetRefProject?.meta?.name || this.vm.searchSetRefProject} 公版專案搜尋關鍵字`}
        </Tag>
      )}

      <Button
        type="primary"
        style={{ marginRight: 10 }}
        onClick={this.importModalVM.show}
      >
        補充匯入
      </Button>
      <Button
        type="primary"
        danger
        onClick={() => this.clearAllModalVM.show()}
      >
        清空現有設定
      </Button>
    </>
  )

  render() {
    return (
      <>
        <SearchKeywordSummary angle={this.vm.searchAngle} />

        <Tabs
          type="card"
          tabBarExtraContent={this.vm.canRef ? this.createButtons() : this.clearButtons()}
        >
          <Tabs.TabPane key={1} tab={TEXT[this.vm.searchAngle]?.L1}>
            <L1Pane vm={this.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab={TEXT[this.vm.searchAngle]?.L2}>
            <L2Pane vm={this.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={3} tab={TEXT[this.vm.searchAngle]?.L3}>
            <L3Pane vm={this.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={4} tab="標籤">
            <TagPane vm={this.vm} />
          </Tabs.TabPane>
        </Tabs>

        <DisableModal
          visible={this.vm.disableSOP.modalVisible}
          onCancel={this.vm.disableSOP.cancel}
          onOk={this.vm.disableSOP.disable}
          content={(
            <>
              <div style={{ marginBottom: 20 }}>停用關鍵字系統將：</div>
              <div style={{ marginBottom: 20 }}>
                1.
                將晚於您所設定的日期的資料(ex:停用時間設定為2021/1/1，則2021/1/1當天以及往後的資料都會被刪除。），並重新進行現有的自動化運算。
              </div>
              <div style={{ marginBottom: 20 }}>
                2.
                關鍵字一經停用即無法再次啟用，如需要回復這些關鍵字的資料，則需要先進行刪除關鍵字，再重新添加關鍵字並進行重新跑資料。
              </div>
              <div style={{ marginBottom: 20 }}>3. 此動作的處理時間因專案大小而異，可能無法立即完成。</div>
              <div>是否確認停用這些關鍵字？</div>
            </>
          )}
        />

        <ClearAllModal
          visible={this.clearAllModalVM.visible}
          onOk={this.vm.clearAll}
          onCancel={this.clearAllModalVM.hide}
          projectName={this.vm.name}
          text="清空專案搜尋關鍵字目前儲存的設定之外，也將刪除專案中這些搜尋關鍵字相關的口碑結果，處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。"
        />

        <ImportModal
          onUpload={this.vm.onUpload}
          visible={this.importModalVM.visible}
          hide={this.importModalVM.hide}
          type="search"
        />

        <EditDrawer
          searchAngle={this.vm.searchAngle}
          vm={this.vm.editDrawer}
          keywordPool={this.vm.keywordPool}
          tagPool={this.vm.tagPool}
        />


        <RefDrawer
          visible={this.refDrawerVM.visible}
          onClose={this.refDrawerVM.hide}
          onRef={this.vm.onRef}
          type="search"
        />

        {this.vm.importDrawer && (
          <Drawer
            destroyOnClose
            title="匯入搜尋關鍵字"
            width="90%"
            placement="right"
            onClose={this.vm.onDrawerClose}
            visible={this.vm.importDrawer?.visible ?? false}
            zIndex={100}
            maskClosable={false}
            footer={(
              <Button
                type="primary"
                onClick={this.vm.onImport}
                disabled={!this.vm.importDrawer.canExport}
              >
                確定匯入
              </Button>
            )}
          >
            <ImportDrawer vm={this.vm.importDrawer} />
          </Drawer>
        )}

        {this.vm.isProcessingData && (
          <RootModal>
            <div className={styles.modal}>
              <Spin
                spinning
                tip="正在處理資料"
              />
            </div>
          </RootModal>
        )}

      </>
    );
  }
}

SearchSetPane.propTypes = {
  router: PropTypes.objectOf(PropTypes.any)
};

SearchSetPane.defaultProps = {
  router: null
};

export default withRouter(SearchSetPane);
