import React from 'react';
import { observer, Observer } from 'mobx-react';
import { Row, Col, Button, Spin, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import AnalysisVM from 'src/viewModels/Analysis';

import { LOG_TYPE } from 'src/constants';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ActionBar from './ActionBar';

@observer
class AnalysisPage extends React.Component {

  anchorRef = React.createRef();

  constructor(props) {
    super(props);
    this.vm = new AnalysisVM();
    this.columns = [
      {
        title: <Row justify="center">分析類型</Row>,
        key: 'type',
        width: 150,
        render: (data) => (
          <div>{LOG_TYPE[data.log.type]?.name ?? '-'}</div>
        )
      },
      {
        title: <Row justify="center">分析設定</Row>,
        key: 'settings',
        width: 200,
        render: (data) => (
          <div style={{ width: 160 }}>
            <div>{`頻道組合：${data.log?.channelCount ?? '-'}`}</div>
            <div>{`搜尋關鍵字：${data.log?.keywordCount ?? '-'}`}</div>
            <div>{`特性關鍵字：${data.log?.featureCount ?? '-'}`}</div>
          </div>
        )
      },
      {
        title: <Row justify="center">所屬專案</Row>,
        key: 'project',
        render: (data) => (
          <a href={`/project/settings/${data.log.projectId}`}>
            <Col>
              <div>{data.log.name}</div>
              <div>{`(${data.log.projectId})`}</div>
            </Col>
          </a>
        )
      },
      {
        title: <Row justify="center">分析建置時間</Row>,
        key: 'type',
        width: 160,
        render: (data) => (
          <div>
            {data.log.createdAt
              ? dayjs(data.log.createdAt).format('YYYY-MM-DD HH:mm')
              : '-'}
          </div>
        )
      },
      {
        title: <Row justify="center">資料分析區間</Row>,
        key: 'type',
        width: 220,
        render: (data) => (
          <div>
            {`
            ${dayjs(data.log.startDate).format('YYYY-MM-DD')} -
            ${dayjs(data.log.finishDate).format('YYYY-MM-DD')}
            `}
          </div>
        )
      },
      {
        title: <Row justify="center">開始分析時間</Row>,
        key: 'type',
        width: 160,
        render: (data) => (
          <div>
            {data.log.beginAt
              ? dayjs(data.log.beginAt).format('YYYY-MM-DD HH:mm')
              : '-'}
          </div>
        )
      },
      {
        title: <Row justify="center">結束分析時間</Row>,
        key: 'type',
        width: 160,
        render: (data) => (
          <div>
            {data.log.endAt
              ? dayjs(data.log.endAt).format('YYYY-MM-DD HH:mm')
              : '-'}
          </div>
        )
      },
      {
        title: <Row justify="center">分析進度</Row>,
        key: 'type',
        width: 150,
        render: (data) => (
          <div>{`${data.log?.progress ?? '- '}%`}</div>
        )
      },
      {
        title: <Row justify="center">分析狀態</Row>,
        key: 'stageDesc',
        width: 150,
        render: (data) => (
          <Row align="middle">
            <div style={{ margin: '0 5px' }}>{data.log.stageDesc}</div>
            {data.log.message && (
              <Tooltip
                trigger={['hover']}
                title={data.log.message}
              >
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Row>
        )
      },
      {
        title: <Row justify="center">操作</Row>,
        key: 'type',
        width: 160,
        render: (data) => (
          <Observer>
            {() => (
              <>
                {
              data.log.cancellable
                ? <Button type="primary" onClick={data.confirmBacktrack}>中止回溯</Button>
                : <Button type="primary" onClick={() => data.downloadBacktrackSetting(this.anchorRef.current)}>檢視設定</Button>
              }
              </>
            )}
          </Observer>
        )
      }
    ];
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div>
        <ActionBar onQuery={this.vm.onQuery} />

        <ReactResizeDetector handleHeight>
          {({ height = 0 }) => (
            <Observer>
              {() => (
                <Spin
                  tip="正在加載資料"
                  spinning={this.vm.isLoading}
                >
                  <div
                    style={{ height: 'calc(100vh - 120px)' }}
                  >

                    <Table
                      dataSource={this.vm.list}
                      rowKey="id"
                      size="small"
                      lineCount={3}
                      columns={this.columns}
                      onFetch={this.vm.onBottom}
                      scroll={{ y: height, x: 1800 }}
                    />

                  </div>

                </Spin>
              )}
            </Observer>
          )}
        </ReactResizeDetector>

        {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
        <a ref={this.anchorRef} />
      </div>
    );
  }
}

export default withProfile(withRouter(AnalysisPage), true);
