import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Row, Button, Spin } from 'antd';

// Top100特性關鍵字話題列表

@observer
class FeatureRankTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: <Row justify="center">排行</Row>,
        key: 'index',
        width: 80,
        render: (data, _, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: <Row justify="center">子群組</Row>,
        key: 'groupName',

        render: (data) => (<div>{data.groupName}</div>)
      },
      {
        title: <Row justify="center">關鍵字</Row>,
        key: 'name',
        render: (data) => (
          <div>
            <Button
              type="link"
              onClick={() => this.props.showFeatureTopicDrawer(data.fid)}
            >
              {data.name}
            </Button>
          </div>
        )
      },
      {
        title: <Row justify="center">UID</Row>,
        key: 'fid',
        width: 250,
        render: (data) => (<Row justify="center">{data.fid}</Row>)
      },
      {
        title: <Row justify="center">提及數</Row>,
        key: 'count',
        width: 180,
        render: (data) => (<Row justify="center">{data.count}</Row>)
      }
    ];
  }

  render() {
    return (
      <div style={{ height: 'calc(100vh - 190px)' }}>
        <h3>Top100特性關鍵字</h3>
        <Spin
          spinning={this.props.isLoading}
          tip="正在加載資料"
        >
          <Table
            rowKey="fid"
            dataSource={this.props.data}
            columns={this.columns}
            size="small"
            pagination={false}
            scroll={{ y: 'calc(100vh - 270px)' }}
          />
        </Spin>
      </div>
    );
  }
}

FeatureRankTable.propTypes = {
  data: PropTypes.array.isRequired,
  showFeatureTopicDrawer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default FeatureRankTable;
