import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Input, Tag, Typography } from 'antd';

const SubTag = observer((props) => {
  const [value, setValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const [style, setStyle] = useState({ display: 'inherit' });

  const handleInputConfirm = async (e) => {
    if (e.keyCode === 229) return;

    if (value === props.name) {
      setInputVisible(false);
      return;
    }

    try {
      await props.onUpdate(value);
    } catch {
      setValue(props.name);
    } finally {
      setInputVisible(false);
    }
  };

  useEffect(() => {
    setValue(props.name);
  }, [props.name]);

  if (!props.canEdit) {
    return (
      <Tag style={{ margin: 5 }}>
        <Typography.Paragraph
          style={{ display: 'inline-block' }}
          copyable={
            props.canCopy
              ? { text: props.id, tooltips: [props.id] }
              : null
          }
        >
          {value}
        </Typography.Paragraph>
      </Tag>
    );
  }

  return (
    <div style={{ display: 'inline-block', margin: '3px 0' }}>
      {inputVisible && (
      <Input
        type="text"
        size="small"
        style={{ width: 150 }}
        value={value}
        autoFocus
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
      />
      )}
      {!inputVisible && (
      <Tag
        closable={props.closable}
        style={style}
        onClose={async () => {
          try {
            await props.onClose();
            setStyle({ display: 'none' });
          } catch {
            setStyle({ display: 'inherit' });
          }
        }}
        onDoubleClick={() => {
          setValue(value || props.name);
          setInputVisible(true);
        }}
      >
        <Typography.Paragraph
          style={{ display: 'inline-block' }}
          copyable={
            props.canCopy
              ? { text: props.id, tooltips: [props.id] }
              : null
            }
        >
          {value}
        </Typography.Paragraph>
      </Tag>
      )}
    </div>
  );
});

SubTag.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  canCopy: PropTypes.bool,
  closable: PropTypes.bool,
  canEdit: PropTypes.bool
};

SubTag.defaultProps = {
  canCopy: true,
  closable: true,
  canEdit: true
};

export default SubTag;
