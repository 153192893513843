import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const CustomToolTip = observer((props) => {
  const date = dayjs(props.data.disableDate).format('YYYY-MM-DD');
  return (
    <Tooltip title={props.data.disableDate ? `已於${date}停用` : null}>
      <div style={{ width: '100%' }}>
        {props.children}
      </div>
    </Tooltip>
  );
});

CustomToolTip.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired
};

export default CustomToolTip;
