import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Table, Row } from 'antd';

import NewTag from 'src/components/NewTag';
import SubTag from 'src/components/SubTag';
import ParentTag from 'src/components/ParentTag';

import styles from './styles.module.css';

const CategoryTable = observer((props) => {
  const columns = () => {
    const index = {
      title: (<div className={styles.alignCenter}>序號</div>),
      width: 50,
      key: 'index',
      render: (_, __, i) => (
        <div className={styles.alignCenter}>{i + 1}</div>
      )
    };

    const name = {
      title: '母群組',
      key: 'name',
      render: (data) => (
        <Observer>
          {() => (
            <ParentTag
              canCopy={props.canCopy}
              canEdit={props.canEdit}
              name={data.name}
              id={data.cid}
              onUpdate={(newName) => {
                props.vm.updateCategory(data.cid, newName);
              }}
            />
          )}
        </Observer>
      )
    };

    const subCategory = {
      title: (
        <Row>
          <div>子群組</div>
          {props.canEdit && <div className={styles.note}>(雙擊子群組可編輯子群組內容)</div>}
        </Row>
      ),
      key: 'subCategory',
      render: (data) => (
        <Observer>
          {() => (
            <div>
              {data.subCategory.map((subC) => (
                <SubTag
                  canCopy={props.canCopy}
                  canEdit={props.canEdit}
                  key={subC.id}
                  name={subC.name}
                  id={subC.id}
                  onClose={async () => props.vm.deleteSubCategory(data.cid, subC.id)}
                  onUpdate={(newName) => props.vm.updateSubCategory(data.cid, subC.id, newName)}
                />
              ))}
              {props.canAddTag && (<NewTag action={(value) => props.vm.createSubCategory(data.cid, value)} />)}
            </div>
          )}
        </Observer>
      )
    };

    if (props.vm.feature?.level === 2) return [index, name];
    return [index, { ...name, width: 400 }, subCategory];
  };

  return (
    <Table
      dataSource={props.vm.category}
      rowKey="cid"
      size="small"
      columns={columns()}
      loading={false}
      pagination={false}
      scroll={{
        crollToFirstRowOnChange: true,
        x: 800,
        y: props.scrollY || 600
      }}
      rowSelection={props.canSelectRow ? {
        selectedRowKeys: props.vm.selectedCategoryKey,
        onChange: (selectedRowKeys, selectedRows) => {
          props.vm.onCategprySelectChange(selectedRowKeys);
        }
      } : null}
    />
  );
});

CategoryTable.propTypes = {
  vm: PropTypes.object.isRequired,
  canCopy: PropTypes.bool,
  canSelectRow: PropTypes.bool,
  canAddTag: PropTypes.bool,
  canEdit: PropTypes.bool,
  scrollY: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CategoryTable.defaultProps = {
  canCopy: true,
  canSelectRow: true,
  canAddTag: true,
  canEdit: true
};

export default CategoryTable;
