
import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message } from 'antd';
import { nanoid } from 'nanoid';

export default class SubTag {

  id = nanoid();

  importDrawerVM = null;

  parentTag = null;

  @observable name = '';

  constructor(name, parentTag, importDrawerVM) {
    makeObservable(this);

    this.importDrawerVM = importDrawerVM;

    runInAction(() => {
      this.name = name;
      this.parentTag = parentTag;
    });

  }

  @action
  update = (newName) => {
    const isRepeated = this.parentTag.subTags.some((tag) => tag.name === newName);
    if (isRepeated) {
      message.error('名稱重複，無法更新');
      throw new Error();
    }

    this.importDrawerVM.updateSubTag(this.name, newName, this.parentTag.fullName);
    this.name = newName;
  }

  delete = () => {
    this.parentTag.deleteSubTag(this.name);
  }
}

