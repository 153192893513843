import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Row, Select, Tabs } from 'antd';
import withRouter from 'src/components/withRouter';
import { SEARCH_KEYWORD_TXT } from 'src/constants';
import DownloadDataVM from 'src/viewModels/ProjectReports/DownloadData';
import styles from './styles.module.css';

// 下載數據列表
@observer
class DownloadData extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DownloadDataVM(this.props.router.params.id);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    const txt = SEARCH_KEYWORD_TXT[this.props.project?.meta?.searchAngle]?.L1 ?? '';

    return (
      <>
        <Row align="middle" className={styles.margin}>
          <div>下載trend：</div>
          <DatePicker.RangePicker
            onChange={this.vm.onDateChange}
          />
        </Row>

        <Row align="middle" className={styles.margin}>
          <div>
            {`選擇${txt}：`}
          </div>
          <Select
            allowClear
            onChange={this.vm.onKeywordChange}
            className={styles.select}
            mode="multiple"
            optionFilterProp="children"
            placeholder={`選擇${txt}`}
          >
            {this.vm.keywords.map((keyword) => (
              <Select.Option value={keyword._id} key={keyword._id}>
                {keyword.name}
              </Select.Option>
            ))}
          </Select>
        </Row>

        <div className={styles.extra}>
          ※ 不選 = 全選
        </div>

        <Button
          type="primary"
          onClick={this.vm.downloadTrend}
          className={styles.margin}
        >
          產出trend
        </Button>
      </>
    );
  }
}

DownloadData.propTypes = {
  router: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

export default withRouter(DownloadData);
