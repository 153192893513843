import React, { useState, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Row, Button, Tag, Typography, Drawer, Spin, message, Checkbox } from 'antd';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import DrawerViewModel from 'src/viewModels/drawer';
import ModalViewModel from 'src/viewModels/modal';
import CustomToolTip from 'src/components/DisableToolTip';
import ActionBar from 'src/components/search/L2ActionBar';
import SortButton from 'src/components/SortButton';
import CreateItem from '../CreateItem';
import AttachTagModal from '../AttachTagModal';
import { TEXT } from '../constants';

import styles from './styles.module.css';

const L2Pane = observer((props) => {
  const [columns, setColumns] = useState(null);
  const [createDrawerVM] = useState(() => new DrawerViewModel());
  const [attachTagVM] = useState(() => new ModalViewModel());

  useEffect(() => {
    props.vm.L2.getAllKeywords(1);
    props.vm.L2.query();
  }, [props.vm]);

  useEffect(() => {
    const ellipsisRows = (props.vm.L2.tags.length > 2) ? props.vm.L2.tags.length : 2;

    setColumns([
      {
        title: (
          <Observer>
            {() => (
              <Row justify="center">
                <Checkbox checked={props.vm.L2.isSelecteAll} onChange={props.vm.L2.selectAll} />
              </Row>
            )}
          </Observer>
        ),
        width: 50,
        key: 'checkbox',
        render: (data) => (
          <Observer>
            {() => (
              <Checkbox checked={data.isSelected} onChange={() => data.toggleIsSelected()} />
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">序號</Row>,
        width: 50,
        key: 'index',
        render: (data, __, i) => (
          <CustomToolTip data={data}>
            <Row justify="center">{i + 1}</Row>
          </CustomToolTip>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L1}名稱`}
          </Row>
        ),
        width: 200,
        key: 'l1',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={{ text: data.sid, tooltips: [data.sid] }}
                  style={{ width: 180 }}
                  className={data.enable ? '' : styles.disable}
                  ellipsis={{ rows: ellipsisRows }}
                >
                  {props.vm.keywordPool.get(data.sid)?.name ?? '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <SortButton
                title={`${TEXT[props.vm.searchAngle]?.L2 ?? ''}名稱`}
                order={props.vm.L2.order}
                onOrderChange={props.vm.L2.onOrderChange}
              />
            )}
          </Observer>
        ),
        width: 200,
        key: 'l2',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={{ text: data.id, tooltips: [data.id] }}
                  style={{ width: 180 }}
                  className={data.enable ? '' : styles.disable}
                  ellipsis={{ rows: ellipsisRows }}
                >
                  {data.name || '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L2}搜尋關鍵字`}
          </Row>
        ),
        key: 'keyword',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>
                  {data.keyword || '-'}
                </div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">標籤</Row>,
        width: 200,
        key: 'tag',
        render: (data) => {
          if (!data.tags) return null;
          return (
            <Observer>
              {() => (
                <CustomToolTip data={data}>
                  {data.tags.map((t) => (
                    <Row key={t.value} className={styles.paddingLeft}>
                      <div>{`${props.vm.tagPool.get(t.key)?.name ?? ''}：`}</div>
                      <Tag key={t.value}>{props.vm.tagPool.get(t.value)?.name ?? ''}</Tag>
                    </Row>
                  ))}
                </CustomToolTip>
              )}
            </Observer>
          );
        }
      },
      {
        title: <Row justify="center">同義詞</Row>,
        key: 'synonym',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>
                  {data.synonym || '-'}
                </div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">執行動作</Row>,
        width: 150,
        key: 'action',
        render: (data) => {
          if (data.enable) {
            return (
              <Button
                type="link"
                className={styles.noPadding}
                onClick={() => props.vm.editDrawer.show(data)}
              >
                檢視與編輯
              </Button>
            );
          }
          return (
            <Button
              danger
              type="link"
              className={styles.noPadding}
              onClick={() => props.vm.L2.delete(data)}
            >
              刪除
            </Button>
          );
        }
      }
    ]);
  }, [props.vm, props.vm.L2, props.vm.searchAngle, props.vm.L2.tags.length]);

  return (
    <>
      <Row className={styles.marginBottom} justify="end">
        <Button
          className={styles.marginRight}
          type="primary"
          ghost
          onClick={() => {
            if (props.vm.L2.selectedKeywordLength === 0) return message.warn('請勾選資料項目');
            attachTagVM.show();
            return null;
          }}
        >
          一鍵貼標
        </Button>
        <Button danger className={styles.marginRight} onClick={() => props.vm.L2.delete()}>
          {`刪除已勾選${TEXT[props.vm.searchAngle]?.L2}`}
        </Button>
        <Button danger className={styles.marginRight} onClick={() => props.vm.L2.disable()}>
          {`永久停用已勾選${TEXT[props.vm.searchAngle]?.L2}`}
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            createDrawerVM.show();
            props.vm.L2.addNewKeyword();
          }}
        >
          {`＋新增${TEXT[props.vm.searchAngle]?.L2 ?? ''}`}
        </Button>
      </Row>


      <ActionBar
        onQuery={props.vm.L2.onQuery}
        tags={props.vm.L2.tags}
        L1KeywordOption={props.vm.L2.L1KeywordOption}
        searchAngle={props.vm.searchAngle}
        keywordPool={props.vm.keywordPool}
        tagPool={props.vm.tagPool}
        mode="myProject"
      />

      {columns && (
      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <Spin
                tip="正在加載資料"
                spinning={props.vm.L2.isTableLoading}
              >
                <div
                  style={{ height: 'calc(100vh - 410px)' }}
                  className={styles.tableContainer}
                >
                  <Table
                    dataSource={props.vm.L2.keywords}
                    rowKey="id"
                    size="small"
                    rowClassName={(record) => {
                      if (record.enable) return '';
                      return styles.disable;
                    }}
                    columns={columns}
                    onFetch={props.vm.L2.onBottom}
                    scroll={{ y: height, x: 1500 }}
                    lineCount={props.vm.L2.tags.length}
                  />
                </div>
              </Spin>
            )}
          </Observer>
        )}
      </ReactResizeDetector>
      )}

      <Drawer
        title="新增搜尋關鍵字"
        width={600}
        onClose={() => {
          props.vm.L2.resetNewKeyword();
          createDrawerVM.hide();
        }}
        visible={createDrawerVM.visible}
        maskClosable={false}
        footer={(
          <Button
            type="primary"
            disabled={!props.vm.L2.isOkToCreate}
            loading={props.vm.L2.isCreating}
            onClick={async () => {
              await props.vm.L2.create();
              props.vm.L2.resetNewKeyword();
              createDrawerVM.hide();
            }}
          >
            新增
          </Button>
        )}
        footerStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <>
          {props.vm.L2.newKeywords.map((k, index) => (
            <CreateItem
              key={k.id}
              vm={k}
              onDelete={() => props.vm.L2.deleteNewKeyword(index)}
            />
          ))}
          <Row justify="center">
            <Button type="primary" onClick={() => props.vm.L2.addNewKeyword()} className={styles.marginRight}>
              新增下一筆
            </Button>
            <Button type="primary" onClick={() => props.vm.L2.addNewKeyword(true)}>
              新增同分類搜尋關鍵字
            </Button>
          </Row>
        </>

      </Drawer>


      <AttachTagModal
        visible={attachTagVM.visible}
        onClose={attachTagVM.hide}
        tagPool={props.vm.tagPool}
        tags={props.vm.L2.tags}
        length={props.vm.L2.selectedKeywordLength}
        onOk={props.vm.L2.updateTagsBatch}
      />


    </>
  );
});

L2Pane.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired
};

export default L2Pane;
