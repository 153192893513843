import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Table, Tag, Row } from 'antd';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

const TagPane = observer((props) => {


  const columns = () => {
    return [
      {
        title: (<Row justify="center">序號</Row>),
        width: 50,
        key: 'index',
        render: (_, __, i) => (
          <Row justify="center">{i + 1}</Row>
        )
      },
      {
        title: '母標籤',
        key: 'parentTag',
        width: 200,
        render: (data) => (<div>{data.name}</div>)
      },
      {
        title: '所屬階層',
        key: 'level',
        width: 200,
        render: (data) => {
          let text = '';
          const searchAngle = props.vm.searchAngle;
          switch (data.scope) {
            case 'l2':
              text = SEARCH_KEYWORD_TXT[searchAngle]?.L2;
              break;
            case 'l3':
              text = SEARCH_KEYWORD_TXT[searchAngle]?.L3;
              break;
            case 'all':
              text = SEARCH_KEYWORD_TXT[searchAngle]?.all;
              break;
            default:
              text = '';
          }
          return (<div>{text}</div>);
        }
      },
      {
        title: '子標籤',
        key: 'subTags',
        render: (data) => (
          <div>
            {data.subTags.map((sub) => {
              if (sub.default) return (<Tag key={sub.id}>其他</Tag>);

              return (<Tag key={sub.id}>{sub.name}</Tag>);
            })}
          </div>
        )
      }
    ];
  };

  return (
    <div style={{ height: 'calc(100vh - 210px)' }}>
      <Table
        dataSource={props.vm.tags}
        rowKey="id"
        size="small"
        columns={columns()}
        loading={false}
        pagination={false}
        scroll={{
          y: 'calc(100vh - 260px)'
        }}
      />
    </div>
  );

});

TagPane.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired
};

TagPane.defaultProps = {

};

export default TagPane;
