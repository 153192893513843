import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const SkipKeywordBtn = (props) => {
  return (
    <div style={{ position: 'absolute', top: 35, left: '-145px' }}>
      <Button
        style={{ marginRight: 5 }}
        onClick={props.callback}
      >
        不設定關鍵字
      </Button>
      <Tooltip
        title="因系統預設此欄位不能空白，若不想設定關鍵字，必須填入固定字串「這裡不搜不搜不搜」，讓分析程式辨識略過不處理，以免浪費分析資源"
      >
        <ExclamationCircleOutlined />
      </Tooltip>
    </div>
  );
};

SkipKeywordBtn.propTypes = {
  callback: PropTypes.func.isRequired
};

export default SkipKeywordBtn;
