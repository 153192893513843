import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, DatePicker, Row, Select, Radio, Col, Spin, Modal } from 'antd';
import withRouter from 'src/components/withRouter';
import ScheduleVM from 'src/viewModels/ProjectAnalysis/Schedule';
import Stat from '../components/Stat';

import styles from './styles.module.css';

// 下載話題列表
@observer
class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ScheduleVM(this.props.router.params.id, this.props.changeTabAndRefresh);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <Spin
        spinning={this.vm.isWaiting}
        size="large"
        tip={(
          <div className={styles.spinningText}>
            <div>6秒後跳轉『專案分析紀錄』</div>
            <div>若無對應紀錄，請『點擊套用』或『執行手動回溯』</div>
          </div>
        )}
      >
        <Row justify="space-between">
          <Col>
            <Row align="middle" className={styles.margin}>
              <div className={styles.title}>專案資料起訖時間：</div>
              <DatePicker.RangePicker
                onChange={this.vm.onDateChange}
                value={[this.vm.data.startDate, this.vm.data.endDate]}
              />
            </Row>

            <Row align="middle" className={styles.margin}>
              <div className={styles.title}>當日資料自動排程：</div>
              <Radio.Group
                className={styles.margin}
                value={this.vm.data.autoRun}
                onChange={this.vm.onAutoRunChange}
              >
                <Radio value>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Row>

            <Row align="middle" className={styles.margin}>
              <div className={styles.title}>更新前一日資料完成時間：</div>
              <Radio.Group
                className={styles.margin}
                value={this.vm.data.updateTimeRequirement}
                onChange={this.vm.onTimeRequirementChange}
              >
                <Radio value>9:00 前</Radio>
                <Radio value={false}>不指定</Radio>
              </Radio.Group>
            </Row>

            <Row align="middle" className={styles.margin}>
              <div className={styles.title}>
                {!this.vm.isRun && <div className={styles.star}>*</div>}
                比對頻道名稱設定：
              </div>
              {this.vm.isRun
                ? (
                  <div>
                    {this.vm.data.checkChannelName
                      ? '搜尋關鍵字比對頻道名稱'
                      : '搜尋關鍵字不比對頻道名稱'}
                  </div>
                )
                : (
                  <Radio.Group
                    className={styles.margin}
                    value={this.vm.data.checkChannelName}
                    onChange={this.vm.onCheckChannelNameChange}
                  >
                    <Radio value>搜尋關鍵字比對頻道名稱</Radio>
                    <Radio value={false}>搜尋關鍵字不比對頻道名稱</Radio>
                  </Radio.Group>
                )}
            </Row>

            {!this.vm.isRun && (
              <div className={styles.warn}>
                請注意，比對頻道名稱設定選擇送出後就無法再進行修改。
              </div>
            )}
          </Col>


          {this.vm.isRun && (
            <Col className={styles.colRight}>
              <div>特性檔案口碑資料區間：</div>
              {this.props.features.map((feature) => {
                const name = feature.name;
                const vocRange = this.vm.vocRange.find((item) => item.setId === feature.fid);
                const dateString = vocRange
                  ? `${dayjs(vocRange.from).format('YYYY/MM/DD')} - ${dayjs(vocRange.to).format('YYYY/MM/DD')}`
                  : '尚無資料';

                return (<div key={feature.fid}>{`${name}：${dateString}`}</div>);
              })}
            </Col>
          )}
        </Row>

        <Stat
          channelStat={this.props.channelStat}
          searchStat={this.props.searchStat}
          searchAngle={this.props.searchAngle}
        />

        <Row
          className={styles.margin}
          align="middle"
        >
          <div className={styles.title}>特性關鍵字檔案：</div>
          <Select
            mode="multiple"
            placeholder="請選擇檔案(可複選)"
            style={{ width: 300 }}
            showArrow
            value={this.vm.data.featureSets}
            onSelect={this.vm.onFeatureSetsSelect}
            onDeselect={(e) => {
              Modal.confirm({
                okText: '確定',
                cancelText: '取消',
                title: '確定是否取消此特性檔案?',
                content: (
                  <>
                    <br />
                    <div>確定取消後並點擊儲存設定鈕將造成以下影響：</div>
                    <br />
                    <div>1. 後續不再進行此特性檔案分析</div>
                    <div>2.系統將自動刪除此特性檔案所有相關口碑資料</div>
                  </>
                ),
                onOk: (close) => {
                  this.vm.onFeatureSetsDeselect(e);
                  close();
                }
              });
            }}
          >
            {this.props.features.map((item) => (
              <Select.Option
                key={item.fid}
                value={item.fid}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Row>


        <Row justify="center">
          <Button
            type="primary"
            onClick={this.vm.save}
            className={styles.margin}
          >
            {this.vm.isRun ? '儲存設定' : '啟動專案'}
          </Button>
        </Row>

      </Spin>
    );
  }
}

Schedule.propTypes = {
  router: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  channelStat: PropTypes.array.isRequired,
  searchStat: PropTypes.object.isRequired,
  searchAngle: PropTypes.string.isRequired,
  changeTabAndRefresh: PropTypes.func.isRequired
};

export default withRouter(Schedule);
