import { makeObservable, observable, action, runInAction } from 'mobx';
import dayjs from 'dayjs';
import ProjectService from 'src/services/project';
import ProjectScheduleService from 'src/services/projectSchedule';
import { LIMIT } from 'src/constants';
import { errorModal } from 'src/utils';
import LogItem from './LogItem';

export default class Analysis {
  projectId = '';
  page = 1;
  hasNextPage = true;
  isLoading = false;

  @observable list = [];
  @observable featureSets = [];

  // default required conditions
  @observable queryCondition = {
    startDate: dayjs().subtract(30, 'day').startOf('day').toISOString(),
    endDate: dayjs().endOf('day').toISOString()
  };
  @observable drawerVisible = false;


  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  didMount = async () => {
    this.getList();
  }

  getFeatureSets = async () => {
    try {
      const res = await ProjectService.getProject(this.projectId);
      runInAction(() => {
        this.featureSets = res.autorun.featureSets;
      });
    } catch {
      // ignore
    }
  }

  getList = async () => {
    try {
      if (this.isLoading) return;

      runInAction(() => {
        this.isLoading = true;
      });

      const res = await ProjectScheduleService.projectScheduleLog(this.projectId, {
        ...this.queryCondition,
        page: this.page,
        limit: LIMIT
      });

      if (res.length < LIMIT) {
        this.hasNextPage = false;
      }

      const logs = res.map((log) => new LogItem(log));

      runInAction(() => {
        this.list = [...this.list, ...logs];
        this.page += 1;
        this.isLoading = false;
      });

    } catch {
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法取得分析紀錄');
    }
  }

  @action
  onQuery = (newCondition) => {
    this.queryCondition = newCondition;
    this.refresh();
  }

  @action
  onBottom = () => {
    if (this.hasNextPage) {
      this.getList();
    }
  }


  @action
  showDrawer = async () => {
    this.drawerVisible = true;
    await this.getFeatureSets();
  }

  @action
  hideDrawer = () => {
    this.drawerVisible = false;
  }

  @action
  refresh = () => {
    this.page = 1;
    this.list = [];
    this.getList();
  }

}
