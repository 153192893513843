
import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { keywordParse } from 'src/services/keywordParser';
import { nanoid } from 'nanoid';

export default class Keyword {

  level = null;
  importDrawerVM = null;
  id = nanoid();

  @observable level1 = '';
  @observable level2 = '';
  @observable level3 = '';
  @observable keyword = '';
  @observable synonym = '';
  @observable tags = null;

  constructor(keywordItem, importDrawerVM) {
    makeObservable(this);

    this.importDrawerVM = importDrawerVM;

    runInAction(() => {
      this.level1 = keywordItem.level1;
      this.level2 = keywordItem.level2;
      this.level3 = keywordItem.level3;
      this.keyword = keywordItem.keyword;
      this.synonym = keywordItem.synonym;
      this.tags = keywordItem.tags;

      if (this.level3) {
        this.level = 'level3';
      } else if (this.level2) {
        this.level = 'level2';
      } else {
        this.level = 'level1';
      }
    });

  }
  @computed
  get name() {
    return this[this.level];
  }
  @computed
  get keywordResult() {
    const { result, reasons } = keywordParse(this.keyword);
    const hasError = (result === 'error') || (result === 'notComplete');
    const hasWaran = (result === 'warn');
    return { result, reasons, hasError, hasWaran };
    // return { result: 'success', reasons: [], hasError: false, hasWaran: false };
  }
  @action
  updateSelf = ({ name, keyword, synonym }) => {
    this.keyword = keyword;
    this.synonym = synonym;

    this.importDrawerVM.updateKeyowrd(this.level, this.name, name, this.level1, this.level2);
  }
  @action
  updateName = (level, oldName, newName) => {
    if (this[level] === oldName) {
      this[level] = newName;
    }
  }
  @action
  updateTag = (key, value) => {
    this.tags[key] = value;
  }
  delete = () => {
    this.importDrawerVM.deleteKeyword(this.level, this.name);
  }

}

