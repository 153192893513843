import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Button, Table, Tag } from 'antd';
import ParentTag from 'src/components/ParentTag';
import SubTag from 'src/components/SubTag';
import { TEXT } from '../constants';
import L1Pane from './L1Pane';
import L2Pane from './L2Pane';
import L3Pane from './L3Pane';
import EditDrawer from './EditDrawer';

@observer
class ImportDrawer extends React.Component {
  render() {
    return (
      <div style={{ height: '100%', overflow: 'hidden' }}>
        <Tabs type="card">
          <Tabs.TabPane key={1} tab={TEXT[this.props.vm.searchAngle]?.L1}>
            <L1Pane vm={this.props.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab={TEXT[this.props.vm.searchAngle]?.L2}>
            <L2Pane vm={this.props.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={3} tab={TEXT[this.props.vm.searchAngle]?.L3}>
            <L3Pane vm={this.props.vm} />
          </Tabs.TabPane>
          <Tabs.TabPane key={4} tab="標籤">
            <Table
              dataSource={this.props.vm.tags}
              rowKey="id"
              size="small"
              columns={(
                [
                  {
                    title: (<Row justify="center">序號</Row>),
                    width: 50,
                    key: 'index',
                    render: (_, __, i) => (
                      <Row justify="center">{i + 1}</Row>
                    )
                  },
                  {
                    title: '母標籤',
                    key: 'parentTag',
                    width: 200,
                    render: (data) => (
                      <Observer>
                        {() => (
                          <ParentTag
                            canCopy={false}
                            name={data.name}
                            id={data.id}
                            onUpdate={(newName) => data.update(newName)}
                          />
                        )}
                      </Observer>
                    )
                  },
                  {
                    title: '所屬階層',
                    key: 'level',
                    width: 150,
                    render: (data) => {
                      let text = '';
                      const searchAngle = this.props.vm.searchAngle;
                      switch (data.scope) {
                        case 'l2':
                          text = TEXT[searchAngle]?.L2;
                          break;
                        case 'l3':
                          text = TEXT[searchAngle]?.L3;
                          break;
                        case 'all':
                          text = TEXT[searchAngle]?.all;
                          break;
                        default:
                          text = '';
                      }
                      return (<div>{text}</div>);
                    }
                  },
                  {
                    title: (
                      <Row>
                        <div>子標籤</div>
                        <div style={{ color: 'rgb(177, 177, 177)' }}>(雙擊子標籤可編輯子標籤內容)</div>
                      </Row>
                    ),
                    key: 'subTags',
                    render: (data) => (
                      <Observer>
                        {() => (
                          <>
                            {data.subTags.map((sub) => {
                              if (sub.name !== '其他') {
                                return (
                                  <SubTag
                                    canCopy={false}
                                    key={sub.id}
                                    name={sub.name}
                                    id={sub.id}
                                    onClose={sub.delete}
                                    onUpdate={(newName) => sub.update(newName)}
                                  />
                                );
                              }
                              return <Tag key="other">其他</Tag>;
                            })}
                          </>
                        )}
                      </Observer>
                    )
                  },
                  {
                    title: '操作',
                    key: 'action',
                    width: 200,
                    render: (data) => (
                      <Button type="link" onClick={data.delete}>刪除</Button>
                    )
                  }
                ]
              )}
              pagination={false}
              scroll={{
                crollToFirstRowOnChange: true,
                x: 800,
                y: 'calc(100vh - 250px)'
              }}
            />
          </Tabs.TabPane>
        </Tabs>

        {this.props.vm.edit ? (<EditDrawer vm={this.props.vm.edit} />) : null}
      </div>
    );
  }
}

ImportDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

export default ImportDrawer;
