export default class FeatureKeyword {
  constructor(params) {
    const {
      subCid,
      name,
      keyword,
      _id,
      featureSetId,
      cid
    } = params;

    this.kid = _id;
    this.keyword = keyword;
    this.name = name;
    this.featureSetId = featureSetId;
    this.cid = cid;
    this.subCid = subCid;
  }

  static fromRes(data) {
    return new FeatureKeyword(data);
  }
}
