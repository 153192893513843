import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Button, Empty } from 'antd';

import withRouter from 'src/components/withRouter';
import FeatureSetViewModel from 'src/viewModels/ProjectSettings/Feature';
import ModalViewModal from 'src/viewModels/modal';
import SubTabs from './components/SubTabs';
import CreateFeatureModal from './components/CreateFeatureModal';

@observer
class FeatureSetPane extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new FeatureSetViewModel(props.router.params.id);
    this.createModalVM = new ModalViewModal();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  createButtons = () => (
    <Row justify="end">
      <Button
        type="primary"
        onClick={this.createModalVM.show}
        disabled={this.vm.featureSet?.length === 5}
      >
        ＋ 新增特性關鍵字檔案
      </Button>
    </Row>
  )

  render() {
    return (
      <>
        {this.vm.featureSet?.length === 0 && this.createButtons()}
        {this.vm?.featureSet?.length === 0 && <Empty style={{ marginTop: '30vh' }} />}


        <Tabs
          type="card"
          tabBarExtraContent={this.vm.featureSet?.length !== 0 && this.createButtons()}
        >
          {this.vm.featureSet && this.vm.featureSet.map((feature) => (
            <Tabs.TabPane key={feature.fid} tab={feature.name}>
              <SubTabs
                feature={feature}
                onUpdateInfo={this.vm.onUpdateInfo}
                onDeleteFeatureFile={this.vm.onDeleteFeatureFile}
                projectMetaData={this.vm.projectMetaData}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>

        <CreateFeatureModal
          visible={this.createModalVM.visible}
          onCancel={this.createModalVM.hide}
          onOk={this.vm.onCreate}
        />
      </>
    );
  }
}

FeatureSetPane.propTypes = {
  router: PropTypes.objectOf(PropTypes.any)
};

FeatureSetPane.defaultProps = {
  router: null
};

export default withRouter(FeatureSetPane);
