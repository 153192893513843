import { getHost, request } from 'src/utils';
import AuthService from './auth';

export default class SystemService {

  static async get() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/system/loading`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }
}
