import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

export default class ChannelItem {
  parent = null;
  @observable isAvaliable = true;

  @observable id = '';
  @observable channelId = '';
  @observable channelDisplayName = '';
  @observable count = 0;
  @observable country = '';
  @observable earliestDate = null;
  @observable isKol = false;
  @observable isValid = true;
  @observable mediaType = '';
  @observable tags = [];
  @observable url = '';
  @observable enable = true;
  @observable disableDate = null;

  constructor(item, parent) {
    makeObservable(this);

    this.parent = parent;

    runInAction(() => {
      this.id = item._id;
      this.channelId = item.channelId;
      this.channelDisplayName = item.channelDisplayName;
      this.count = item.count;
      this.country = item.country;
      this.earliestDate = item.earliestDate;
      this.isKol = item.isKol;
      this.isValid = item.isValid;
      this.mediaType = item.mediaType;
      this.tags = item.tags;
      this.url = item.url;
      this.enable = item.enable;
      this.disableDate = item.disableDate;
    });
  }

  @computed
  get isSelected() {
    return this.parent.selectedIdSet.has(this.channelId || this.id);
  }

  @action
  toggleIsSelected = (bool = null) => {
    if (this.isSelected && bool !== true) {
      this.parent.selectedIdSet.delete(this.channelId || this.id);
    } else {
      this.parent.selectedIdSet.add(this.channelId || this.id);
    }
  }

  @action
  disable = (date) => {
    this.enable = false;
    this.disableDate = date;
  }
}
