import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message } from 'antd';
import SearchSetService from 'src/services/searchSet';
import { errorModal } from 'src/utils';
import { NAME_ORDER } from 'src/constants';
import CreateItemViewModel from './CreateItem';
import KeywordItem from './KeywordItem';

export default class KeywordPaneViewModel {

  searchVM = null;

  @observable keywords = [];
  @observable newKeywords = [];
  @observable newKeywordsMap = new Map();
  @observable selectedKeywords = [];
  @observable selectedIdSet = new Set();

  @observable isTableLoading = false;
  @observable isCreating = false;
  @observable queryData = { limit: 100 };
  @observable anchor = null;
  @observable order = NAME_ORDER.ASC;
  @observable isSelecteAll = false;
  @observable level = null;


  constructor(searchVM, level) {
    makeObservable(this);
    this.searchVM = searchVM;
    runInAction(() => { this.level = level; });
  }

  @action
  refresh = async () => {
    runInAction(() => {
      this.keywords = [];
      this.resetNewKeyword();
      this.selectedIdSet.clear();
      this.isTableLoading = false;
      this.queryData = { limit: 100 };
      this.order = NAME_ORDER.ASC;
      this.isSelecteAll = false;
    });
    await this.query();
  }

  @action
  filter = (ids) => {
    const set = new Set(ids);
    this.keywords = this.keywords.filter((keyword) => !set.has(keyword.id));
  }

  query = async () => {
    if (this.isTableLoading) return;

    try {
      const params = {
        ...this.queryData,
        order: this.order,
        level: this.level,
        anchor: this.anchor
      };

      runInAction(() => { this.isTableLoading = true; });

      const { keywords, parents, anchor } = await SearchSetService.queryKeyword(this.searchVM.id, params);

      runInAction(() => {
        const _keywords = keywords.map((k) => {
          const newItem = new KeywordItem({
            keywordPaneVM: this,
            projectId: this.searchVM.id,
            params: k.keyword,
            tags: k.tags
          });

          this.searchVM.keywordPool.set(newItem.id, newItem);
          return newItem;
        });

        this.keywords = [...this.keywords, ..._keywords];
        this.anchor = anchor;
      });

    } catch (err) {
      errorModal('發生錯誤，無法取得搜尋關鍵字');
    } finally {
      runInAction(() => { this.isTableLoading = false; });
    }
  }

  @computed
  get isOkToCreate() {
    const newKeywordsLength = this.newKeywords.length;
    const hasDupName = Array.from(this.newKeywordsMap).length !== newKeywordsLength;
    return newKeywordsLength > 0 && this.newKeywords.every((k) => !k.hasError) && !hasDupName;
  }

  create = async () => {
    if (this.newKeywords.length === 0) return;

    runInAction(() => { this.isCreating = true; });

    try {
      let totalSkipCount = 0;

      const data = this.newKeywords.map((k) => {
        const { skipCount, keyword, name, synonym } = k.getData;
        totalSkipCount += skipCount;

        return ({
          keywords: [{ keyword, name, synonym }],
          sid: k.sid ?? null,
          subSid: k.subSid ?? null,
          tags: k.tags?.map((t) => ({ key: t.tid, value: t.subTid })) ?? null
        });
      });

      await Promise.all(data.map(async (keyword) => {
        const res = await SearchSetService.createKeyword(
          this.searchVM.id,
          {
            keywords: keyword.keywords,
            sid: keyword.sid,
            subSid: keyword.subSid,
            level: this.level.toString()
          }
        );

        if (keyword.tags) {
          await Promise.all(keyword.tags.map(async (tag) => SearchSetService.updateTagRelation(
            this.searchVM.id, tag.key, tag.value, [res[0].id]
          )));
          res[0].updateTags(keyword.tags);
        }
        return res[0];
      }));

      if (totalSkipCount > 0) {
        message.success(`新增成功，共有 ${totalSkipCount} 個搜尋關鍵字欲留空`);
      } else {
        message.success('新增成功');
      }

    } catch (err) {
      switch (err.response?.status) {
        case 409:
          errorModal('名稱重複，無法新增關鍵字');
          break;
        default:
          errorModal('發生錯誤，無法新增關鍵字');
      }

    } finally {
      runInAction(() => { this.isCreating = false; });
      this.refresh();
    }

  }


  disable = async () => {
    if (this.selectedKeywordLength === 0) {
      return message.warn('請勾選資料項目');
    }

    this.searchVM.disableSOP.start(this);
    return null;
  }


  delete = async (targetKeyword = null) => {
    if (!targetKeyword && this.selectedKeywordLength === 0) {
      return message.warn('請勾選資料項目');
    }

    const targetKeywordId = targetKeyword ? [targetKeyword.id] : null;
    await this.searchVM.deleteSOP.start(this, targetKeywordId);
    return null;
  }


  //  --------- get all keywords ---------

  getAllKeywords = async (level) => {
    try {
      const keywords = await SearchSetService.getAllKeyword(this.searchVM.id, { level });

      runInAction(() => {
        keywords.forEach((k) => {
          if (!this.searchVM.keywordPool.has(k._id)) {
            this.searchVM.keywordPool.set(k._id, new KeywordItem({
              keywordPaneVM: this,
              projectId: this.searchVM.id,
              params: k
            }));
          }
        });
      });

    } catch {
      // ignore
    }
  }

  @computed
  get L1KeywordOption() {
    const keys = Array.from(this.searchVM.keywordPool)
      .map((k) => k[1])
      .filter((k) => k.level === 1);

    return keys;
  }

  @computed
  get L2KeywordOption() {
    const keys = Array.from(this.searchVM.keywordPool)
      .map((k) => k[1])
      .filter((k) => k.level === 2);

    return keys;
  }




  //  --------- tags ---------

  @computed
  get tags() {
    switch (this.level) {
      case 2:
        return this.searchVM.tag.tags.filter((t) => t.scope === 'l2' || t.scope === 'all');
      case 3:
        return this.searchVM.tag.tags.filter((t) => t.scope === 'l3' || t.scope === 'all');
      default:
        return null;
    }
  }

  // 一鍵貼標
  updateTagsBatch = async (tid, subTid) => {
    try {
      const ids = Array.from(this.selectedIdSet);
      await SearchSetService.updateTagRelation(this.searchVM.id, tid, subTid, ids);

      this.keywords
        .filter((keyword) => this.selectedIdSet.has(keyword.id))
        .forEach((keyword) => keyword.updateOneTag(tid, subTid));
    } catch {
      errorModal('發生錯誤。無法更新標籤');
    } finally {
      this.resetSelectedKeyword();
    }
  }



  //  --------- selected keywords ---------

  @action
  resetSelectedKeyword = () => {
    this.selectedIdSet.clear();
  }

  @computed
  get selectedKeywordLength() {
    const ids = Array.from(this.selectedIdSet);
    return ids.length;
  }


  //  --------- new keywords ---------

  @action
  addNewKeyword = (copyLastOne = false) => {
    if (copyLastOne) {
      const length = this.newKeywords.length - 1;
      const lastOne = this.newKeywords[length];
      this.newKeywords.push(new CreateItemViewModel(this, lastOne?.sid ?? '', lastOne?.subSid ?? ''));
    } else {
      this.newKeywords.push(new CreateItemViewModel(this));
    }
  }
  @action
  resetNewKeyword = () => {
    this.newKeywords = [];
    this.newKeywordsMap.clear();
  }
  @action
  deleteNewKeyword = (index) => {
    this.newKeywords.splice(index, 1);
    this.updateNewKeywordsMap();
  }
  @action
  updateNewKeywordsMap = () => {
    this.newKeywordsMap.clear();
    this.newKeywords.forEach((keyword) => {
      const key = `${keyword.sid || ''}_${keyword.subSid || ''}_${keyword.name}`;

      if (this.newKeywordsMap.has(key)) {
        const count = this.newKeywordsMap.get(key) + 1;
        this.newKeywordsMap.set(key, count);
      } else {
        this.newKeywordsMap.set(key, 1);
      }
    });
  }

  //  --------- sort & search ---------

  @action
  onOrderChange = (direction) => {
    if (this.order === NAME_ORDER.DESC) {
      runInAction(() => { this.order = NAME_ORDER.ASC; });
    } else {
      runInAction(() => { this.order = NAME_ORDER.DESC; });
    }
    this.keywords = [];
    this.anchor = null;
    this.query();
  }

  @action
  onQuery = (newQueryCondition) => {
    const { isSelected, ...otherCondition } = newQueryCondition;

    if (isSelected) {
      const ids = Array.from(this.selectedIdSet);
      const selectedKeywords = ids.map((id) => {
        return this.searchVM?.keywordPool?.get(id);
      });

      this.keywords = selectedKeywords;
      this.anchor = null;

    } else {
      this.keywords = [];
      this.anchor = null;
      this.isTableLoading = false;
      this.queryData = otherCondition;
      this.queryData.limit = 100;
      this.query();
    }
  }

  onBottom = () => {
    if (this.anchor) {
      this.query();
    }
  }

  @action
  selectAll = async () => {
    const result = !this.isSelecteAll;
    runInAction(() => { this.isSelecteAll = result; });


    // to select all
    if (result) {

      // fetch all keywords
      if (this.anchor) {
        runInAction(() => { this.queryData.limit = 100000; });
        await this.query();
      }

      // put all ids into selectedIdSet
      runInAction(() => {
        this.keywords.forEach((keyword) => {
          this.selectedIdSet.add(keyword.id);
        });
      });

    // to deselect all
    } else {
      this.selectedIdSet.clear();
    }
  }

}
