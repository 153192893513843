import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { LIMIT } from 'src/constants';
import UserService from 'src/services/user';
import { errorModal } from 'src/utils';
import UserItem from './UserItem';

export default class PermisionPageVM {
  @observable list = [];
  @observable anchor = null;
  @observable order = 'department';
  @observable isLoading = false;
  @observable searchConditions = null;

  constructor() {
    makeObservable(this);
  }

  didMount = async () => {
    await this.getList();
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });


      const { users, anchor } = await UserService.getUsers({
        ...this.searchConditions,
        limit: LIMIT,
        anchor: this.anchor,
        order: this.order
      });

      const list = users.map((u) => new UserItem(u));

      runInAction(() => {
        this.list = [...this.list, ...list];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      console.log(err);
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法取得列表');
    }
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this._reload();
  }

  @action
  onOrderChange = (newOrder) => {
    this.order = newOrder;
    this._reload();
  }

  @action
  _reload = () => {
    this.list = [];
    this.anchor = null;
    this.getList();
  }
}

