import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Drawer, Row, Spin, Tabs, Tag } from 'antd';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import ChannelTabVM from 'src/viewModels/ProjectSettings/Channle/ChannelTabVM';
import ModalViewModel from 'src/viewModels/modal';

import withRouter from 'src/components/withRouter';
import ClearAllModal from 'src/components/modal/ClearAllModal';
import DisableModal from 'src/components/modal/DisableModal';
import ImportModal from 'src/components/modal/ImportModal';
import SearchBar from 'src/components/channel/SearchBar';
import commonColumns from 'src/components/channel/commonColumns';
import ChannelSummary from 'src/components/channel/Summary';
import RefDrawer from 'src/components/RefDrawer';

import styles from './styles.module.css';

@observer
class ChannelSetPane extends React.Component {
  storedChannelCol = [];
  pickingChannelCol = [];
  addDrawerCol = [];
  importDrawerCol = [];

  constructor(props) {
    super(props);

    this.vm = new ChannelTabVM(props.router.params.id);
    this.refDrawerVM = new ModalViewModel();

    const getColumns = (ChannleList) => [
      {
        title: (
          <Observer>
            {() => (
              <Row justify="center">
                <Checkbox
                  checked={ChannleList.isSelectAll}
                  onChange={ChannleList.toggleSelectAll}
                />
              </Row>
            )}
          </Observer>
        ),
        key: 'isSelected',
        width: 60,
        render: (data) => (
          <Observer>
            {() => (
              <Checkbox
                checked={data.isSelected}
                onChange={data.toggleIsSelected}
              />
            )}
          </Observer>
        )
      },
      ...commonColumns
    ];

    this.pickingChannelCol = getColumns(this.vm.pickingChannel);
    this.storedChannelCol = getColumns(this.vm.storedChannel);
    this.addDrawerCol = getColumns(this.vm.addDrawer);
    this.importDrawerCol = getColumns(this.vm.importDrawer);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  // 從csv檔大量匯入
  renderImportDrawer() {
    return (
      <Drawer
        title="新增頻道"
        placement="right"
        width="90%"
        destroyOnClose
        visible={this.vm.importDrawerVisible}
        onClose={this.vm.hideImportDrawer}
        maskClosable={false}
        footer={(
          <Button
            type="primary"
            onClick={this.vm.fromImportDrawerToPickingChannels}
            disabled={this.vm.importDrawer?.noSeletedIds}
          >
            加入挑頻道
          </Button>
        )}
        bodyStyle={{ overflow: 'hidden', backgroundColor: '#FFFBE7' }}
      >
        {this.vm.importDrawer && (
          <>
            <SearchBar onQuery={this.vm.importDrawer?.applyFilter} />
            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <Spin
                      tip="正在加載資料"
                      spinning={this.vm.importDrawer.isLodaing}
                    >
                      <div
                        style={{ height: 'calc(100vh - 230px)' }}
                        className={styles.tableContainer}
                      >
                        <Table
                          size="small"
                          rowKey="_id"
                          onFetch={() => {
                          }}
                          columns={this.importDrawerCol}
                          scroll={{ y: height, x: 1650 }}
                          dataSource={this.vm.importDrawer.list}
                          lineCount={3}
                          gridCellStyle={{ backgroundColor: '#FFFBE7' }}
                          gridTableStyle={{ backgroundColor: '#FFFBE7' }}
                          onChange={(pagination, filters, sorter) => {
                            this.vm.importDrawer.onSortChange(sorter.order);
                          }}
                        />
                      </div>
                    </Spin>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>

          </>
        )}

      </Drawer>
    );
  }

  // 從中華資料庫撈回資料
  renderAddDrawer() {
    return (
      <Drawer
        title="新增頻道"
        placement="right"
        width="90%"
        visible={this.vm.addDrawerVisible}
        onClose={this.vm.hideAddDrawer}
        maskClosable={false}
        footer={(
          <Button
            type="primary"
            onClick={this.vm.fromAddDrawerToPickingChannels}
            disabled={this.vm.addDrawer?.noSeletedIds}
          >
            加入挑頻道
          </Button>
        )}
        bodyStyle={{ overflow: 'hidden', backgroundColor: '#FFFBE7' }}
      >
        {this.vm.addDrawer && (
          <>
            <SearchBar
              onQuery={(data) => this.vm.addDrawer.applyFilter(data)}
              defaultQueryCondition={this.vm.addDrawer.defaultQueryCondition}
            />
            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <Spin
                      tip="正在加載資料"
                      spinning={this.vm.addDrawer.isLodaing}
                    >
                      <div
                        style={{ height: 'calc(100vh - 320px)' }}
                        className={styles.tableContainer}
                      >
                        <Table
                          size="small"
                          rowKey="_id"
                          onFetch={this.vm.addDrawer.onBottom}
                          columns={this.addDrawerCol}
                          scroll={{ y: height, x: 1650 }}
                          dataSource={this.vm.addDrawer._list}
                          lineCount={3}
                          gridCellStyle={{ backgroundColor: '#FFFBE7' }}
                          gridTableStyle={{ backgroundColor: '#FFFBE7' }}
                          onChange={(pagination, filters, sorter) => {
                            this.vm.addDrawer.onSortChange(sorter.order);
                          }}
                        />
                      </div>
                    </Spin>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>

          </>
        )}
      </Drawer>
    );
  }

  render() {
    return (
      <>
        <Tabs
          type="card"
          size="small"
          activeKey={this.vm.activePaneKey}
          onTabClick={this.vm.changePane}
        >

          <Tabs.TabPane
            tab="頻道組合清單"
            key={ChannelTabVM.paneKey.myChannel}
          >

            <ChannelSummary
              projectId={this.vm.projectId}
            />
            <SearchBar
              onQuery={(data) => this.vm.storedChannel.applyFilter(data)}
            />

            <Row justify="end" align="middle" className={styles.mb}>
              {this.vm.channelSetRefProject && (
                <div>
                  <Tag color="red">
                    {`引用 ${this.vm.channelSetRefProject?.meta?.name || this.vm.channelSetRefProject} 公版專案頻道組合`}
                  </Tag>
                </div>
              )}
              <Button
                danger
                onClick={this.vm.disable}
                className={styles.mr}
              >
                永久停用已勾選頻道
              </Button>
              <Button
                danger
                onClick={this.vm.delete}
                className={styles.mr}
              >
                刪除已勾選頻道
              </Button>
              <Button
                danger
                onClick={() => this.vm.toggleClearAllModal(true)}
                type="primary"
              >
                清空現有設定
              </Button>
            </Row>

            {this.vm.storedChannel.selectedIdSet.size > 0 && (
              <Row
                align="middle"
                className={styles.selectedBox}
              >
                <div>
                  {`已選${this.vm.storedChannel.selectedIdSet.size}項`}
                </div>
                <Button
                  type="link"
                  onClick={this.vm.storedChannel.channelSelect}
                >
                  取消選擇
                </Button>
              </Row>
            )}

            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <Spin
                      tip="正在加載資料"
                      spinning={this.vm.storedChannel.isLodaing}
                    >
                      <div
                        style={{ height: 'calc(100vh - 340px)' }}
                        className={styles.tableContainer}
                      >
                        {(this.vm) && (
                          <Table
                            size="small"
                            rowKey="_id"
                            onFetch={this.vm.storedChannel.onButton}
                            columns={this.storedChannelCol}
                            scroll={{ y: height, x: 1650 }}
                            dataSource={this.vm.storedChannel._list}
                            lineCount={3}
                            onChange={(pagination, filters, sorter) => {
                              this.vm.storedChannel.onSortChange(sorter.order);
                            }}
                          />
                        )}
                      </div>
                    </Spin>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>
          </Tabs.TabPane>


          <Tabs.TabPane
            tab="挑頻道"
            key={ChannelTabVM.paneKey.pickChannel}
          >
            <div>
              <SearchBar onQuery={this.vm.pickingChannel.applyFilter} />

              <Row justify="end" className={styles.mb}>
                {(this.vm.canRef)
                  ? (
                    <>
                      <Button
                        type="primary"
                        className={styles.mr}
                        onClick={() => this.refDrawerVM.show()}
                      >
                        引用其他頻道組合
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => this.vm.toggleImportModal(true)}
                        className={styles.mr}
                      >
                        Excel 大量匯入
                      </Button>
                    </>
                  )
                  : (
                    <Button
                      danger
                      onClick={this.vm.pickingChannel.deleteChannels}
                      className={styles.mr}
                    >
                      刪除已勾選頻道
                    </Button>
                  )}
                <Button
                  type="primary"
                  onClick={this.vm.showAddDrawer}
                  className={styles.yellowBtn}
                >
                  ＋ 新增頻道
                </Button>
              </Row>


              {this.vm.pickingChannel.selectedIdSet.size > 0 && (
                <Row
                  align="middle"
                  className={styles.selectedBox}
                >
                  <div>
                    {`已選${this.vm.pickingChannel.selectedIdSet.size}項`}
                  </div>
                  <Button
                    type="link"
                    onClick={this.vm.pickingChannel.channelSelect}
                  >
                    取消選擇
                  </Button>
                </Row>
              )}

              <ReactResizeDetector handleHeight>
                {({ height = 0 }) => (
                  <Observer>
                    {() => (
                      <Spin
                        tip="正在加載資料"
                        spinning={this.vm.pickingChannel.isLodaing}
                      >
                        <div
                          style={{ height: 'calc(100vh - 350px)' }}
                          className={styles.tableContainer}
                        >
                          {(this.vm) && (
                            <Table
                              size="small"
                              rowKey="_id"
                              onFetch={() => {}}
                              columns={this.pickingChannelCol}
                              scroll={{ y: height, x: 1650 }}
                              dataSource={this.vm.pickingChannel.list}
                              lineCount={3}
                              onChange={(pagination, filters, sorter) => {
                                this.vm.pickingChannel.onSortChange(sorter.order);
                              }}
                            />
                          )}
                        </div>
                      </Spin>
                    )}
                  </Observer>
                )}
              </ReactResizeDetector>

              <div className={styles.fixedBtn}>
                <Button
                  type="primary"
                  disabled={this.vm.pickingChannel.noSeletedIds}
                  onClick={this.vm.fromPickingtoStoredChannels}
                >
                  儲存至頻道組合清單
                </Button>
              </div>
            </div>
          </Tabs.TabPane>

        </Tabs>

        {this.renderAddDrawer()}
        {this.renderImportDrawer()}

        <DisableModal
          visible={this.vm.disableSOP.modalVisible}
          onCancel={this.vm.disableSOP.cancel}
          onOk={this.vm.disableSOP.disableChannels}
          content={(
            <div>
              <div className={styles.mb}>停用頻道系統將：</div>
              <div className={styles.mb}>
                1. 將晚於您所設定的日期的資料(ex:停用時間設定為2021/1/1，則2021/1/1當天以及往後的資料都會被刪除。），並重新進行現有的自動化運算。
              </div>
              <div className={styles.mb}>2. 頻道一經停用即無法再次啟用，如需要回復這些頻道的資料，則需要先進行刪除頻道，再重新添加頻道並進行重新跑資料。</div>
              <div className={styles.mb}>3. 此動作的處理時間因專案大小而異，可能無法立即完成。</div>
              <div className={styles.mb}>是否確認停用這些頻道？</div>
            </div>
          )}
        />

        <ClearAllModal
          visible={this.vm.clearAllModalVisible}
          onOk={this.vm.clearAll}
          onCancel={() => this.vm.toggleClearAllModal(false)}
          projectName={this.vm?.metaData?.meta?.name ?? ''}
          text="清空專案頻道組合目前儲存的設定之外，也將刪除專案中這些頻道相關的口碑結果，處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。"
        />

        <ImportModal
          onUpload={this.vm.onUpload}
          visible={this.vm.importModalVisible}
          hide={() => this.vm.toggleImportModal(false)}
          type="channel"
        />

        <RefDrawer
          visible={this.refDrawerVM.visible}
          onClose={this.refDrawerVM.hide}
          onRef={this.vm.onRef}
          type="channel"
        />
      </>
    );
  }
}

ChannelSetPane.propTypes = {
  router: PropTypes.objectOf(PropTypes.any)
};

ChannelSetPane.defaultProps = {
  router: null
};

export default withRouter(ChannelSetPane);
