import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

export default class TagOption {

  fullName = '';
  name = '';
  defaultValue = '';
  subTags = [];
  @observable subTag = '';

  constructor(tag, defaultValue) {
    makeObservable(this);
    this.fullName = tag.fullName;
    this.name = tag.name;
    this.subTags = tag.subTags;
    this.defaultValue = defaultValue;

    runInAction(() => {
      this.subTag = defaultValue;
    });
  }

  @action
  onSelect = (value) => {
    this.subTag = value;
  }

  @computed
  get hasChanged() {
    return this.defaultValue !== this.subTag;
  }

}
