/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Row, Select, Col, TreeSelect, DatePicker } from 'antd';
import { CHANNEL_SOURCE } from 'src/constants';
import ChannelSetService from 'src/services/channelSet';
import { countryCodeToName } from 'src/utils';
import { OfficialMedia, Audience } from './selectOptions';

import styles from './styles.module.css';

const SearchBar = (props) => {
  const [form] = Form.useForm();
  const [isSns, setIsSns] = useState(false);
  const [officialTags, setOfficialTags] = useState([]);
  const [audienceTags, setAudienceTags] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      (async () => {
        try {
          const res = await ChannelSetService.getCountry();
          setCountries(res);
          if (props.defaultQueryCondition) {
            form.setFieldsValue(props.defaultQueryCondition);
          }
        } catch {
          // todo
        }
      })();
    }

    return () => { mounted = false; };
  }, [form, props.defaultQueryCondition]);

  const reset = () => {
    form.resetFields();
    setIsSns(false);
    setOfficialTags([]);
    setAudienceTags([]);
  };

  const renderTree = (array, parentValue = '', tags) => (
    <>
      {array.map((item) => {

        // skip current level and render child nodes when value is null or '政治'
        if (item.value === 'null' && item.sub) {
          return (
            renderTree(item.sub, `${parentValue}_null`, tags)
          );
        }
        if (item.value === '政治' && item.sub) {
          return (
            renderTree(item.sub, `${parentValue}_政治`, tags)
          );
        }


        // disabled when parent node is selected
        const [_, attr, subAttr, themes] = parentValue.split('_');
        const disabled = [`_${attr}`, `_${attr}_${subAttr}`, `_${attr}_${subAttr}_${themes}`].some((e) => tags.includes(e));

        return (
          <TreeSelect.TreeNode
            title={item.title}
            value={`${parentValue}_${item.value}`}
            key={`${parentValue}_${item.value}`}
            disabled={disabled}
          >
            {item.sub && renderTree(item.sub, `${parentValue}_${item.value}`, tags)}
          </TreeSelect.TreeNode>
        );
      })}
    </>
  );

  const valueLabelMap = {
    _人物自營頻道_名嘴_政治_泛藍: '名嘴_泛藍',
    _人物自營頻道_名嘴_政治_泛綠: '名嘴_泛綠',
    _人物自營頻道_名嘴_政治_泛白: '名嘴_泛白',
    _人物自營頻道_名嘴_政治_其他: '名嘴_其他',
    _人物自營頻道_政治人物_政治_泛藍: '政治人物_泛藍',
    _人物自營頻道_政治人物_政治_泛綠: '政治人物_泛綠',
    _人物自營頻道_政治人物_政治_泛白: '政治人物_泛白',
    _人物自營頻道_政治人物_政治_其他: '政治人物_其他',
    _組織自營頻道_政黨自營頻道_政治_泛藍: '政黨自營頻道_泛藍',
    _組織自營頻道_政黨自營頻道_政治_泛綠: '政黨自營頻道_泛綠',
    _組織自營頻道_政黨自營頻道_政治_泛白: '政黨自營頻道_泛白',
    _組織自營頻道_政黨自營頻道_政治_其他: '政黨自營頻道_其他',
    _組織自營頻道_側翼粉專自營頻道_政治_泛藍: '側翼粉專自營頻道_泛藍',
    _組織自營頻道_側翼粉專自營頻道_政治_泛綠: '側翼粉專自營頻道_泛綠',
    _組織自營頻道_側翼粉專自營頻道_政治_泛白: '側翼粉專自營頻道_泛白',
    _組織自營頻道_側翼粉專自營頻道_政治_其他: '側翼粉專自營頻道_其他',
    _組織自營頻道_政府機關自營頻道_學校: '政府機關自營頻道_學校'
  };

  const onTagChange = (value, isOfficialTag, triggerValue) => {
    // change tag label
    value.forEach((ele) => {
      if (valueLabelMap[ele.value]) {
        ele.label = valueLabelMap[ele.value];
      }
    });

    // remove child nodes of triggerValue
    value = value.filter((e) => !e.value.includes(`${triggerValue}_`));

    if (isOfficialTag) {
      setOfficialTags(value.map((tag) => tag.value));
      form.setFieldsValue({ officialTags: value });
    } else {
      setAudienceTags(value.map((tag) => tag.value));
      form.setFieldsValue({ audienceTags: value });
    }
  };

  const onQuery = async () => {
    const values = form.getFieldsValue();
    const data = {
      ...values,
      startDate: values.date ? values.date[0]?.startOf('day')?.toISOString() : null,
      endDate: values.date ? values.date[1]?.endOf('day')?.toISOString() : null,
      officialTags: values.officialTags ? parseTags(values.officialTags) : [],
      audienceTags: values.audienceTags ? parseTags(values.audienceTags) : []
    };
    // console.log(data);
    await props.onQuery(data);
  };

  const parseTags = (tags) => {
    const map = new Map();

    tags.forEach((tag) => {
      const [_, attr, subAttr, themes, subThemes] = tag.value.split('_');
      const key = (!themes && !subThemes) ? `${attr}_${subAttr})_null` : `${attr}_${subAttr})`;

      if (map.has(key)) {
        if (themes && !(themes === 'null' || themes === '政治')) {
          map.get(key).themes.push(themes);
        }
        if (subThemes && subThemes !== 'null') {
          map.get(key).subThemes.push(subThemes);
        }
      } else {
        map.set(key, {
          attr,
          subAttr: subAttr !== 'null' ? subAttr : null,
          themes: (themes && themes !== 'null') ? [themes] : [],
          subThemes: subThemes ? [subThemes] : []
        });
      }
    });
    const array = Array.from(map);
    const results = array.map((item) => item[1]);
    return results;
  };

  return (
    <div className={styles.box}>
      <Form form={form}>
        <Row justify="space-between" align="bottom" gutter={20}>

          <Col>
            <div>一般欄位搜尋</div>
            <Row>
              <Form.Item name="country" style={{ marginBottom: 5 }}>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  style={{ width: 120 }}
                  className={styles.mr}
                  placeholder="國家"
                >
                  {countries.map((country) => (
                    <Select.Option value={country} key={country}>{countryCodeToName(country)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="mediaType" style={{ marginBottom: 5 }}>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  style={{ width: 120 }}
                  className={styles.mr}
                  placeholder="來源類型"
                  onChange={(value) => {
                    if (value === 'sns') {
                      setIsSns(true);
                    } else {
                      setIsSns(false);
                    }
                  }}
                >
                  {Object.keys(CHANNEL_SOURCE).map((key) => (
                    <Select.Option value={key} key={key}>{CHANNEL_SOURCE[key]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {isSns && (
                <Form.Item name="isKol" style={{ marginBottom: 5 }}>
                  <Select
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentElement}
                    style={{ width: 120 }}
                    className={styles.mr}
                    placeholder="is_kol"
                  >
                    <Select.Option value>網紅</Select.Option>
                    <Select.Option value={false}>非網紅</Select.Option>
                    <Select.Option value={null}>-</Select.Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item name="isValid" style={{ marginBottom: 5 }}>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  allowClear
                  style={{ width: 120 }}
                  placeholder="狀態"
                >
                  <Select.Option value>true</Select.Option>
                  <Select.Option value={false}>false</Select.Option>
                </Select>
              </Form.Item>

            </Row>
          </Col>

          <Col flex={1}>
            <div>自定義搜尋</div>
            <Form.Item name="keyword" style={{ marginBottom: 5 }}>
              <Input placeholder="頻道名稱 / 頻道 ID / URL / 網站" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between" gutter={20}>
          <Col flex={1}>
            <div>頻道標籤搜尋</div>
            <Row style={{ width: '100%' }}>
              <div className={styles.official}>官方媒體</div>
              <Form.Item name="officialTags" className={styles.mb} style={{ width: 'calc(100% - 100px)' }}>
                <TreeSelect
                  getPopupContainer={(trigger) => trigger.parentElement}
                  value={[]}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  labelInValue
                  treeCheckable
                  treeCheckStrictly
                  onChange={(value, labelList, extra) => {
                    onTagChange(value, true, extra.triggerValue);
                  }}
                >
                  {renderTree(OfficialMedia, '', officialTags)}
                </TreeSelect>
              </Form.Item>
            </Row>
            <Row>
              <div className={styles.audience}>受眾媒體</div>
              <Form.Item name="audienceTags" className={styles.mb} style={{ width: 'calc(100% - 100px)' }}>
                <TreeSelect
                  getPopupContainer={(trigger) => trigger.parentElement}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  labelInValue
                  treeCheckable
                  treeCheckStrictly
                  onChange={(value, labelList, extra) => onTagChange(value, false, extra.triggerValue)}
                >
                  {renderTree(Audience, '', audienceTags)}
                </TreeSelect>
              </Form.Item>
            </Row>
          </Col>

          <Col>
            <div>只顯示在此時間區間有資料頻道</div>
            <Form.Item name="date" className={styles.mb} style={{ width: 400 }}>
              <DatePicker.RangePicker
                getPopupContainer={(trigger) => trigger.parentElement}
                allowEmpty={[true, true]}
                format="YYYY-MM-DD"
                style={{ width: 400 }}
              />
            </Form.Item>
            <Row justify="end">
              <Button
                onClick={reset}
                className={styles.mr}
              >
                清空篩選條件
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  const values = form.getFieldsValue();
                  await onQuery(values);
                }}
              >
                套用
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

SearchBar.propTypes = {
  onQuery: PropTypes.func.isRequired,
  defaultQueryCondition: PropTypes.object
};

SearchBar.defaultProps = {
  defaultQueryCondition: null
};

export default SearchBar;
