import React from 'react';
import { observer } from 'mobx-react';
import { getHost } from 'src/utils';
import { Button } from 'antd';

import styles from './styles.module.css';

@observer
class LoginPage extends React.Component {
  render() {
    return (
      <div className={styles.page}>
        <div className={styles.center}>
          <div className={styles.title}>專案管理工具</div>
          <img src="/logo192.png" alt="logo" className={styles.img} />
          <Button
            type="primary"
            href={`${getHost()}/api/v1/auth/login`}
            block
            className={styles.btn}
          >
            登入
          </Button>
        </div>
      </div>
    );
  }
}

export default LoginPage;
