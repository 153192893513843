import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, Modal, Row, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';


const AttachTagModal = observer((props) => {
  const [tag, setTag] = useState(null);
  const [subTag, setSubTag] = useState(null);
  const [form] = Form.useForm();

  const close = () => {
    setTag(null);
    setSubTag(null);
    form.resetFields();
    props.onClose();
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      closable={false}
      okText="確定"
      cancelText="取消"
      visible={props.visible}
      onCancel={close}
      onOk={() => {
        props.onOk(tag, subTag);
        close();
      }}
      okButtonProps={{ disabled: !subTag }}
      title={(
        <div>
          <ExclamationCircleOutlined style={{ color: '#FAAD14', marginRight: 10 }} />
          {`共${props.length}個產品，將被分類至此標籤：`}
        </div>
      )}
    >
      <Form form={form}>
        <Row justify="center">
          <Form.Item name="tag" style={{ width: '40%', marginRight: 10 }}>
            <Select
              placeholder="母標籤名稱"
              onChange={(value) => {
                setTag(value);
                setSubTag(null);
                form.setFieldsValue({ subTag: null });
              }}
            >
              {props.tags.map((t) => (
                <Select.Option key={t.id} value={t.id}>
                  {props.tagPool.get(t.id)?.name ?? ''}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="subTag" style={{ width: '40%' }}>
            <Select
              placeholder="子標籤名稱"
              onChange={(value) => {
                setSubTag(value);
              }}
            >
              {props.tags
                .find((t) => t.id === tag)
                ?.subTags
                .map((subT) => (
                  <Select.Option key={subT.id} value={subT.id}>
                    {props.tagPool.get(subT.id)?.name ?? ''}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
});

AttachTagModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tagPool: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  length: PropTypes.number.isRequired,
  onOk: PropTypes.func.isRequired
};

export default AttachTagModal;
