import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Row, Button, Spin } from 'antd';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

import styles from './styles.module.css';

// 主題 TOP 100 話題

@observer
class TopTopicsTable extends React.Component {
  constructor(props) {
    super(props);

    const searchAngle = this.props.searchAngle;

    this.columns = [
      {
        title: <Row justify="center">序號</Row>,
        key: 'index',
        width: 50,
        render: (data, _, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: <Row justify="center">{SEARCH_KEYWORD_TXT[searchAngle]?.L1 ?? ''}</Row>,
        key: 'L1',
        render: (data) => (
          <div className={styles.txt}>
            {data.lv1 ?? <div className={styles.center}>-</div>}
          </div>
        )
      },
      {
        title: <Row justify="center">{SEARCH_KEYWORD_TXT[searchAngle]?.L2 ?? ''}</Row>,
        key: 'L2',
        render: (data) => (
          <div className={styles.txt}>
            {data.lv2 ?? <div className={styles.center}>-</div>}
          </div>
        )
      },
      {
        title: <Row justify="center">{SEARCH_KEYWORD_TXT[searchAngle]?.L3 ?? ''}</Row>,
        key: 'L3',
        render: (data) => (
          <div className={styles.txt}>
            {data.lv3 ?? <div className={styles.center}>-</div>}
          </div>
        )
      },
      {
        title: <Row justify="center">標題</Row>,
        key: 'title',
        width: 400,
        render: (data) => (
          <div
            className={clsx([styles.txt, styles.hyperlink])}
            onClick={() => this.props.showTopicModal(data.mainId)}
          >
            {data.title}
          </div>
        )
      },
      {
        title: <Row justify="center">發表時間</Row>,
        key: 'date',
        width: 160,
        render: (data) => (<div>{dayjs(data.date).format('YYYY/MM/DD HH:mm:ss')}</div>)
      },
      {
        title: <Row justify="center">來源頻道</Row>,
        key: 'channelDisplayName',
        render: (data) => (
          <a
            className={styles.txt}
            href={data.channelUrl}
            disabled={!data.channelUrl}
            target="_blank"
            rel="noreferrer"
          >
            {data.channelDisplayName}
          </a>
        )
      },
      {
        title: <Row justify="center">口碑數</Row>,
        key: 'count',
        width: 100,
        render: (data) => (<Row justify="center">{data.count}</Row>)
      }
    ];
  }

  render() {
    return (
      <Spin
        spinning={this.props.isLoading}
        tip="正在加載資料"
      >
        <Table
          rowKey={(record) => `${record.mainId}${record.sid}`}
          dataSource={this.props.data}
          columns={this.columns}
          size="small"
          pagination={false}
          scroll={{ y: 'calc(100vh - 223px)' }}
        />
      </Spin>
    );
  }
}

TopTopicsTable.propTypes = {
  data: PropTypes.array.isRequired,
  searchAngle: PropTypes.string.isRequired,
  showTopicModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default TopTopicsTable;
