import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { message } from 'antd';
import ProjectService from 'src/services/project';
import ChannelSetService from 'src/services/channelSet';
import { errorModal } from 'src/utils';

import BaseChannelList from 'src/viewModels/components/channels/BaseChannelList';
import StoredChannelList from 'src/viewModels/components/channels/StoredChannelList';
import LocalChannelList from 'src/viewModels/components/channels/LocalChannelList';
import ChunghwaChannelList from 'src/viewModels/components/channels/ChunghwaChannelList';
import DisableSOP from './DisableSOP';
import DeleteSOP from './DeleteSOP';

export default class ChannelTabVM {

  static paneKey = {
    pickChannel: 'pickChannel',
    myChannel: 'myChannel'
  }

  @observable activePaneKey = ChannelTabVM.paneKey.myChannel;
  @observable clearAllModalVisible = false;
  @observable importModalVisible = false;
  addDrawer = null;
  importDrawer = null;
  @observable metaData = null;
  @observable channelSetRefProject = null;
  projectId = '';

  @observable addDrawerVisible = false;
  @observable importDrawerVisible = false;

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
    this.pickingChannel = new LocalChannelList(projectId);
    this.storedChannel = new StoredChannelList(projectId);
    this.addDrawer = new ChunghwaChannelList(projectId);
    this.importDrawer = new LocalChannelList(projectId);
    this.disableSOP = new DisableSOP(projectId, this.storedChannel);
    this.deleteSOP = new DeleteSOP(projectId, this.storedChannel);
  }

  didMount = async () => {
    await Promise.all([
      this.storedChannel.getChannels(),
      this.getMetaData()
    ]);

    if (this.storedChannel.list.length === 0 && !this.channelSetRefProject) {
      runInAction(() => {
        this.activePaneKey = ChannelTabVM.paneKey.pickChannel;
      });
    }
  }

  getMetaData = async () => {
    try {
      const res = await ProjectService.getProject(this.projectId);
      runInAction(() => {
        this.channelSetRefProject = res.ref.channelSetRefProject;
        this.metaData = res;
      });
    } catch {
      // ignore
    }
  }

  changePane = (newPaneKey) => {
    runInAction(() => { this.activePaneKey = newPaneKey; });
  }

  disable = () => {
    if (this.storedChannel.noSeletedIds) {
      return message.warning('請勾選資料項目');
    }

    this.disableSOP.start();
    return null;
  }

  delete = () => {
    if (this.storedChannel.noSeletedIds) {
      return message.warning('請勾選資料項目');
    }

    this.deleteSOP.start();
    return null;
  }

  @action
  showAddDrawer = () => {
    this.addDrawerVisible = true;

    // set default query condition for add drawer
    this.addDrawer.applyFilter(this.addDrawer.defaultQueryCondition);
  }
  @action
  hideAddDrawer = () => {
    this.addDrawerVisible = false;
    this.addDrawer.reset();
    this.addDrawer.selectedIdSet.clear();
  }

  @action
  hideImportDrawer = () => {
    this.importDrawerVisible = false;
    this.importDrawer.reset();
  }

  onRef = async (data) => {
    try {
      const { refProjectId } = data;
      const refProjectTips = await ChannelSetService.getTip(refProjectId);

      if (refProjectTips.count === 0) {
        errorModal('頻道組合內容為空，無法引用');
        return;
      }

      await ChannelSetService.ref(this.projectId, refProjectId);
      const refProject = await ProjectService.getProject(refProjectId);
      this.storedChannel.getChannels();

      runInAction(() => {
        this.activePaneKey = ChannelTabVM.paneKey.myChannel;
        this.channelSetRefProject = refProject;
      });

    } catch {
      errorModal('發生錯誤，無法引用公版專案');
    }
  }

  fromAddDrawerToPickingChannels = () => {
    if (!this.addDrawer) return;
    const ids = Array.from(this.addDrawer.selectedIdSet);
    this.pickingChannel.add(ids);
    message.success(`本次選入${ids.length}個，（去除重複後共已選入 ${this.pickingChannel.candidateIds.length} 個）`);
  }

  fromImportDrawerToPickingChannels = () => {
    if (!this.importDrawer) return;
    BaseChannelList.addChannelFrom(this.pickingChannel, this.importDrawer);
    this.hideImportDrawer();
    message.success('新增至挑頻道成功！');
  }

  fromPickingtoStoredChannels = () => {
    this.changePane('myChannel');
    BaseChannelList.addChannelFrom(this.storedChannel, this.pickingChannel);
    this.pickingChannel.reset();
    message.success('新增頻道組合成功！');
  }

  clearAll = async () => {
    try {
      await ChannelSetService.removeAll(this.projectId);
      this.storedChannel.reset();
      runInAction(() => { this.channelSetRefProject = null; });

    } catch {
      errorModal('發生錯誤，無法清空現有設定');
    }
  }

  @action
  onUpload = async (file) => {
    try {
      const ids = await ChannelSetService.readUploadedFile(file);

      runInAction(() => { this.importDrawerVisible = true; });
      this.importDrawer.add(ids);

    } catch (e) {
      errorModal('匯入失敗', e.message);
    }
  }

  @action
  toggleClearAllModal = (bool) => {
    this.clearAllModalVisible = bool;
  }

  @action
  toggleImportModal = (bool) => {
    this.importModalVisible = bool;
  }

  @computed
  get canRef() {
    const result = !this.channelSetRefProject
      && this.pickingChannel.isEmpty
      && this.storedChannel.isEmpty;

    return result;
  }

}
