import React, { useState, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { Button, Row, Tag, Tooltip } from 'antd';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import Table from 'src/components/VirtualTable';
import SortButton from 'src/components/SortButton';
import ActionBar from 'src/components/search/L3ActionBar';
import { TEXT } from '../constants';

import styles from './styles.module.css';

const L3Pane = observer((props) => {
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    const ellipsisRows = (props.vm.L3Tags.length > 2) ? props.vm.L3Tags.length : 2;

    setColumns([
      {
        title: <Row justify="center">序號</Row>,
        width: 50,
        key: 'index',
        render: (_, __, i) => (<div>{i + 1}</div>)
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L1}名稱`}
          </Row>
        ),
        width: 150,
        key: 'l1',
        render: (data) => (
          <Observer>
            {() =>
              (<div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>{data.level1 ?? ''}</div>)}
          </Observer>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L2}名稱`}
          </Row>
        ),
        width: 150,
        key: 'l1',
        render: (data) => (
          <Observer>
            {() => (
              <div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>{data.level2 ?? ''}</div>)}
          </Observer>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <SortButton
                title={`${TEXT[props.vm.searchAngle]?.L3}名稱`}
                order={props.vm.L3Order}
                onOrderChange={props.vm.onL3OrderChange}
              />
            )}
          </Observer>
        ),
        width: 150,
        key: 'l2',
        render: (data) => (
          <Observer>
            {() => (
              <div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>{data.name ?? ''}</div>)}
          </Observer>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L3}搜尋關鍵字`}
          </Row>
        ),
        key: 'keyword',
        render: (data) => (
          <Observer>
            {() => (
              <Tooltip
                title={data.keywordResult.reasons.length > 0 && (
                  <div>
                    {data.keywordResult.reasons.map((r) => (
                      <div key={r.id} className={styles[r.type]}>{r.message}</div>
                    ))}
                  </div>
                )}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  {data.keywordResult.hasError && <ExclamationCircleOutlined className={styles.exclamationIcon} />}
                  {data.keywordResult.hasWaran && <WarningOutlined className={styles.warningIcon} />}
                  <div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>{data.keyword ?? ''}</div>
                </div>
              </Tooltip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">標籤</Row>,
        width: 200,
        key: 'tag',
        render: (data) => {
          if (!data.tags) return null;
          return (
            <Observer>
              {() => (
                <div style={{ width: '100%' }}>
                  {props.vm.L3Tags.map((t) => (
                    <Row key={t.id}>
                      <div>{`${t.name ?? ''}：`}</div>
                      <Tag>{data.tags[t.fullName] ?? ''}</Tag>
                    </Row>
                  ))}
                </div>
              )}
            </Observer>
          );
        }
      },
      {
        title: <Row justify="center">同義詞</Row>,
        key: 'synonym',
        render: (data) => (
          <Observer>
            {() => (
              <div className={styles.txt} style={{ WebkitLineClamp: ellipsisRows }}>{data.synonym ?? '-'}</div>)}
          </Observer>
        )
      },
      {
        title: <Row justify="center">執行動作</Row>,
        width: 150,
        key: 'action',
        render: (data) => (
          <>
            <Button type="link" onClick={() => props.vm.showEditDrawer(data, 3)}>編輯</Button>
            <Button type="link" onClick={() => data.delete()}>刪除</Button>
          </>
        )
      }
    ]);
  }, [props.vm.searchAngle, props.vm, props.vm.L3Tags.length]);
  return (
    <>
      <ActionBar
        onQuery={props.vm.setL3QueryData}
        tags={props.vm.L3Tags}
        L1KeywordOption={props.vm.L1KeywordOption}
        L2KeywordOption={props.vm.L2KeywordOption}
        searchAngle={props.vm.searchAngle}
        mode="importDrawer"
      />

      {columns && (
      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <div style={{ width: '100%', height: 'calc(100vh - 355px)' }}>
                <Table
                  height="'calc(100vh - 355px)'"
                  tableKey={props.vm.L3TableKey}
                  onFetch={() => {}}
                  lineCount={props.vm.L3Tags.length}
                  dataSource={props.vm.L3Keywords}
                  columns={columns}
                  rowKey="id"
                  size="small"
                  pagination={false}
                  scroll={{
                    x: 1600,
                    y: height
                  }}
                />
              </div>
            )}
          </Observer>
        )}
      </ReactResizeDetector>
      )}

    </>
  );
});

export default L3Pane;
