import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Row, Col } from 'antd';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

import styles from './styles.module.css';

const Stat = observer((props) => {
  const [sumSite, setSumSite] = useState(0);
  const [sumChannel, setSumChannel] = useState(0);

  useEffect(() => {
    let totalSite = 0;
    let totalChannel = 0;

    props.channelStat.forEach((item) => {
      totalSite += item.websiteCount;
      totalChannel += item.channelCount;
    });

    setSumSite(totalSite);
    setSumChannel(totalChannel);
  }, [props.channelStat]);


  return (
    <Row className={styles.box}>
      <Col flex={1}>
        <div className={styles.subHeader}>
          頻道組合概覽
        </div>

        <Row className={styles.content}>
          <Col flex={1}>
            <div className={styles.dark}>頻道來源類型</div>
            <div className={styles.dark}>網站數</div>
            <div className={styles.dark}>頻道數</div>
          </Col>

          {props.channelStat.map((s) => (
            <Col
              flex={1}
              key={`${s.mediaType}${s.websiteCount}${s.channelCount}`}
            >
              <div className={styles.dark}>{s.mediaType}</div>
              <div className={styles.cell}>{s.websiteCount}</div>
              <div className={styles.cell}>{s.channelCount}</div>
            </Col>
          ))}

          <Col flex={1}>
            <div className={styles.dark}>總計</div>
            <div className={styles.cell}>{sumSite}</div>
            <div className={styles.cell}>{sumChannel}</div>
          </Col>
        </Row>
      </Col>


      <Col className={styles.rightCol}>
        <div className={styles.subHeader}>
          搜尋關鍵字概覽
        </div>
        <Row className={styles.content}>
          <Col flex={1}>
            <div className={styles.dark}>{`${SEARCH_KEYWORD_TXT[props.searchAngle].L1}數`}</div>
            <div className={styles.dark}>{`${SEARCH_KEYWORD_TXT[props.searchAngle].L2}數`}</div>
            <div className={styles.dark}>{`${SEARCH_KEYWORD_TXT[props.searchAngle].L3}數`}</div>
          </Col>
          <Col flex={1}>
            <div className={styles.cell}>{props.searchStat?.level1?.count ?? ''}</div>
            <div className={styles.cell}>{props.searchStat?.level2?.count ?? ''}</div>
            <div className={styles.cell}>{props.searchStat?.level3?.count ?? ''}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

Stat.propTypes = {
  searchAngle: PropTypes.string.isRequired,
  searchStat: PropTypes.object.isRequired,
  channelStat: PropTypes.array.isRequired
};

export default Stat;
