import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import ReactResizeDetector from 'react-resize-detector';

import KeywordTableBody from './KeywordTableBody';
import ActionBar from './ActionBar';
import { WIDTH } from '../../../pages/ProjectSettingsPage/FeatureSetPane/components/constants';

import styles from './styles.module.css';

@observer
class KeywordTable extends React.Component {
  render() {
    return (
      <div className={styles.content}>
        <ActionBar
          level={this.props.vm.feature.level}
          category={this.props.vm.category}
          onSearch={this.props.vm.onSearch}
          filterError={this.props.filterError}
        />
        {this.props.vm.category.length === 0 && this.props.vm.keyword.length === 0
          ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.empty} />
          : (
            <div
              style={{
                width: this.props.vm.columnCount < 5
                  ? `min(${this.props.vm.columnCount * (WIDTH.NAME + WIDTH.KEYWORD + WIDTH.ACTION)}px, 100%)`
                  : null
              }}
            >

              <ReactResizeDetector handleHeight>
                {({ height = 0 }) => (
                  <Observer>
                    {() => (
                      <div
                        style={{ height: this.props.vm.feature.level === 2 ? 'calc(100vh - 385px)' : 'calc(100vh - 420px)' }}
                      >
                        {(this.props.vm && height) && (
                        <KeywordTableBody
                          vm={this.props.vm}
                          showModal={this.props.showModal}
                          keyword={this.props.vm.filteredKeyword || this.props.vm.keyword}
                          canAdd={this.props.canAdd}
                          canEdit={this.props.canEdit}
                          scroll={{ y: height }}
                        />
                        )}
                      </div>
                    )}
                  </Observer>
                )}
              </ReactResizeDetector>
            </div>
          )}
      </div>
    );
  }
}

KeywordTable.propTypes = {
  vm: PropTypes.object.isRequired,
  showModal: PropTypes.func,
  canAdd: PropTypes.bool,
  canEdit: PropTypes.bool,
  filterError: PropTypes.bool
};

KeywordTable.defaultProps = {
  showModal: () => {},
  canAdd: true,
  canEdit: true,
  filterError: false
};


export default KeywordTable;
