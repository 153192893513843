import { getHost, request } from 'src/utils';

import AuthService from './auth';

export default class UserService {

  // get my profile
  static async getProfile() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user/me`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options, true);

    const {
      id,
      name,
      email,
      teamId,
      teamName,
      isTeamLeader,
      admin
    } = res.data.result;

    return {
      id,
      name,
      email,
      teamId,
      teamName,
      isTeamLeader,
      admin
    };
  }

  /**
   *
   * @param {Object} data
   * @param {'department'|'accountStatus'} data.order
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.team
   * @param {null|'activate'|'deactivate'} data.status
   * @param {null|'enable'|'disable'} data.pmStatus
   * @param {null|'manager'|'member'|'teamLeader'} data.pmPermission
   * @returns
   */
  static async getUsers(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/user`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);

    const { users, anchor } = res.data.result;
    return { users, anchor };
  }


  /**
   *
   * @param {string} userId
   * @param {Object} data
   * @param {null|'enable'|'disable'} data.pmStatus
   * @param {null|'manager'|'member'|'teamLeader'} data.pmPermission
   * @returns
   */
  static async updateUser(userId, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/user/${userId}/permission`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }
}
