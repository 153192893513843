import React, { useState, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import { Button, Typography, Row, Spin } from 'antd';
import SortButton from 'src/components/SortButton';
import CustomToolTip from 'src/components/DisableToolTip';
import ActionBar from 'src/components/search/L1ActionBar';
import SearchMeta from 'src/components/search/SearchMeta';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

import styles from './styles.module.css';


const L1Pane = observer((props) => {
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    setColumns([
      {
        title: <Row justify="center">序號</Row>,
        width: 50,
        key: 'index',
        render: (data, __, i) => (
          <CustomToolTip data={data}>
            <Row justify="center">{i + 1}</Row>
          </CustomToolTip>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <SortButton
                title={`${SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L1 ?? ''}名稱`}
                order={props.vm.L1.order}
                onOrderChange={props.vm.L1.onOrderChange}
              />
            )}
          </Observer>
        ),
        width: 250,
        key: 'l1',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={{ text: data.id, tooltips: [data.id] }}
                  className={data.enable ? '' : styles.disable}
                  ellipsis={{ rows: 2 }}
                >
                  {data.name || '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L1 ?? ''}搜尋關鍵字`}
          </Row>
        ),
        key: 'keyword',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt}>{data.keyword || '-'}</div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">同義詞</Row>,
        key: 'synonym',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt}>{data.synonym || '-'}</div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">執行動作</Row>,
        width: 150,
        key: 'action',
        render: (data) => (
          <Button
            type="link"
            className={styles.noPadding}
            onClick={() => props.vm.drawer.show(data)}
          >
            檢視
          </Button>
        )
      }
    ]);
  }, [props.vm, props.vm.searchAngle]);

  return (
    <>
      <div className={styles.marginBottom}>
        <SearchMeta
          projectId={props.projectId}
          title={SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L1 ?? ''}
        />
      </div>

      <ActionBar onQuery={props.vm.L1.onSearch} />


      {columns && (
      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <Spin
                tip="正在加載資料"
                spinning={props.vm.L1.isLoading}
              >
                <div
                  style={{ height: 'calc(100vh - 355px)' }}
                >
                  <Table
                    dataSource={props.vm.L1.list}
                    rowKey="id"
                    size="small"
                    rowClassName={(record) => {
                      if (record.enable) return '';
                      return styles.disable;
                    }}
                    columns={columns}
                    onFetch={props.vm.L1.onBottom}
                    scroll={{ y: height }}
                  />
                </div>
              </Spin>
            )}
          </Observer>
        )}
      </ReactResizeDetector>

      )}


    </>
  );
});

L1Pane.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired,
  projectId: PropTypes.string
};

L1Pane.defaultProps = {
  projectId: ''
};

export default L1Pane;
