import {
  makeObservable, observable, action, runInAction
} from 'mobx';
import ProjectService from 'src/services/project';
import ProjectScheduleService from 'src/services/projectSchedule';
import { errorModal } from 'src/utils';
import dayjs from 'dayjs';
import { message } from 'antd';

export default class Schedule {
  projectId = '';

  @observable project = null;
  @observable isRun = true;
  @observable vocRange = [];
  @observable isWaiting = false;

  @observable data = {
    startDate: null,
    endDate: null,
    autoRun: false,
    updateTimeRequirement: false,
    checkChannelName: null,
    featureSets: []
  };


  constructor(projectId, changeTabAndRefresh) {
    makeObservable(this);
    this.projectId = projectId;
    this.changeTabAndRefresh = changeTabAndRefresh;
  }

  didMount = async () => {
    await this.getProject();

    if (this.isRun) {
      await this.getVocRange();
    }
  }

  save = async () => {
    if (!this.data.startDate || !this.data.endDate) {
      errorModal('請輸入日期');
      return;
    }

    if (this.data.checkChannelName === null) {
      errorModal('請選擇比對頻道名稱設定');
      return;
    }

    const result = {
      ...this.data,
      startDate: this.data.startDate.startOf('day').toISOString(),
      endDate: this.data.endDate.endOf('day').toISOString()
    };

    try {
      if (this.isRun) {
        await ProjectScheduleService.update(this.projectId, result);
        message.success('更新專案成功！');

      } else {
        await ProjectScheduleService.start(this.projectId, result);

        runInAction(() => {
          this.isWaiting = true;
        });

        await this.wait();

        runInAction(() => {
          this.isRun = true;
          this.isWaiting = false;
        });

        message.success('啟動專案成功！');
        this.changeTabAndRefresh();
      }
    } catch (err) {
      switch (err.response?.status) {
        case 400:
          errorModal(err.response?.data?.message ?? `發生錯誤，專案${this.isRun ? '更新' : '啟動'}失敗`);
          break;

        default:
          errorModal(`發生錯誤，專案${this.isRun ? '更新' : '啟動'}失敗`);
      }
    }
  }

  wait = () => {
    const SECONDS = 6000;
    return new Promise((resolve) => {
      setTimeout(() => { resolve(); }, SECONDS);
    });
  }

  @action
  onDateChange = (date) => {
    if (!date) {
      this.data.startDate = null;
      this.data.endDate = null;
    } else {
      this.data.startDate = date[0];
      this.data.endDate = date[1];
    }
  }

  @action
  onAutoRunChange = (e) => {
    this.data.autoRun = e.target.value;
  }

  @action
  onTimeRequirementChange = (e) => {
    this.data.updateTimeRequirement = e.target.value;
  }

  @action
  onCheckChannelNameChange = (e) => {
    this.data.checkChannelName = e.target.value;
  }


  @action
  onFeatureSetsSelect = (e) => {
    this.data.featureSets = [...this.data.featureSets, e];
  }

  @action
  onFeatureSetsDeselect = (e) => {
    this.data.featureSets = this.data.featureSets.filter((featureSet) => featureSet !== e);
  }

  getProject = async () => {
    try {
      const res = await ProjectService.getProject(this.projectId);
      runInAction(() => {
        this.project = res;
        this.isRun = res.meta.isRun;

        if (res.meta.isRun) {
          this.data.startDate = dayjs(res.autorun.startDate);
          this.data.endDate = dayjs(res.autorun.endDate);
          this.data.autoRun = res.autorun.enable;
          this.data.updateTimeRequirement = res.autorun.updateTimeRequirement;
          this.data.checkChannelName = res.autorun.checkChannelName;
          this.data.featureSets = res.autorun.featureSets;
        }
      });

    } catch {
      errorModal('發生錯誤，無法取得資料');
    }
  }

  getVocRange = async () => {
    try {
      const res = await ProjectService.getVocRange(this.projectId, 'feature');
      runInAction(() => { this.vocRange = res; });
    } catch {
      // ignore
    }
  }

}
