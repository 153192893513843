export const Action = {
  UpdateProfile: 'UpdateProfile',
  UpdateProject: 'UpdateProject'
};

export const actions = (dispatch) => ({
  updateProfile: (profile) => dispatch({
    type: Action.UpdateProfile,
    payload: profile
  }),
  updateProject: (project) => dispatch({
    type: Action.UpdateProject,
    payload: project
  })
});
