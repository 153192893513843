import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Collapse, Row, message } from 'antd';
import { DownOutlined, ExclamationCircleOutlined, RedoOutlined, UpOutlined } from '@ant-design/icons';
import ChannelSetService from 'src/services/channelSet';
import withRouter from 'src/components/withRouter';

import styles from './styles.module.css';

const ChannelSummary = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stats, setStats] = useState([]);
  const [sumSite, setSumSite] = useState(0);
  const [sumChannel, setSumChannel] = useState(0);

  const getStats = useCallback(async () => {
    if (!props.projectId) return;

    const res = await ChannelSetService.getStats(props.projectId);

    let totalSite = 0;
    let totalChannel = 0;

    res.forEach((item) => {
      totalSite += item.websiteCount;
      totalChannel += item.channelCount;
    });

    setStats(res);
    setSumSite(totalSite);
    setSumChannel(totalChannel);
  }, [props.projectId]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        if (!props.projectId) return;

        const res = await ChannelSetService.getStats(props.projectId);

        let totalSite = 0;
        let totalChannel = 0;

        res.forEach((item) => {
          totalSite += item.websiteCount;
          totalChannel += item.channelCount;
        });

        if (mounted) {
          setStats(res);
          setSumSite(totalSite);
          setSumChannel(totalChannel);
        }

      } catch {
        // ignore
      }
    })();

    return () => { mounted = false; };

  }, [props.projectId]);


  const reload = async () => {
    try {
      await getStats();
      message.success('資料已更新');
    } catch {
      // ignore
    }
  };

  return (
    <Collapse
      expandIcon={null}
      bordered={false}
      style={{ marginBottom: 10 }}
      onChange={(activeKeys) => {
        if (activeKeys.find((key) => key === '1')) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      }}
    >
      <Collapse.Panel
        header={(
          <div className={styles.header}>
            <div className={styles.marginRight}>{`${isOpen ? '收起' : '展開'}已有頻道數量統計`}</div>
            {isOpen ? <UpOutlined /> : <DownOutlined />}
          </div>
        )}
        key="1"
        style={{ background: '#1890FF' }}
        showArrow={false}
      >
        <div className={styles.subHeader}>
          <div>整體概況</div>

          <div className={styles.warn}>
            <div className={styles.inline}>
              <ExclamationCircleOutlined className={styles.marginRight} />
              重新loading後才會是即時資訊
            </div>
            <Button type="text" onClick={reload}>
              <RedoOutlined className={styles.reloadIcon} />
            </Button>
          </div>
        </div>

        <Row className={styles.content}>
          <Col flex={1}>
            <div className={styles.dark}>頻道來源類型</div>
            <div className={styles.dark}>網站數</div>
            <div className={styles.dark}>頻道數</div>
          </Col>
          {stats.map((s) => (
            <Col
              flex={1}
              key={`${s.mediaType}${s.websiteCount}${s.channelCount}`}
            >
              <div className={styles.dark}>{s.mediaType}</div>
              <div className={styles.cell}>{s.websiteCount}</div>
              <div className={styles.cell}>{s.channelCount}</div>
            </Col>
          ))}

          <Col flex={1}>
            <div className={styles.dark}>總計</div>
            <div className={styles.cell}>{sumSite}</div>
            <div className={styles.cell}>{sumChannel}</div>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};

ChannelSummary.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default withRouter(ChannelSummary);
