import { Button, Row, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { CopyOutlined } from '@ant-design/icons';

import styles from './styles.module.css';

const sourceParse = /([\w\W]+)\[([a-z0-9]+)\]/;

export const logColumns = [
  {
    title: (<Row justify="center">異動日期</Row>),
    key: 'date',
    width: 150,
    render: (data) => (<Row justify="center">{dayjs(data.date).format('YYYY-MM-DD')}</Row>)
  },
  {
    title: (<Row justify="center">異動項目</Row>),
    key: 'action',
    width: 180,
    render: (data) => (<Row justify="center">{data.action}</Row>)
  },
  {
    title: (<Row justify="center">異動紀錄 ID</Row>),
    key: 'id',
    width: 110,
    render: (data) => (
      <Row justify="center">
        <Typography.Paragraph
          copyable={{ text: data.id, tooltips: [data.id] }}
        />
      </Row>
    )
  },
  {
    title: (<Row justify="center">項目名稱(ID)</Row>),
    key: 'source',
    width: 300,
    render: (data) => {
      if (data.source) {
        try {
          const list = data.source.split('/').filter((e) => !!e);
          const source = list.map((e, index) => {
            const [_, name, id] = e.match(sourceParse);
            return (
              <span key={e}>
                <Typography.Paragraph
                  style={{ display: 'inline-block', maxWidth: 300 }}
                  copyable={{ text: id, tooltips: [id] }}
                  ellipsis={{
                    rows: 2,
                    tooltip: name
                  }}
                >
                  {name}
                </Typography.Paragraph>
                { index < list.length - 1 ? <span style={{ margin: 10 }}>/</span> : ''}
              </span>
            );
          });
          return (<Row style={{ width: '100%' }}>{source}</Row>);
        } catch (e) {
          return (<Row justify="center" className={styles.txt}>{data.source}</Row>);
        }
      }
      return (<Row justify="center" className={styles.txt}>-</Row>);
    }
  },
  {
    title: (<Row justify="center">異動前內容</Row>),
    key: 'before',
    width: 350,
    render: (data) => (
      <Typography.Paragraph
        style={{ width: '100%' }}
        ellipsis={{
          rows: 3,
          tooltip: data.after
        }}
      >
        {data.before ?? '-'}
      </Typography.Paragraph>
    )
  },
  {
    title: (<Row justify="center">異動後內容</Row>),
    key: 'after',
    width: 350,
    render: (data) => (
      <Typography.Paragraph
        style={{ width: '100%' }}
        ellipsis={{
          rows: 3,
          tooltip: data.after
        }}
      >
        {data.after ?? '-'}
      </Typography.Paragraph>
    )
  },
  {
    title: (<Row justify="center">專案名稱</Row>),
    key: 'projectName',
    width: 200,
    render: (data) => (<Row justify="center" className={styles.txt}>{data.projectName ?? '-'}</Row>)
  },
  {
    title: (<Row justify="center">負責人名稱</Row>),
    key: 'userName',
    width: 200,
    render: (data) => (<Row justify="center" className={styles.txt}>{data.userName ?? '-'}</Row>)
  }
];
