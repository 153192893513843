/* eslint-disable no-param-reassign */
import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import KeywordVM from './KeywordVM';
import CategoryVM from './CategoryVM';

export default class DrawerVM {

  @observable feature = {
    name: '',
    note: '',
    level: 1
  };
  @observable category = [];
  @observable keyword = [];
  @observable filteredKeyword = null;
  @observable filteredCategory = null;
  @observable lastQueryData = null;

  constructor(feature) {
    makeObservable(this);
    runInAction(() => {
      this.feature = feature;
    });
  }

  @computed
  get columnCount() {
    if (this.feature.level === 2) {
      return this.filteredCategory ? this.filteredCategory.length : this.category.length;
    }
    let count = 0;
    if (this.filteredCategory) {
      this.filteredCategory.forEach((c) => { count += c.subCategory.length; });
    } else {
      this.category.forEach((c) => { count += c.subCategory.length; });
    }
    return count;
  }

  @computed
  get tableData() {
    const array = [];
    const map = new Map();
    const category = this.filteredCategory || this.category;
    const keywords = this.filteredKeyword || this.keyword;

    if (this.feature.level === 3) {
      // set map keys with subcategoryId
      category.forEach((c) => {
        c.subCategory.forEach((subc) => { map.set(subc.id, []); });
      });
      // add data to the array according to subcategoryId
      keywords.forEach((data) => { map.get(data.subCid)?.push(data); });
    }

    if (this.feature.level === 2) {
      // set map keys with categoryId
      category.forEach((c) => { map.set(c.cid, []); });
      // add data to the array according to categoryId
      keywords.forEach((data) => { map.get(data.cid)?.push(data); });
    }

    // to get array of arrays grouping by subcategoryId or categoryId
    map.forEach((value, key) => array.push(value));


    let maxRowCount = 0;
    array.forEach((a) => {
      if (a.length > maxRowCount) { maxRowCount = a.length; }
    });

    return { array, maxRowCount };
  }
  @computed
  get canExport() {
    return this.keyword.every((keyword) => !keyword.keywordResult.hasError);
  }
  @action
  closeDrawer = () => {
    this.category = [];
    this.keyword = [];
    this.filteredKeyword = null;
    this.filteredCategory = null;
    this.lastQueryData = null;
  }
  @action
  resetQuery = () => {
    this.filteredKeyword = null;
    this.filteredCategory = null;
    this.lastQueryData = null;
  }
  @action
  getUploadData = (data) => {
    const { rows, fileName, note } = data;
    this.feature.name = fileName;
    this.feature.note = note;

    const category = new Map();

    rows.forEach((r) => {
      if (category.has(r.category)) {
        category.get(r.category).add(r.subCategory);
      } else {
        category.set(r.category, new Set([r.subCategory]));
      }
    });
    const categoryArray = [];
    category.forEach((value, key) => {
      categoryArray.push({ category: key, subCategory: Array.from(value) });
    });

    this.category = categoryArray.map((c) => new CategoryVM(c.category, c.subCategory, 1));


    this.keyword = rows.map((r, index) => new KeywordVM({
      category: r.category,
      subCategory: r.subCategory,
      name: r.name,
      keyword: r.keyword,
      kid: index
    }, this));
  }

  @action
  updateCategory = (cid, name) => {

    this.keyword.forEach((keyword) => {
      if (keyword.cid === cid) {
        keyword.updateCategory(name);
      }
    });

    this.category.find((category) => category.id === cid).update(name);
    this.resetQuery();
  }

  @action
  updateSubCategory = (cid, subCid, name) => {

    this.keyword.forEach((keyword) => {
      if (keyword.cid === cid && keyword.subCid === subCid) {
        keyword.updateSubCategory(name);
      }
    });

    this.category.find((category) => category.id === cid).updateSubCategory(name, subCid);
    this.resetQuery();
  }

  @action
  deleteSubCategory = (cid, subCid) => {
    const category = this.category.find((c) => c.id === cid);
    category.subCategory = category.subCategory.filter((c) => c.id !== subCid);

    this.category = this.category.filter((c) => c.subCategory.length !== 0);
    this.keyword = this.keyword.filter((k) => k.subCid !== subCid);
  }

  @action
  updateKeyword = (data) => {
    const { cid, kid, subCid, name, keyword } = data;
    this.keyword.find((k) => k.kid === kid).update(name, keyword);
    if (this.lastQueryData) this.onSearch(this.lastQueryData);
  }

  @action
  deleteKeyword = async (data) => {
    const { cid, kid, subCid } = data;
    const index = this.keyword.findIndex((k) => (k.kid === kid));

    // delete category if it has no keywords
    if (this.keyword[index].siblings.length === 1) {
      if (this.feature.level === 1) {
        this.deleteSubCategory(cid, subCid);
      } else {
        this.category = this.category.filter((c) => c.id !== cid);
      }
    }

    this.keyword.splice(index, 1);
    if (this.lastQueryData) this.onSearch(this.lastQueryData);
  }

  @action
  onSearch = (data) => {
    if (!data.keyword && !data.cid && !data.subCid && !data.errorOnly) {
      this.resetQuery();
      return;
    }

    this.lastQueryData = data;

    this.filteredKeyword = this.keyword.filter((k) => {
      const hasError = data.errorOnly ? k.keywordResult.hasError : true;
      const searchTxt = data.keyword ? k.keyword.includes(data.keyword) || k.kid.toString().includes(data.keyword) || k.name.includes(data.keyword) : true;
      const category = data.cid ? (k.cid === data.cid) : true;
      const subCategory = data.subCid ? (k.subCid === data.subCid) : true;
      return hasError && searchTxt && category && subCategory;
    });

    // keep categories that contain filtered keyword
    const cidSet = new Set();
    const subCidSet = new Set();
    this.filteredKeyword.forEach((k) => {
      cidSet.add(k.cid);
      subCidSet.add(k.subCid);
    });

    if (this.feature.level === 2) {
      this.filteredCategory = this.category.filter((c) => cidSet.has(c.cid));
    } else {
      // this.feature.level === 3
      const array = data.cid
        ? this.category.filter((c) => cidSet.has(c.cid) || c.cid === data.cid)
        : this.category.filter((c) => cidSet.has(c.cid));

      if (data.subCid) {
        this.filteredCategory = array.map((c) => ({ ...c, subCategory: c.subCategory.filter((sub) => sub.id === data.subCid || subCidSet.has(sub.id)) }));
      } else if (data.keyword || data.errorOnly) {
        this.filteredCategory = array.map((c) => ({ ...c, subCategory: c.subCategory.filter((sub) => subCidSet.has(sub.id)) }));
      } else {
        this.filteredCategory = array;
      }
    }
  }

  @action
  resetFilter = () => {
    this.filteredCategory = null;
    this.filteredKeyword = null;
  }

  @action
  onNameChange = (e) => { this.feature = { ...this.feature, name: e.target.value }; }

  @action
  onNoteChange = (e) => { this.feature = { ...this.feature, note: e.target.value }; }
}
