import React from 'react';
import { observer, Observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import AllProjectPageVM from 'src/viewModels/AllProjects';
import ProjectItem from 'src/components/project/ProjectItem';
import ActionBar from 'src/components/project/ActionBar';

@observer
class AllProjectsPage extends React.Component {
  constructor() {
    super();
    this.vm = new AllProjectPageVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div style={{ height: '100%', overflowY: 'hidden' }}>
        <ActionBar onQuery={this.vm.onSearch} />

        <Spin
          tip="正在加載資料"
          spinning={this.vm.isLoading}
        >
          <BottomScrollListener onBottom={this.vm.onBotton}>
            {(scrollRef) => (
              <div
                ref={scrollRef}
                style={{ overflowY: 'scroll', height: 'calc(100vh - 135px' }}
              >
                <Observer>
                  {() => (
                    <>
                      {this.vm.list.map((project) => (
                        <ProjectItem
                          key={project._id}
                          exportable
                          projectId={project._id}
                          project={project}
                          infoButton={(
                            <>
                              <Button
                                type="primary"
                                style={{ flex: 1, marginRight: 5 }}
                                block
                              >
                                <Link to={`/project/records/${project._id}`}>
                                  異動紀錄
                                </Link>
                              </Button>
                            </>
                         )}
                          settingButton={(
                            <>
                              <Button
                                type="primary"
                                block
                              >
                                <Link to={`/allprojects/${project._id}`}>
                                  檢視
                                </Link>
                              </Button>
                            </>
                        )}
                        />
                      ))}
                    </>
                  )}

                </Observer>
              </div>
            )}
          </BottomScrollListener>
        </Spin>
      </div>
    );
  }
}

export default withProfile(withRouter(AllProjectsPage), true);
