import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.css';

const NotFoundPage = (props) => {
  const navigate = useNavigate();

  const { state } = props.context;

  return (
    <div className={styles.page}>
      <div
        className={clsx(styles.img, state.isMobile && styles.mobile)}
        onClick={() => navigate('/')}
      />
    </div>
  );
};


NotFoundPage.propTypes = {
  context: PropTypes.shape({
    state: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
};

export default NotFoundPage;
