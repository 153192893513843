import Cookies from 'js-cookie';
import { AUTH_TOKEN_KEY } from 'src/constants';
import { useNavigate } from 'react-router-dom';

const LoginSuccessPage = () => {
  const navigate = useNavigate();

  (async () => {
    const jwt = await Cookies.get('jwt');

    localStorage.setItem(AUTH_TOKEN_KEY, jwt);
    Cookies.remove('jwt');

    setTimeout(() => {
      navigate('/');
    }, 500);
  })();

  return null;
};

export default LoginSuccessPage;
