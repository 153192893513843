import { useState, useEffect } from 'react';
import { Modal, DatePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const DisableModal = (props) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    return function cleanup() {
      setDate(null);
    };
  }, []);

  return (
    <Modal
      maskClosable={false}
      closable={false}
      okText="確認停用"
      cancelText="取消"
      visible={props.visible}
      title={(
        <div>
          <ExclamationCircleOutlined style={{ color: '#FAAD14', marginRight: 10 }} />
          請輸入永久停用資料起始日
        </div>
      )}
      onOk={() => {
        props.onOk(date.startOf('day').toISOString());
        setDate(null);
      }}
      onCancel={() => {
        setDate(null);
        props.onCancel();
      }}
      okButtonProps={{
        disabled: !date
      }}
    >
      <DatePicker
        value={date}
        allowClear={false}
        onChange={setDate}
        style={{ width: '100%', marginBottom: 20 }}
      />
      <div>{props.content}</div>
    </Modal>
  );
};

DisableModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};


export default DisableModal;
