import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { Modal } from 'antd';
import { errorModal } from 'src/utils';
import ProjectService from 'src/services/project';

export default class DeleteSOP {

  @observable ids = [];
  projectId = '';
  deleteCallback = () => {};
  resolve = () => {};
  reject = () => {};
  rejectWhenCancel = false;

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  @action
  end = () => {
    this.ids = [];
    this.resolve();
  }

  start = async (ids, deleteCallback, rejectWhenCancel = false) => {
    return new Promise((resolve, reject) => {
      // get project status
      const getData = async () => {
        const res = await ProjectService.getProject(this.projectId);
        const isRuning = res.meta.isRun;
        const { beginAt, endAt } = res.progress;

        this.deleteCallback = deleteCallback;
        this.rejectWhenCancel = rejectWhenCancel;
        runInAction(() => { this.ids = ids; });

        // project is not running
        if (!isRuning) {
          return this.deleteAsProjectNotRuning();
        }

        // project is running and backtracking
        if (beginAt && !endAt) {
          return errorModal(
            '刪除失敗！',
            '此專案有一個以上的資料分析工作正在進行，為避免分析結果錯亂，目前無法執行資料刪除工作。'
          );
        }

        // project is running but not backtracking
        this.deleteAsProjectRuning();
        return null;
      };

      getData();
      this.resolve = resolve;
      this.reject = reject;

    });
  }


  deleteAsProjectNotRuning = async () => {

    Modal.confirm({
      title: `確定刪除共 ${this.ids.length} 筆特性群組/特性關鍵字嗎？`,
      okText: '確認刪除',
      cancelText: '取消',
      okButtonProps: { danger: true },
      zIndex: 900,
      onOk: async () => {
        try {
          await this.deleteCallback();
          Modal.success({ title: '刪除成功。', okText: '關閉' });
        } catch {
          // ignore
        } finally {
          this.end();
        }
      },
      onCancel: async (close) => {
        if (this.rejectWhenCancel) {
          this.reject();
        } else {
          this.end();
        }
      }
    });
  }

  deleteAsProjectRuning = async () => {

    Modal.confirm({
      title: `共 ${this.ids.length} 筆特性群組/特性關鍵字將被刪除`,
      content: '刪除特性群組/特性關鍵字代表系統將刪除此專案中這些特性群組/特性關鍵字有關的資料(包含過往分析過的口碑)，並重新分析現存資料結果。資料一經刪除無法復原。此動作處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。是否確認永久刪除這些特性群組/特性關鍵字？',
      okText: '確認刪除',
      cancelText: '取消',
      okButtonProps: { danger: true },
      zIndex: 900,
      onOk: async () => {
        try {
          await this.deleteCallback();
          Modal.success({
            title: '特性群組/特性關鍵字設定已刪除，並將資料刪除任務排入佇列，稍後可到分析列表中查詢進度。',
            okText: '關閉'
          });
        } catch {
          // ignore
        } finally {
          this.end();
        }
      },
      onCancel: async (close) => {
        if (this.rejectWhenCancel) {
          this.reject();
        } else {
          this.end();
        }
      }
    });
  }


}
