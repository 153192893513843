import { makeObservable, action } from 'mobx';
import { Modal } from 'antd';
import { errorModal } from 'src/utils';
import ProjectService from 'src/services/project';
import ChannelSetService from 'src/services/channelSet';

export default class DeleteSOP {

  projectId = '';
  storedChannel = null;

  constructor(projectId, storedChannel) {
    makeObservable(this);
    this.projectId = projectId;
    this.storedChannel = storedChannel;
  }

  @action
  end = () => {
    this.storedChannel.selectedIdSet.clear();
  }

  @action
  cancel = () => {}

  start = async (ids) => {
    // get project status
    try {
      const res = await ProjectService.getProject(this.projectId);
      const isRuning = res.meta.isRun;
      const { beginAt, endAt } = res.progress;


      // project is not running
      if (!isRuning) {
        this.deleteAsProjectNotRuning();
        return;
      }


      // project is running and backtracking
      if (beginAt && !endAt) {
        errorModal(
          '刪除失敗！',
          '此專案有一個以上的資料分析工作正在進行，為避免分析結果錯亂，目前無法執行資料刪除工作。'
        );
        return;
      }

      // project is running but not backtracking
      this.deleteAsProjectRuning();

    } catch {
      errorModal('發生錯誤，請稍後再試');
    }
  }


  deleteAsProjectNotRuning = async () => {
    const ids = Array.from(this.storedChannel.selectedIdSet);

    Modal.confirm({
      title: `確定刪除共 ${ids.length} 筆頻道嗎？`,
      okText: '確認刪除',
      cancelText: '取消',
      okButtonProps: { danger: true },
      zIndex: 900,
      onOk: async () => {
        try {
          await ChannelSetService.removeChannel(this.projectId, ids);

          this.storedChannel.filterList(this.storedChannel.selectedIdSet);
          Modal.success({ title: '刪除成功。', okText: '關閉' });

        } catch {
          errorModal('發生錯誤，無法刪除頻道');

        } finally {
          this.end();
        }
      },
      onCancel: this.cancel
    });
  }

  deleteAsProjectRuning = async () => {
    const ids = Array.from(this.storedChannel.selectedIdSet);

    Modal.confirm({
      title: `共 ${ids.length || 1} 筆頻道組合將被刪除`,
      content: '刪除頻道代表系統將刪除專案中這些頻道的資料(包含過往分析過的口碑)，並重新分析現存資料結果，資料一經刪除無法復原。此動作的處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。是否確認永久刪除這些頻道？如不想刪除資料，請改使用「永久停用功能」',
      okText: '確認刪除',
      cancelText: '取消',
      okButtonProps: { danger: true },
      zIndex: 900,
      onOk: async () => {
        try {
          await ChannelSetService.removeChannel(this.projectId, ids);

          this.storedChannel.filterList(this.storedChannel.selectedIdSet);
          Modal.success({
            title: '頻道設定已刪除，並將資料刪除任務排入佇列，稍後可到分析列表中查詢進度。',
            okText: '關閉'
          });

        } catch {
          errorModal('發生錯誤，無法刪除頻道');

        } finally {
          this.end();
        }
      },
      onCancel: this.cancel
    });
  }


}
