export const TEXT = {
  brand: {
    L1: '品牌',
    L2: '產品線',
    L3: '產品',
    all: '產品線＋產品'
  },
  category: {
    L1: '品類',
    L2: '品牌',
    L3: '產品',
    all: '品牌＋產品'
  }
};
