/* eslint-disable no-fallthrough */
import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import ProjectDownloadService from 'src/services/projectDownload';
import SearchSetService from 'src/services/searchSet';
import { DOWNLOAD_TOPIC_TYPE } from 'src/constants';
import { errorModal } from 'src/utils';
import { message, Modal } from 'antd';

export default class DownloadTopics {
  projectId = '';

  @observable tags = []; // 專案所有標籤
  @observable selectedTags = []; // 主題範圍交叉標籤
  @observable selectedParenTag = ''; // 母標籤

  @observable currentLevel = 'L1';
  @observable keywordMap = new Map();
  @observable L1Keywords = [];
  @observable L2Keywords = [];
  @observable L3Keywords = [];
  @observable selectedL1KeywordId = [];
  @observable selectedL2KeywordId = [];
  @observable selectedL3KeywordId = [];
  @observable keywordsForUI = [];

  @observable isLoading = false;
  @observable isFetchingKeywords = false;

  @observable data = {
    type: DOWNLOAD_TOPIC_TYPE.ALL.value,
    startDate: null,
    endDate: null,
    keywords: []
  };

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  didMount = async () => {
    Promise.all([
      this.getTags(),
      this.getSearchKeywords({ level: 1 })
    ]);
  }

  produceTopics = async () => {
    if (this.isLoading) return;

    if (!this.data.startDate || !this.data.endDate) {
      errorModal('請輸入日期');
      return;
    }

    const _tags = [];
    this.selectedTags.forEach((tagKeyValue) => {
      const [key, value] = tagKeyValue.split('&&');
      _tags.push({ key, value });
    });

    const result = {
      // ...this.data,
      type: this.data.type,
      tags: _tags.length > 0 ? _tags : undefined,
      keywords: (this.data.keywords.length === 0)
        ? undefined
        : this.data.keywords,
      startDate: this.data.startDate.startOf('day').toISOString(),
      endDate: this.data.endDate.endOf('day').toISOString()
    };

    try {
      runInAction(() => { this.isLoading = true; });
      await ProjectDownloadService.topics(this.projectId, result);
      message.success('正在分析中，待完成會寄出結果');

    } catch (err) {
      switch (err.response?.status) {
        case 404:
          errorModal('所選搜尋關鍵字之標籤與交叉標籤不相符，或此專案尚未跑過分析');
          break;
        default:
          errorModal('發生錯誤，無法中止回溯');
      }
    } finally {
      runInAction(() => { this.isLoading = false; });
    }
  }

  getTags = async () => {
    try {
      const res = await SearchSetService.getAllTags(this.projectId, { scope: 'all' });

      const map = new Map();

      // 母標籤
      res.forEach((tag) => {
        if (!tag.parentId) {
          map.set(tag.id, {
            id: tag.id,
            name: tag.name,
            subTags: []
          });
        }
      });

      // 子標籤 （濾掉其他）
      res.forEach((tag) => {
        if (tag.parentId && !tag.default) {
          map.get(tag.parentId).subTags.push({
            id: tag.id,
            name: tag.name
          });
        }
      });

      const result = Array.from(map).map((item) => item[1]);
      runInAction(() => {
        this.tags = result;
      });

    } catch {
      // ignore
    }
  }

  getSearchKeywords = async ({ level, sid = undefined, subSid = undefined }) => {
    try {
      runInAction(() => { this.isFetchingKeywords = true; });
      const res = await SearchSetService.getAllKeyword(this.projectId, { level, sid, subSid });

      runInAction(() => {
        switch (level) {
          case 1:
            this.L1Keywords = [...this.L1Keywords, ...res];
            break;
          case 2:
            this.L2Keywords = [...this.L2Keywords, ...res];
            break;
          case 3:
            this.L3Keywords = [...this.L3Keywords, ...res];
            break;
          default:
        }

        res.forEach((keyword) => {
          if (!this.keywordMap.has(keyword._id)) {
            this.keywordMap.set(keyword._id, keyword);
          }
        });

        this.isFetchingKeywords = false;
      });

    } catch {
      // ignore
    }
  }

  _checkLevel = () => {
    let level;
    let keywordIds;
    let isError = false;

    if (this.selectedL3KeywordId.length) {
      level = 'L3';
      keywordIds = this.selectedL3KeywordId;

    } else if (this.selectedL2KeywordId.length) {
      level = 'L2';
      keywordIds = this.selectedL2KeywordId;

    } else if (this.selectedL1KeywordId.length) {
      level = 'L1';
      keywordIds = this.selectedL1KeywordId;
    }

    if (this.keywordsForUI.length === 0) {
      this.currentLevel = level;
    } else if (this.currentLevel !== level) {
      isError = true;
    }

    return { keywordIds, isError };
  }

  @action
  addKeyword = () => {
    const { keywordIds, isError } = this._checkLevel();
    if (isError) return message.warn('所選層級不符');

    const set = new Set(this.data.keywords);

    keywordIds.forEach((keywordId) => {

      if (!set.has(keywordId)) {
        set.add(keywordId);

        const keyword = this.keywordMap.get(keywordId);

        this.keywordsForUI.push({
          id: keyword._id,
          sid: keyword.level === 1 ? keyword._id : keyword.sid,
          subSid: keyword.level === 2 ? keyword._id : keyword.subSid,
          sub2Sid: keyword.level === 3 ? keyword._id : keyword.sub2Sid,
          keyword: keyword.keyword
        });
      }
    });

    this.data.keywords = Array.from(set);
    this.selectedL1KeywordId = [];
    this.selectedL2KeywordId = [];
    this.selectedL3KeywordId = [];
    this.L2Keywords = [];
    this.L3Keywords = [];

    return null;
  }

  @action
  deleteKeyword = (index) => {
    const keywordId = this.keywordsForUI[index].id;
    this.data.keywords = this.data.keywords.filter((keyword) => keyword !== keywordId);
    this.keywordsForUI.splice(index, 1);
  }

  @action
  resetKeywords = () => {
    Modal.confirm({
      title: '重新選擇層級將清除目前所選主題範圍。是否清除？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        runInAction(() => {
          this.data.keywords = [];
          this.keywordsForUI = [];
          this.currentLevel = 'L1';
          this.selectedL1KeywordId = [];
          this.selectedL2KeywordId = [];
          this.selectedL3KeywordId = [];
          this.L2Keywords = [];
          this.L3Keywords = [];
        });
      }
    });
  }

  @action
  resetData = () => {
    this.keywordsForUI = [];
    this.selectedL1KeywordId = [];
    this.selectedL2KeywordId = [];
    this.selectedL3KeywordId = [];
    this.L2Keywords = [];
    this.L3Keywords = [];
    this.data = {
      type: DOWNLOAD_TOPIC_TYPE.ALL.value,
      startDate: null,
      endDate: null,
      keywords: []
    };
    this.selectedTags = [];
  }

  @action
  onDateChange = (date) => {
    this.data.startDate = date?.[0] ?? null;
    this.data.endDate = date?.[1] ?? null;
  }

  @action
  onTagChange = (newArray) => {
    if (newArray.length === 0) {
      this.selectedParenTag = '';
    } else {
      this.selectedParenTag = newArray[0].split('&&')[0];
    }
    this.selectedTags = newArray;
  }

  @action
  onTypeChange = (e) => {
    this.data.type = e.target.value;
  }

  @action
  onKeywordSelect = (e, level) => {
    if (level === 1) {
      this.getSearchKeywords({ level: 2, sid: e });
      this.getSearchKeywords({ level: 3, sid: e });
      this.selectedL1KeywordId.push(e);

    } else if (level === 2) {
      this.selectedL2KeywordId.push(e);
      const L3ToStay = new Set(this.L3KeywordOptions.map((L3Keyword) => L3Keyword._id));
      this.selectedL3KeywordId = this.selectedL3KeywordId.filter((L3KeywordId) => L3ToStay.has(L3KeywordId));


    } else {
      this.selectedL3KeywordId.push(e);
    }
  }

  @action
  onKeywordDeselect = (e, level) => {
    const L2KeywordsIdToRemove = new Set();
    const L3KeywordsIdToRemove = new Set();

    // level 1
    if (level === 1) {
      this.L2Keywords = this.L2Keywords.filter((keyword) => {
        if (keyword.sid !== e) return true;
        L2KeywordsIdToRemove.add(keyword._id);
        return false;
      });

      this.L3Keywords = this.L3Keywords.filter((keyword) => {
        if (keyword.sid !== e) return true;
        L3KeywordsIdToRemove.add(keyword._id);
        return false;
      });

      this.selectedL1KeywordId = this.selectedL1KeywordId.filter((keyword) => keyword !== e);
      this.selectedL2KeywordId = this.selectedL2KeywordId.filter((keywordId) => !L2KeywordsIdToRemove.has(keywordId));
      this.selectedL3KeywordId = this.selectedL3KeywordId.filter((keywordId) => !L3KeywordsIdToRemove.has(keywordId));


    // level 2
    } else if (level === 2) {
      this.selectedL2KeywordId = this.selectedL2KeywordId.filter((keyword) => keyword !== e);

      const L2Set = new Set(this.selectedL2KeywordId);

      this.selectedL3KeywordId = this.selectedL3KeywordId.filter((keywordId) => {
        const keyword = this.keywordMap.get(keywordId);
        return L2Set.has(keyword.subSid);
      });


    // level 3
    } else {
      this.selectedL3KeywordId = this.selectedL3KeywordId.filter((keyword) => keyword !== e);
    }
  }

  @action
  onDeselectAll = (level) => {
    switch (level) {
      case 1:
        this.selectedL1KeywordId = [];
        this.selectedL2KeywordId = [];
        this.selectedL3KeywordId = [];
        this.L2Keywords = [];
        this.L3Keywords = [];
        break;
      case 2:
        this.selectedL2KeywordId = [];
        break;
      default:
        this.selectedL3KeywordId = [];
    }
  }

  @action
  onSelectAll = async (level) => {
    if (level === 1) {
      this.selectedL1KeywordId = this.L1Keywords.map((keyword) => keyword._id);
      this.L2Keywords = [];
      this.L3Keywords = [];
      await this.getSearchKeywords({ level: 2 });
      await this.getSearchKeywords({ level: 3 });
    } else if (level === 2) {
      this.selectedL2KeywordId = this.L2Keywords.map((keyword) => keyword._id);
    } else {
      this.selectedL3KeywordId = this.L3KeywordOptions.map((keyword) => keyword._id);
    }
  }

  @computed
  get L3KeywordOptions() {
    if (this.selectedL2KeywordId.length) {
      const L2Set = new Set(this.selectedL2KeywordId);
      return this.L3Keywords.filter((L3Keyword) => L2Set.has(L3Keyword.subSid));
    }
    return this.L3Keywords;
  }

  @computed
  get L2KeywordOptions() {
    return this.L2Keywords;
  }

  @computed
  get L1KeywordOptions() {
    return this.L1Keywords;
  }
}
