import {
  makeObservable, computed, observable, action
} from 'mobx';

export default class DrawerViewModel {
  @observable config = null;
  @observable visible = false;

  constructor() {
    makeObservable(this);
  }

  @action
  hide = () => {
    this.visible = false;
    this.config = null;
  }

  @action
  show = (config = null) => {
    this.visible = true;
    if (config) { this.config = config; }
  }

  action = (callbackFunc) => {
    if (callbackFunc) callbackFunc();
  }
}
