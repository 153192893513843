import React, { useState, useEffect, useRef, useCallback } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, Spin, Row, Button, Dropdown, Menu } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import styles from './styles.module.css';
import { WIDTH } from '../../../pages/ProjectSettingsPage/FeatureSetPane/components/constants';

const KeywordTableBody = (props) => {
  const [tableWidth, setTableWidth] = useState(0);
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft
          });
        }
      }
    });
    return obj;
  });
  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true
      });
    }
  };
  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderColumns = [
    {
      title: '關鍵字',
      render: (data) => (
        <Observer>
          {() => (
            <Row>
              <div className={styles.txt}>{data?.name ?? ''}</div>
            </Row>
          )}
        </Observer>
      )
    },
    {
      title: '關鍵字語法設定',
      render: (data) => (
        <Observer>
          {() => (
            <div className={styles.txt}>

              {data?.keywordResult?.hasError
                && <ExclamationCircleFilled style={{ color: 'red', marginTop: 5, marginRight: 5 }} />}
              {data?.keywordResult?.hasWarn
                && <ExclamationCircleFilled style={{ color: '#FF9314', marginTop: 5, marginRight: 5 }} />}

              <div style={{ display: 'inline' }}>
                {data?.keyword ?? ''}
              </div>
            </div>
          )}
        </Observer>
      )
    },
    {
      title: '操作',
      render: (data) => (
        <>
          {data?.kid || data?.kid === 0
            ? (
              <Dropdown overlay={(
                <Menu>
                  {props.canEdit
                    ? (
                      <>
                        <Menu.Item key="edit" onClick={() => props.showModal(data)}>檢視與編輯</Menu.Item>
                        <Menu.Item key="delete" onClick={() => props.vm.deleteKeyword(data)}>刪除</Menu.Item>
                      </>
                    )
                    : (
                      <Menu.Item key="edit" onClick={() => props.showModal(data)}>檢視</Menu.Item>
                    )}
                </Menu>
                )}
              >
                <Button type="text" style={{ marginLeft: '-10px' }}>...</Button>
              </Dropdown>
            )
            : null}
        </>
      )
    }
  ];


  const columnHeader = useCallback(() => {
    const category = props.vm.filteredCategory || props.vm.category;
    const commonChild = [
      {
        title: '關鍵字',
        key: 'name',
        width: WIDTH.NAME
      },
      {
        title: '關鍵字語法設定',
        key: 'keyword',
        width: WIDTH.KEYWORD
      },
      {
        title: '操作',
        key: 'action',
        width: WIDTH.ACTION
      }
    ];

    if (props.vm.feature.level === 2) {
      return category.map((c) => ({
        title: (
          <Row justify="space-between">
            <div>{c.name}</div>
            {props.canAdd && (
              <Button
                type="primary"
                size="small"
                onClick={() => props.showModal({ cid: c.cid })}
              >
                ＋ 新增
              </Button>
            )}
          </Row>
        ),
        id: c.cid,
        children: commonChild
      }));
    }

    return category
      .filter((c) => c.subCategory.length > 0)
      .map((c) => ({
        title: c.name,
        id: c.cid,
        children: c.subCategory.map((sub) => ({
          title: (
            <Row justify="space-between">
              <div>{sub.name}</div>
              {props.canAdd && (
              <Button
                type="primary"
                size="small"
                onClick={() => props.showModal({ cid: c.cid, subCid: sub.id })}
              >
                ＋ 新增
              </Button>
              )}
            </Row>
          ),
          id: sub.id,
          width: 460,
          children: commonChild
        }))
      }));


  }, [props]);



  const RenderVirtualGrid = (rawData, { scrollbarSize, ref, onScroll }) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = connectObject;

    return (
      <Observer>
        {() => (
          <Grid
            ref={gridRef}
            columnCount={props.vm.columnCount * 3}
            columnWidth={(index) => {
              if (index % 3 === 0) return WIDTH.NAME;
              if (index % 3 === 1) return WIDTH.KEYWORD;
              return WIDTH.ACTION;
            }}
            height={props.scroll.y}
            rowHeight={() => 60}
            rowCount={props.vm.tableData.maxRowCount}
            width={tableWidth}
            onScroll={({ scrollLeft }) => {
              onScroll({
                scrollLeft
              });
            }}
          >
            {({ columnIndex, rowIndex, style }) => {
              return (
                <div
                  style={{
                    ...style,
                    padding: '6px 12px',
                    borderBottom: '1px solid #F0F0F0',
                    borderLeft: (columnIndex % 3 === 0) ? '1px solid #F0F0F0' : 'null'
                  }}
                  className={
                classNames(
                  'virtual-table-cell',
                  { 'virtual-table-cell-last': columnIndex === props.vm.columnCount.length - 1 }
                )
              }
                >
                  {renderColumns[columnIndex % 3].render(props.vm.tableData.array[(Math.floor(columnIndex / 3))][rowIndex])}
                </div>
              );
            }}
          </Grid>
        )}

      </Observer>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        key={`ss${tableWidth}`}
        dataSource={props.keyword}
        columns={columnHeader()}
        className="virtual-table"
        pagination={false}
        bordered
        components={{
          body: RenderVirtualGrid
        }}
        size="small"
        loading={false}
        loadingIndicator={<Spin tip="正在加載資料" />}
        scroll={{
          x: 700,
          y: 500
        }}
      />
    </ResizeObserver>
  );
};


KeywordTableBody.propTypes = {
  keyword: PropTypes.array.isRequired,
  vm: PropTypes.object.isRequired,
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
  scroll: PropTypes.shape({ y: PropTypes.number }).isRequired
};

KeywordTableBody.defaultProps = {
  showModal: () => {}
};

export default observer(KeywordTableBody);
