import { getHost, request } from 'src/utils';
import AuthService from './auth';

// 手動回溯
export default class ProjectBackTrack {

  /**
   * create project backtrack
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string} data.startDate
   * @param {string} data.finishDate
   * @param {bool} data.featureOnly
   */
  static async create(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/backTrack`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   *
   * @param {string} id  required
   */
  static async delete(id) {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/backTrack`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }
}
