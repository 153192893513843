import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message, Modal } from 'antd';

import FeatureSetService from 'src/services/featureSet';

export default class SubTabsViewModel {

  projectId = null;
  featureId = null;
  lastQueryData = null;
  @observable feature = {
    name: '',
    note: '',
    level: 1
  };
  @observable category = [];
  @observable keyword = [];
  @observable filteredKeyword = null;
  @observable filteredCategory = null;

  constructor({ feature }) {
    makeObservable(this);
    runInAction(() => {
      this.projectId = feature.projectId;
      this.featureId = feature.fid;
      this.feature = feature;
    });
  }

  didMount = () => {
    this.queryFeatureDetail();
  }

  @computed
  get columnCount() {
    if (this.feature.level === 2) {
      return this.filteredCategory ? this.filteredCategory.length : this.category.length;
    }
    let count = 0;
    if (this.filteredCategory) {
      this.filteredCategory.forEach((c) => { count += c.subCategory.length; });
    } else {
      this.category.forEach((c) => { count += c.subCategory.length; });
    }
    return count;
  }

  @computed
  get tableData() {
    const array = [];
    const map = new Map();
    const category = this.filteredCategory || this.category;
    const keywords = this.filteredKeyword || this.keyword;

    if (this.feature.level === 3) {
      // set map keys with subcategoryId
      category.forEach((c) => {
        c.subCategory.forEach((subc) => { map.set(subc.id, []); });
      });
      // add data to the array according to subcategoryId
      keywords.forEach((data) => { map.get(data.subCid)?.push(data); });
    }

    if (this.feature.level === 2) {
      // set map keys with categoryId
      category.forEach((c) => { map.set(c.cid, []); });
      // add data to the array according to categoryId
      keywords.forEach((data) => { map.get(data.cid)?.push(data); });
    }

    // to get array of arrays grouping by subcategoryId or categoryId
    map.forEach((value, key) => array.push(value));


    let maxRowCount = 0;
    array.forEach((a) => {
      if (a.length > maxRowCount) { maxRowCount = a.length; }
    });
    return {
      array,
      maxRowCount
    };
  }


  queryAllCatogory = async () => {
    try {
      const category = await FeatureSetService.queryAllCategory(this.projectId, this.featureId);
      runInAction(() => { this.category = category; });
      this.resetFilter();
    } catch (err) {
      message.error('發生錯誤，無法取得資訊');
    }
  }

  queryFeatureDetail = async () => {
    try {
      const { featureSet, category, keyword } = await FeatureSetService.queryFeatureSetDetail(this.projectId, this.featureId);
      runInAction(() => {
        this.feature = featureSet;
        this.category = category;
        this.keyword = keyword;
      });
      this.resetFilter();
    } catch (err) {
      message.error('發生錯誤，無法取得資訊');
    }
  }



  @action
  onSearch = (data) => {
    if (!data.keyword && !data.cid && !data.subCid) {
      this.filteredKeyword = null;
      this.filteredCategory = null;
      this.lastQueryData = null;
      return;
    }

    this.lastQueryData = data;

    this.filteredKeyword = this.keyword.filter((k) => {
      const searchTxt = data.keyword ? k.keyword.includes(data.keyword) || k.kid.includes(data.keyword) || k.name.includes(data.keyword) : true;
      const category = data.cid ? (k.cid === data.cid) : true;
      const subCategory = data.subCid ? (k.subCid === data.subCid) : true;
      return searchTxt && category && subCategory;
    });

    // keep categories that contain filtered keyword
    const cidSet = new Set();
    const subCidSet = new Set();
    this.filteredKeyword.forEach((k) => {
      cidSet.add(k.cid);
      subCidSet.add(k.subCid);
    });

    if (this.feature.level === 2) {
      this.filteredCategory = data.cid
        ? this.category.filter((c) => cidSet.has(c.cid) || c.cid === data.cid)
        : this.category.filter((c) => cidSet.has(c.cid));
    } else {
      // this.feature.level === 3
      const array = data.cid
        ? this.category.filter((c) => cidSet.has(c.cid) || c.cid === data.cid)
        : this.category.filter((c) => cidSet.has(c.cid));

      if (data.subCid) {
        this.filteredCategory = array.map((c) => ({ ...c, subCategory: c.subCategory.filter((sub) => sub.id === data.subCid || subCidSet.has(sub.id)) }));
      } else if (data.keyword) {
        this.filteredCategory = array.map((c) => ({ ...c, subCategory: c.subCategory.filter((sub) => subCidSet.has(sub.id)) }));
      } else {
        this.filteredCategory = array;
      }
    }
  }

  @action
  resetFilter = () => {
    this.filteredCategory = null;
    this.filteredKeyword = null;
    this.lastQueryData = null;
  }

}
