import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Input, Modal } from 'antd';
import { keywordParse } from 'src/services/keywordParser';

import styles from './styles.module.css';

const KeywordModal = (props) => {
  const [name, setName] = useState('');
  const [keyword, setKeyword] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [keywordResult, setResult] = useState(null);

  const init = useCallback(() => {
    setName('');
    setKeyword('');
    setIsEdit(false);
    props.hide();
  }, [props]);

  useEffect(() => {
    if (props.config?.kid || props.config?.kid === 0) {
      setName(props.config.name);
      setKeyword(props.config.keyword);
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [props.config]);


  useEffect(() => {
    const { result, reasons } = keywordParse(keyword);
    const hasError = (result === 'error') || (result === 'notComplete');
    const hasWaran = (result === 'warn');
    setResult({ result, reasons, hasError, hasWaran });
  }, [keyword]);

  const footer = useCallback(() => {
    if (props.config?.kid || props.config?.kid === 0) {
      if (isEdit) {
        return (
          <Button
            type="primary"
            disabled={!keyword || !name || keywordResult.hasError}
            onClick={() => {
              props.onUpdate({
                name: name.trim(),
                keyword: keyword.trim(),
                kid: props.config.kid,
                cid: props.config.cid,
                subCid: props.config?.subCid ?? null
              });
              init();
            }}
          >
            儲存
          </Button>
        );
      }
      return (
        <Button type="primary" onClick={() => setIsEdit(true)}>
          編輯
        </Button>
      );
    }

    return (
      <Button
        type="primary"
        disabled={!keyword || !name || keywordResult.hasError}
        onClick={() => {
          props.onCreate({
            name: name.trim(),
            keyword: keyword.trim(),
            cid: props.config.cid,
            subCid: props.config?.subCid ?? null
          });
          init();
        }}
      >
        新增
      </Button>
    );
  }, [init, isEdit, keyword, keywordResult, name, props]);

  const titleTxt = useCallback(() => {
    if (!props.canEdit) return '檢視特性關鍵字';
    if (props.config?.kid) return '檢視與編輯特性關鍵字';
    return '新增特性關鍵字';
  }, [props.canEdit, props.config?.kid]);

  return (
    <Modal
      title={titleTxt()}
      width={800}
      visible={props.visible}
      onCancel={() => {
        props.hide();
        setName(null);
        setKeyword(null);
      }}
      footer={props.canEdit && footer()}
    >
      <Row className={styles.marginBottom}>
        <div>關鍵字：</div>
        {isEdit ? (
          <Input
            placeholder="輸入關鍵字名稱"
            className={styles.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <div className={styles.name}>{name}</div>
        )}
      </Row>
      <Row>
        <div>關鍵字語法設定：</div>
        {isEdit ? (
          <>
            <Input.TextArea
              rows={10}
              className={styles.keyword}
              style={{ border: keywordResult.hasError ? '1px solid red' : null }}
              placeholder="輸入關鍵字語法"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className={styles.warnTxt}>
              {keywordResult.reasons.map((r) => (
                <div key={r.id} className={styles[r.type]}>{r.message}</div>
              ))}
            </div>

          </>
        ) : (
          <div className={styles.keyword}>{keyword}</div>
        )}
      </Row>
    </Modal>
  );
};

KeywordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  config: PropTypes.any,
  canEdit: PropTypes.bool
};

KeywordModal.defaultProps = {
  config: null,
  onCreate: () => {},
  canEdit: true
};

export default KeywordModal;
