import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Tag, Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ProjectService from 'src/services/project';


const ProjectHeader = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [project, setProject] = useState(null);
  const [vocRange, setVocRange] = useState(null); // 現有口碑資料區間
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    const projectId = props.projectId || params.id;

    (async () => {
      try {
        if (!mounted) return;
        setLoading(true);
        const _project = await ProjectService.getProject(projectId);
        setProject(_project);

      } catch {
        navigate('/project');
      } finally {
        if (mounted) setLoading(false);
      }
    })();

    return () => { mounted = false; };

  }, [params, navigate, props.projectId]);


  useEffect(() => {
    let mounted = true;
    const projectId = props.projectId || params.id;

    (async () => {
      try {
        if (!mounted || !(project?.meta?.isRun ?? false)) return;
        const _vocRange = await ProjectService.getVocRange(projectId);
        setVocRange(_vocRange[0]);

      } catch {
        // ignore
      }
    })();

    return () => { mounted = false; };

  }, [props.projectId, project, params.id]);

  const renderStatusTag = () => {
    switch (project?.meta?.status) {
      case 'notRun':
        return <Tag color="blue">未啟動</Tag>;
      case 'run':
        return <Tag color="blue">專案運作中</Tag>;
      case 'outOfDate':
        return <Tag color="blue">已過期</Tag>;
      default:
        return null;
    }
  };

  const renderSearchAngleTag = () => {
    switch (project?.meta?.searchAngle) {
      case 'brand':
        return <Tag>品牌分析切角</Tag>;
      case 'category':
        return <Tag>品類分析切角</Tag>;
      default:
        return null;
    }
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          <Spin size="middle" spinning={loading} style={{ marginLeft: 20, marginTop: 10 }} />
          <div style={{ fontSize: 20, fontWeight: 500 }}>
            {project?.meta?.name ?? ''}
          </div>
          <div>
            {renderStatusTag()}
            {renderSearchAngleTag()}
            {project?.autorun?.checkChannelName && <Tag>比對頻道名稱</Tag>}
          </div>
        </Col>
        <Col>
          <Row>
            <div>專案資料起訖區間：</div>
            <div>
              {`
              ${project?.autorun?.startDate
                ? dayjs(project.autorun.startDate).format('YYYY/MM/DD')
                : '尚無起始日'} - 
              ${project?.autorun?.endDate
                  ? dayjs(project.autorun.endDate).format('YYYY/MM/DD')
                  : '尚無截止日'}
            `}
            </div>
          </Row>
          <Row>
            <div>現有口碑資料區間：</div>
            <div>
              {`
              ${vocRange?.from
                ? dayjs(vocRange.from).format('YYYY/MM/DD')
                : '尚無起始日'} - 
              ${vocRange?.to
                  ? dayjs(vocRange.to).format('YYYY/MM/DD')
                  : '尚無截止日'}
            `}
            </div>
          </Row>
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

ProjectHeader.propTypes = {
  projectId: PropTypes.string
};

ProjectHeader.defaultProps = {
  projectId: ''
};


export default ProjectHeader;
