import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Row, Select, Col } from 'antd';
import { ORDER, NO_DEPARTMENT_ID, CUSTOMER_DEPARTMENT_ID, LARGE_LIMIT, PROJECT_DOMAINS } from 'src/constants';
import TeamService from 'src/services/team';
import UserService from 'src/services/user';

import styles from './styles.module.css';

const ActionBar = (props) => {
  const [form] = Form.useForm();
  const [team, setTeam] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      (async () => {
        try {
          const res = await TeamService.get();
          const teams = res.filter((d) => d.id !== NO_DEPARTMENT_ID && d.id !== CUSTOMER_DEPARTMENT_ID);
          const { users } = await UserService.getUsers({
            limit: LARGE_LIMIT,
            order: 'department',
            pmStatus: 'enable'
          });
          setTeam(teams);
          setUser(users);
        } catch (err) {
          console.log(err);
        }
      })();
    }

    return () => { mounted = false; };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      order: ORDER.createDateDesc,
      isTemplate: true
    });
  }, [form]);

  return (
    <div className={styles.box}>
      <Form form={form}>
        <Row justify="space-between" wrap={false}>
          <Col flex={1}>
            <Form.Item name="keyword" style={{ marginBottom: 5 }}>
              <Input placeholder="搜尋（專案名稱、專案ID）" style={{ width: '70%' }} />
            </Form.Item>

            <Row align="middle">
              <div>篩選條件：</div>
              <Row>
                <Form.Item name="domain" className={styles.selector}>
                  <Select
                    style={{ width: 140 }}
                    placeholder="所屬領域"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                  >
                    {PROJECT_DOMAINS.map((domain) => (
                      <Select.Option key={domain} value={domain}>{domain}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="status" className={styles.selector}>
                  <Select style={{ width: 130 }} placeholder="專案狀態" allowClear>
                    <Select.Option value="notRun">未啟動</Select.Option>
                    <Select.Option value="run">運作中</Select.Option>
                    <Select.Option value="outOfDate">已過期</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="isTemplate" className={styles.selector}>
                  <Select
                    style={{ width: 150 }}
                    placeholder="是否為公版專案"
                    // allowClear
                    // disabled={props.templateProjectsOnly}
                  >
                    <Select.Option value>僅顯示公版專案</Select.Option>
                    <Select.Option value={null}>顯示全部</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="searchAngle" className={styles.selector}>
                  <Select style={{ width: 140 }} placeholder="切角分析" allowClear>
                    <Select.Option value="brand">品牌分析切角</Select.Option>
                    <Select.Option value="category">品類分析切角</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item name="teamId" className={styles.selector}>
                  <Select
                    placeholder="部門"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 165 }}
                  >
                    {team.map((t) => (
                      <Select.Option value={t.id} key={t.id}>{t.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="userId" style={{ marginBottom: 5 }}>
                  <Select
                    placeholder="負責人"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 220 }}
                  >
                    {user.map((t) => (
                      <Select.Option value={t.id} key={t.id}>{`${t.teamName} - ${t.name}`}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

              </Row>
            </Row>
          </Col>


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minWidth: 210 }}>
            <Form.Item name="order" label="排序依：" style={{ marginBottom: 5 }}>
              <Select>
                <Select.Option value={ORDER.startDateDesc}>專案開始日新到舊</Select.Option>
                <Select.Option value={ORDER.startDateAsc}>專案開始日舊到新</Select.Option>
                <Select.Option value={ORDER.endDateDesc}>專案結束日新到舊</Select.Option>
                <Select.Option value={ORDER.endDateAsc}>專案結束日舊到新</Select.Option>
                <Select.Option value={ORDER.createDateDesc}>專案建立日新到舊</Select.Option>
                <Select.Option value={ORDER.createDateAsc}>專案建立日舊到新</Select.Option>
              </Select>
            </Form.Item>

            <Row justify="end" style={{ paddingBottom: 10 }}>
              <Button onClick={() => {
                form.resetFields();
                form.setFieldsValue({
                  order: ORDER.createDateDesc,
                  isTemplate: true
                });
              }}
              >
                清空篩選條件
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() => props.onQuery(form.getFieldsValue())}
              >
                套用
              </Button>
            </Row>
          </div>
        </Row>

      </Form>
    </div>
  );
};

ActionBar.propTypes = {
  onQuery: PropTypes.func.isRequired
  // templateProjectsOnly: PropTypes.bool
};

ActionBar.defaultProps = {
  // templateProjectsOnly: false
};


export default ActionBar;
