import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';


const ChangeLogNoteModal = observer((props) => {
  const [text, setText] = useState('');
  const [isLodaing, setIsLoading] = useState(false);

  useEffect(() => {
    const modal = props.modal;

    if (modal.visible && modal.config) {
      setText(modal.config.note);
    } else {
      setText('');
    }
  }, [props.modal.visible, props.modal.config, props.modal]);


  return (
    <Modal
      okText="儲存"
      cancelText="取消"
      title="備註"
      confirmLoading={isLodaing}
      visible={props.modal.visible}
      onCancel={props.modal.hide}
      onOk={async () => {
        setIsLoading(true);
        await props.modal.config.updateNote(text);
        setIsLoading(false);
        props.modal.hide();
      }}
    >
      <Input.TextArea
        rows={12}
        onChange={(e) => setText(e.target.value)}
        value={text}
      >
        {text}
      </Input.TextArea>
    </Modal>
  );
});


ChangeLogNoteModal.propTypes = {
  modal: PropTypes.object.isRequired
};

export default ChangeLogNoteModal;
