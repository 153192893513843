export default class FeatureSet {
  constructor(params) {
    const {
      refProject,
      note,
      _id,
      projectId,
      name,
      level,
      createDate
    } = params;

    this.refProject = refProject;
    this.note = note;
    this.fid = _id;
    this.projectId = projectId;
    this.name = name;
    this.level = level;
    this.createDate = createDate;
  }

  static fromRes(data) {
    return new FeatureSet(data);
  }
}

