import React from 'react';
import PropTypes from 'prop-types';

import { AppStateContext } from 'src/stores';
import UserService from 'src/services/user';
import AuthService from 'src/services/auth';
import withRouter from 'src/components/withRouter';


const withProfile = (WrappedComponent, isRequired = true) => {
  class WithProfileHOC extends React.Component {
    static propTypes = {
      router: PropTypes.object.isRequired
    }

    async componentDidMount() {
      const { actions } = this.context ?? {};

      try {
        const p = await UserService.getProfile();
        actions.updateProfile(p);

      } catch (err) {
        console.log('cannot login', err);
        actions.updateProfile(null);
        AuthService.logout();

        if (isRequired) {
          this.props.router?.navigate('/login');
        }
      }
    }

    render() {
      const {
        // forwardedRef,
        router,
        ...ps
      } = this.props;

      return isRequired && !this.context.state.profile ? null : (
        <WrappedComponent
          {...ps}
          profile={this.context.state.profile}
          // ref={forwardedRef}
        />
      );
    }
  }

  WithProfileHOC.contextType = AppStateContext;

  const Component = withRouter(WithProfileHOC);

  return React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
  });
};

export default withProfile;
