import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { FixedSizeList as List } from 'react-window';
import PropTypes from 'prop-types';
import { Button, DatePicker, Row, TreeSelect, Radio, Select, Collapse, Spin, Empty } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import withRouter from 'src/components/withRouter';
import { SEARCH_KEYWORD_TXT, DOWNLOAD_TOPIC_TYPE } from 'src/constants';
import DownloadTopicsVM from 'src/viewModels/ProjectReports/DownloadTopics';

import styles from './styles.module.css';

// 下載話題列表
@observer
class DownloadTopics extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DownloadTopicsVM(this.props.router.params.id);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderRow = ({ index, style }) => {
    const keywordMap = this.vm.keywordMap;
    const keyword = this.vm.keywordsForUI[index];
    const l1 = keywordMap.get(keyword.sid)?.name ?? '';
    const l2 = keywordMap.get(keyword.subSid)?.name ?? '';
    const l3 = keywordMap.get(keyword.sub2Sid)?.name ?? '';
    return (
      <Row align="middle" style={style}>
        <MinusCircleOutlined
          onClick={() => this.vm.deleteKeyword(index)}
          className={styles.red}
        />
        <div>{l1}</div>
        <div>{l2 && `-${l2}`}</div>
        <div>{l3 && `-${l3}`}</div>
      </Row>
    );
  };

  render() {
    const searchAngle = this.props.project?.meta?.searchAngle;
    const keywordTxt = SEARCH_KEYWORD_TXT[searchAngle];

    return (
      <>
        <Row align="middle" className={styles.margin}>
          <div className={styles.title}>查詢範圍：</div>
          <DatePicker.RangePicker
            onChange={this.vm.onDateChange}
            value={[this.vm.data.startDate, this.vm.data.endDate]}
          />
        </Row>


        <Row align="bottom" className={styles.margin}>
          <div className={styles.title}>主題範圍搜尋關鍵字：</div>
          {
            [1, 2, 3].map((index) => {
              const text = keywordTxt?.[`L${index}`] ?? '';
              const selectedKeywordIds = this.vm[`selectedL${index}KeywordId`] ?? [];
              const options = this.vm[`L${index}KeywordOptions`] ?? [];

              return (
                <div className={styles.col} key={index}>
                  <div className={styles.flex_row_end}>
                    <Button type="link" onClick={() => this.vm.onSelectAll(index)}>全選</Button>
                    <Button type="link" onClick={() => this.vm.onDeselectAll(index)}>取消選擇</Button>
                  </div>
                  <Select
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    maxTagCount={0}
                    className={styles.select}
                    maxTagPlaceholder={`${text}(${selectedKeywordIds.length})`}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    value={selectedKeywordIds}
                    onSelect={(e) => this.vm.onKeywordSelect(e, index)}
                    onDeselect={(e) => this.vm.onKeywordDeselect(e, index)}
                    placeholder={`請搜尋或選擇${text}`}
                    notFoundContent={this.vm.isFetchingKeywords
                      ? <Spin size="small" />
                      : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                  >
                    {options.map((keyword) => (
                      <Select.Option
                        key={keyword._id}
                        value={keyword._id}
                      >
                        {keyword.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              );
            })
          }

          <Button
            shape="circle"
            type="primary"
            onClick={this.vm.addKeyword}
            className={styles.mr}
          >
            +
          </Button>
          <Button
            onClick={this.vm.resetKeywords}
          >
            重新選擇層級
          </Button>
        </Row>

        <div className={styles.extra}>
          ※ 不選 = 全選
        </div>

        <Collapse
          defaultActiveKey={['1']}
          className={styles.collapse}
        >
          <Collapse.Panel
            key="1"
            header={`所選主題範圍 - 範圍層級：
            ${SEARCH_KEYWORD_TXT[searchAngle ?? 'brand'][this.vm.currentLevel]}
            (${this.vm.currentLevel})`}
          >
            <div className={styles.collapseBody}>
              <List
                height={300}
                width="100%"
                itemSize={30}
                itemCount={this.vm.keywordsForUI.length}
              >
                {this.renderRow}
              </List>
            </div>
          </Collapse.Panel>
        </Collapse>


        <Row align="middle" className={clsx([styles.marginBottom, styles.tagBox])}>
          <div className={styles.title}>主題範圍交叉標籤：</div>
          <TreeSelect
            allowClear
            multiple
            getPopupContainer={(trigger) => trigger.parentElement}
            showSearch
            filterTreeNode
            treeCheckable
            treeNodeFilterProp="title"
            placeholder="請搜尋或選擇標籤"
            onChange={this.vm.onTagChange}
            className={styles.treeSelect}
            value={this.vm.selectedTags}
          >
            {this.vm.tags.map((tag) => {

              const disabled = (this.vm.selectedParenTag !== '') && (tag.id !== this.vm.selectedParenTag);

              return (
                <TreeSelect.TreeNode
                  key={tag.id}
                  value={tag.id}
                  title={tag.name}
                  selectable={false}
                  disabled={disabled}
                >
                  {tag.subTags.map((subTag) => (
                    <TreeSelect.TreeNode
                      disabled={disabled}
                      key={`${tag.id}&&${subTag.id}`}
                      value={`${tag.id}&&${subTag.id}`}
                      title={subTag.name}
                    />
                  ))}
                </TreeSelect.TreeNode>
              );
            })}
          </TreeSelect>
        </Row>

        <div className={styles.extra}>
          ※ 不選 = 全選，主題範圍若有第二層(品牌/產品線)及第三層產品時，請留意與標籤交叉是否會產生空集合。
        </div>


        <Radio.Group
          className={styles.margin}
          onChange={this.vm.onTypeChange}
          value={this.vm.data.type}
        >
          {Object.keys(DOWNLOAD_TOPIC_TYPE).map((item) => (
            <Radio
              value={DOWNLOAD_TOPIC_TYPE[item].value}
              key={DOWNLOAD_TOPIC_TYPE[item].value}
            >
              {DOWNLOAD_TOPIC_TYPE[item].name}
            </Radio>
          ))}
        </Radio.Group>


        <Row justify="center" className={styles.btns}>
          <Button
            type="primary"
            onClick={this.vm.produceTopics}
            className={styles.margin}
            loading={this.vm.isLoading}
          >
            產出話題列表
          </Button>
          <Button
            onClick={this.vm.resetData}
            className={styles.margin}
          >
            重新設定
          </Button>
        </Row>

      </>
    );
  }
}

DownloadTopics.propTypes = {
  router: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

export default withRouter(DownloadTopics);
