import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import SearchSetService from 'src/services/searchSet';
import ProjectService from 'src/services/project';
import ModalViewModel from 'src/viewModels/modal';
import KeywordPaneVM from './KeywordPaneVM';
import KeywordItem from './KeywordItem';

export default class SearchPaneVM {

  projectId = ''
  L1 = null;
  L2 = null;
  L3 = null;
  @observable searchAngle = null;
  @observable tags = [];
  @observable L1KeywordOptions = [];
  @observable L2KeywordOptions = [];
  @observable keywordPool = new Map();
  @observable tagPool = new Map();
  drawer = new ModalViewModel();

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
    this.L1 = new KeywordPaneVM(this.projectId, '1');
    this.L2 = new KeywordPaneVM(this.projectId, '2');
    this.L3 = new KeywordPaneVM(this.projectId, '3');
  }

  didMount = async () => {
    try {
      this.getAllTags();
      this.L1.getList();
      this.L2.getList();
      this.L3.getList();

      const { meta: { searchAngle } } = await ProjectService.getProject(this.projectId);
      const L1Res = await SearchSetService.getAllKeyword(this.projectId, { level: 1 });
      const L2Res = await SearchSetService.getAllKeyword(this.projectId, { level: 2 });

      runInAction(() => {
        this.searchAngle = searchAngle; // brand | category
        this.L1KeywordOptions = L1Res.map((res) => new KeywordItem(res));
        this.L2KeywordOptions = L2Res.map((res) => new KeywordItem(res));

        this.L1KeywordOptions.forEach((keyword) => {
          this.keywordPool.set(keyword.id, keyword);
        });
        this.L2KeywordOptions.forEach((keyword) => {
          this.keywordPool.set(keyword.id, keyword);
        });
      });

    } catch {
      // ignore
    }
  }


  getAllTags = async () => {
    try {
      const res = await SearchSetService.getAllTags(this.projectId, { scope: 'all' });

      const map = new Map();
      const tags = [];

      res.forEach((r) => !r.parentId && map.set(r.id, r));
      res.forEach((r) => r.parentId && map.get(r.parentId).pushSubTags(r));
      map.forEach((value, key) => tags.push(value));

      runInAction(() => {
        this.tags = tags;
        res.forEach((t) => { this.tagPool.set(t.id, t); });
      });
    } catch (err) {
      // TODO
    }
  }

  @computed
  get L2tags() {
    // scope === l2 or all
    return this.tags.filter((tag) => tag.scope !== 'l3');
  }

  @computed
  get L3tags() {
    // scope === l3 or all
    return this.tags.filter((tag) => tag.scope !== 'l2');
  }

}
