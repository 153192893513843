import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import UserService from 'src/services/user';
import withRouter from 'src/components/withRouter';
import { AppStateContext } from 'src/stores';
import ProjectHeader from 'src/components/project/ProjectHeader';
import ChannelSetPane from './ChannelSetPane';
import FeatureSetPane from './FeatureSetPane';
import SearchSetPane from './SearchSetPane';

@observer
class SettingsPage extends React.Component {

  async componentDidMount() {
    const { actions } = this.context ?? {};

    try {
      const p = await UserService.getProfile();
      actions.updateProfile(p);

    } catch (err) {
      console.log('cannot login', err);
      actions.updateProfile(null);

      this.props.router.navigate(`/project/settings/${this.props.router.params.id}/visitor`);
    }
  }

  render() {
    return (
      <>
        <ProjectHeader />

        <Tabs
          defaultActiveKey="1"
          size="small"
        >
          <Tabs.TabPane tab="頻道組合" key="1">
            <ChannelSetPane />
          </Tabs.TabPane>
          <Tabs.TabPane tab="搜尋關鍵字" key="2">
            <SearchSetPane />
          </Tabs.TabPane>
          <Tabs.TabPane tab="特性關鍵字" key="3">
            <FeatureSetPane />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }
}

SettingsPage.contextType = AppStateContext;

SettingsPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withRouter(SettingsPage);
