import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Button, Tag, Row, Tooltip, Typography, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { PROJECT_STATUS } from 'src/constants';
import DeletedPageVM from 'src/viewModels/MyProject/Deleted';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ActionBar from '../components/ActionBar';

import styles from './styles.module.css';


@observer
class DeletedPage extends React.Component {
  constructor() {
    super();
    this.vm = new DeletedPageVM();
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderStatusTag = (project) => {
    switch (project?.meta?.status) {
      case PROJECT_STATUS.NOT_RUN:
        return <Tag color="blue">未啟動</Tag>;
      case PROJECT_STATUS.RUN:
        return <Tag color="blue">專案運作中</Tag>;
      case PROJECT_STATUS.OUT_OF_DATE:
        return <Tag color="blue">已過期</Tag>;
      default:
        return null;
    }
  };

  tooltipTitle = (project) => {
    switch (project?.meta?.status) {
      case PROJECT_STATUS.NOT_RUN:
        return <div>專案未跑過資料，目前無任何資料分析結果</div>;
      case PROJECT_STATUS.RUN:
        return <div>專案資料起訖區間未過期，執行每日排程自動分析</div>;
      case PROJECT_STATUS.OUT_OF_DATE:
        return <div>專案資料起訖區間已過期，不再執行每日排程分析</div>;
      default:
        return null;
    }
  };

  render() {
    return (
      <div>
        <ActionBar
          profile={this.props.profile}
          onQuery={this.vm.onSearch}
        />

        <Button type="link" style={{ marginBottom: 10 }}>
          <Link to="/project">{'<  返回專案列表'}</Link>
        </Button>

        <Spin
          tip="正在加載資料"
          spinning={this.vm.isLoading}
        >
          <BottomScrollListener onBottom={this.vm.onBotton}>
            {(scrollRef) => (
              <div ref={scrollRef} style={{ overflowY: 'scroll', height: 'calc(100vh - 185px)' }}>
                <Observer>
                  {() => (
                    <>
                      {this.vm.list.map((project) => (

                        // deleted item
                        <div
                          key={project?.id ?? ''}
                          className={styles.card}
                        >
                          <div className={styles.header}>
                            <div className={styles.headerLeft}>
                              {project.meta.isTemplate && <Tag>公版專案</Tag>}
                              {project.isExternal && <Tag>子系統專案</Tag>}
                            </div>
                            <div>專案基本資料</div>
                          </div>
                          <div className={styles.content}>
                            <div>
                              <Row align="middle">
                                <div className={styles.title}>專案狀態</div>
                                <Tooltip trigger={['hover']} title={this.tooltipTitle(project)}>
                                  <InfoCircleOutlined className={styles.mt} />
                                </Tooltip>
                              </Row>
                              {this.renderStatusTag(project)}
                            </div>
                            <div>
                              <div className={styles.title}>名稱</div>
                              <div>{project?.meta?.name ?? '-'}</div>
                            </div>
                            <div>
                              <div className={styles.title}>範圍定義</div>
                              <div>{project?.meta?.note ?? '-'}</div>
                            </div>
                            <div>
                              <div className={styles.title}>專案資訊</div>
                              <Row>
                                { project?.meta?.domain && <Tag>{project.meta.domain}</Tag>}
                                { project?.meta?.searchAngle === 'brand' && <Tag>品牌分析切角</Tag>}
                                { project?.meta?.searchAngle === 'category' && <Tag>品類分析切角</Tag>}
                                { project?.meta?.checkChannelName && <Tag>比對頻道名稱</Tag> }
                              </Row>
                            </div>
                            <div>
                              <div className={styles.title}>UID</div>
                              <Typography.Paragraph
                                copyable={{
                                  text: project?.id ?? '',
                                  tooltips: [project?.id]
                                }}
                              >
                                {project?.id ?? ''}
                              </Typography.Paragraph>
                            </div>
                            <div>
                              <div className={styles.title}>建立時間</div>
                              <div>{project?.meta?.createDate ? dayjs(project?.meta?.createDate).format('YYYY-MM-DD') : '-'}</div>
                            </div>
                            <div>
                              <div className={styles.title}>刪除時間</div>
                              <div>{project?.meta?.deleteDate ? dayjs(project?.meta?.deleteDate).format('YYYY-MM-DD') : '-'}</div>
                            </div>
                            <div>
                              <div className={styles.title}>專案負責人</div>
                              <Row>
                                <div>{project?.owner?.team?.name ?? '-'}</div>
                                <div> - </div>
                                <div>{project?.owner?.user?.name ?? '-'}</div>

                              </Row>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                </Observer>
              </div>
            )}
          </BottomScrollListener>
        </Spin>
      </div>
    );
  }
}

DeletedPage.propTypes = {
  profile: PropTypes.object.isRequired
};

export default withProfile(withRouter(DeletedPage), true);
