import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { CUSTOMER_DEPARTMENT_ID, NO_DEPARTMENT_ID, USER_ORDER } from 'src/constants';
import { errorModal } from 'src/utils';
import TeamService from 'src/services/team';
import UserService from 'src/services/user';
import ProjectService from 'src/services/project';
import ChannelSetService from 'src/services/channelSet';
import SearchSetService from 'src/services/searchSet';
import FeatureSetService from 'src/services/featureSet';

export default class ProjectItemVM {

  originalProject = null;
  isTeamLeader = false;

  @observable project = null;
  @observable projectId = '';

  @observable channelTip = null;
  @observable searchTip = null;
  @observable featureTip = null;

  @observable isEdit = false;
  @observable name = '';
  @observable note = '';
  @observable teamId = '';
  @observable userId = '';

  @observable teams = [];
  @observable users = [];
  @observable featureFiles = [{ fid: '', name: 'loading...' }];
  fetchfeatureFiles = false;


  constructor(project, projectId, isTeamLeader) {
    makeObservable(this);

    this.originalProject = {
      name: project.meta.name,
      note: project.meta.note,
      teamId: project.owner.team?.id,
      userId: project.owner.user?.id
    };
    this.isTeamLeader = isTeamLeader;

    runInAction(() => {
      this.project = project;
      this.projectId = projectId;
      this.name = project.meta.name;
      this.note = project.meta.note;
      this.teamId = project.owner.team?.id;
      this.userId = project.owner.user?.id;
    });
  }

  getChannelTip = async () => {
    try {
      const res = await ChannelSetService.getTip(this.project._id);
      runInAction(() => { this.channelTip = res; });
    } catch {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  resetChannelTip = () => {
    runInAction(() => { this.channelTip = null; });
  }

  getSearchTip = async () => {
    try {
      const res = await SearchSetService.getTip(this.project._id);
      runInAction(() => { this.searchTip = res; });
    } catch {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  resetSearchTip = () => {
    runInAction(() => { this.searchTip = null; });
  }

  getFeatureTip = async (id) => {
    try {
      runInAction(() => { this.featureTip = null; });
      const res = await FeatureSetService.getTip(this.project._id, id);
      runInAction(() => { this.featureTip = ({ id, ...res }); });
    } catch {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  resetFeatureTip = () => {
    runInAction(() => { this.featureTip = null; });
  }

  @action
  toggleIsEdit = (bool) => {
    this.isEdit = bool;
  }

  getTeams = async () => {
    try {
      const res = await TeamService.get();
      const filteredTeams = res.filter((team) =>
        team.id !== CUSTOMER_DEPARTMENT_ID && team.id !== NO_DEPARTMENT_ID);

      runInAction(() => {
        this.teams = filteredTeams;
      });
    } catch {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  getUsers = async () => {
    try {
      const res = await UserService.getUsers({
        team: this.teamId,
        order: USER_ORDER.DEPARTMENT,
        limit: 100000
      });

      runInAction(() => {
        this.users = res.users;
      });
    } catch {
      errorModal('發生錯誤，無法取得資訊');
    }
  }

  getFeatureFiles = async () => {
    if (this.fetchfeatureFiles) return;
    const { features } = await FeatureSetService.queryFeatureSet(this.projectId);
    runInAction(() => {
      this.featureFiles = (features.length > 0) ? features : [{ fid: '', name: '尚無資料' }];
      this.fetchfeatureFiles = true;
    });
  }

  @action
  onNameChange = (e) => {
    this.name = e.target.value;
  }

  @action
  onNoteChange = (e) => {
    this.note = e.target.value;
  }

  @action
  onTeamChange = (teamId) => {
    this.teamId = teamId;
    this.userId = '';
    this.getUsers();
  }

  @action
  onUserChange = (userId) => {
    this.userId = userId;
  }

  onEdit = async () => {
    this.toggleIsEdit(true);
    if (this.isTeamLeader) {
      this.getTeams();
      this.getUsers();
    }
  }

  onSave = async () => {
    if (!this.name || !this.teamId || !this.userId) {
      return errorModal('欄位有缺，無法更新');
    }

    try {
      await ProjectService.updateProject(this.projectId, {
        name: this.name,
        note: this.note,
        userId: this.userId,
        teamId: this.teamId
      });

      this.originalProject = {
        name: this.name,
        note: this.note,
        userId: this.userId,
        teamId: this.teamId
      };

    } catch (err) {
      runInAction(() => {
        this.name = this.originalProject.name;
        this.note = this.originalProject.note;
        this.teamId = this.originalProject.teamId;
        this.userId = this.originalProject.userId;
      });

      switch (err.response?.status) {
        case 409:
          errorModal('專案名稱重複，無法更新', '與資料庫已有名稱重複，請重新輸入。');
          break;

        case 400:
          errorModal('格式有誤，無法更新');
          break;

        default:
          errorModal('發生錯誤，無法更新');
      }
    } finally {
      this.toggleIsEdit(false);
    }
    return null;
  }


  exportChannel = () => {
    ChannelSetService.export(this.projectId, this.originalProject.name);
  }

  exportSearch = () => {
    SearchSetService.export(this.projectId, this.originalProject.name);
  }

  exportFeature = (fid, featureName) => {
    if (!fid) return;
    FeatureSetService.export(this.projectId, fid, `${this.originalProject.name}_${featureName}`);
  }

}
