import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Form, DatePicker, Row, Select, Col, Input } from 'antd';
import { LOG_STAGE, LOG_TYPE } from 'src/constants';

import styles from './styles.module.css';


const ActionBar = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      date: [
        dayjs().startOf('day').subtract(1, 'day'),
        dayjs().endOf('day')]
    });
  }, [form]);


  const reset = () => {
    form.resetFields();
    form.setFieldsValue({
      date: [
        dayjs().startOf('day').subtract(1, 'day'),
        dayjs().endOf('day')]
    });
  };

  return (
    <div className={styles.box}>
      <Form form={form}>
        <Row justify="space-between" align="bottom">

          <Col flex={1}>
            <Row>
              <Col
                flex={1}
                className={styles.mrl}
                style={{ maxWidth: 600 }}
              >
                <div>自定義搜尋</div>
                <Form.Item
                  name="keyword"
                  className={styles.mb}
                >
                  <Input
                    placeholder="專案名稱、專案ID"
                  />
                </Form.Item>
              </Col>

              <Col>
                <div>分析建置時間日期</div>
                <Form.Item
                  name="date"
                  className={styles.mb}
                >
                  <DatePicker.RangePicker
                    allowClear={false}
                    className={styles.mrl}
                  />
                </Form.Item>
              </Col>

              <Col>
                <div>篩選條件</div>
                <div style={{ display: 'flex' }}>
                  <Form.Item
                    name="type"
                    className={styles.mb}
                  >
                    <Select
                      allowClear
                      style={{ width: 200 }}
                      className={styles.mr}
                      placeholder="分析類型"
                    >
                      {Object.keys(LOG_TYPE).map((type) => (
                        <Select.Option
                          key={LOG_TYPE[type].value}
                          value={LOG_TYPE[type].value}
                        >
                          {LOG_TYPE[type].name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>


                  <Form.Item
                    name="stage"
                    className={styles.mb}
                  >
                    <Select
                      allowClear
                      style={{ width: 200 }}
                      className={styles.mr}
                      placeholder="分析狀態"
                    >
                      {Object.keys(LOG_STAGE).map((type) => (
                        <Select.Option
                          key={LOG_STAGE[type].value}
                          value={LOG_STAGE[type].value}
                        >
                          {LOG_STAGE[type].name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                </div>
              </Col>

            </Row>
          </Col>

          <Col style={{ marginBottom: 6 }}>
            <Button
              onClick={reset}
              className={styles.mr}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                const values = form.getFieldsValue();
                const data = {
                  ...values,
                  startDate: values.date ? (values.date[0]?.startOf('day')?.toISOString() ?? null) : null,
                  endDate: values.date ? (values.date[1]?.endOf('day')?.toISOString() ?? null) : null
                };
                delete values.date;
                await props.onQuery(data);
              }}
            >
              套用
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ActionBar.propTypes = {
  onQuery: PropTypes.func.isRequired
};

export default ActionBar;
