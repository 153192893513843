import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import FeatureSetService from 'src/services/featureSet';
import SubTabsViewModel from './SubTabVM';

export default class FeatureSetViewModel {

  id = null

  @observable subTabs = [];

  constructor(id) {
    makeObservable(this);
    this.id = id;
    // this.getList();
  }


  getList = async () => {
    try {
      if (!this.id) return;
      const { features } = await FeatureSetService.queryFeatureSet(this.id);
      const subTabs = features.map((feature) => new SubTabsViewModel({ feature }));
      runInAction(() => { this.subTabs = subTabs; });

    } catch {
      // TODO
    }
  }


}
