import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Upload, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const ImportModal = (props) => {
  const [file, setFile] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);


  const onUpload = useCallback(
    async () => {
      try {
        if (!file) { return; }
        setConfirmLoading(true);
        await props.onUpload(file);
        setConfirmLoading(false);
        props.hide();
      } catch (err) {
        // TODO: update this
      } finally {
        setFile(null);
      }
    },
    [file, props]
  );

  const makeFileName = () => {
    switch (props.type) {
      case 'channel':
        return '頻道組合範例';
      case 'search':
        return '搜尋關鍵字範例';
      case 'feature1':
        return '特性關鍵字範例_階層1';
      case 'feature2':
        return '特性關鍵字範例_階層2';
      default:
        return '';
    }
  };

  const onCustomRequest = useCallback(
    (params) => {
      setFile(params.file);
      params.onSuccess();
    },
    []
  );

  const onFileRemove = useCallback(
    () => {
      setFile(null);
    },
    []
  );

  return (
    <Modal
      title="匯入檔案"
      visible={props.visible}
      centered
      destroyOnClose
      okText="匯入"
      cancelText="取消"
      onOk={onUpload}
      zIndex={2}
      confirmLoading={confirmLoading}
      onCancel={() => {
        props.hide();
        setFile(null);
      }}
      okButtonProps={{
        disabled: !file
      }}
    >
      <Upload.Dragger
        multiple={false}
        maxCount={1}
        accept="text/csv"
        customRequest={onCustomRequest}
        onRemove={onFileRemove}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          band files
        </p>
      </Upload.Dragger>

      <Row justify="center" align="middle" style={{ marginTop: 10 }}>
        <div style={{ marginRight: 10 }}>或是</div>
        <Button type="primary">
          <a href={`/${props.type}.csv`} download={makeFileName()}>
            下載匯入檔案範例格式
          </a>
        </Button>
      </Row>
    </Modal>
  );
};

ImportModal.propTypes = {
  onUpload: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['channel', 'search', 'feature1', 'feature2']).isRequired
};

ImportModal.defaultProps = {};


export default ImportModal;
