import { makeObservable, observable, runInAction } from 'mobx';
import FeatureSetService from 'src/services/featureSet';
import ProjectService from 'src/services/project';
import { errorModal } from 'src/utils';

export default class FeatureSetViewModel {

  id = null

  @observable featureSet = null;
  @observable projectMetaData = null;

  constructor(id) {
    makeObservable(this);
    this.id = id;
  }

  didMount = async () => {
    Promise.all([
      this.refresh(),
      this.getMetaData()
    ]);
  }

  refresh = async () => {
    if (!this.id) return;

    try {
      const { features } = await FeatureSetService.queryFeatureSet(this.id);
      runInAction(() => { this.featureSet = features; });
    } catch {
      // ignore
    }
  }

  getMetaData = async () => {
    try {
      const res = await ProjectService.getProject(this.id);
      runInAction(() => { this.projectMetaData = res; });
    } catch {
      // ignore
    }
  }

  onUpdateInfo = async (featureId, data) => {

    runInAction(() => {
      for (let index = 0; index < this.featureSet?.length ?? 0; index += 1) {
        const f = this.featureSet[index];
        if (f.fid === featureId) {
          this.featureSet[index] = { ...f, ...data };
          break;
        }
      }
    });
  }

  onDeleteFeatureFile = (featureId) => {
    runInAction(() => {
      this.featureSet = this.featureSet.filter((f) => f.fid !== featureId);
    });
  }

  onCreate = async (data) => {
    try {
      await FeatureSetService.createFeatureSet(this.id, data);
      this.refresh();
    } catch {
      errorModal('發生錯誤，無法新增檔案');
    }
  }
}
