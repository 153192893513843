import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

export default class SearchKeyword {
  @observable sid = '';
  @observable subSid = '';
  @observable name = '';
  @observable keyword = '';
  @observable synonym = '';
  @observable enable = false;
  @observable disableDate = '';
  @observable id = '';
  @observable tags = '';
  @observable level = '';

  constructor(params, tags) {
    const {
      sid,
      subSid,
      name,
      keyword,
      synonym,
      enable,
      disableDate,
      _id,
      level
    } = params;

    makeObservable(this);

    runInAction(() => {
      this.sid = sid;
      this.subSid = subSid;
      this.name = name;
      this.keyword = keyword;
      this.synonym = synonym;
      this.enable = enable;
      this.disableDate = disableDate;
      this.id = _id;
      this.level = level;

      this.tags = tags ?? null;
    });

  }

  @action
  update = async ({ name, keyword, synonym }) => {
    this.name = name;
    this.keyword = keyword;
    this.synonym = synonym;
  }

  @action
  updateTags = (array) => {
    this.tags = [...array];
  }

  @action
  updateOneTag = (key, value) => {
    this.tags.find((t) => t.key === key).value = value;
  }

  @action
  disable = (date) => {
    this.disableDate = date;
    this.enable = false;
  }

  static fromRes(keyword, tags) {
    return new SearchKeyword(keyword, tags);
  }
}
