import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Row, Switch, DatePicker } from 'antd';

import styles from './styles.module.css';

const ActionBar = (props) => {
  const [form] = Form.useForm();

  return (
    <>
      <Form form={form}>
        <div className={styles.form}>

          <Row
            align="middle"
            wrap={false}
            className={styles.search}
          >
            <Form.Item
              label="搜尋"
              name="keyword"
              className={styles.searchInput}
            >
              <Input
                // placeholder="搜尋(異動紀錄ID、備註、項目名稱、名稱UID)"
                placeholder={props.placeholder}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            label="僅列有備註"
            name="hasNote"
            valuePropName="checked"
            className={styles.marginBottom}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="日期篩選"
            name="date"
            className={styles.marginBottom}
          >
            <DatePicker.RangePicker
              allowEmpty={[true, true]}
              format="YYYY-MM-DD"
            />
          </Form.Item>


          <Row wrap={false}>
            <Button
              onClick={() => form.resetFields()}
              className={styles.marginRightTen}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                const values = form.getFieldsValue();
                const data = {
                  ...values,
                  hasNote: values.hasNote ? true : undefined,
                  startDate: values.date ? (values.date[0]?.startOf('day')?.toISOString() ?? null) : null,
                  endDate: values.date ? (values.date[1]?.endOf('day')?.toISOString() ?? null) : null
                };
                console.log(data);
                await props.onQuery(data);
              }}
            >
              套用
            </Button>
          </Row>
        </div>


      </Form>
    </>
  );
};


ActionBar.propTypes = {
  onQuery: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

ActionBar.defaultProps = {
  placeholder: '請輸入'
};


export default ActionBar;
