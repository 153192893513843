import nearley from 'nearley';
import { nanoid } from 'nanoid';
import grammar from './grammar';

const operation = /[+\-&]/;
// eslint-disable-next-line no-control-regex
const invisible = /[\xA0\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/;
const warnChars = /[“”｀‘’；＋—＆（）]/;

function analyze(node, reasons) {
  if (node.type === 'expression') {
    analyze(node.left, reasons);
    analyze(node.right, reasons);
  } else if (node.type === 'orUnit') {
    node.set.forEach((n) => analyze(n, reasons));
  } else if (node.type === 'near') {
    const id = nanoid();

    if (node.left.match(operation)) {
      reasons.push({
        id,
        type: 'warn',
        message: `警告: 雙引號與 '/' 連用 '${node.left}'`
      });

    }
    if (node.right.match(operation)) {
      reasons.push({
        id,
        type: 'warn',
        message: `警告: 雙引號與 '/' 連用 '${node.right}'`
      });
    }
  }
}

const compiledGrammar = nearley.Grammar.fromCompiled(grammar);

export function keywordParse(value) {
  try {
    const parser = new nearley.Parser(compiledGrammar);
    parser.feed(value);

    if (parser.results.length) {
      const reasons = [];
      analyze(parser.results[0], reasons);

      if (warnChars.test(value)) {
        const id = nanoid();

        reasons.push({
          id,
          type: 'warn',
          message: '警告: 內含警告全形字元「 “”｀‘’；＋—＆（）」'
        });
      }

      return {
        result: reasons.length ? 'warn' : 'pass',
        tree: parser.results[0],
        reasons
      };
    }

    return {
      result: 'notComplete',
      reasons: [{ id: nanoid(), type: 'notComplete', message: '關鍵字內容不完整' }]
    };
  } catch (parseError) {
    const invisibleChar = parseError.token?.value.match(invisible);
    return {
      result: 'error',
      errorOffset: parseError.offset,
      reasons: [
        {
          id: nanoid(),
          type: 'error',
          message: `錯誤: 第${parseError.offset + 1}字元 '${parseError.token?.value}' ${invisibleChar ? '不可見字元' : '語法'}不如預期`
        }
      ]
    };
  }
}
