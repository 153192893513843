import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Table, Row, Button, Tag, Modal, Radio, Input, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ModalViewModel from 'src/viewModels/modal';

import NewTag from 'src/components/NewTag';
import SubTag from 'src/components/SubTag';
import ParentTag from 'src/components/ParentTag';

import { TEXT } from './constants';

const TagPane = observer((props) => {

  const [createModalVM] = useState(new ModalViewModel());
  const [input, setInput] = useState(null);
  const [scope, setScope] = useState(null);

  const confirmDelete = ({ type, tid, subTid }) => {
    const config = {
      parentTag: {
        text: '即將刪除指定母標籤及所屬子標籤資料庫對應之所有欄位內容，確認是否刪除？',
        delete: props.vm.tag.deleteTag
      },
      subTag: {
        text: '即將刪除指定子標籤及資料庫對應之所有欄位內容，確認是否刪除？',
        delete: async () => {
          await props.vm.tag.deleteSubTag(tid, subTid);
        }
      }
    };
    return new Promise((resolve, reject) => {
      Modal.confirm({
        maskClosable: true,
        title: '是否刪除',
        icon: <ExclamationCircleOutlined />,
        content: config[type].text,
        okText: '確認',
        cancelText: '取消',
        onOk: async () => {
          try {
            await config[type].delete();
            resolve();
          } catch {
            reject();
          }
        },
        onCancel: () => reject()
      });
    });
  };

  const closeCreateModal = () => {
    createModalVM.hide();
    setInput(null);
    setScope(null);
  };

  const columns = () => {
    return [
      {
        title: (<Row justify="center">序號</Row>),
        width: 50,
        key: 'index',
        render: (_, __, i) => (
          <Row justify="center">{i + 1}</Row>
        )
      },
      {
        title: '母標籤',
        key: 'parentTag',
        width: 200,
        render: (data) => (
          <ParentTag
            canCopy={false}
            name={data.name}
            id={data.id}
            onUpdate={(newName) => props.vm.tag.updateTag(data, newName)}
          />
        )
      },
      {
        title: '所屬階層',
        key: 'level',
        width: 200,
        render: (data) => {
          let text = '';
          const searchAngle = props.vm.searchAngle;
          switch (data.scope) {
            case 'l2':
              text = TEXT[searchAngle]?.L2;
              break;
            case 'l3':
              text = TEXT[searchAngle]?.L3;
              break;
            case 'all':
              text = TEXT[searchAngle]?.all;
              break;
            default:
              text = '';
          }
          return (<div>{text}</div>);
        }
      },
      {
        title: (
          <Row>
            <div>子標籤</div>
            <div style={{ color: 'rgb(177, 177, 177)' }}>(雙擊子標籤可編輯子標籤內容)</div>
          </Row>
        ),
        key: 'subTags',
        render: (data) => (
          <div>
            {data.subTags.map((sub) => {
              if (sub.default) return (<Tag key={sub.id}>其他</Tag>);

              return (
                <SubTag
                  canCopy={false}
                  key={sub.id}
                  name={sub.name}
                  id={sub.id}
                  onClose={async () => {
                    await confirmDelete({ type: 'subTag', tid: data.id, subTid: sub.id });
                  }}
                  onUpdate={(newName) => props.vm.tag.updateSubTag(sub, newName)}
                />
              );
            })}

            <NewTag action={(value) => props.vm.tag.createSubTag(data.id, value)} />
          </div>
        )
      }
    ];
  };

  return (
    <>
      <Row justify="end" style={{ marginBottom: 10 }}>
        <Button
          danger
          style={{ marginRight: 10 }}
          onClick={async () => {
            if (props.vm.tag.selectedTagKey.length === 0) {
              return message.warn('請先勾選再使用刪除功能');
            }

            try {
              await confirmDelete({ type: 'parentTag' });
            } catch {
              // ignore
            }
            return null;
          }}
        >
          刪除已勾選標籤
        </Button>
        <Button type="primary" onClick={createModalVM.show}>
          ＋新增母標籤
        </Button>
      </Row>


      <Table
        dataSource={props.vm.tag.tags}
        rowKey="id"
        size="small"
        columns={columns()}
        loading={false}
        pagination={false}
        scroll={{
          crollToFirstRowOnChange: true,
          x: 800,
          y: 'calc(100vh - 300px)'
        }}
        rowSelection={{
          selectedRowKeys: props.vm.tag.selectedTagKey,
          onChange: (selectedRowKeys, selectedRows) => {
            props.vm.tag.onTagSelectChange(selectedRowKeys);
          }
        }}
      />



      <Modal
        title="新增母標籤"
        visible={createModalVM.visible}
        onCancel={closeCreateModal}
        footer={(
          <Button
            type="primary"
            onClick={() => {
              props.vm.tag.createTag(input, scope);
              closeCreateModal();
            }}
            disabled={!input || !scope}
          >
            新增
          </Button>
        )}
      >
        <Row style={{ marginBottom: 15 }}>
          <div>母標籤名稱：</div>
          <Input
            placeholder="輸入母標籤名稱"
            style={{ width: 'calc(100% - 100px)' }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <div>所屬階層：</div>
          <Radio.Group onChange={(e) => setScope(e.target.value)} value={scope}>
            <Radio value="l2">{TEXT[props.vm.searchAngle]?.L2}</Radio>
            <Radio value="l3">{TEXT[props.vm.searchAngle]?.L3}</Radio>
            {/* <Radio value="all">{TEXT[props.vm.searchAngle]?.all}</Radio> */}
          </Radio.Group>
        </Row>
        <div style={{ color: '#F00202' }}>所屬階層設定後不能再更改。</div>
      </Modal>

    </>
  );

});

TagPane.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired
};

TagPane.defaultProps = {

};

export default TagPane;
