import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Input, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const ParentTag = observer((props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.name);
  }, [props.name]);

  const handeleUpdate = async (e) => {
    if (e.keyCode === 229) return;

    try {
      if (value === props.name) return;
      await props.onUpdate(value);
    } catch (err) {
      setValue(props.name);
    } finally {
      setIsEdit(false);
    }
  };

  if (!props.canEdit) {
    return (
      <Typography.Paragraph
        style={{ display: 'inline-block' }}
        copyable={
          props.canCopy
            ? { text: props.id, tooltips: [props.id] }
            : null
          }
      >
        {value}
      </Typography.Paragraph>
    );
  }

  return (
    <>
      {isEdit
        ? (
          <Input
            autoFocus
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handeleUpdate}
            onPressEnter={handeleUpdate}
          />
        )
        : (
          <div>
            <Typography.Paragraph
              style={{ display: 'inline-block' }}
              copyable={
                props.canCopy
                  ? { text: props.id, tooltips: [props.id] }
                  : null
              }
            >
              {value}
              <EditOutlined
                onClick={() => setIsEdit(true)}
                style={{ marginLeft: 5, color: '#1890FF' }}
              />
            </Typography.Paragraph>
          </div>
        )}
    </>
  );
});

ParentTag.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  canCopy: PropTypes.bool,
  canEdit: PropTypes.bool
};

ParentTag.defaultProps = {
  canCopy: true,
  canEdit: true
};

export default ParentTag;
