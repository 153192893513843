import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import PropTypes from 'prop-types';
import { Button, Form, Input, Row, Col, Switch, Select } from 'antd';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

// mode: default || myProject || importDrawer
const L2 = observer((props) => {
  const [form] = Form.useForm();
  const [tag, setTag] = useState('');
  const [subTag, setSubTag] = useState('');
  const [isSelect, setIsSelect] = useState(false);
  const [selectOptions, setSelectOptions] = useState(null);

  useEffect(() => {
    runInAction(() => {
      const subTags = props.tags
        .find((t) => {
          if (props.mode === 'importDrawer') {
            return t.fullName === tag;
          }
          return t.id === tag;
        })
        ?.subTags;

      if (!subTags) {
        setSelectOptions(null);
        return;
      }

      const produceComponent = (subT) => (
        // in importDrawer mode, the value is its name, otherwise the value is its id
        <Select.Option
          key={subT.id}
          value={props.mode === 'importDrawer' ? subT.name : subT.id}
        >
          {props.tagPool ? (props.tagPool.get(subT.id)?.name ?? '') : subT.name}
        </Select.Option>
      );

      const NotDefaultTags = subTags
        .filter((subT) => !subT.default)
        .map(produceComponent);

      const DefaultTags = subTags
        .filter((subT) => subT.default)
        .map(produceComponent);

      setSelectOptions(NotDefaultTags.concat(DefaultTags));

    });
  }, [props.mode, props.tagPool, props.tags, tag]);

  return (
    <div style={{
      border: '1px solid #aaa',
      padding: 10,
      marginBottom: 10
    }}
    >
      <Form form={form}>
        <Row justify="space-between" align="bottom" gutter={20}>
          <Col flex={1}>
            <div>自定義搜尋</div>
            <Form.Item name="keyword" style={{ marginBottom: 5 }}>
              <Input
                placeholder="搜尋（名稱、ID、或同義詞）"
                style={{ width: '100%' }}
                disabled={isSelect}
              />
            </Form.Item>
          </Col>

          <Col>
            <div>標籤篩選條件</div>
            <Row>
              <Form.Item name="tagKeyId" style={{ marginBottom: 5, width: 300 }}>
                <Select
                  allowClear
                  disabled={isSelect}
                  showSearch
                  optionFilterProp="children"
                  onClear={() => {
                    setSubTag('');
                    form.setFieldsValue({ tagValueId: undefined });
                  }}
                  onChange={(value) => {
                    setTag(value);
                    setSubTag('');
                    form.setFieldsValue({ tagValueId: undefined });
                  }}
                  placeholder="母標籤名稱"
                >
                  {/* in importDrawer mode, the value is its name, otherwise the value is its id */}
                  {props.tags.map((t) => (
                    <Select.Option
                      key={t.id}
                      value={props.mode === 'importDrawer' ? t.fullName : t.id}
                    >
                      {props.tagPool ? (props.tagPool.get(t.id)?.name ?? '') : t.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="tagValueId" style={{ marginBottom: 5, width: 300, marginLeft: 10 }}>
                <Select
                  allowClear
                  disabled={isSelect}
                  onChange={(value) => setSubTag(value)}
                  placeholder="子標籤名稱"
                  showSearch
                  optionFilterProp="children"
                >
                  {selectOptions}
                </Select>
              </Form.Item>
            </Row>
          </Col>



          {props.mode === 'myProject' && (
          <Col>
            <div>是否已勾選</div>
            <Form.Item name="isSelected" style={{ marginBottom: 5 }} valuePropName="checked">
              <Switch onChange={() => setIsSelect(!isSelect)} />
            </Form.Item>
          </Col>
          )}


          {props.mode === 'importDrawer' && (
          <Col>
            <div>其他篩選條件</div>
            <Form.Item name="isError" style={{ marginBottom: 5 }} valuePropName="checked">
              <Switch checkedChildren="只顯示錯誤內容" unCheckedChildren="只顯示錯誤內容" />
            </Form.Item>
          </Col>
          )}

        </Row>


        <Row justify="space-between" align="bottom" gutter={20}>
          <Col flex={1}>
            <div>資料篩選條件(可多選)</div>
            <Form.Item name="sids" style={{ marginBottom: 0 }}>
              <Select
                disabled={isSelect}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder={`${SEARCH_KEYWORD_TXT[props.searchAngle]?.L1}`}
                optionFilterProp="children"
              >
                {props.L1KeywordOption.map((o) => (
                  <Select.Option key={o.id} value={props.mode === 'importDrawer' ? o.name : o.id}>
                    {props.keywordPool ? (props.keywordPool.get(o.id)?.name ?? '') : o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button
              onClick={() => {
                form.resetFields();
                setTag('');
                setSubTag('');
                setIsSelect(false);
              }}
              style={{ marginRight: 10 }}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              disabled={tag && !subTag}
              onClick={async () => {
                const values = form.getFieldsValue();
                await props.onQuery(values);
              }}
            >
              套用
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
});

L2.propTypes = {
  onQuery: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  L1KeywordOption: PropTypes.array.isRequired,
  searchAngle: PropTypes.string.isRequired,
  keywordPool: PropTypes.object,
  tagPool: PropTypes.object,
  mode: PropTypes.string
};

L2.defaultProps = {
  keywordPool: null,
  tagPool: null,
  mode: 'default'
};

export default L2;
