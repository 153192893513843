import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Col, Input } from 'antd';

import ModalViewModel from 'src/viewModels/modal';
import CategoryTable from 'src/components/feature/CategoryTable';
import KeywordTable from 'src/components/feature/KeywordTable';
import KeywordModal from 'src/components/feature/KeywordModal';

import styles from './styles.module.css';

@observer
class ImportDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.keywordVM = new ModalViewModel();
  }
  render() {
    return (
      <>
        <Tabs>
          <Tabs.TabPane key={1} tab="群組與子群組">
            <div className={styles.border}>
              <Row justify="center" className={styles.borderBottom}>
                <div>特性關鍵字基本資料</div>
              </Row>
              <div className={styles.padding}>
                <Row justify="space-between" className={styles.marginBottom}>
                  <Col flex={1}>
                    <Row align="middle" className={styles.row}>
                      <div>檔案名稱：</div>
                      <Input
                        value={this.props.vm.feature.name}
                        style={{ width: 'calc(100% - 80px)' }}
                        onChange={this.props.vm.onNameChange}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.row}>
                      <div>檔案群組階層數：</div>
                      <div>{this.props.vm.feature.level - 1}</div>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" className={styles.row}>
                  <div>備註：</div>
                  <Input
                    value={this.props.vm.feature.note}
                    style={{ width: 'calc(100% - 50px)' }}
                    onChange={this.props.vm.onNoteChange}
                  />
                </Row>
              </div>
            </div>
            <CategoryTable
              vm={this.props.vm}
              canCopy={false}
              canSelectRow={false}
              canAddTag={false}
              scrollY="calc(100vh - 350px)"
            />
          </Tabs.TabPane>

          <Tabs.TabPane key={2} tab="關鍵字與語法">
            <KeywordTable
              filterError
              canAdd={false}
              vm={this.props.vm}
              showModal={this.keywordVM.show}
            />
          </Tabs.TabPane>
        </Tabs>

        <KeywordModal
          visible={this.keywordVM.visible}
          config={this.keywordVM.config}
          hide={this.keywordVM.hide}
          onUpdate={this.props.vm.updateKeyword}
        />
      </>
    );
  }
}

ImportDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

export default ImportDrawer;
