import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import { LOG_TYPE } from 'src/constants';
import withRouter from 'src/components/withRouter';
import AnalysisVM from 'src/viewModels/ProjectAnalysis/Analysis';
import ActionBar from './ActionBar';
import Drawer from './Drawer';

import styles from './styles.module.css';

@observer
class AnalysisPane extends React.Component {

  anchorRef = React.createRef();

  constructor(props) {
    super(props);
    this.vm = new AnalysisVM(this.props.router.params.id);

    // binding AnalysisPageVM.analysisVM to AnalysisVM
    this.props.analysisPageVM.analysisVM = this.vm;
  }

  componentDidMount() {
    this.vm.didMount();
  }

  columns = [
    {
      title: <Row justify="center">分析類型</Row>,
      key: 'type',
      width: 150,
      render: (data) => (
        <div>{LOG_TYPE[data.log.type]?.name ?? '-'}</div>
      )
    },
    {
      title: <Row justify="center">分析設定</Row>,
      key: 'settings',
      render: (data) => (
        <div style={{ width: 160 }}>
          <div>{`頻道組合：${data.log?.channelCount ?? '-'}`}</div>
          <div>{`搜尋關鍵字：${data.log?.keywordCount ?? '-'}`}</div>
          <div>{`特性關鍵字：${data.log?.featureCount ?? '-'}`}</div>
        </div>
      )
    },
    {
      title: <Row justify="center">分析建置時間</Row>,
      key: 'createdAt',
      render: (data) => (
        <div>
          {data.log.createdAt
            ? dayjs(data.log.createdAt).format('YYYY-MM-DD HH:mm')
            : '-'}
        </div>
      )
    },
    {
      title: <Row justify="center">資料分析區間</Row>,
      key: 'date',
      render: (data) => (
        <div>
          {`
          ${dayjs(data.log.startDate).format('YYYY-MM-DD')} -
          ${dayjs(data.log.finishDate).format('YYYY-MM-DD')}
          `}
        </div>
      )
    },
    {
      title: <Row justify="center">開始分析時間</Row>,
      key: 'beginAt',
      render: (data) => (
        <div>
          {data.log.beginAt
            ? dayjs(data.log.beginAt).format('YYYY-MM-DD HH:mm')
            : '-'}
        </div>
      )
    },
    {
      title: <Row justify="center">結束分析時間</Row>,
      key: 'endAt',
      render: (data) => (
        <div>
          {data.log.endAt
            ? dayjs(data.log.endAt).format('YYYY-MM-DD HH:mm')
            : '-'}
        </div>
      )
    },
    {
      title: <Row justify="center">分析進度</Row>,
      key: 'progress',
      width: 160,
      render: (data) => (
        <div>{`${data.log?.progress ?? '- '}%`}</div>
      )
    },
    {
      title: <Row justify="center">分析狀態</Row>,
      key: 'stageDesc',
      width: 160,
      render: (data) => (
        <Row align="middle">
          <div style={{ margin: '0 5px' }}>{data.log.stageDesc}</div>
          {data.log.message && (
          <Tooltip
            trigger={['hover']}
            title={data.log.message}
          >
            <InfoCircleOutlined />
          </Tooltip>
          )}
        </Row>
      )
    },
    {
      title: <Row justify="center">操作</Row>,
      width: 160,
      key: 'action',
      render: (data) => (
        <Observer>
          {() => (
            <>
              {
                data.log.cancellable
                  ? <Button type="primary" onClick={data.confirmBacktrack}>中止回溯</Button>
                  : (
                    <Button
                      type="primary"
                      onClick={() => data.downloadBacktrackSetting(this.anchorRef.current)}
                    >
                      檢視設定
                    </Button>
                  )
              }
            </>
          )}
        </Observer>
      )
    }
  ];

  render() {
    return (
      <>
        <Row justify="end">
          <Button
            className={styles.btn}
            onClick={this.vm.showDrawer}
          >
            + 專案手動回溯
          </Button>
        </Row>

        <ActionBar onQuery={this.vm.onQuery} />

        <ReactResizeDetector handleHeight>
          {({ height = 0 }) => (
            <Observer>
              {() => (
                <div
                  style={{ height: 'calc(100vh - 255px)' }}
                  className={styles.tableContainer}
                >

                  <Table
                    dataSource={this.vm.list}
                    rowKey="_id"
                    size="small"
                    lineCount={3}
                    columns={this.columns}
                    onFetch={this.vm.onBottom}
                    scroll={{ y: height, x: 1600 }}
                  />

                </div>
              )}
            </Observer>
          )}
        </ReactResizeDetector>


        <Drawer
          visible={this.vm.drawerVisible}
          hide={this.vm.hideDrawer}
          features={this.props.features}
          channelStat={this.props.channelStat}
          searchStat={this.props.searchStat}
          searchAngle={this.props.searchAngle}
          refresh={this.vm.refresh}
          featureSets={this.vm.featureSets}
        />

        {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
        <a ref={this.anchorRef} />
      </>
    );
  }
}

AnalysisPane.propTypes = {
  router: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  channelStat: PropTypes.array.isRequired,
  searchStat: PropTypes.object.isRequired,
  searchAngle: PropTypes.string.isRequired,
  analysisPageVM: PropTypes.object.isRequired
};

export default withRouter(AnalysisPane);
