import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Observer, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import { Row, Button, Drawer, Tabs, DatePicker, Input, Select, Modal, message } from 'antd';

import withRouter from 'src/components/withRouter';
import TabPaneVM from 'src/viewModels/ProjectAnalysis/Analysis/TabPane';
import Stat from 'src/pages/ProjectAnalysisPage/components/Stat';

import styles from './styles.module.css';

@observer
class AnalysisDrawer extends React.Component {
  @observable input = {
    text: ''
  };

  constructor(props) {
    super(props);
    makeObservable(this);

    // 口碑話題
    this.wordOfMouth = new TabPaneVM({
      projectId: this.props.router.params.id,
      featureOnly: false,
      close: this.close,
      askDate: this.askDate,
      refresh: this.props.refresh
    });

    // 特性話題
    this.feature = new TabPaneVM({
      projectId: this.props.router.params.id,
      featureOnly: true,
      close: this.close,
      askDate: this.askDate,
      refresh: this.props.refresh
    });
  }

  close = () => {
    this.wordOfMouth.reset();
    this.feature.reset();
    this.props.hide();
  }


  renderContent(vm) {
    return (
      <Observer>
        {() => (
          <>
            <div className={styles.margin}>
              {vm.featureOnly
                ? '* 執行特性話題分析：將以設定之資料區間之專案現存口碑話題重新比對特性關鍵字後統計提及數。'
                : '* 執行口碑話題分析：將以設定之資料區間自ES資料庫重新撈取話題後，重新統計口碑數及重新比對特性關鍵字提及數。'}
            </div>
            <Row
              className={styles.margin}
              align="middle"
            >
              <div>專案資料起訖時間：</div>
              <DatePicker.RangePicker
                value={[vm.data.startDate, vm.data.endDate]}
                onChange={vm.onDateChange}
              />
            </Row>
            <Stat
              channelStat={this.props.channelStat}
              searchStat={this.props.searchStat}
              searchAngle={this.props.searchAngle}
            />
            <Row
              className={styles.margin}
              align="middle"
            >
              <div>特性關鍵字檔案：</div>
              <Select
                value={this.props.featureSets}
                onChange={vm.onFileChange}
                mode="multiple"
                showArrow={false}
                style={{ width: 300 }}
                disabled
              >
                {this.props.features.map((item) => (
                  <Select.Option
                    key={item.fid}
                    value={item.fid}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>

          </>
        )}
      </Observer>
    );
  }

  askDate = (vm) => {
    return new Promise((resolve, reject) => {
      const startDate = dayjs(vm.project.autorun.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(vm.project.autorun.endDate).format('YYYY-MM-DD');
      Modal.confirm({
        width: 500,
        title: '是否確認執行？',
        content: (
          <Observer>
            {() => (
              <>
                <div style={{ marginBottom: 20 }}>
                  {`原有的資料設定日期為${startDate}-${endDate}，您設定的回溯日期早於/晚於原設定的資料日期，系統將同步變更資料設定日期，是否確認執行？`}
                </div>
                <div style={{ fontWeight: 700, marginBottom: 10 }}>若要更資料設定日期，請輸入專案名稱</div>
                <div style={{ marginBottom: 10 }}>{`專案名稱: ${vm.project.meta.name}`}</div>
                <Input
                  placeholder="請輸入專案名稱"
                  value={vm.confirmInput}
                  onChange={vm.confirmInputChange}
                />
              </>
            )}
          </Observer>
        ),
        okText: '執行',
        cancelText: '取消',
        onCancel: () => reject(),
        onOk: (close) => {
          if (vm.project.meta.name === vm.confirmInput) {
            resolve();
            close();
          } else {
            message.error('與專案名稱不符');
          }
          // eslint-disable-next-line no-param-reassign
          runInAction(() => { vm.confirmInput = ''; });
        }
      });
    });
  }



  render() {
    return (
      <Drawer
        title="專案手動回溯"
        visible={this.props.visible}
        width="90%"
        onClose={this.close}
        maskClosable={false}
      >
        <Tabs
          size="small"
        >
          <Tabs.TabPane tab="口碑話題分析" key="1">
            <div className={styles.tabBody}>
              {this.renderContent(this.wordOfMouth)}
              <Button
                type="primary"
                className={styles.submitBtn}
                onClick={this.wordOfMouth.checkStatus}
                loading={this.wordOfMouth.isProcessingBacktrack}
              >
                手動回溯
              </Button>
            </div>
          </Tabs.TabPane>


          <Tabs.TabPane tab="特性話題分析" key="2">
            <div className={styles.tabBody}>
              {this.renderContent(this.feature)}
              <Button
                type="primary"
                className={styles.submitBtn}
                onClick={this.feature.checkStatus}
                loading={this.feature.isProcessingBacktrack}
              >
                手動回溯
              </Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    );
  }
}

AnalysisDrawer.propTypes = {
  router: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  featureSets: PropTypes.array.isRequired,
  features: PropTypes.array.isRequired,
  channelStat: PropTypes.array.isRequired,
  searchStat: PropTypes.object.isRequired,
  searchAngle: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired
};

export default withRouter(AnalysisDrawer);
