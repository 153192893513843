import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

export default class TagOption {

  tid = '';
  name = '';
  subTags = [];
  defaultValue = '';
  @observable subTid = '';


  constructor(tagValue, tagVM) {
    makeObservable(this);

    runInAction(() => {
      this.tid = tagVM.id;
      this.name = tagVM.name;
      this.subTags = tagVM.subTags;
      this.subTid = tagValue;
      this.defaultValue = tagValue;
    });
  }


  @action
  onSelect = (subTid) => {
    this.subTid = subTid;
  }

  @computed
  get hasChanged() {
    return this.defaultValue !== this.value;
  }

}
