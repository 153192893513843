import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Input, Modal } from 'antd';

const AddCategoryModal = (props) => {
  const [input, setInput] = useState(null);

  return (
    <Modal
      title="新增群組"
      visible={props.visible}
      onCancel={() => {
        props.hide();
        setInput(null);
      }}
      footer={(
        <Button
          type="primary"
          onClick={() => {
            props.action(input);
            props.hide();
            setInput(null);
          }}
        >
          新增
        </Button>
    )}
    >
      <Row>
        <div>母群組名稱：</div>
        <Input
          placeholder="輸入群組名稱"
          style={{ width: 'calc(100% - 100px)' }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </Row>
    </Modal>
  );
};

AddCategoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

export default AddCategoryModal;
