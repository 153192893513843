import { getHost, request } from 'src/utils';
import { AUTH_TOKEN_KEY } from 'src/constants';

export default class Auth {
  static hasToken() {
    return !!localStorage.getItem(AUTH_TOKEN_KEY);
  }

  static getToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  }

  static removeToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }

  static async logout() {
    try {
      const token = Auth.getToken();
      Auth.removeToken();

      if (token) {
        const options = {
          method: 'get',
          url: `${getHost()}/api/v1/auth/logout`,
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            authorization: `Bearer ${token}`
          }
        };
        await request(options);
      }
    } catch (err) {
      // ignore
    }
  }
}
