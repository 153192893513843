import { getHost, request } from 'src/utils';
import AuthService from './auth';

export default class ProjectDownload {

  /**
   * 話題列表
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {'all'|'posts'|'buildings'|'featurePosts'|'featureAll'} data.type
   * @param {string} data.startDate
   * @param {string} data.endDate
   * @param {string[]} data.keywords
   * @param {Object[]} data.tags
   * @param {string} data.tags.key
   * @param {string} data.tags.value
   */
  static async topics(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/download/topics`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   * 產出 trend
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string} data.startDate
   * @param {string} data.endDate
   * @param {string[]} data.keywords
   */
  static async trend(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/download/trend`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }
}
