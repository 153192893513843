import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Row, Select, Spin } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import UserService from 'src/services/user';
import { USER_ORDER, ACCOUNT_STATUS, ACCOUNT_STATUS_TXT, USER_PERMISSION_TXT, USER_STATUS_TXT, USER_PERMISSION, USER_STATUS } from 'src/constants';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import PermisionPageVM from 'src/viewModels/Permission/index';
import ActionBar from './ActionBar';

import styles from './styles.module.css';

@observer
class PermissionPage extends React.Component {

  user = null;

  constructor(props) {
    super(props);
    this.vm = new PermisionPageVM();
  }

  // if the user is not admin, then navigate to the home page
  async componentDidMount() {
    try {
      const p = await UserService.getProfile();

      if (p.admin) {
        this.vm.didMount();
        this.user = p;

      } else {
        this.props.router.navigate('/');
      }

    } catch {
      this.vm.didMount();
    }
  }

  columns = [
    {
      title: (<Row justify="center">帳號</Row>),
      key: 'permission',
      render: (data) => (<Row justify="center" className={styles.txt}>{data.email}</Row>)
    },
    {
      title: <Row justify="center">使用者姓名</Row>,
      key: 'name',
      render: (data) => (<Row justify="center" className={styles.txt}>{data.name}</Row>)
    },
    {
      title: (
        <Observer>
          {() => (
            <Row justify="center" align="middle">
              <div>部門</div>
              <CaretDownOutlined
                onClick={() => this.vm.onOrderChange(USER_ORDER.DEPARTMENT)}
                style={{
                  color: this.vm?.order === USER_ORDER.DEPARTMENT ? '#1890FF' : '#aaa',
                  marginLeft: 5
                }}
              />
            </Row>
          )}
        </Observer>
      ),
      key: 'teamName',
      width: 180,
      render: (data) => (<Row justify="center">{data.teamName}</Row>)
    },
    {
      title: <Row justify="center">聯絡電話</Row>,
      key: 'phone',
      width: 200,
      render: (data) => (<Row justify="center" className={styles.txt}>{data.phone}</Row>)
    },
    {
      title: (
        <Observer>
          {() => (
            <Row justify="center" align="middle">
              <div>帳號狀態</div>
              <CaretDownOutlined
                onClick={() => this.vm.onOrderChange(USER_ORDER.ACCOUNT_STATUS)}
                style={{
                  color: this.vm?.order === USER_ORDER.ACCOUNT_STATUS ? '#1890FF' : '#aaa',
                  marginLeft: 5
                }}
              />
            </Row>
          )}
        </Observer>
      ),
      key: 'status',
      width: 180,
      render: (data) => (
        <Row justify="center" align="middle">
          <div
            className={styles.dot}
            style={{
              background: data.status === ACCOUNT_STATUS.ACTIVATE.value ? '#52C41A' : '#FF4D4F'
            }}
          />
          {ACCOUNT_STATUS_TXT[data.status]}
        </Row>
      )
    },
    {
      title: <Row justify="center">可登入</Row>,
      key: 'pmStatus',
      width: 120,
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              {data.isEdit
                ? (
                  <Select
                    style={{ width: 100 }}
                    value={data.editedPmStatus}
                    onChange={data.onStatusChange}
                  >
                    {Object.keys(USER_STATUS).map((key) => (
                      <Select.Option
                        key={USER_STATUS[key].value}
                        value={USER_STATUS[key].value}
                      >
                        {USER_STATUS[key].name}
                      </Select.Option>
                    ))}
                  </Select>
                )
                : (<Row justify="center">{USER_STATUS_TXT[data.pmStatus]}</Row>)}
            </Row>
          )}
        </Observer>
      )
    },
    {
      title: <Row justify="center">權限設定</Row>,
      key: 'pmPermission',
      width: 120,
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              {data.isEdit
                ? (
                  <Select
                    style={{ width: 100 }}
                    value={data.editedPmPermission !== 'none' ? data.editedPmPermission : null}
                    onChange={data.onPermissionChange}
                  >
                    {Object.keys(USER_PERMISSION).map((key) => (
                      <Select.Option
                        key={USER_PERMISSION[key].value}
                        value={USER_PERMISSION[key].value}
                      >
                        {USER_PERMISSION[key].name}
                      </Select.Option>
                    ))}
                  </Select>
                )
                : (<Row justify="center">{USER_PERMISSION_TXT[data.pmPermission]}</Row>)}
            </Row>
          )}
        </Observer>
      )
    },
    {
      title: <Row justify="center">操作</Row>,
      key: 'action',
      width: 170,
      render: (data) => (
        <Observer>
          {() => (
            <Row justify="center">
              {data.isEdit
                ? (
                  <>
                    <Button type="link" onClick={data.onSave}>儲存</Button>
                    <Button type="link" onClick={data.onCancel}>取消</Button>
                  </>
                )
                : (
                  <Button
                    type="link"
                    onClick={data.onEdit}
                    disabled={data.id === this.user.id}
                  >
                    編輯
                  </Button>
                )}
            </Row>
          )}
        </Observer>
      )
    }
  ];

  render() {
    return (
      <div>
        <ActionBar onQuery={this.vm.onSearch} />

        <ReactResizeDetector handleHeight>
          {({ height = 0 }) => (
            <Observer>
              {() => (
                <Spin
                  tip="正在加載資料"
                  spinning={this.vm.isLoading}
                >
                  <div
                    style={{ height: 'calc(100vh - 115px)' }}
                  >
                    {(this.vm) && (
                    <Table
                      size="small"
                      rowKey="id"
                      onFetch={() => {
                        if (this.vm.anchor) this.vm.getList();
                      }}
                      columns={this.columns}
                      scroll={{ y: height, x: 1600 }}
                      dataSource={this.vm.list}
                    />
                    )}
                  </div>
                </Spin>
              )}
            </Observer>
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

PermissionPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withProfile(withRouter(PermissionPage), true);
