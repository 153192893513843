import { getHost, request } from 'src/utils';
import AuthService from './auth';

export default class ProjectSchedule {

  /**
   * 啟動專案
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string} data.startDate required
   * @param {string} data.endDate   required
   * @param {bool} data.autoRun
   * @param {bool} data.updateTimeRequirement
   * @param {bool} data.checkChannelName
   * @param {string[]} data.featureSets
   */
  static async start(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/schedule/start`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   * 更新當日排程設定
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string} data.startDate required
   * @param {string} data.endDate   required
   * @param {bool} data.autoRun
   * @param {bool} data.updateTimeRequirement
   * @param {string[]} data.featureSets
   */
  static async update(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/schedule/update`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   * 特定專案之專案分析紀錄
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {'init'|'backtrack'|'scheduled'} data.type
   * @param {'WAITING'|'BEGIN'|'SEARCH'|'FEATURE'|'UPDATE'|'TREND'|'FAIL'|'DONE'|'CANCEL'} data.stage
   * @param {string} data.startDate
   * @param {string} data.endDate
   * @param {number} data.page  required
   * @param {number} data.limit required
   */
  static async projectScheduleLog(id, data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/schedule/log`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data.result;
  }

  /**
   * 下載特定專案之專案分析設定
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {'init'|'backtrack'|'scheduled'} data.type
   * @param {'WAITING'|'BEGIN'|'SEARCH'|'FEATURE'|'UPDATE'|'TREND'|'FAIL'|'DONE'|'CANCEL'} data.stage
   * @param {string} data.startDate
   * @param {string} data.endDate
   * @param {number} data.page  required
   * @param {number} data.limit required
   */
  static async downloadProjectScheduleLogSetting(id, logId, anchor) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/schedule/log/${logId}/setting`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      responseType: 'blob'
    };

    const res = await request(options);
    const href = window.URL.createObjectURL(res.data);
    // eslint-disable-next-line no-param-reassign
    anchor.href = href;
    // eslint-disable-next-line no-param-reassign
    anchor.download = 'setting.xlsx';
    anchor.click();
  }


  /**
   * 我的所有專案分析紀錄
   *
   * @param {Object} data
   * @param {string} data.keyword
   * @param {'init'|'backtrack'|'scheduled'} data.type
   * @param {'WAITING'|'BEGIN'|'SEARCH'|'FEATURE'|'UPDATE'|'TREND'|'FAIL'|'DONE'|'CANCEL'} data.stage
   * @param {string} data.startDate
   * @param {string} data.endDate
   * @param {number} data.page  required
   * @param {number} data.limit required
   */
  static async myProjectsScheduleLog(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/my/schedule/log`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data.result;
  }
}
