import { message } from 'antd';
import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import debounce from 'lodash.debounce';
import SearchSetService from 'src/services/searchSet';
import { keywordParse } from 'src/services/keywordParser';
import { noSearchKeyword, noSearchCheck } from 'src/utils';
import TagOption from './TagOption';

export default class EditDrawerViewModel {
  @observable isVisible
  @observable isEdit = false;
  @observable name = '';
  @observable keyword = '';
  @observable synonym = '';
  @observable tags = null;
  @observable level = 1;
  @observable isExist = false;

  sid = null;
  subSid = null;

  targetKeyword = null;
  searchKeywordVM = null;

  constructor(searchKeywordVM) {
    makeObservable(this);
    this.searchKeywordVM = searchKeywordVM;
  }

  @action
  show = (targetKeyword) => {
    this.isVisible = true;

    this.targetKeyword = targetKeyword;
    this.level = targetKeyword.level;
    this.name = targetKeyword.name;
    this.keyword = targetKeyword.keyword;
    this.synonym = targetKeyword.synonym;
    this.tags = targetKeyword.tags
      ? targetKeyword.tags.map((tag) => {
        return new TagOption(tag.value, this.searchKeywordVM.tag.tags.find((t) => t.id === tag.key));
      })
      : null;
  }

  @action
  hide = () => {
    this.isVisible = false;
    this.targetKeyword = null;
    this.level = 1;
    this.name = '';
    this.keyword = '';
    this.synonym = '';
    this.tags = null;
    this.isEdit = false;
  }

  @action
  onEdit = () => {
    this.isEdit = true;
  }

  checkNameExist = async () => {
    try {
      const result = await SearchSetService.checkNameExist(this.name, this.targetKeyword.projectId, this.targetKeyword.sid, this.targetKeyword.subSid, this.level);
      runInAction(() => { this.isExist = result; });
    } catch {
      // ignore
    }
  }

  debounceCheck = debounce(this.checkNameExist, 300);

  @action
  onSave = async () => {
    try {
      const keywordHasChanges = (
        this.name !== this.targetKeyword.name
        || this.keyword !== this.targetKeyword.keyword
        || this.synonym !== this.targetKeyword.synonym
      );

      const tagsHaveChanges = !this.tags?.every((tag) => !tag.hasChanged);

      if ((this.level !== 1) && tagsHaveChanges) {
        await this.targetKeyword.updateTags(this.tags);
      }

      if (keywordHasChanges) {
        const { skipCount, keyword, name, synonym } = this.getData;

        await this.targetKeyword.update({ keyword, name, synonym });

        if (skipCount > 0) {
          message.success(`儲存成功，共有 ${skipCount} 個搜尋關鍵字欲留空`);
        } else {
          message.success('儲存成功');
        }
      }

    } catch (err) {
      this.hide();
      message.error('發生錯誤，更新失敗');

    } finally {
      this.hide();
    }
  }

  @action
  onNameChange = (e) => {
    this.name = e.target.value;

    if (!this.name) return;
    this.debounceCheck();
  }

  @action
  onKeywordChange = (e) => {
    this.keyword = e.target.value;
  }

  @action
  onSynonymChange = (e) => {
    this.synonym = e.target.value;
  }

  @computed
  get getData() {
    let keyword = this.keyword;
    let skipCount = 0;

    if (noSearchCheck(this.keyword)) {
      keyword = noSearchKeyword;
      skipCount += 1;
    }

    return {
      skipCount,
      keyword,
      name: this.name,
      synonym: this.synonym
    };
  }

  @action
  skipKeyword = () => {
    this.keyword = noSearchKeyword;
  }

  @computed
  get keywordResult() {
    const { result, reasons } = keywordParse(this.keyword);
    const hasError = (result === 'error') || (result === 'notComplete');
    const hasWaran = (result === 'warn');
    return { result, reasons, hasError, hasWaran };
  }

  @computed
  get hasError() {
    switch (this.level) {
      case 1:
        return !this.name || !this.keyword || this.keywordResult.hasError || this.isExist;
      case 2:
        return !this.name || !this.keyword || this.keywordResult.hasError || this.isExist;
      case 3:
        return !this.name || !this.keyword || this.keywordResult.hasError || this.isExist;
      default:
        return false;
    }
  }

  @computed
  get helpTxt() {
    if (!this.isEdit) return '';
    if (!this.name) return '此欄必填';
    if (this.isExist) return '資料庫已存在此名稱';
    return '';
  }
}
