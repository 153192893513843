import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { LIMIT } from 'src/constants';
import ChannelSetService from 'src/services/channelSet';
import BaseChannelList from './BaseChannelList';
import ChannelItem from './ChannelItem';

export default class ChunghwaChannelList extends BaseChannelList {

  constructor() {
    super();
    makeObservable(this);
  }

  getChannels = async (limit = null) => {
    try {
      if (this.isLodaing) return;
      runInAction(() => { this.isLodaing = true; });
      const _limit = limit || 100;

      const { channels, anchor } = await ChannelSetService.queryChannels({
        ...this.filterCondition,
        anchor: this.anchor,
        sort: this.sort,
        limit: _limit
      });
      const newChannels = channels.map((c) => new ChannelItem(c, this));

      runInAction(() => {
        this.anchor = (channels.length < _limit) ? null : anchor;
        this._list = [...this._list, ...newChannels];
        this.isLodaing = false;
      });

    } catch (err) {
      console.log(err);
      runInAction(() => {
        this.isLodaing = false;
      });
    }
  }


  add = () => {}

  @action
  applyFilter = (newCondition) => {
    this.reset();
    this.filterCondition = newCondition;
    runInAction(() => { this.isLodaing = false; });
    this.getChannels();
  }

  onBottom = () => {
    if (this.anchor) this.getChannels();
  }

  toggleSelectAll = async () => {
    runInAction(() => { this.isSelectAll = !this.isSelectAll; });

    if (this.isSelectAll) {

      runInAction(() => {
        this._list = [];
        this.anchor = null;
      });

      do {
        // eslint-disable-next-line no-await-in-loop
        await this.getChannels(20000);
      } while (this.hasNextPage);

      this.list.forEach((channel) => { channel.toggleIsSelected(true); });

    } else {
      this.list.forEach((channel) => { channel.toggleIsSelected(false); });
    }
  }

  get defaultQueryCondition() {
    return { country: 'TW', isValid: true };
  }

}
