import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message } from 'antd';
import ProjectDownloadService from 'src/services/projectDownload';
import SearchSetService from 'src/services/searchSet';
import { errorModal } from 'src/utils';

export default class DownloadData {
  projectId = '';

  @observable keywords = [];

  @observable trend = {
    startDate: null,
    endDate: null,
    keywords: []
  };

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  didMount = async () => {
    try {
      const res = await SearchSetService.getAllKeyword(this.projectId, { level: 1 });
      runInAction(() => { this.keywords = res; });
    } catch {
      // ignore
    }
  }

  downloadTrend = async () => {
    if (!this.trend.startDate || !this.trend.endDate) {
      return errorModal('請輸入日期');
    }

    const data = {
      keywords: this.trend.keywords.length === 0
        ? undefined
        : this.trend.keywords,
      startDate: this.trend.startDate.startOf('day').toISOString(),
      endDate: this.trend.endDate.endOf('day').toISOString()
    };

    try {
      await ProjectDownloadService.trend(this.projectId, data);
      message.success('正在分析中，待完成會寄出結果');

    } catch (err) {
      errorModal('發生錯誤，無法產出列表');
    }
    return null;
  }

  @action
  onKeywordChange = (keywordArray) => {
    this.trend.keywords = keywordArray;
  }

  @action
  onDateChange = (date) => {
    this.trend.startDate = date?.[0] ?? null;
    this.trend.endDate = date?.[1] ?? null;
  }

}
