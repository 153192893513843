import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col, Button, message } from 'antd';
import { ExclamationCircleOutlined, RedoOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { SEARCH_KEYWORD_TXT } from 'src/constants';
import SearchSetService from 'src/services/searchSet';
import withRouter from 'src/components/withRouter';

import styles from './styles.module.css';

const SearchKeywordSummary = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [summary, setSummary] = useState(null);

  const getStats = useCallback(async () => {
    const projectId = props.projectId || props.router.params.id;
    const res = await SearchSetService.getSummary(projectId);
    setSummary(res);
  }, [props.projectId, props.router.params.id]);


  useEffect(() => {
    try {
      getStats();
    } catch {
      // ignore
    }
  }, [getStats]);


  const reload = async () => {
    try {
      await getStats();
      message.success('資料已更新');
    } catch {
      // ignore
    }
  };


  return (
    <Collapse
      expandIcon={null}
      bordered={false}
      style={{ marginBottom: 10 }}
      onChange={(activeKeys) => {
        if (activeKeys.find((key) => key === '1')) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      }}
    >
      <Collapse.Panel
        header={(
          <div className={styles.header}>
            <div className={styles.marginRight}>{`${isOpen ? '收起' : '展開'}搜尋關鍵字整體概況`}</div>
            {isOpen ? <UpOutlined /> : <DownOutlined />}
          </div>
        )}
        key="1"
        style={{ background: '#1890FF' }}
        showArrow={false}
      >


        <div className={styles.subHeader}>
          <div>整體概況</div>

          <div className={styles.warn}>
            <div className={styles.inline}>
              <ExclamationCircleOutlined className={styles.marginRight} />
              重新loading後才會是即時資訊
            </div>
            <Button type="text" onClick={reload}>
              <RedoOutlined className={styles.reloadIcon} />
            </Button>
          </div>

        </div>


        <Row gutter={16} className={styles.content} style={{ margin: 0 }}>
          <Col flex={3}>
            <div className={styles.title}>{`${SEARCH_KEYWORD_TXT[props.angle]?.L1 ?? ''}數`}</div>
            <Row>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>總數</div>
                <div>{summary?.level1?.count ?? ''}</div>
              </Col>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>同義詞數</div>
                <div>{summary?.level1?.synonymCount ?? ''}</div>
              </Col>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>品牌為空值</div>
                <div>{summary?.level1?.emptyCount ?? ''}</div>
              </Col>
            </Row>
          </Col>

          <Col flex={3}>
            <div className={styles.title}>{`${SEARCH_KEYWORD_TXT[props.angle]?.L2 ?? ''}數`}</div>
            <Row>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>總數</div>
                <div>{summary?.level2?.count ?? ''}</div>
              </Col>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>同義詞數</div>
                <div>{summary?.level2?.synonymCount ?? ''}</div>
              </Col>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>產品為空值</div>
                <div>{summary?.level2?.emptyCount ?? ''}</div>
              </Col>
            </Row>
          </Col>

          <Col flex={2}>
            <div className={styles.title}>產品數</div>
            <Row>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>總數</div>
                <div>{summary?.level3?.count ?? ''}</div>
              </Col>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>同義詞數</div>
                <div>{summary?.level3?.synonymCount ?? ''}</div>
              </Col>
            </Row>
          </Col>

          <Col flex={1}>
            <div className={styles.title}>母標籤數</div>
            <Row>
              <Col className={styles.card} flex={1}>
                <div className={styles.text}>總數</div>
                <div>{summary?.tag?.count ?? ''}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};

SearchKeywordSummary.propTypes = {
  router: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  angle: PropTypes.string.isRequired
};

SearchKeywordSummary.defaultProps = {
  projectId: ''
};

export default withRouter(SearchKeywordSummary);
