import Papa from 'papaparse';
import { downloadFile, getHost, request } from 'src/utils';
import Config from 'src/config';
import FeatureSetModel from 'src/models/response/FeatureSet';
import FeatureKeywordModel from 'src/models/response/FeatureKeyword';
import FeatureCategoryModel from 'src/models/response/FeatureCategory';

import AuthService from './auth';

export default class FeatureSet {

  // ========== feature set ========== //

  /**
   *
   * @param {string} id
   * @param {Object} data
   * @param {string} data.name
   * @param {(2|3)} data.level
   * @param {string} data.note
   */
  static createFeatureSet = async (id, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureSet`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   * query all feature set of project
   * @param {string} id
   */
  static queryFeatureSet = async (id) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureSet`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);

    const features = res.data.result.map((f) => FeatureSetModel.fromRes(f));

    return { features };
  }

  /**
   * query a feature set all data of project
   * @param {string} id
   * @param {string} fid
   */
  static queryFeatureSetDetail = async (id, fid) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/all?includeRef=true`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);

    const category = res.data.result.category.map((c) => FeatureCategoryModel.fromRes(c));
    const map = new Map();
    const processedArray = [];

    category.forEach((c) => {
      if (!c.parentId) {
        // 母群組
        map.set(c.cid, { name: c.name, subCategory: [] });
      } else {
        // 子群組
        const { name, subCategory } = map.get(c.parentId);
        map.set(c.parentId, { name, subCategory: [...subCategory, { name: c.name, id: c.cid }] });
      }
    });

    map.forEach((value, key) => processedArray.push({ cid: key, name: value.name, subCategory: value.subCategory }));

    return {
      featureSet: FeatureSetModel.fromRes(res.data.result.featureSet),
      category: processedArray,
      keyword: res.data.result.keyword.map((k) => FeatureKeywordModel.fromRes(k))
    };
  }

  /**
   * remove feature set of project
   * @param {string} id
   * @param {string} fid
   */
  static removeFeatureSet = async (id, fid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   * update feature set of project
   * @param {string} id
   * @param {string} fid
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.note
   */
  static updateFeatureSet = async (id, fid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/meta`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   */
  static clearAllFeatureSet = async (id, fid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/all`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} projectName
   */
  static export = async (id, fid, projectName) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/export`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };

    const res = await request(options);
    const { name, note, level, rows } = res.data.result;
    const realLevel = level - 1;

    downloadFile(rows, `${projectName}特性關鍵字`, `${name}\n${note}\n${realLevel}`);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {Object} data
   * @param {Object[]} rows
   * @param {string} rows[].category
   * @param {string} rows[].subCategory
   * @param {string} rows[].name
   * @param {string} rows[].keyword
   */
  static importFeatureSet = async (id, fid, array) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/import`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { rows: array }
    };
    return request(options);
  }

  /**
   *
   * @param {string} projectId
   * @param {string} featureId
   * @param {Object} data
   * @param {string} data.refProjectId
   * @param {string} data.refFid
   */
  static ref = async (projectId, featureId, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${projectId}/featureSet/${featureId}/ref`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  // ========== feature category ========== //


  /**
   * turn flat categories to hierarchy format
   *
   */
  static transformCategoryWithHierarchy = (categories) => {
    const category = categories.map((c) => FeatureCategoryModel.fromRes(c));
    const map = new Map();
    const processedArray = [];

    category.forEach((c) => {
      if (!c.parentId) {
        // 母群組
        map.set(c.cid, { name: c.name, id: c.cid, subCategory: [], count: c.count });
      } else {
        // 子群組
        const { subCategory } = map.get(c.parentId);
        subCategory.push({ name: c.name, id: c.cid, count: c.count });
      }
    });

    map.forEach((value, key) => processedArray.push({
      cid: key,
      name: value.name,
      subCategory: value.subCategory,
      count: value.count
    }));

    return processedArray;
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {Object} data
   * @param {string[]} data.names
   * @param {string} data.parentId
   */
  static createCategory = async (id, fid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   */
  static queryAllCategory = async (id, fid) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    const result = FeatureSet.transformCategoryWithHierarchy(res.data.result);
    return result;
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   */
  static querySubCategory = async (id, fid, cid) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);

    const category = res.data.result.map((f) => FeatureCategoryModel.fromRes(f));

    return { category };
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {Object} data
   * @param {string} data.name
   */
  static updateCategory = async (id, fid, cid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   */
  static deleteCategory = async (id, fid, cid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {string} subCid
   * @param {Object} data
   * @param {string} data.name
   */
  static updateSubCategory = async (id, fid, cid, subCid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}/${subCid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {string} subCid
   */
  static deleteSubCategory = async (id, fid, cid, subCid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}/${subCid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   * get categories with mention count
   *
   * @param {string} id   required
   * @param {string} fid  required
   * @param {Object} data
   * @param {string[]} data.keywords  array of keyword id
   * @param {string} data.startDate   ISO 8601
   * @param {string} data.finishDate  ISO 8601
   */
  static mention = async (id, fid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/mention`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    const result = FeatureSet.transformCategoryWithHierarchy(res.data.result);
    return result;
  }


  // ========== feature keyword ========== //

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {Object} data
   * @param {Object[]} data.keywords
   * @param {string} data.keywords[].name
   * @param {string} data.keywords[].keyword
   * @param {string} data.cid
   * @param {string} data.subCid
   */
  static createKeyword = async (id, fid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/keyword`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    const keyword = res.data.result.map((k) => FeatureKeywordModel.fromRes(k));
    return keyword;
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {string} kid
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.keyword
   */
  static updateKeyword = async (id, fid, cid, kid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}/keyword/${kid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {string} kid
   */
  static deleteKeyword = async (id, fid, cid, kid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}/keyword/${kid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {string} subCid
   * @param {string} kid
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.keyword
   */
  static updateKeywordWithSub = async (id, fid, cid, subCid, kid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}/${subCid}/keyword/${kid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} fid
   * @param {string} cid
   * @param {string} subCid
   * @param {string} kid
   */
  static deleteKeywordWithSub = async (id, fid, cid, subCid, kid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/category/${cid}/${subCid}/keyword/${kid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }


  /**
   *
   * @param {*} file Blob object
   * @returns parsed json
   */
  static readUploadedFile = async (file, featureLevel) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let json;

      reader.onload = async (evt) => {
        const stringArray = evt.target.result.split('\n');
        const fileName = stringArray[0]?.trim()?.split(',')[0] ?? '';
        const note = stringArray[1]?.trim()?.split(',')[0] ?? '';
        const level = stringArray[2]?.trim()?.split(',')[0] ?? '1';
        stringArray.splice(0, 3);
        const csvString = stringArray.join('\n');

        // parse data
        const csv = await new Promise(((_resolve, _reject) => {
          Papa.parse(csvString, {
            ...Config.csv.import,
            header: true,
            complete: (results) => {
              _resolve(results.data);
            },
            error: (e) => {
              _reject(e);
            }
          });
        }));

        json = { fileName, note, level: parseInt(level, 10) + 1, rows: [] };

        csv.forEach((row, index) => {
          const { category, subCategory, name, keyword } = row;
          json.rows.push({
            category: category?.trim(),
            subCategory: subCategory?.trim(),
            name: name?.trim(),
            keyword: keyword?.trim(),
            index
          });
        });

        // validate json
        if (parseInt(level, 10) !== featureLevel - 1) {
          reject(new Error('格式錯誤，檔案群組階層數與預設不一致'));
        }

        if (parseInt(level, 10) === 1) {
          const set = new Set();
          json.rows.forEach((row) => {
            if (row.subCategory) {
              reject(new Error(`格式錯誤，在第 ${row.index + 5} 列偵測到子群組有值`));
            }
            if (!row.category || !row.name || !row.keyword) {
              reject(new Error(`格式錯誤，在第 ${row.index + 5} 列偵測到欄位為空值`));
            }
            if (set.has(`${row.category}-${row.name}`)) {
              reject(new Error(`在第 ${row.index + 5} 列偵測到重複的關鍵字`));
            } else {
              set.add(`${row.category}-${row.name}`);
            }
          });
        }

        if (parseInt(level, 10) === 2) {
          const set = new Set();
          json.rows.forEach((row) => {
            if (!row.subCategory || !row.category || !row.name || !row.keyword) {
              reject(new Error(`格式錯誤，在第 ${row.index + 5} 列偵測到欄位為空值`));
            }
            if (set.has(`${row.category}-${row.subCategory}-${row.name}`)) {
              reject(new Error(`在第 ${row.index + 5} 列偵測到重複的關鍵字`));
            } else {
              set.add(`${row.category}-${row.subCategory}-${row.name}`);
            }
          });
        }

        resolve(json);
      };

      reader.readAsText(file);
    });
  }

  /**
   * get tip
   */
  static getTip = async (id, fid) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureSet/${fid}/tip`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }
}
