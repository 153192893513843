import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, Row } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import SkipKeywordBtn from 'src/components/search/SkipKeywordBtn';
import { TEXT } from './constants';

import styles from './styles.module.css';

@observer
class CreateItem extends React.Component {
  render() {
    let text;
    switch (this.props.vm.level) {
      case 1:
        text = TEXT[this.props.vm.searchAngle]?.L1;
        break;
      case 2:
        text = TEXT[this.props.vm.searchAngle]?.L2;
        break;
      case 3:
        text = TEXT[this.props.vm.searchAngle]?.L3;
        break;
      default:
        text = ' ';
    }


    return (
      <Form
        labelCol={{ span: 7 }}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: 10,
          margin: '10px 0'
        }}
        fields={[
          {
            name: ['sid'],
            value: this.props.vm.sid
          },
          {
            name: ['subSid'],
            value: this.props.vm.subSid
          }
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
          <Button
            type="text"
            onClick={this.props.onDelete}
          >
            <DeleteFilled style={{ color: '#F00202' }} />
          </Button>
        </div>


        {(this.props.vm.level === 2 || this.props.vm.level === 3) ? (
          <Form.Item
            label={`${TEXT[this.props.vm.searchAngle]?.L1}名稱`}
            help={this.props.vm.sid ? '  ' : '此欄必填'}
            name="sid"
            validateStatus={this.props.vm.sid ? 'success' : 'error'}
            rules={[{ required: true }]}
          >
            <Select
              onSelect={this.props.vm.onSidSelect}
              initialvalues={this.props.vm.sid}
              getPopupContainer={(trigger) => trigger.parentElement}
              showArrow
              showSearch
              optionFilterProp="children"
              placeholder={`請選擇${TEXT[this.props.vm.searchAngle]?.L1}名稱`}
            >
              {this.props.vm.L1?.map((keyword) => (
                <Select.Option
                  value={keyword.id}
                  key={keyword.id}
                >
                  {this.props.vm.keywordPool?.get(keyword.id)?.name ?? ''}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        {(this.props.vm.level === 3) ? (
          <Form.Item
            label={`${TEXT[this.props.vm.searchAngle]?.L2}名稱`}
            name="subSid"
          >
            <Select
              onSelect={this.props.vm.onSubSidSelect}
              initialvalues={this.props.vm.subSid}
              getPopupContainer={(trigger) => trigger.parentElement}
              showArrow
              showSearch
              optionFilterProp="children"
              placeholder={`請選擇${TEXT[this.props.vm.searchAngle]?.L2}名稱`}
            >
              {this.props.vm.L2
                ?.filter((keyword) => keyword.sid === this.props.vm.sid)
                ?.map((keyword) => (
                  <Select.Option
                    value={keyword.id}
                    key={keyword.id}
                  >
                    {this.props.vm.keywordPool?.get(keyword.id)?.name ?? ''}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          label={`${text}名稱`}
          name="name"
          help={this.props.vm.helpTxt}
          validateStatus={(this.props.vm.name && !this.props.vm.isExist && !this.props.vm.hasDupName) ? 'success' : 'error'}
          rules={[{ required: true }]}
        >
          <Input
            onChange={this.props.vm.onNameChange}
            value={this.props.vm.name}
            placeholder={`請輸入${text}名稱`}
          />
        </Form.Item>

        <Form.Item
          label={`${text}搜尋關鍵字`}
          name="keyword"
          validateStatus={this.props.vm.keywordResult.hasError ? 'error' : 'success'}
          rules={[{ required: true }]}
          help={(
            <div>
              {this.props.vm.keywordResult.reasons.map((r) => (
                <div key={r.id} className={styles[r.type]}>{r.message}</div>
              ))}
            </div>
          )}
        >
          <Input.TextArea
            rows={10}
            onChange={this.props.vm.onKeywordChange}
            value={this.props.vm.keyword}
          />
          <SkipKeywordBtn callback={this.props.vm.skipKeyword} />
        </Form.Item>

        {(this.props.vm.level === 2 || this.props.vm.level === 3) ? (
          <Form.Item label="標籤">
            {this.props.vm.tags?.map((tagVM) => (
              <Row
                key={tagVM.tid}
                align="middle"
                style={{ marginBottom: 10 }}
              >
                <div>{`${this.props.vm.tagPool?.get(tagVM.tid)?.name ?? ''}： `}</div>
                <Select
                  value={tagVM.subTid}
                  onSelect={tagVM.onSelect}
                  style={{ width: '70%' }}
                  showSearch
                  optionFilterProp="children"
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {tagVM.subTags.map((subTag) => (
                    <Select.Option
                      key={subTag.id}
                      value={subTag.id}
                    >
                      {this.props.vm.tagPool?.get(subTag.id)?.name ?? ''}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            ))}
          </Form.Item>
        ) : null}

        <Form.Item
          label="同義詞"
          name="synonym"
        >
          <Input
            onChange={this.props.vm.onSynonymChange}
            value={this.props.vm.synonym}
            placeholder="請輸入同義詞"
          />
        </Form.Item>

      </Form>

    );
  }
}

CreateItem.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired
};


export default CreateItem;
