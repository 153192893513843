import Papa from 'papaparse';
import { getHost, request, downloadFile, flattenObject } from 'src/utils';
import Config from 'src/config';
import SearchTag from 'src/models/response/SearchTag';
import SearchKeyword from 'src/models/response/SearchKeyword';
import AuthService from './auth';

export default class SearchSet {

  // ========== search set ========== //

  /**
   * get search set all data of project
   * @param {string} id
   */
   static getAll = async (id) => {
     const options = {
       method: 'get',
       url: `${getHost()}/api/v1/project/${id}/searchSet/all`,
       headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-cache',
         authorization: `Bearer ${AuthService.getToken()}`
       }
     };
     return request(options);
   }


  /**
   * clear all search set data of project
   * @param {string} id
   */
  static clearAll = async (id) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/searchSet/all`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }


  /**
   * get search set all data of project
   * @param {string} id
   */
  static getMeta = async (id) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/meta`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    const { name, reduceKeyword } = res.data.result;
    return { name, reduceKeyword };
  }


  /**
   * get search set all data of project
   * @param {string} id
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.reduceKeyword
   */
  static updateMeta = async (id, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/searchSet/meta`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   * get search set all data of project
   * @param {string} id
   */
     static getSummary = async (id) => {
       const options = {
         method: 'get',
         url: `${getHost()}/api/v1/project/${id}/searchSet/summary`,
         headers: {
           'Content-Type': 'application/json',
           'Cache-Control': 'no-cache',
           authorization: `Bearer ${AuthService.getToken()}`
         }
       };
       const res = await request(options);
       const { result } = res.data;
       return result;
     }

  /**
   *
   * @param {string} id
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.reduceKeyword
   * @param {Object[]} data.rows
   * @param {string} data.rows[].level1
   * @param {string} data.rows[].level2
   * @param {string} data.rows[].level3
   * @param {string} data.rows[].keyword
   * @param {string} data.rows[].synonym
   * @param {Object} data.tags
   */
  static import = async (id, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/searchSet/import`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   * @param {string} refId
   */
  static ref = async (id, refProjectId) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/searchSet/ref`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { refProjectId }
    };
    return request(options);
  }



  // ========== search keyword ========== //

  /**
   * query search keyword of project
   * @param {string} id
   * @param {Object} data
   * @param {(1|2|3)} data.level
   * @param {string[]} data.sids sid or sid_subSid
   * @param {('nameAsc'|'nameDesc')} data.order required
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.tagKeyId
   * @param {string} data.tagValueId
   */
  static queryKeyword = async (id, data) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const { data: { result } } = await request(options);

    const keywords = result.keywords;
    const parents = result.parents;
    const anchor = result.anchor;

    return { keywords, parents, anchor };
  }

  /**
   * query search keyword of project, no pagination, no tags
   * @param {string} id
   * @param {Object} data
   * @param {(1|2|3)} data.level
   * @param {string} data.sid
   * @param {string} data.subSid
   */
  static getAllKeyword = async (id, data) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/keyword/all`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const { data: { result } } = await request(options);
    return result.keywords;
  }

  /**
   *
   * @param {string} id
   * @param {Object} data
   * @param {Object[]} data.keywords
   * @param {string} data.keywords[].name
   * @param {string} data.keywords[].keyword
   * @param {string} data.keywords[].synonym
   * @param {string} data.sid
   * @param {string} data.subSid
   * @param {string} data.level
   */
  static createKeyword = async (id, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const { data: { result } } = await request(options);
    const keywords = result.map((k) => (SearchKeyword.fromRes(k)));
    return keywords;
  }

  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.keyword
   * @param {string} data.synonym
   * @param {string} id
   * @param {string} sid
   * @param {string} subSid
   * @param {string} sub2Sid
   * @param {number} level
   */
     static updateKeyword = async (data, id, sid, subSid = null, sub2Sid = null, level) => {
       let url;
       if (level === 3 && subSid) {
         url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/${subSid}/${sub2Sid}`;
       } else if (level === 3) {
         url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/{subSid}/${sub2Sid}`;
       } else if (level === 2) {
         url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/${subSid}`;
       } else {
         url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}`;
       }
       const options = {
         method: 'put',
         url,
         headers: {
           'Content-Type': 'application/json',
           'Cache-Control': 'no-cache',
           authorization: `Bearer ${AuthService.getToken()}`
         },
         data
       };
       return request(options);
     }

  /**
   *
   * @param {string} id
   * @param {string} sid
   * @param {string} subSid
   * @param {string} sub2Sid
   * @param {number} level
   */
  static deleteKeyword = async (id, sid, subSid = null, sub2Sid = null, level) => {
    let url;
    if (level === 3 && subSid) {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/${subSid}/${sub2Sid}`;
    } else if (level === 3) {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/{subSid}/${sub2Sid}`;
    } else if (level === 2) {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/${subSid}`;
    } else {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}`;
    }
    const options = {
      method: 'delete',
      url,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   *
   * @param {string} date
   * @param {string} id
   * @param {string} sid
   * @param {string} subSid
   * @param {string} sub2Sid
   * @param {number} level
   */
  static disableKeyword = async (date, id, sid, subSid = null, sub2Sid = null, level) => {
    let url;
    if (level === 3 && subSid) {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/${subSid}/${sub2Sid}/disable`;
    } else if (level === 3) {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/{subSid}/${sub2Sid}/disable`;
    } else if (level === 2) {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/${subSid}/disable`;
    } else {
      url = `${getHost()}/api/v1/project/${id}/searchSet/keyword/${sid}/disable`;
    }
    const options = {
      method: 'put',
      url,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        disableDate: date
      }
    };
    return request(options);
  }


  /**
   *
   * @param {string} name
   * @param {string} projectId
   * @param {string} sid
   * @param {string} subSid
   */
  static checkNameExist = async (name, projectId, sid = null, subSid = null, level) => {
    let url;
    if (level === 3 && subSid) {
      url = `${getHost()}/api/v1/project/${projectId}/searchSet/keyword/${sid}/${subSid}/exist`;
    } else if (level === 3) {
      url = `${getHost()}/api/v1/project/${projectId}/searchSet/keyword/${sid}/{subSid}/exist`;
    } else if (level === 2 && sid) {
      url = `${getHost()}/api/v1/project/${projectId}/searchSet/keyword/${sid}/exist`;
    } else {
      url = `${getHost()}/api/v1/project/${projectId}/searchSet/keyword/exist`;
    }
    const options = {
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        name
      }
    };
    const res = await request(options);
    return !!res.data.result;
  }


  // ========== search tag ========== //

  /**
   *
   * @param {string} id
   * @param {Object} data
   * @param {('l2'|'l3'|'all')} data.scope
   */
  static getAllTags = async (id, data) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/all`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    const tags = res.data.result.map((t) => SearchTag.fromRes(t));
    return tags;
  }

  /**
   *
   * @param {string} id
   * @param {string} name
   * @param {('l2'|'l3'|'all')} scope
   */
  static createTag = async (id, name, scope) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: {
        tagKeys: [name],
        scope
      }
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} tid
   * @param {string} subTid
   * @param {Object} data
   * @param {string} data.name
   */
  static updateTag = async (id, tid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} tid
   */
  static deleteTag = async (id, tid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} tid
   */
  static getSubTags = async (id, tid) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data.result.map((r) => SearchTag.fromRes(r));
  }

  /**
   *
   * @param {string} id
   * @param {string} tid
   * @param {string} name
   */
  static createSubTag = async (id, tid, name) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { tagValues: [name] }
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} tid
   * @param {string} subTid
   * @param {Object} data
   * @param {string} data.name
   */
  static updateSubTag = async (id, tid, subTid, data) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}/${subTid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    return request(options);
  }

  /**
   *
   * @param {string} id
   * @param {string} tid
   * @param {string} subTid
   */
  static deleteSubTag = async (id, tid, subTid) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}/${subTid}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }

  /**
   * update search tag for project
   * @param {string} id
   * @param {string} tid
   * @param {string} subTid
   * @param {string[]} sids
   */
  static updateTagRelation = async (id, tid, subTid, sids) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tag/${tid}/${subTid}/relation`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { sids }
    };
    return request(options);
  }


  /**
   *
   * @param {*} file Blob object
   * @returns parsed json
   */
  static readUploadedFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let json;

      reader.onload = async (evt) => {
        const stringArray = evt.target.result.split('\n');

        const name = stringArray[0]?.trim()?.split(',')[0] ?? '';
        const reduceKeyword = stringArray[1]?.trim()?.split(',')[0] ?? '';
        const tableHeader = stringArray[2]?.trim()?.split(',') ?? [];

        // to validate tag headers
        const isValidTagHeader = tableHeader
          .filter((header) => (
            header !== 'level1'
            && header !== 'level2'
            && header !== 'level3'
            && header !== 'keyword'
            && header !== 'synonym'
          ))
          .every((header) => {
            const level = header.split('@')[1]?.toLowerCase();
            const result = (
              level === 'l2'
              || level === 'l3'
              || level === 'all'
            );
            return result;
          });

        if (!isValidTagHeader) {
          reject(new Error('偵測到標籤階級標示不正確'));
        }

        stringArray.splice(0, 2);
        const csvString = stringArray.join('\n');

        // parse data
        const csv = await new Promise(((_resolve, _reject) => {
          Papa.parse(csvString, {
            ...Config.csv.import,
            header: true,
            complete: (results) => { _resolve(results.data); },
            error: (e) => { _reject(e); }
          });
        }));

        json = { name, reduceKeyword, rows: [] };

        csv.forEach((row, index) => {
          const { level1, level2, level3, keyword, synonym, ...tags } = row;

          if (!level1) {
            reject(new Error(`在第 ${index + 4} 列偵測到level1為空值`));
          }

          json.rows.push({
            level1, level2, level3, keyword, synonym, tags, index
          });
          return null;
        });

        // to validate uploaded data
        const L1Map = new Map();
        const L2Map = new Map();
        const L3Map = new Map();

        // no repeated name in same group
        json.rows.forEach((row) => {
          if (row.level3) {
            if (L3Map.has(`${row.level1}\v${row.level2}\v\v${row.level3}`)) {
              reject(new Error(`在第 ${row.index + 4} 列偵測到重複的level3名稱`));
            }
            L3Map.set(`${row.level1}\v${row.level2}\v\v${row.level3}`, row);

          } else if (row.level2) {
            if (L2Map.has(`${row.level1}\v${row.level2}`)) {
              reject(new Error(`在第 ${row.index + 4} 列偵測到重複的level2名稱`));
            }
            L2Map.set(`${row.level1}\v${row.level2}`, row);

          } else {
            if (L1Map.has(row.level1)) {
              reject(new Error(`在第 ${row.index + 4} 列偵測到重複的level1名稱`));
            }
            L1Map.set(row.level1, row);
          }
        });

        // no lack of upper level or tags
        L2Map.forEach((value, key) => {
          if (!L1Map.has(key.split('\v')[0])) {
            reject(new Error(`在第 ${value.index + 4} 列偵測到不存在的level1名稱`));
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const [_key, _value] of Object.entries(value.tags)) {
            const scope = _key.split('@')[1];
            if ((scope === 'all' || scope === 'l2') && !_value) {
              reject(new Error(`在第 ${value.index + 4} 列偵測到 ${_key} 為空值`));
            }
          }


        });
        L3Map.forEach((value, key) => {
          if (!value.level2) {
            if (!L1Map.has(key.split('\v')[0])) {
              reject(new Error(`在第 ${value.index + 4} 列偵測到不存在的level1名稱`));
            }

          } else if (!L2Map.has(key.split('\v\v')[0])) {
            reject(new Error(`在第 ${value.index + 4} 列偵測到不存在的level1或level2名稱`));
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const [_key, _value] of Object.entries(value.tags)) {
            const scope = _key.split('@')[1];
            if ((scope === 'all' || scope === 'l3') && !_value) {
              reject(new Error(`在第 ${value.index + 4} 列偵測到 ${_key} 為空值`));
            }
          }
        });

        resolve(json);
      };

      reader.readAsText(file);
    });
  }


  /**
   * get tip
   */
  static getTip = async (id) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/tip`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }


  static export = async (id, projectName) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/searchSet/export`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };

    const res = await request(options);
    const { name, reduceKeyword, rows } = res.data.result;
    const flattenRows = rows.map((row) => flattenObject(row));

    downloadFile(flattenRows, `${projectName}搜尋關鍵字`, `${name}\n${reduceKeyword}`);
  }


  /**
   * 專案是否有停用狀況
   *
   * @param {string} id
   * @param {string} endDate date time
   *
   * @return {boolean}
   */
    static hasDisableItem = async (id, endDate) => {
      const options = {
        method: 'get',
        url: `${getHost()}/api/v1/project/${id}/searchSet/keyword/disableCount`,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          authorization: `Bearer ${AuthService.getToken()}`
        },
        params: { endDate }
      };
      const res = await request(options);
      return res.data.result.disableCount > 0;
    }
}
