import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Col, Input, Popover, Row, Select, Tag, Tooltip, Typography, Dropdown, Menu } from 'antd';
import { ExportOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { PROJECT_STATUS } from 'src/constants';
import useAppStateContext from 'src/stores/global/useAppStateContext';

import ProjectItemVM from './ProjectItemVM';

import styles from './styles.module.css';

const ProjectItem = (props) => {
  const { project, projectId } = props;
  const { state } = useAppStateContext();
  const [vm] = useState(() => new ProjectItemVM(project, projectId, state.profile.isTeamLeader));


  const renderEditableInfo = () => (
    <>
      <div>
        <div className={styles.title}>名稱</div>
        <div>
          {vm.isEdit
            ? <Input value={vm.name} onChange={vm.onNameChange} />
            : <div>{vm.name}</div>}
        </div>
      </div>
      <div>
        <div className={styles.title}>範圍定義</div>
        <div>
          {vm.isEdit
            ? <Input.TextArea value={vm.note} onChange={vm.onNoteChange} />
            : <div>{vm.note}</div>}

        </div>
      </div>
    </>
  );

  const renderStaticInfo = () => (
    <>
      <div>
        <div className={styles.title}>名稱</div>
        <div>{project?.meta?.name ?? '-'}</div>
      </div>
      <div>
        <div className={styles.title}>範圍定義</div>
        <div>{project?.meta?.note ?? '-'}</div>
      </div>
    </>
  );

  const renderEditableInfoHeader = () => (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        {project.meta.isTemplate && <Tag>公版專案</Tag>}
        {project.ref.externalReferencedCount > 0 && <Tag>子系統專案</Tag>}
      </div>
      <div>專案基本資料</div>
      <div className={styles.headerRight}>
        {vm.isEdit
          ? <Button onClick={vm.onSave} type="link">儲存</Button>
          : <Button onClick={vm.onEdit} type="link">編輯</Button>}
      </div>
    </div>
  );

  const renderStaticInfoHeader = () => (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        {project.meta.isTemplate && <Tag>公版專案</Tag>}
        {project.ref.externalReferencedCount > 0 && <Tag>子系統專案</Tag>}
      </div>
      <div>專案基本資料</div>
    </div>
  );

  const renderStaticOwner = () => (
    <div>
      <div className={styles.title}>專案負責人</div>
      <Row>
        <div>{project?.owner?.team?.name ?? '-'}</div>
        <div> -</div>
        <div>{project?.owner?.user?.name ?? '-'}</div>

      </Row>
    </div>
  );

  const renderEditableOwner = () => (
    <div>
      <div className={styles.title}>專案負責人</div>
      <Row>
        {vm.isEdit
          ? (
            <Select
              style={{ width: 140 }}
              value={vm.teamId}
              onChange={vm.onTeamChange}
              showSearch
              optionFilterProp="children"
            >
              {vm.teams.map((t) => (<Select.Option value={t.id} key={t.id}>{t.name}</Select.Option>))}
            </Select>
          )
          : (<div>{vm.teams.find((t) => t.id === vm.teamId)?.name || (project?.owner?.team?.name ?? '-')}</div>)}
        <div> -</div>
        {vm.isEdit
          ? (
            <Select
              style={{ width: 140 }}
              value={vm.userId}
              onChange={vm.onUserChange}
              showSearch
              optionFilterProp="children"
            >
              {vm.users?.map((t) => (<Select.Option value={t.id} key={t.id}>{t.name}</Select.Option>))}
            </Select>
          )
          : (<div>{vm.users.find((t) => t.id === vm.userId)?.name || (project?.owner?.user?.name ?? '-')}</div>)}

      </Row>
    </div>
  );

  const renderDateRange = () => (
    <Row>
      <span>
        {
          project?.autorun?.startDate
            ? dayjs(project.autorun.startDate).format('YYYY/MM/DD')
            : '尚無起始日'
        }
      </span>
      <span> - </span>
      <span>
        {
          project?.autorun?.endDate
            ? dayjs(project.autorun.endDate).format('YYYY/MM/DD')
            : '尚無截止日'
        }
      </span>
    </Row>
  );

  const renderStatusTag = () => {
    switch (project?.meta?.status) {
      case PROJECT_STATUS.NOT_RUN:
        return <Tag color="blue">未啟動</Tag>;
      case PROJECT_STATUS.RUN:
        return <Tag color="blue">專案運作中</Tag>;
      case PROJECT_STATUS.OUT_OF_DATE:
        return <Tag color="blue">已過期</Tag>;
      default:
        return null;
    }
  };

  const tooltipTitle = () => {
    switch (project?.meta?.status) {
      case PROJECT_STATUS.NOT_RUN:
        return <div>專案未跑過資料，目前無任何資料分析結果</div>;
      case PROJECT_STATUS.RUN:
        return <div>專案資料起訖區間未過期，執行每日排程自動分析</div>;
      case PROJECT_STATUS.OUT_OF_DATE:
        return <div>專案資料起訖區間已過期，不再執行每日排程分析</div>;
      default:
        return null;
    }
  };

  return (
    <Row style={{ width: '100%', height: 'fit-content', marginBottom: 15 }} wrap={false}>

      <Col style={{ width: '50%' }} className={styles.card}>
        {props.editable ? renderEditableInfoHeader() : renderStaticInfoHeader()}
        <div className={styles.flexContent}>
          <div>
            <Row align="middle">
              <div className={styles.title}>專案狀態</div>
              <Tooltip
                trigger={['hover']}
                title={tooltipTitle()}
                className={styles.tooltip}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Row>
            {renderStatusTag()}
          </div>
          {props.editable ? renderEditableInfo() : renderStaticInfo()}
          <div>
            <div className={styles.title}>專案資訊</div>
            <Row>
              {project?.meta?.domain && <Tag>{project.meta.domain}</Tag>}
              {project?.meta?.searchAngle === 'brand' && <Tag>品牌分析切角</Tag>}
              {project?.meta?.searchAngle === 'category' && <Tag>品類分析切角</Tag>}
              {project?.autorun?.checkChannelName && <Tag>比對頻道名稱</Tag>}
            </Row>
          </div>
          <div>
            <div className={styles.title}>UID</div>
            <Typography.Paragraph
              copyable={{
                text: project?._id ?? '',
                tooltips: [project?._id ?? null]
              }}
            >
              {project?._id ?? ''}
            </Typography.Paragraph>
          </div>
          <div>
            <div className={styles.title}>建立時間</div>
            <div>{project?.meta?.createDate && dayjs(project?.meta?.createDate).format('YYYY/MM/DD')}</div>
          </div>
          {props.editable && state.profile.isTeamLeader ? renderEditableOwner() : renderStaticOwner()}
          {props.infoButton && (<div className={styles.buttonRow}>{props.infoButton}</div>)}
        </div>
      </Col>


      <Col flex={1} style={{ display: 'flex', flexDirection: 'column', margin: '0 15px', width: '25%' }}>
        <div style={{ border: '1px solid #F0F0F0', flex: 1, marginBottom: 15 }}>
          <div className={styles.header}>專案引用資訊</div>
          <div className={styles.content}>
            <div className={styles.title}>引用通知累積次數</div>
            {props.canGoToRefNoticePage
              ? <Link to={`/project/ref/${projectId}`}>{project?.ref?.referencedLogCount ?? '-'}</Link>
              : <div>{project?.ref?.referencedLogCount ?? '-'}</div>}

            <div className={styles.title}>外部系統引用次數</div>
            <div>{project?.ref?.externalReferencedCount ?? '-'}</div>
          </div>
        </div>


        <div style={{ flex: 2, border: '1px solid #F0F0F0' }}>
          <div className={styles.header}>分析進度摘要</div>
          <div className={styles.flexContent}>
            <div>
              <div className={styles.title}>專案資料起訖區間</div>
              {renderDateRange()}
            </div>
            <div>
              <div className={styles.title}>每日排程截止</div>
              <div>
                {
                  project?.autorun?.endDate
                    ? dayjs(project.autorun.endDate).format('YYYY/MM/DD')
                    : '尚無截止日'
                }
              </div>
            </div>
            <div>
              <div className={styles.title}>分析狀況</div>
              <div>{`最新分析至 ${project?.progress?.progress ? `${project.progress.progress}%` : '-'}`}</div>
              <div>{`開始分析 ${project?.progress?.beginAt ? dayjs(project.progress.beginAt).format('YYYY/MM/DD HH:mm:ss') : '-'}`}</div>
              <div>{`實際結束 ${project?.progress?.endAt ? dayjs(project.progress.endAt).format('YYYY/MM/DD HH:mm:ss') : '-'}`}</div>
            </div>
            {props.summaryButton && (<div className={styles.buttonRow}>{props.summaryButton}</div>)}
          </div>
        </div>
      </Col>


      <Col style={{ width: '25%' }} className={styles.card}>
        <div className={styles.header}>專案設定</div>
        <div className={styles.flexContent}>
          <div className={styles.settingCard}>
            <div className={styles.cardTitle}>頻道組合</div>
            <Popover
              trigger="click"
              visible={vm.channelTip}
              content={
                vm.channelTip ? (
                  <div className={styles.tip}>
                    {
                      vm.channelTip.lastLog && (
                        <div>
                          最新異動:
                          {dayjs(vm.channelTip.lastLog.date).format('YYYY/MM/DD')}
                        </div>
                      )
                    }
                    <div>
                      頻道數量:
                      {vm.channelTip.count}
                    </div>
                    <Button className={styles.tipBottom} onClick={vm.resetChannelTip}>關閉</Button>
                  </div>
                ) : null
              }
            >
              <InfoCircleOutlined onClick={vm.getChannelTip} />
            </Popover>
            {
              project.ref.channelSetRefProject ? (
                <div className={styles.ref}>
                  引用公版專案頻道組合
                </div>
              ) : null
            }
            {props.exportable && <ExportOutlined className={styles.export} onClick={vm.exportChannel} />}
          </div>
          <div className={styles.settingCard}>
            <div className={styles.cardTitle}>搜尋關鍵字</div>
            <Popover
              trigger="click"
              visible={vm.searchTip}
              content={
                vm.searchTip ? (
                  <div className={styles.tip}>
                    {
                      vm.searchTip.lastLog && (
                        <div>
                          最新異動:
                          {dayjs(vm.searchTip.lastLog.date).format('YYYY/MM/DD')}
                        </div>
                      )
                    }
                    <div>
                      {project?.meta?.searchAngle === 'brand' ? '品牌數: ' : '品類數: '}
                      {vm.searchTip.level1.count}
                    </div>
                    <div>
                      {project?.meta?.searchAngle === 'brand' ? '產品線數: ' : '品牌數: '}
                      {vm.searchTip.level2.count}
                    </div>
                    <div>
                      {project?.meta?.searchAngle === 'brand' ? '產品數: ' : '產品數: '}
                      {vm.searchTip.level3.count}
                    </div>
                    <div>
                      標籤數:
                      {vm.searchTip.tag.count}
                    </div>
                    <Button className={styles.tipBottom} onClick={vm.resetSearchTip}>關閉</Button>
                  </div>
                ) : null
              }
            >
              <InfoCircleOutlined onClick={vm.getSearchTip} />
            </Popover>
            {
              project.ref.searchSetRefProject ? (
                <div className={styles.ref}>
                  引用公版專案搜尋關鍵字
                </div>
              ) : null
            }
            {props.exportable && <ExportOutlined className={styles.export} onClick={vm.exportSearch} />}
          </div>
          <div className={styles.settingCard}>
            <div className={styles.cardTitle}>特性關鍵字</div>
            <div className={styles.featureSets}>
              {
                project.featureSets ? project.featureSets.map((featureSet, index) => {
                  return (
                    <Popover
                      trigger="click"
                      key={featureSet._id}
                      visible={vm.featureTip && vm.featureTip.id === featureSet._id}
                      content={
                        vm.featureTip && vm.featureTip.id === featureSet._id ? (
                          <div className={styles.tip}>
                            <div>
                              檔案
                              {index + 1}
                            </div>
                            <div>
                              {vm.featureTip.doc.name}
                            </div>
                            <div>
                              {featureSet._id}
                            </div>
                            {
                              vm.featureTip.lastLog && (
                                <div>
                                  最新異動:
                                  {dayjs(vm.featureTip.lastLog.date).format('YYYY/MM/DD')}
                                </div>
                              )
                            }
                            <div>
                              群組數：
                              {vm.featureTip.categoryCount}
                            </div>
                            <div>
                              詞彙數：
                              {vm.featureTip.keywordCount}
                            </div>
                            {
                              featureSet.refProject && (
                                <div>
                                  引用
                                  {featureSet.refProject}
                                  公版特性關鍵字
                                </div>
                              )
                            }
                            <div>
                              備註：
                              {vm.featureTip.doc.note}
                            </div>
                            <Button className={styles.tipBottom} onClick={vm.resetFeatureTip}>關閉</Button>
                          </div>
                        ) : null
                      }
                    >
                      <div
                        className={featureSet.refProject ? styles.numberIconHasRef : styles.numberIcon}
                        onClick={() => vm.getFeatureTip(featureSet._id)}
                      >
                        {index + 1}
                      </div>
                    </Popover>
                  );
                }) : null
              }
            </div>
            {
              project.featureSets?.some((featureSet) => !!featureSet.refProject) ? (
                <div className={styles.ref}>
                  標色為引用公版專案特性關鍵字
                </div>
              ) : null
            }
            {props.exportable && (
              <Dropdown
                arrow
                trigger={['click']}
                placement="bottomLeft"
                overlay={(
                  <Menu
                    items={vm.featureFiles.map((f) => ({
                      key: f.fid,
                      label: (
                        <Button
                          type="text"
                          onClick={() => vm.exportFeature(f.fid, f.name)}
                        >
                          {f.name}
                        </Button>
                      )
                    }))}
                  />
                )}
              >
                <ExportOutlined
                  className={styles.export}
                  onClick={() => {
                    setTimeout(() => {
                      vm.getFeatureFiles();
                    }, '300');
                  }}
                />
              </Dropdown>
            )}
          </div>
          {props.settingButton && (<div className={styles.buttonRow}>{props.settingButton}</div>)}
        </div>
      </Col>
    </Row>
  );
};

ProjectItem.propTypes = {
  projectId: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  exportable: PropTypes.bool,
  canGoToRefNoticePage: PropTypes.bool,
  settingButton: PropTypes.node,
  summaryButton: PropTypes.node,
  infoButton: PropTypes.node
};

ProjectItem.defaultProps = {
  editable: false,
  exportable: false,
  canGoToRefNoticePage: false,
  settingButton: null,
  summaryButton: null,
  infoButton: null
};

export default observer(ProjectItem);
