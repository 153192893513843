
import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message } from 'antd';
import { nanoid } from 'nanoid';
import SubTag from './SubTag';

export default class Tag {

  id = nanoid();
  importDrawerVM = null;

  @observable fullName = '';
  @observable subTags = null;
  @observable name = '';
  @observable scope = '';

  constructor(fullName, subTags, importDrawerVM) {
    makeObservable(this);

    this.importDrawerVM = importDrawerVM;

    runInAction(() => {
      this.fullName = fullName;
      this.subTags = subTags.filter((subTag) => !!subTag).map((subTag) => new SubTag(subTag, this, this.importDrawerVM));
      this.name = fullName.split('@')[0];
      this.scope = fullName.split('@')[1];


      // remove '其他' tag if it has, and add new one in the end of the array for consistency
      this.subTags = this.subTags.filter((subTag) => subTag.name !== '其他');
      this.subTags.push(new SubTag('其他', this, this.importDrawerVM));
    });
  }


  @action
  update = (newName) => {
    const isRepeated = this.importDrawerVM.tags.some((tag) => tag.name === newName);
    if (isRepeated) {
      message.error('名稱重複，無法更新');
      throw new Error();
    }
    if (newName.includes('@')) {
      message.error('母標籤名稱不能有@');
      throw new Error();
    }

    this.importDrawerVM.updateTag(this.fullName, `${newName}@${this.scope}`);
    this.name = newName;
    this.fullName = `${newName}@${this.scope}`;
    return null;
  }

  delete = () => {
    this.importDrawerVM.deleteTag(this.fullName);
  }

  @action
  deleteSubTag = (name) => {
    this.subTags = this.subTags.filter((subtag) => subtag.name !== name);
    this.importDrawerVM.deleteSubTag(this.fullName, name);
  }

}

