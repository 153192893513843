import { getHost, request } from 'src/utils';

import AuthService from './auth';

export default class ChangeLogService {

  /**
   * query all change log of project
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {bool} data.ref  required
   * @param {'channelSet'|'searchSet'|'featureSet'} data.type  required
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {string} data.keyword
   * @param {string} data.startDate date time
   * @param {string} data.endDate date time
   * @param {bool} data.hasNote  nullable
   */
  static async getChangeLog(id, data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/changeLog`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    const { logs, anchor } = res.data;
    return { logs, anchor };
  }


  /**
   * query all change log summary of project
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {bool} data.ref  required
   * @param {string} data.startDate date time
   * @param {string} data.endDate date time
   */
  static async getChangeLogSummary(id, data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/changeLog/summary`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   * update note of change log
   *
   * @param {string} projectId  required
   * @param {string} logId  required
   * @param {string} note
   */
  static async updateChangeLog(projectId, logId, note) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${projectId}/changeLog/${logId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { note }
    };
    return request(options);
  }
}
