/* eslint-disable no-param-reassign */
import { makeObservable, observable, runInAction, action } from 'mobx';
import FeatureSetService from 'src/services/featureSet';
import ChannelSetService from 'src/services/channelSet';
import SearchSetService from 'src/services/searchSet';
import ProjectService from 'src/services/project';
import { PROJECT_STATUS } from 'src/constants';
import { errorModal } from 'src/utils';

export default class ProjectAnalysis {
  projectId = '';

  @observable features = [];
  @observable channelStat = [];
  @observable searchStat = {};
  @observable project = null;
  @observable searchAngle = 'brand';
  @observable currentTabKey = 'analysis';

  analysisVM = null;

  constructor(projectId) {
    makeObservable(this);
    this.projectId = projectId;
  }

  didMount = async () => {
    await Promise.all([
      this.getProject(),
      this.getFeatures(),
      this.getChannelStat(),
      this.getSearchStat()
    ]);
  }

  getFeatures = async () => {
    try {
      const { features } = await FeatureSetService.queryFeatureSet(this.projectId);
      runInAction(() => {
        this.features = features;
      });
    } catch {
      // ignore
    }
  }

  getChannelStat = async () => {
    try {
      const res = await ChannelSetService.getStats(this.projectId);
      runInAction(() => {
        this.channelStat = res;
      });
    } catch {
      // ignore
    }
  }

  getSearchStat = async () => {
    try {
      const res = await SearchSetService.getSummary(this.projectId);
      runInAction(() => {
        this.searchStat = res;
      });
    } catch {
      // ignore
    }
  }

  getProject = async () => {
    try {
      const res = await ProjectService.getProject(this.projectId);
      runInAction(() => {
        this.project = res;
        this.searchAngle = res.meta.searchAngle;
      });

      if (this.project.meta.status === PROJECT_STATUS.NOT_RUN) {
        this.onTabChange('schedule');
      }

    } catch {
      // ignore
    }
  }

  @action
  onTabChange = (newKey) => {
    this.currentTabKey = newKey;
  }

  @action
  changeTabAndRefresh = async () => {
    this.onTabChange('analysis');
    await this.analysisVM?.refresh();
  }

}
