import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { Row, Button, Col, Input, Tooltip, message } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import SearchSetService from 'src/services/searchSet';
import withRouter from 'src/components/withRouter';

import styles from './styles.module.css';

const SearchMeta = observer((props) => {
  const [name, setName] = useState('-');
  const [reduceKeyword, setReduceKeyword] = useState('-');
  const [originalMeta, setOriginalMeta] = useState(null);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [isReduceKeywordEdit, setIsReduceKeywordEdit] = useState(false);
  const [projectId, setProjectId] = useState(false);
  const [refreshStamp, setRefreshStamp] = useState('');

  // fetch meta data on component mount or when stamp changed
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      autorun(() => {
        setRefreshStamp(props.refreshStamp);
        (async () => {
          const _projectId = props.projectId || props.router.params.id;
          setProjectId(_projectId);
          try {
            if (!_projectId) return;
            const meta = await SearchSetService.getMeta(_projectId);
            setName(meta.name);
            setReduceKeyword(meta.reduceKeyword);
            setOriginalMeta({
              name: meta.name,
              reduceKeyword: meta.reduceKeyword
            });
          } catch {
            message.err('發生錯誤，無法取得搜尋關鍵字資訊');
          }
        })();
      });
    }

    return () => { mounted = false; };
  }, [props.projectId, props.router.params.id, props.refreshStamp]);


  //  update meta data if one of the attributes have changed
  const updateMeta = async () => {
    if (originalMeta.name === name && originalMeta.reduceKeyword === reduceKeyword) return;
    try {
      await SearchSetService.updateMeta(projectId, { name, reduceKeyword });
    } catch {
      message.err('發生錯誤，無法更新資訊');
      setName(originalMeta.name);
      setReduceKeyword(originalMeta.reduceKeyword);
    }
  };


  return (
    <>
      <Row>
        <Col flex={1} className={styles.marginRight}>
          <Row align="middle" className={styles.border} wrap={false}>
            <div className={styles.padding} style={{ width: '12ch' }}>分析主題名稱</div>
            <Tooltip title={name} className={styles.nameInput}>
              <div>
                {props.editable
                  ? (
                    <Input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      disabled={!isNameEdit}
                    />
                  )
                  : <div className={styles.txt}>{name}</div>}

              </div>
            </Tooltip>
            {(isNameEdit && props.editable) && (
              <Button
                type="text"
                className={styles.padding}
                onClick={() => {
                  setIsNameEdit(false);
                  updateMeta();
                }}
              >
                <CheckOutlined />
              </Button>
            )}
            {(!isNameEdit && props.editable) && (
              <Button type="text" className={styles.padding} onClick={() => setIsNameEdit(true)}>
                <EditOutlined />
              </Button>
            )}

          </Row>
        </Col>

        <Col flex={2} className={styles.marginRight}>
          <Row align="middle" className={styles.border} wrap={false}>
            <div className={styles.padding} style={{ width: '17ch' }}>{`${props.title}縮限搜尋關鍵字`}</div>
            <Tooltip title={reduceKeyword} className={styles.keywordInput}>
              <div>
                {props.editable
                  ? (
                    <Input
                      onChange={(e) => setReduceKeyword(e.target.value)}
                      value={reduceKeyword}
                      disabled={!isReduceKeywordEdit}
                    />
                  )
                  : <div className={styles.txt}>{reduceKeyword}</div>}

              </div>
            </Tooltip>



            {(isReduceKeywordEdit && props.editable) && (
              <Button
                type="text"
                className={styles.padding}
                onClick={() => {
                  setIsReduceKeywordEdit(false);
                  updateMeta();
                }}
              >
                <CheckOutlined />
              </Button>
            )}
            {(!isReduceKeywordEdit && props.editable) && (
            <Button type="text" className={styles.padding} onClick={() => setIsReduceKeywordEdit(true)}>
              <EditOutlined />
            </Button>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
});

SearchMeta.propTypes = {
  router: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  projectId: PropTypes.string,
  title: PropTypes.string.isRequired
};

SearchMeta.defaultProps = {
  editable: false,
  projectId: ''
};

export default withRouter(SearchMeta);
