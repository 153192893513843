import { getHost, request } from 'src/utils';
import AuthService from './auth';

export default class Es {

  /**
   * query es list
   *
   * @param {Object} data
   * @param {string} data.keyword      required
   * @param {string} data.startDate    required
   * @param {string} data.endDate      required
   * @param {string} data.projectId
   */
  static async get(data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/es`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: data
    };
    const res = await request(options);
    return res.data.result;
  }
}
