import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Row, Col, Switch } from 'antd';

// mode: default || myProject || importDrawer

const L1 = (props) => {
  const [form] = Form.useForm();
  const [isSelect, setIsSelect] = useState(false);

  return (
    <div style={{
      border: '1px solid #aaa',
      padding: 10,
      marginBottom: 10
    }}
    >
      <Form form={form}>
        <Row justify="space-between" align="bottom" gutter={20}>

          <Col flex={1}>
            <div>自定義搜尋</div>
            <Form.Item name="keyword" style={{ marginBottom: 5 }}>
              <Input
                placeholder="搜尋（名稱、ID、或同義詞）"
                disabled={isSelect}
              />
            </Form.Item>
          </Col>


          {props.mode === 'myProject' && (
          <Col>
            <div>是否已勾選</div>
            <Form.Item name="isSelected" style={{ marginBottom: 5 }} valuePropName="checked">
              <Switch onChange={() => setIsSelect(!isSelect)} />
            </Form.Item>
          </Col>
          )}


          {props.mode === 'importDrawer' && (
          <Col>
            <div>其他篩選條件</div>
            <Form.Item name="isError" style={{ marginBottom: 5 }} valuePropName="checked">
              <Switch checkedChildren="只顯示錯誤內容" unCheckedChildren="只顯示錯誤內容" />
            </Form.Item>
          </Col>
          )}


          <Col style={{ marginBottom: 5 }}>
            <Button
              onClick={() => {
                form.resetFields();
                setIsSelect(false);
              }}
              style={{ marginRight: 10 }}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                const values = form.getFieldsValue();
                await props.onQuery(values);
              }}
            >
              套用
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

L1.propTypes = {
  onQuery: PropTypes.func.isRequired,
  mode: PropTypes.string
};

L1.defaultProps = {
  mode: 'default'
};

export default L1;
