import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Button, DatePicker, Typography } from 'antd';

import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProjectHeader from 'src/components/project/ProjectHeader';
import { summaryColumns } from 'src/components/changeLog/summaryColumns';
import { logColumns } from 'src/components/changeLog/logCloumns';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import ProjectChangeLogVM from 'src/viewModels/ProjectChangeLog';
import ChangeLogNoteModal from 'src/components/changeLog/ChangeLogNoteModal';
import ActionBar from 'src/components/changeLog/ActionBar';

import styles from './styles.module.css';

@observer
class ChangeLogPage extends React.Component {

  constructor(props) {
    super(props);
    this.vm = new ProjectChangeLogVM(props.router.params.id, null);
  }

  componentDidMount() {
    this.vm.didMount();
    this.columns = [
      ...logColumns,
      {
        title: (<Row justify="center">備註</Row>),
        key: 'note',
        width: 250,
        render: (data) => (
          <Observer>
            {() => (
              <Typography.Paragraph
                style={{ width: '100%' }}
                ellipsis={{
                  rows: 3,
                  tooltip: data.note
                }}
              >
                {data.note ?? '-'}
              </Typography.Paragraph>
            )}
          </Observer>
        )
      },
      {
        title: (<Row justify="center">備註操作</Row>),
        key: 'note',
        width: 120,
        render: (data) => (
          <Row justify="center">
            <Button
              onClick={() => this.vm.modal.show(data)}
              type="link"
            >
              {data.note ? '編輯' : '新增'}
            </Button>
          </Row>
        )
      }
    ];
  }


  render() {
    return (
      <div style={{ height: '100%', overflowY: 'hidden' }}>

        <ProjectHeader />

        <Tabs defaultActiveKey="1" size="small" onTabClick={() => { console.log('refresh list by key'); }}>

          <Tabs.TabPane tab="近期總覽" key="1">

            <Row align="middle" className={styles.marginBottom}>
              <div className={styles.marginRight}>異動日期篩選:</div>
              <DatePicker.RangePicker
                value={[this.vm.summaryStartDate, this.vm.summaryEndDate]}
                allowEmpty={[true, true]}
                onChange={this.vm.onDateChange}
              />
            </Row>

            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <div style={{ width: '100%', height: 'calc(100vh - 180px)' }}>
                      <Table
                        size="small"
                        rowKey="date"
                        onFetch={() => {}}
                        columns={summaryColumns}
                        scroll={{ y: height }}
                        dataSource={this.vm.summary}
                      />
                    </div>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>
          </Tabs.TabPane>


          <Tabs.TabPane tab="頻道組合" key="2">
            <ActionBar
              onQuery={this.vm.channelSet.onSearch}
              placeholder="搜尋(異動紀錄ID 、專案ID、頻道ID、頻道名稱、備註)"
            />
            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <div style={{ width: '100%', height: 'calc(100vh - 180px)' }}>
                      <Table
                        size="small"
                        rowKey="id"
                        onFetch={this.vm.channelSet.onBottom}
                        columns={this.columns}
                        scroll={{ y: height, x: 1820 }}
                        dataSource={this.vm.channelSet.list}
                        lineCount={3}
                      />
                    </div>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>
          </Tabs.TabPane>


          <Tabs.TabPane tab="搜尋關鍵字" key="3">
            <ActionBar
              onQuery={this.vm.searchSet.onSearch}
              placeholder="搜尋(異動紀錄ID、備註、項目名稱、名稱UID)"
            />
            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <div style={{ maxWidth: '100%', height: 'calc(100vh - 180px)' }}>
                      <Table
                        size="small"
                        rowKey="id"
                        onFetch={this.vm.searchSet.onBottom}
                        columns={this.columns}
                        scroll={{ y: height, x: 1820 }}
                        dataSource={this.vm.searchSet.list}
                        lineCount={3}
                      />
                    </div>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>
          </Tabs.TabPane>


          <Tabs.TabPane tab="特性關鍵字" key="4">
            <ActionBar
              onQuery={this.vm.featureSet.onSearch}
              placeholder="搜尋(異動紀錄ID、檔案名稱ID、項目ID、項目名稱、備註)"
            />
            <ReactResizeDetector handleHeight>
              {({ height = 0 }) => (
                <Observer>
                  {() => (
                    <div style={{ width: '100%', height: 'calc(100vh - 180px)' }}>
                      <Table
                        size="small"
                        rowKey="id"
                        onFetch={this.vm.featureSet.onBottom}
                        columns={this.columns}
                        scroll={{ y: height, x: 1820 }}
                        dataSource={this.vm.featureSet.list}
                        lineCount={3}
                      />
                    </div>
                  )}
                </Observer>
              )}
            </ReactResizeDetector>
          </Tabs.TabPane>
        </Tabs>



        <ChangeLogNoteModal
          modal={this.vm.modal}
        />

      </div>
    );
  }
}

ChangeLogPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withProfile(withRouter(ChangeLogPage), true);
