import React from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Form, Input, Radio, Select } from 'antd';
import { PROJECT_DOMAINS } from 'src/constants';

const CreateProjectDrawer = (props) => {
  const [form] = Form.useForm();

  return (
    <Drawer
      title="新增專案"
      placement="right"
      width={600}
      destroyOnClose
      footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      visible={props.visible}
      maskClosable={false}
      onClose={() => {
        props.onClose();
        form.resetFields();
      }}
      footer={(
        <Button
          type="primary"
          htmlType="submit"
          onClick={async () => {
            form.validateFields()
              .then(async () => {
                await props.onCreate(form.getFieldsValue());
                props.onClose();
                form.resetFields();
              })
              .catch(() => {
                // ignor validate error
              });
          }}
        >
          儲存專案
        </Button>
        )}
    >
      <Form form={form}>
        <Form.Item
          label="專案名稱"
          name="name"
          rules={[
            {
              required: true,
              pattern: '^[\u4e00-\u9fa5_a-zA-Z0-9]+$',
              message: '專案名稱為必填，且只能使用中文、半型英文數字、底線'
            }
          ]}
        >
          <Input placeholder="請輸入專案名稱" />
        </Form.Item>

        <Form.Item
          label="所屬領域"
          name="domain"
          rules={[
            {
              required: true,
              message: '所屬領域為必填'
            }
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="所屬領域"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            {PROJECT_DOMAINS.map((domain) => (
              <Select.Option key={domain} value={domain}>{domain}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="分析切角設定"
          name="searchAngle"
          rules={[
            {
              required: true,
              message: '分析切角設定為必選'
            }
          ]}
        >
          <Radio.Group>
            <Radio value="brand">品牌分析切角</Radio>
            <Radio value="category">品類分析切角</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="是否標記為公版專案"
          name="isTemplate"
          rules={[
            {
              required: true,
              message: '是否標記為公版專案為必選'
            }
          ]}
        >
          <Radio.Group>
            <Radio value>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="專案範圍定義（可空白）" name="note">
          <Input.TextArea
            placeholder="請輸入專案收錄之資料範圍定義描述"
            maxLength={300}
            showCount
            rows={5}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

CreateProjectDrawer.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default CreateProjectDrawer;
