import { downloadFile, getHost, request } from 'src/utils';
import Papa from 'papaparse';
import Config from 'src/config';
import { CHANNEL_SOURCE } from 'src/constants';
import AuthService from './auth';

export default class ChannelSet {

  /**
   *
   * @param {string} id
   * @param {string[]} channels array channel id
   */
  static addChannel = async (id, channels) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/channelSet/add`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { channels }
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   * @param {string[]} channels
   * @param {string} date
   */
  static disableChannel = async (id, channels, disableDate) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/channelSet/disable`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { channels, disableDate }
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   * @param {string[]} channels
   */
  static removeChannel = async (id, channels) => {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/project/${id}/channelSet/remove`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { channels }
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   */
  static removeAll = async (id) => {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/project/${id}/channelSet/all`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    return request(options);
  }


  /**
   *
   * @param {string} id
   * @param {string} refId
   */
  static ref = async (id, refProjectId) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/channelSet/ref`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data: { refProjectId }
    };
    return request(options);
  }


  /**
   * get my own channels
   * @param {string} projectId
   * @param {Object} data
   * @param {'default'|'halfYearAsc'|'halfYearDesc'|null} data.sort
   * @param {string} data.country
   * @param {string} data.mediaType
   * @param {boolean} data.isValid
   * @param {boolean} data.isKol
   * @param {string} data.keyword
   * @param {string} data.topTag
   * @param {string} data.middleTag
   * @param {string} data.subTag
   * @param {string} data.startDate date time
   * @param {string} data.endDate date time
   * @param {Object[]} data.officialTags
   * @param {string} data.officialTags.attr
   * @param {string} data.officialTags.subAttr
   * @param {string[]} data.officialTags.themes
   * @param {string[]} data.officialTags.subThemes
   * @param {Object[]} data.audienceTags
   * @param {string} data.audienceTags.attr
   * @param {string} data.audienceTags.subAttr
   * @param {string[]} data.audienceTags.themes
   * @param {string[]} data.audienceTags.subThemes
   */
  static getChannelOfProject = async (projectId, data) => {
    const { officialTags, audienceTags, ...params } = data || {};
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${projectId}/channelSet`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params,
      data: {
        officialTags,
        audienceTags
      }
    };
    const res = await request(options);
    const { result, anchor } = res.data;

    return {
      channels: result,
      anchor
    };
  }


  /**
   * get channels stats
   */
  static getStats = async (projectId) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${projectId}/channelSet/stats`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };

    const res = await request(options);

    const mediaTypes = Object.keys(CHANNEL_SOURCE);

    const result = mediaTypes.map((mediaType) => {
      const target = res.data.result.find((item) => item.mediaType === mediaType);
      return ({
        mediaType,
        channelCount: target?.channelCount ?? 0,
        websiteCount: target?.websiteCount ?? 0
      });
    });

    return result;
  }


  /**
   * get all selectable channels from backstage
   * @param {Object} data
   * @param {number} data.limit
   * @param {string} data.anchor
   * @param {'default'|'halfYearAsc'|'halfYearDesc'|null} data.sort
   *
   * @param {string} data.country
   * @param {string} data.mediaType
   * @param {boolean} data.isValid
   * @param {boolean} data.isKol
   * @param {string} data.keyword
   * @param {string} data.startDate date time
   * @param {string} data.endDate date time
   * @param {Object[]} data.officialTags
   * @param {string} data.officialTags.attr
   * @param {string} data.officialTags.subAttr
   * @param {string[]} data.officialTags.themes
   * @param {string[]} data.officialTags.subThemes
   * @param {Object[]} data.audienceTags
   * @param {string} data.audienceTags.attr
   * @param {string} data.audienceTags.subAttr
   * @param {string[]} data.audienceTags.themes
   * @param {string[]} data.audienceTags.subThemes
   */
  static queryChannels = async (data) => {
    const {
      limit,
      anchor,
      sort,
      country,
      mediaType,
      isValid,
      isKol,
      keyword,
      startDate,
      endDate,
      officialTags,
      audienceTags
    } = data;

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/channel`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        limit,
        anchor,
        sort,
        country,
        mediaType,
        isValid,
        isKol,
        keyword,
        startDate,
        endDate
      },
      data: {
        officialTags,
        audienceTags
      }
    };
    const res = await request(options);

    return {
      channels: res.data.result.results,
      anchor: res.data.result.anchor
    };
  }


  /**
   * get channels by ids from backstage
   * @param {Object} data
   * @param {number} data.limit
   * @param {'default'|'halfYearAsc'|'halfYearDesc'|null} data.sort
   *
   * @param {string} data.anchor
   * @param {string} data.country
   * @param {string} data.mediaType
   * @param {boolean} data.isValid
   * @param {boolean} data.isKol
   * @param {string} data.keyword
   * @param {string} data.startDate date time
   * @param {string} data.endDate date time
   * @param {string[]} data.ids
   */
  static queryChannelsByIds = async (data) => {
    const {
      limit,
      anchor,
      sort,
      country,
      mediaType,
      isValid,
      isKol,
      keyword,
      startDate,
      endDate,
      ids
    } = data;

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/channel/ids`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        limit,
        anchor,
        sort,
        country,
        mediaType,
        isValid,
        isKol,
        keyword,
        startDate,
        endDate
      },
      data: {
        ids
      }
    };
    const res = await request(options);

    return { list: res.data.result.results, anchor: res.data.result.anchor };
  }


  static getCountry = async () => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/channel/country`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    const { result } = res.data;

    return result;
  }


  /**
   * get tip
   */
  static getTip = async (id) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/channelSet/tip`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);
    return res.data.result;
  }


  static export = async (id, projectName) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/channelSet/export`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      }
    };
    const res = await request(options);

    downloadFile(res.data.result, `${projectName}頻道組合`);
  }


  /**
   * 專案是否有停用狀況
   *
   * @param {string} id
   * @param {string} endDate date time
   *
   * @return {boolean}
   */
  static hasDisableItem = async (id, endDate) => {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/channelSet/disableCount`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: { endDate }
    };
    const res = await request(options);
    return res.data.result.disableCount > 0;
  }

  /**
   *
   * @param {*} file Blob object
   * @returns parsed json
   */
  static readUploadedFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (evt) => {
        const txt = evt.target.result;

        const csv = await new Promise(((_resolve, _reject) => {
          Papa.parse(txt, {
            ...Config.csv.import,
            header: true,
            complete: (results) => {
              _resolve(results.data);
            },
            error: (e) => {
              _reject(e);
            }
          });
        }));

        const ids = [];
        const checkForMongoId = new RegExp('^[0-9a-fA-F]{24}$');

        csv.forEach((item) => {
          const id = item.channelId;
          if (id === '') {
            // ignore
          } else if (checkForMongoId.test(id)) {
            ids.push(id);
          } else {
            reject(new Error('偵測到不合法的頻道ID'));
          }
        });

        resolve(ids);
      };

      reader.readAsText(file);

    });
  }

}
