import React, { useState, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Row, Button, Col, Typography, Drawer, Spin, Checkbox } from 'antd';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import SortButton from 'src/components/SortButton';
import DrawerViewModel from 'src/viewModels/drawer';
import CustomToolTip from 'src/components/DisableToolTip';
import ActionBar from 'src/components/search/L1ActionBar';
import SearchMeta from 'src/components/search/SearchMeta';
import CreateItem from '../CreateItem';
import { TEXT } from '../constants';

import styles from './styles.module.css';


const L1Pane = observer((props) => {
  const [columns, setColumns] = useState(null);
  const [createDrawerVM] = useState(() => new DrawerViewModel());


  useEffect(() => {
    props.vm.L1.query();
  }, [props.vm]);

  useEffect(() => {
    setColumns([
      {
        title: (
          <Observer>
            {() => (
              <Row justify="center">
                <Checkbox
                  checked={props.vm.L1.isSelecteAll}
                  onChange={props.vm.L1.selectAll}
                />
              </Row>
            )}
          </Observer>
        ),
        width: 50,
        key: 'checkbox',
        render: (data) => (
          <Observer>
            {() => (
              <Checkbox
                checked={data.isSelected}
                onChange={() => data.toggleIsSelected()}
              />
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">序號</Row>,
        width: 50,
        key: 'index',
        render: (data, __, i) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Row justify="center">{i + 1}</Row>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <SortButton
                title={`${TEXT[props.vm.searchAngle]?.L1 ?? ''}名稱`}
                order={props.vm.L1.order}
                onOrderChange={props.vm.L1.onOrderChange}
              />
            )}
          </Observer>
        ),
        width: 200,
        key: 'l1',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={{ text: data.id, tooltips: [data.id] }}
                  style={{ width: 180 }}
                  className={data.enable ? '' : styles.disable}
                  ellipsis={{ rows: 2 }}
                >
                  {data.name || '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${TEXT[props.vm.searchAngle]?.L1 ?? ''}搜尋關鍵字`}
          </Row>
        ),
        key: 'keyword',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt}>{data.keyword || '-'}</div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">同義詞</Row>,
        key: 'synonym',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt}>{data.synonym || '-'}</div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">執行動作</Row>,
        width: 150,
        key: 'action',
        render: (data) => {
          if (data.enable) {
            return (
              <Button
                type="link"
                className={styles.noPadding}
                onClick={() => props.vm.editDrawer.show(data)}
              >
                檢視與編輯
              </Button>
            );
          }
          return (
            <Button
              danger
              type="link"
              className={styles.noPadding}
              onClick={() => props.vm.L1.delete(data)}
            >
              刪除
            </Button>
          );
        }
      }
    ]);
  }, [props.vm, props.vm.L1, props.vm.searchAngle]);

  return (
    <>
      <Row className={styles.marginBottom}>
        <Col flex={1} className={styles.marginRight}>
          <SearchMeta
            editable
            refreshStamp={props.vm.refreshStamp}
            title={TEXT[props.vm.searchAngle]?.L1 ?? ''}
          />
        </Col>

        <Col>
          <Row>
            <Button
              danger
              className={styles.marginRight}
              onClick={() => props.vm.L1.delete()}
            >
              {`刪除${TEXT[props.vm.searchAngle]?.L1 ?? ''}`}
            </Button>
            <Button
              danger
              className={styles.marginRight}
              onClick={() => props.vm.L1.disable()}
            >
              {`永久停用${TEXT[props.vm.searchAngle]?.L1 ?? ''}`}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                props.vm.L1.addNewKeyword();
                createDrawerVM.show();
              }}
            >
              {`＋新增${TEXT[props.vm.searchAngle]?.L1 ?? ''}`}
            </Button>
          </Row>
        </Col>
      </Row>


      <ActionBar
        onQuery={props.vm.L1.onQuery}
        mode="myProject"
      />

      {columns && (
      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <Spin
                tip="正在加載資料"
                spinning={props.vm.L1.isTableLoading}
              >
                <div
                  style={{ height: 'calc(100vh - 355px)' }}
                  className={styles.tableContainer}
                >
                  <Table
                    dataSource={props.vm.L1.keywords}
                    rowKey="id"
                    size="small"
                    rowClassName={(record) => {
                      if (record.enable) return '';
                      return styles.disable;
                    }}
                    columns={columns}
                    onFetch={props.vm.L1.onBottom}
                    scroll={{ y: height }}
                  />
                </div>
              </Spin>
            )}
          </Observer>
        )}
      </ReactResizeDetector>
      )}


      <Drawer
        title="新增搜尋關鍵字"
        width={600}
        onClose={() => {
          props.vm.L1.resetNewKeyword();
          createDrawerVM.hide();
        }}
        visible={createDrawerVM.visible}
        maskClosable={false}
        footer={(
          <Button
            type="primary"
            disabled={!props.vm.L1.isOkToCreate}
            loading={props.vm.L1.isCreating}
            onClick={async () => {
              await props.vm.L1.create();
              props.vm.L1.resetNewKeyword();
              createDrawerVM.hide();
            }}
          >
            新增
          </Button>
        )}
        footerStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        {props.vm.L1.newKeywords.map((k, index) => (
          <CreateItem
            key={k.id}
            onDelete={() => props.vm.L1.deleteNewKeyword(index)}
            vm={k}
          />
        ))}
        <Row justify="center">
          <Button
            type="primary"
            onClick={props.vm.L1.addNewKeyword}
          >
            新增下一筆
          </Button>
        </Row>
      </Drawer>


    </>
  );
});

L1Pane.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired
};

export default L1Pane;
