import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Col, Drawer, Input, Row, Spin, Tabs, Tag } from 'antd';

import ImportModal from 'src/components/modal/ImportModal';
import RootModal from 'src/components/modal/RootModal';
import ModalViewModel from 'src/viewModels/modal';
import SubTabsViewModel from 'src/viewModels/ProjectSettings/Feature/SubTabsViewModel';
import ClearAllModal from 'src/components/modal/ClearAllModal';
import CategoryTable from 'src/components/feature/CategoryTable';
import KeywordTable from 'src/components/feature/KeywordTable';
import KeywordModal from 'src/components/feature/KeywordModal';
import RefDrawer from 'src/components/RefDrawer';
import AddCategoryModal from './components/AddCategoryModal';
import ImportDrawer from './components/ImportDrawer';

import styles from './styles.module.css';

@observer
class SubTabs extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new SubTabsViewModel({
      feature: this.props.feature,
      onUpdateInfo: this.props.onUpdateInfo,
      onDeleteFeatureFile: this.props.onDeleteFeatureFile
    });
    this.addCategoryVM = new ModalViewModel();
    this.keywordVM = new ModalViewModel();
    this.importVM = new ModalViewModel();
    this.refDrawerVM = new ModalViewModel();
  }

  componentDidMount() {
    this.vm.queryFeatureDetail();
  }

  renderInfoBox = () => (
    <div className={styles.border}>
      <Row justify="space-between" align="middle" className={styles.borderBottom}>
        <div />
        <div>特性關鍵字基本資料</div>
        {this.vm.isEdit ? (
          <Button type="link" onClick={this.vm.onSave}>儲存</Button>
        ) : (
          <Button type="link" onClick={this.vm.onEdit}>編輯</Button>
        )}
      </Row>
      <div className={styles.padding}>
        <Row justify="space-between" className={styles.marginBotttom}>
          <Col flex={1}>
            <Row align="middle" className={styles.row}>
              <div>檔案名稱：</div>
              {this.vm.isEdit ? (
                <Input
                  value={this.vm.feature.name}
                  style={{ width: 'calc(100% - 80px)' }}
                  onChange={this.vm.onNameChange}
                />
              ) : (
                <div>{this.vm.feature.name}</div>
              )}
            </Row>
          </Col>
          <Col>
            <Row align="middle" className={styles.row}>
              <div>檔案群組階層數：</div>
              <div>{this.vm.feature.level - 1}</div>
            </Row>
          </Col>
        </Row>
        <Row align="middle" className={styles.row}>
          <div>備註：</div>
          {this.vm.isEdit ? (
            <Input
              value={this.vm.feature.note}
              style={{ width: 'calc(100% - 50px)' }}
              onChange={this.vm.onNoteChange}
            />
          ) : (
            <div>{this.vm.feature.note}</div>
          )}
        </Row>
      </div>
    </div>
  )


  tabBarExtra = () => {
    const deleteBtn = (
      <Button
        danger
        type="primary"
        onClick={() => this.vm.toggleDeleteModal(true)}
        className={styles.marginLeft}
      >
        刪除檔案
      </Button>
    );

    if (!this.vm.canRef) {
      return (
        <>
          {this.vm.feature.refProject && (
            <Tag color="red">
              {`引用 ${this.vm.feature.refProject?.meta?.name || this.vm.feature.refProject} 公版專案特性關鍵字`}
            </Tag>
          )}
          <Button
            danger
            type="primary"
            onClick={() => this.vm.toggleClearAllModal(true)}
          >
            清空現有設定
          </Button>
          {deleteBtn}
        </>
      );
    }

    return (
      <>
        <Button
          type="primary"
          onClick={() => this.refDrawerVM.show()}
        >
          引用其他特性關鍵字
        </Button>
        <Button
          type="primary"
          onClick={() => this.importVM.show()}
          className={styles.marginLeft}
        >
          Excel 大量匯入
        </Button>
        {deleteBtn}
      </>
    );
  }

  render() {
    return (
      <>
        <Tabs
          tabBarExtraContent={this.tabBarExtra()}
          onTabClick={this.vm.resetFilter}
        >
          <Tabs.TabPane key={1} tab="群組與子群組">
            {this.renderInfoBox()}

            <Row justify="end" style={{ margin: '10px 0' }}>
              <Button
                danger
                onClick={this.vm.deleteCategory}
              >
                刪除已勾選群組
              </Button>
              <Button
                type="primary"
                onClick={this.addCategoryVM.show}
                className={styles.marginLeft}
              >
                新增群組
              </Button>
            </Row>
            <CategoryTable
              vm={this.vm}
              scrollY="calc(100vh - 430px)"
            />
          </Tabs.TabPane>

          <Tabs.TabPane key={2} tab="關鍵字與語法">
            <KeywordTable
              vm={this.vm}
              showModal={this.keywordVM.show}
            />
          </Tabs.TabPane>
        </Tabs>


        <AddCategoryModal
          visible={this.addCategoryVM.visible}
          hide={this.addCategoryVM.hide}
          action={this.vm.createCategory}
        />


        <KeywordModal
          visible={this.keywordVM.visible}
          config={this.keywordVM.config}
          hide={this.keywordVM.hide}
          onCreate={this.vm.createKeyword}
          onUpdate={this.vm.updateKeyword}
        />

        <ClearAllModal
          visible={this.vm.clearAllModalVisible}
          onOk={this.vm.clearFeature}
          onCancel={() => this.vm.toggleClearAllModal(false)}
          projectName={this.props?.projectMetaData?.meta?.name ?? ''}
          text="清空此特性檔案目前儲存的設定之外，也將刪除專案中這些特性群組/特性關鍵字相關的口碑結果，處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。"
        />

        <ClearAllModal
          actionTxt="刪除"
          visible={this.vm.deleteModalVisible}
          onOk={this.vm.deleteFeature}
          onCancel={() => this.vm.toggleDeleteModal(false)}
          projectName={this.props?.projectMetaData?.meta?.name ?? ''}
          text="刪除此特性檔案目前儲存的設定之外，也將刪除專案中這些特性群組/特性關鍵字相關的口碑結果，處理時間視牽涉的專案資料數量多寡而定，可能無法立即完成。"
        />

        <ImportModal
          onUpload={this.vm.onUpload}
          visible={this.importVM.visible}
          hide={this.importVM.hide}
          type={`feature${this.vm.feature.level - 1}`}
        />

        <RefDrawer
          visible={this.refDrawerVM.visible}
          onClose={this.refDrawerVM.hide}
          onRef={this.vm.onRef}
          type="feature"
          featureLevel={this.vm.feature.level}
        />


        <Drawer
          title="匯入特性關鍵字"
          width="95%"
          placement="right"
          onClose={this.vm.closeImportDrawer}
          visible={this.vm.importDrawer}
          zIndex={100}
          maskClosable={false}
          footer={(
            <Button
              type="primary"
              onClick={this.vm.onImport}
              disabled={!this.vm.importDrawer?.canExport || this.vm.isProcessingData}
            >
              確定匯入
            </Button>
          )}
        >
          {this.vm.importDrawer && (<ImportDrawer vm={this.vm.importDrawer} />)}
        </Drawer>

        {this.vm.isProcessingData && (
          <RootModal>
            <div className={styles.modal}>
              <Spin
                spinning
                tip="正在處理資料"
              />
            </div>
          </RootModal>
        )}
      </>
    );
  }
}

SubTabs.propTypes = {
  feature: PropTypes.shape({
    name: PropTypes.string,
    note: PropTypes.string,
    level: PropTypes.number
  }).isRequired,
  onUpdateInfo: PropTypes.func.isRequired,
  onDeleteFeatureFile: PropTypes.func.isRequired,
  projectMetaData: PropTypes.object
};

SubTabs.defaultProps = {
  projectMetaData: {}
};

export default SubTabs;
