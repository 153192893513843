import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Button, DatePicker, Typography } from 'antd';

import ProjectChangeLogVM from 'src/viewModels/ProjectChangeLog';
import ModalViewModel from 'src/viewModels/modal';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import ProjectHeader from 'src/components/project/ProjectHeader';
import { summaryColumns } from 'src/components/changeLog/summaryColumns';
import { logColumns } from 'src/components/changeLog/logCloumns';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import ChangeLogNoteModal from 'src/components/changeLog/ChangeLogNoteModal';
import ActionBar from 'src/components/changeLog/ActionBar';

import styles from './styles.module.css';

@observer
class RefNoticePage extends React.Component {

  constructor(props) {
    super(props);

    const projectId = props.router.params.id;
    this.othersRefMe = new ProjectChangeLogVM(projectId, 'refMe');
    this.meRefOthers = new ProjectChangeLogVM(projectId, 'myRef');
    this.modal = new ModalViewModel();
  }

  componentDidMount() {
    this.othersRefMe.didMount();
    this.meRefOthers.didMount();
    this.columns = [
      ...logColumns,
      {
        title: (<Row justify="center">備註</Row>),
        key: 'note',
        width: 250,
        render: (data) => (
          <Observer>
            {() => (
              <Typography.Paragraph
                style={{ width: '100%' }}
                ellipsis={{
                  rows: 3,
                  tooltip: data.note
                }}
              >
                {data.note ?? '-'}
              </Typography.Paragraph>
            )}
          </Observer>
        )
      },
      {
        title: (<Row justify="center">備註操作</Row>),
        key: 'note',
        width: 120,
        render: (data) => (
          <Row justify="center">
            <Button
              onClick={() => this.modal.show(data)}
              type="link"
            >
              {data.note ? '編輯' : '新增'}
            </Button>
          </Row>
        )
      },
      {
        title: (<Row justify="center">維護人</Row>),
        key: 'userName',
        width: 200,
        render: (data) => (<Row justify="center" className={styles.txt}>{data.userName ?? '-'}</Row>)
      },
      {
        title: (<Row justify="center">專案ID</Row>),
        key: 'userName',
        width: 110,
        render: (data) => (
          <Row justify="center">
            <Typography.Paragraph
              copyable={{ text: data.targetProjectId, tooltips: [data.targetProjectId] }}
            />
          </Row>
        )
      },
      {
        title: (<Row justify="center">專案名稱</Row>),
        key: 'projectName',
        width: 300,
        render: (data) => (<Row justify="center" className={styles.txt}>{data.projectName ?? '-'}</Row>)
      }
    ];
  }


  render() {
    return (
      <div style={{ height: '100%', overflowY: 'hidden' }}>
        <ProjectHeader />

        <Tabs defaultActiveKey="1" size="small">

          <Tabs.TabPane tab="別人引用我的專案" key="1">
            <Tabs size="small">

              <Tabs.TabPane tab="近期總覽" key="1-1">

                <Row align="middle" className={styles.marginBottom}>
                  <div className={styles.marginRight}>異動日期篩選:</div>
                  <DatePicker.RangePicker
                    value={[this.othersRefMe.summaryStartDate, this.othersRefMe.summaryEndDate]}
                    allowEmpty={[true, true]}
                    onChange={this.othersRefMe.onDateChange}
                  />
                </Row>

                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 215px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={() => {}}
                            columns={summaryColumns}
                            scroll={{ y: height }}
                            dataSource={this.othersRefMe.summary}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>

              <Tabs.TabPane tab="頻道組合" key="1-2">
                <ActionBar
                  onQuery={this.othersRefMe.channelSet.onSearch}
                  placeholder="搜尋(異動紀錄ID 、專案ID、頻道ID、頻道名稱、備註)"
                />
                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={this.othersRefMe.channelSet.onBottom}
                            columns={this.columns}
                            scroll={{ y: height, x: 2620 }}
                            dataSource={this.othersRefMe.channelSet.list}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>

              <Tabs.TabPane tab="搜尋關鍵字" key="1-3">
                <ActionBar
                  onQuery={this.othersRefMe.searchSet.onSearch}
                  placeholder="搜尋(異動紀錄ID、備註、專案ID、項目名稱、名稱UID)"
                />
                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={this.othersRefMe.searchSet.onBottom}
                            columns={this.columns}
                            scroll={{ y: height, x: 2620 }}
                            dataSource={this.othersRefMe.searchSet.list}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>

              <Tabs.TabPane tab="特性關鍵字" key="1-4">
                <ActionBar
                  onQuery={this.othersRefMe.featureSet.onSearch}
                  placeholder="搜尋(異動紀錄ID、檔案名稱ID、項目ID、項目名稱、專案ID、備註)"
                />
                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={this.othersRefMe.featureSet.onBottom}
                            columns={this.columns}
                            scroll={{ y: height, x: 2620 }}
                            dataSource={this.othersRefMe.featureSet.list}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>
            </Tabs>
          </Tabs.TabPane>





          <Tabs.TabPane tab="我引用的專案" key="2">
            <Tabs size="small">
              <Tabs.TabPane tab="近期總覽" key="2-1">

                <Row align="middle" className={styles.marginBottom}>
                  <div className={styles.marginRight}>異動日期篩選:</div>
                  <DatePicker.RangePicker
                    value={[this.meRefOthers.summaryStartDate, this.meRefOthers.summaryEndDate]}
                    allowEmpty={[true, true]}
                    onChange={this.meRefOthers.onDateChange}
                  />
                </Row>

                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 215px)' }}>
                          <Table
                            size="small"
                            rowKey="date"
                            onFetch={() => {}}
                            columns={summaryColumns}
                            scroll={{ y: height }}
                            dataSource={this.meRefOthers.summary}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>

              <Tabs.TabPane tab="頻道組合" key="2-2">
                <ActionBar
                  onQuery={this.meRefOthers.channelSet.onSearch}
                  placeholder="搜尋(異動紀錄ID 、專案ID、頻道ID、頻道名稱、備註)"
                />
                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={this.meRefOthers.channelSet.onBottom}
                            columns={this.columns}
                            scroll={{ y: height, x: 2620 }}
                            dataSource={this.meRefOthers.channelSet.list}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>

              <Tabs.TabPane tab="搜尋關鍵字" key="2-3">
                <ActionBar
                  onQuery={this.meRefOthers.searchSet.onSearch}
                  placeholder="搜尋(異動紀錄ID、備註、專案ID、項目名稱、名稱UID)"
                />
                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={this.meRefOthers.searchSet.onBottom}
                            columns={this.columns}
                            scroll={{ y: height, x: 2620 }}
                            dataSource={this.meRefOthers.searchSet.list}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>

              <Tabs.TabPane tab="特性關鍵字" key="2-4">
                <ActionBar
                  onQuery={this.meRefOthers.featureSet.onSearch}
                  placeholder="搜尋(異動紀錄ID、檔案名稱ID、項目ID、項目名稱、專案ID、備註)"
                />
                <ReactResizeDetector handleHeight>
                  {({ height = 0 }) => (
                    <Observer>
                      {() => (
                        <div style={{ width: '100%', height: 'calc(100vh - 220px)' }}>
                          <Table
                            size="small"
                            rowKey="id"
                            onFetch={this.meRefOthers.featureSet.onBottom}
                            columns={this.columns}
                            scroll={{ y: height, x: 2620 }}
                            dataSource={this.meRefOthers.featureSet.list}
                          />
                        </div>
                      )}
                    </Observer>
                  )}
                </ReactResizeDetector>
              </Tabs.TabPane>
            </Tabs>
          </Tabs.TabPane>
        </Tabs>

        <ChangeLogNoteModal
          modal={this.modal}
        />

      </div>
    );
  }
}

RefNoticePage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withProfile(withRouter(RefNoticePage), true);
