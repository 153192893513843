import React from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Modal, Descriptions, Spin, Row, Button, Col, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import styles from './styles.module.css';

// 話題庫存頁面
@observer
class TopicModal extends React.Component {
  constructor() {
    super();
    this.columns = [
      {
        title: <Row justify="center">序號</Row>,
        key: 'index',
        width: 60,
        render: (data, _, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: <Row justify="center">評價</Row>,
        key: 'sentiment',
        width: 60,
        render: (data) => (
          <Row justify="center">
            {data.sentiment === 1 && (<div className={clsx([styles.dot, styles.positive])} />)}
            {data.sentiment === 0 && (<div className={clsx([styles.dot, styles.neutral])} />)}
            {data.sentiment === -1 && (<div className={clsx([styles.dot, styles.negative])} />)}
          </Row>
        )
      },
      {
        title: <Row justify="center">回文ID</Row>,
        key: 'author',
        width: 200,
        render: (data) => (
          <>
            {
            (!data.author || data.author === 'null')
              ? <div>-</div>
              : <div className={styles.txt}>{data.author}</div>
            }
          </>
        )
      },
      {
        title: <Row justify="center">回文內容</Row>,
        key: 'content',
        render: (data) => (<div className={styles.txt}>{data.content ?? '-'}</div>)
      },
      {
        title: <Row justify="center">回文時間</Row>,
        key: 'date',
        width: 180,
        render: (data) => (
          <div>
            {data.date ? dayjs(data.date).format('YYYY/MM/DD HH:mm:ss') : '-'}
          </div>
        )
      },
      {
        title: <Row justify="center">Vockey</Row>,
        key: 'Vockey',
        width: 80,
        render: (data) => (
          <Row justify="center">
            <Typography.Paragraph
              copyable={{
                text: data.vocKey,
                tooltips: [data.vocKey]
              }}
            />
          </Row>
        )
      }
    ];
  }
  render() {
    const { main, reply, isVisible, isLoading } = this.props.vm;

    return (
      <Modal
        visible={isVisible}
        footer={false}
        title="話題庫存頁面"
        width="85%"
        centered
        onCancel={this.props.hide}
      >
        <Row align="middle">
          <Col>
            <Typography.Paragraph
              copyable={{ text: main.vocKey, tooltips: [main.vocKey] }}
              style={{ fontSize: 20, height: 40 }}
            />
          </Col>
          <Col style={{ width: 'calc(100% - 135px)' }}>
            <h3 className={styles.txt} style={{ WebkitLineClamp: 2 }}>
              {main.title}
            </h3>
          </Col>
          <Col>
            <Button
              type="link"
              href={main.url}
              target="_blank"
            >
              原文URL
              <LinkOutlined />
            </Button>
          </Col>
        </Row>

        <Descriptions
          bordered
          size="small"
          contentStyle={{ flex: 1 }}
        >
          <Descriptions.Item label="主文評價">
            {main.sentiment === 1 && (
              <Row align="middle">
                <div className={clsx([styles.dot, styles.positive])} />
                正面
              </Row>
            )}
            {main.sentiment === 0 && (
              <Row align="middle">
                <div className={clsx([styles.dot, styles.neutral])} />
                中性
              </Row>
            )}
            {main.sentiment === -1 && (
              <Row align="middle">
                <div className={clsx([styles.dot, styles.negative])} />
                負面
              </Row>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="討論串口碑數">{main.womCount}</Descriptions.Item>
          <Descriptions.Item label="最新總回文">{main.replyCount}</Descriptions.Item>
          <Descriptions.Item label="頻道來源">{main.channelDisplayName}</Descriptions.Item>
          <Descriptions.Item label="發文ID">{main.author}</Descriptions.Item>
          <Descriptions.Item label="發文日期">{dayjs(main.date).format('YYYY/MM/DD HH:mm:ss')}</Descriptions.Item>
          <Descriptions.Item label="累計留言數">{main.commentCount}</Descriptions.Item>
          <Descriptions.Item label="累計按讚數">{main.likeCount}</Descriptions.Item>
          <Descriptions.Item label="累計分享數">{main.shareCount}</Descriptions.Item>
        </Descriptions>

        <div className={styles.box}>
          {main.content}
        </div>

        <Descriptions
          bordered
          size="small"
          column={4}
          className={styles.mb}
          contentStyle={{ minWidth: 65 }}
        >
          <Descriptions.Item label="口碑回文">{main.womReplyCount}</Descriptions.Item>
          <Descriptions.Item
            label={(
              <Row align="middle">
                <div className={clsx([styles.dot, styles.positive])} />
                正面
              </Row>
            )}
          >
            {main.positiveCount}
          </Descriptions.Item>
          <Descriptions.Item
            label={(
              <Row align="middle">
                <div className={clsx([styles.dot, styles.neutral])} />
                中性
              </Row>
            )}
          >
            {main.neutralCount}

          </Descriptions.Item>
          <Descriptions.Item
            label={(
              <Row align="middle">
                <div className={clsx([styles.dot, styles.negative])} />
                負面
              </Row>
            )}
          >
            {main.negativeCount}

          </Descriptions.Item>
        </Descriptions>


        <ReactResizeDetector handleHeight>
          {({ height = 0 }) => (
            <div
              style={{ height: 'calc(100vh - 200px)' }}
            >
              <Observer>
                {() => (
                  <Spin
                    tip="正在加載資料"
                    spinning={isLoading}
                  >
                    <Table
                      size="small"
                      rowKey="vocKey"
                      onFetch={this.props.onBottom}
                      columns={this.columns}
                      scroll={{ y: height }}
                      dataSource={reply}
                      lineCount={3}
                    />
                  </Spin>
                )}

              </Observer>
            </div>
          )}
        </ReactResizeDetector>


      </Modal>
    );
  }
}

TopicModal.propTypes = {
  vm: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  onBottom: PropTypes.func.isRequired
};

export default TopicModal;
