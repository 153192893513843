import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { message } from 'antd';
import SearchSetService from 'src/services/searchSet';

export default class TagPane {

  searchVM = null;

  @observable tags = [];
  @observable selectedTagKey = [];


  constructor(searchVM) {
    makeObservable(this);
    this.searchVM = searchVM;
  }

  refresh = async () => {
    if (!this.searchVM.id) return;
    this.getAllTags();
  }

  getAllTags = async () => {
    try {
      const res = await SearchSetService.getAllTags(this.searchVM.id, { scope: 'all' });

      const map = new Map();
      const tags = [];

      res.forEach((r) => !r.parentId && map.set(r.id, r));
      res.forEach((r) => r.parentId && map.get(r.parentId).pushSubTags(r));
      map.forEach((value, key) => tags.push(value));

      runInAction(() => {
        this.tags = tags;
        res.forEach((t) => { this.searchVM.tagPool.set(t.id, t); });
      });
    } catch (err) {
      message.err('發生錯誤，無法取得資訊');
    }
  }
  createTag = async (name, scope) => {
    try {
      await SearchSetService.createTag(this.searchVM.id, name, scope);
      this.searchVM.refreshAll();
    } catch (err) {
      if (err.response.status === 409) {
        message.error('名稱重複，無法新增母標籤');
      } else {
        message.err('發生錯誤，無法新增母標籤');
      }
      throw err;
    }
  }
  updateTag = async (targetTag, name) => {
    try {
      await SearchSetService.updateTag(this.searchVM.id, targetTag.id, { name });
      targetTag.update(name);
    } catch (err) {
      if (err.response.status === 404) {
        message.error('找不到此標籤');
      } else if (err.response.status === 409) {
        message.error('名稱重複，無法更新母標籤');
      } else {
        message.err('發生錯誤，無法更新母標籤');
      }
      throw err;
    }
  }
  deleteTag = async () => {
    try {
      await Promise.all(
        this.selectedTagKey.map((tid) => SearchSetService.deleteTag(this.searchVM.id, tid))
      );
      this.searchVM.refreshAll();
      runInAction(() => { this.selectedTagKey = []; });
    } catch (err) {
      if (err.response.status === 404) {
        message.error('找不到此標籤');
      } else {
        message.err('發生錯誤，無法刪除母標籤');
      }
    } finally {
      // ignore for now
    }
  }
  createSubTag = async (tid, name) => {
    try {
      await SearchSetService.createSubTag(this.searchVM.id, tid, name);
      this.searchVM.refreshAll();
    } catch (err) {
      if (err.response.status === 409) {
        message.error('名稱重複，無法新增子標籤');
      } else {
        message.err('發生錯誤，無法新增子標籤');
      }
      throw err;
    }
  }
  updateSubTag = async (targetTag, name) => {
    try {
      await SearchSetService.updateSubTag(this.searchVM.id, targetTag.parentId, targetTag.id, { name });
      targetTag.update(name);
    } catch (err) {
      if (err.response.status === 404) {
        message.error('找不到此標籤');
      } else if (err.response.status === 409) {
        message.error('名稱重複，無法更新子標籤');
      } else {
        message.err('發生錯誤，無法更新子標籤');
      }
      throw err;
    }
  }
  deleteSubTag = async (tid, subTid) => {
    try {
      await SearchSetService.deleteSubTag(this.searchVM.id, tid, subTid);
      this.searchVM.refreshAll();
    } catch (err) {
      if (err.response.status === 404) {
        message.error('找不到此標籤');
      } else {
        message.err('發生錯誤，無法刪除子標籤');
      }
      throw err;
    }
  }
  @action
  onTagSelectChange = (selectedRowKeys) => {
    this.selectedTagKey = selectedRowKeys;
  }
}
