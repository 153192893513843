import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import { Modal } from 'antd';
import ProjectService from 'src/services/project';
import ProjectBackTrackService from 'src/services/projectBackTrack';
import DrawerViewModel from 'src/viewModels/drawer';
import { LIMIT, ORDER } from 'src/constants';
import { errorModal } from 'src/utils';

export default class MyProjectVM {
  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;
  @observable searchConditions = {
    order: ORDER.createDateDesc
  };

  constructor() {
    makeObservable(this);
    this.drawer = new DrawerViewModel();
  }

  @action
  didMount = () => {
    const JsonString = sessionStorage.getItem('formValues');
    const formValues = JsonString
      ? JSON.parse(JsonString)
      : { order: ORDER.createDateDesc };
    this.searchConditions = formValues;
    this.getList();
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { projects, anchor } = await ProjectService.queryMyProject({
        ...this.searchConditions,
        limit: LIMIT,
        anchor: this.anchor
      });

      runInAction(() => {
        this.list = [...this.list, ...projects];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      // TODO error message
    }
  }

  @action
  refresh = () => {
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this.refresh();
  }

  onBotton = () => {
    if (this.anchor) this.getList();
  }

  createProject = async (data) => {
    try {
      await ProjectService.createProject(data);
      this.refresh();

    } catch (err) {
      switch (err.response?.status) {
        case 409:
          errorModal(
            '專案名稱重複，無法新增',
            '與資料庫已有名稱重複，請重新輸入。'
          );
          break;

        default:
          errorModal('發生錯誤，無法更新');
      }
    }
  }

  deleteBacktrack = async (projectId) => {
    try {
      await ProjectBackTrackService.delete(projectId);
    } catch {
      // ignore
    }
  }

  deleteProject = async (projectId) => {
    // 取得當下資訊
    const project = await ProjectService.getProject(projectId);
    const name = project.meta.name;

    const isExternalRef = project.ref.externalReferencedCount > 0;
    const isRef = project.ref.referencedCount > 0;
    const isBacktracking = project.progress.beginAt && !project.progress.endAt;
    const cancellable = project.progress.cancellable;

    // 專案資料應用中
    if (isExternalRef) {
      return errorModal(
        '專案資料應用中，無法刪除。',
        '為應用端使用中的專案，請終止該服務後再執行刪除動作。'
      );
    }

    // 回溯中且無法中止回溯
    if (isBacktracking && !cancellable) {
      return errorModal(`已進入 ${project.progress.stageDesc} 階段，此分析無法中止，故無法刪除。`);
    }

    // 回溯中且可以中止回溯 或 無回溯，跳出確認彈窗

    let title;
    let content;

    // 專案有被引用
    if (isRef) {
      title = `${name}為引用專案即將刪除`;


      if (isBacktracking) {
        // 專案有被引用且回溯中
        content = `${name}為被引用之專案，刪除此引用專案後所有資料及引用關聯皆無法復原。目前此專案正在回溯中，是否要中止所有回溯程式並刪除？`;
      } else {
        // 專案有被引用且沒回溯
        content = `${name}為被引用之專案，刪除此引用專案後所有資料及引用關聯皆無法復原，確定刪除？`;
      }

    // 專案有沒被引用
    } else {
      title = `${name}專案即將刪除`;


      if (isBacktracking) {
        // 專案沒被引用且回溯中
        content = '專案正在回溯中，是否中止回溯並刪除？';
      } else {
        // 專案沒被引用且沒回溯
        content = '刪除專案後所有資料皆無法復原，確定刪除？';
      }
    }


    Modal.confirm({
      maskClosable: true,
      title,
      content,
      okText: '確認',
      cancelText: '取消',
      onOk: async () => {
        try {
          // 若在回溯中，先停止回溯
          if (isBacktracking) {
            await this.deleteBacktrack(projectId);
          }

          // 執行刪除
          await ProjectService.deleteProject(projectId);
          runInAction(() => {
            this.list = this.list.filter((p) => p._id !== projectId);
          });

          // 刪除成功彈窗
          Modal.success({
            title: '專案刪除成功',
            content: '此專案已中止所有回溯程式並刪除成功。',
            okText: '關閉'
          });

        } catch (err) {
          console.log(err);
          errorModal('專案刪除失敗，請稍後再試');
        }
      }
    });
    return null;
  }


}
