export default class FeatureCategory {
  constructor(params) {
    const {
      parentId,
      name,
      _id,
      featureSetId,
      count
    } = params;

    this.parentId = parentId;
    this.name = name;
    this.cid = _id;
    this.featureSetId = featureSetId;
    this.count = count;
  }

  static fromRes(data) {
    return new FeatureCategory(data);
  }
}
