import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Select, Switch, Button } from 'antd';

const ActionBar = observer((props) => {
  const [form] = Form.useForm();
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);

  useEffect(() => {
    const map = new Map();
    props.category.map((c) => map.set(c.cid, c.subCategory));
    setSubCategory(map);
  }, [props]);


  return (
    <Form
      form={form}
      style={{ border: '1px solid rgb(170, 170, 170)', padding: 10, marginBottom: 10 }}
    >
      <Row>
        <Col flex={1}>
          <div>自定義搜尋</div>
          <Form.Item name="keyword" style={{ marginBottom: 5, marginRight: 20 }}>
            <Input placeholder="輸入關鍵字 or 關鍵字ID or 語法設定" />
          </Form.Item>
        </Col>

        <Col>
          <div>資料篩選條件：</div>
          <Row>
            <Form.Item name="cid" style={{ marginBottom: 5, width: 250, marginRight: 10 }}>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="輸入或搜尋母群組名稱"
                showSearch
                optionFilterProp="children"
                onSelect={(cid) => {
                  setSubCategoryOption(subCategory.get(cid));
                  form.setFieldsValue({ subCid: null });
                }}
              >
                {props.category
                  .filter((c) => (props.level === 3) ? (c.subCategory.length !== 0) : true)
                  .map((c) => (
                    <Select.Option
                      key={c.cid}
                      value={c.cid}
                    >
                      {c.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item name="subCid" style={{ marginBottom: 5, width: 250, marginRight: 20 }}>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="輸入或子搜尋群組名稱"
                disabled={subCategoryOption.length === 0}
                showSearch
                optionFilterProp="children"
              >
                {subCategoryOption.map((sub) => (
                  <Select.Option
                    key={sub.id}
                    value={sub.id}
                  >
                    {sub.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
        </Col>

        {props.filterError && (
        <Col>
          <div>其他篩選條件</div>
          <Form.Item name="errorOnly" style={{ marginBottom: 5 }} valuePropName="checked">
            <Switch
              checkedChildren="只顯示錯誤內容"
              unCheckedChildren="顯示所有內容"
              style={{ width: 120 }}
            />
          </Form.Item>
        </Col>
        )}

        <Col style={{ minWidth: 200 }}>
          <div style={{ height: 22 }} />
          <Row justify="end" wrap={false}>
            <Row />
            <Button
              onClick={() => { form.resetFields(); }}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              onClick={() => props.onSearch(form.getFieldsValue())}
              style={{ marginLeft: 10 }}
            >
              套用
            </Button>
          </Row>
        </Col>
      </Row>

    </Form>
  );
});

ActionBar.propTypes = {
  level: PropTypes.number,
  category: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  filterError: PropTypes.bool
};

ActionBar.defaultProps = {
  level: 2,
  filterError: false
};

export default ActionBar;
