import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { AppStateContext } from 'src/stores';
import { MAINTENANCE_TIME } from 'src/constants';

import MainLayout from 'src/components/MainLayout';
import ProjectMain from './MyProjectPage/MainPage';
import ProjectSettings from './ProjectSettingsPage';
import ProjectAnalysis from './ProjectAnalysisPage';
import ProjectReports from './ProjectReportsPage';
import ProjectChangeLogPage from './ProjectChangeLogPage';
import ProjectRefNotice from './ProjectRefNoticePage';
import ProjectDeleted from './MyProjectPage/DeletedPage';
import AnalysisPage from './AnalysisPage';
import AllProjects from './AllProjectsPage';
import AllProjectsSettingsPage from './AllProjectsSettingsPage';
import SearchPage from './SearchPage';
import PermissionPage from './PremissionPage';
import LoginPage from './LoginPage';
import LoginSuccessPage from './LoginSuccessPage';
import NotFoundPage from './NotFoundPage';

import MaintenancePage from './MaintenancePage';

class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  render() {
    const now = dayjs().tz('Asia/Taipei').valueOf();
    return (
      <BrowserRouter basename="/">
        {
          now >= dayjs.tz(MAINTENANCE_TIME[0], 'Asia/Taipei').valueOf() && now <= dayjs.tz(MAINTENANCE_TIME[1], 'Asia/Taipei').valueOf()
            ? (
              <Routes>
                <Route path="/" element={<MaintenancePage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            )
            : (
              <Routes>
                <Route element={<MainLayout context={this.context} />}>
                  <Route path="/" element={<Navigate replace to="/project" />} />
                  <Route path="/project" element={<ProjectMain context={this.context} />} />
                  <Route path="/project/deleted" element={<ProjectDeleted />} />
                  <Route path="/project/settings/:id" element={<ProjectSettings />} />
                  <Route path="/project/analysis/:id" element={<ProjectAnalysis />} />
                  <Route path="/project/reports/:id" element={<ProjectReports />} />
                  <Route path="/project/records/:id" element={<ProjectChangeLogPage />} />
                  <Route path="/project/ref/:id" element={<ProjectRefNotice />} />
                  <Route path="/analysis" element={<AnalysisPage context={this.context} />} />
                  <Route path="/allprojects" element={<AllProjects context={this.context} />} />
                  <Route path="/allprojects/:id" element={<AllProjectsSettingsPage context={this.context} />} />
                  <Route path="/search" element={<SearchPage context={this.context} />} />
                  <Route path="/permission" element={<PermissionPage context={this.context} />} />
                </Route>

                <Route
                  path="/project/settings/:id/visitor"
                  element={(
                    <div style={{ padding: '10px 20px 0' }}>
                      <AllProjectsSettingsPage />
                    </div>
                  )}
                />

                <Route path="/login" element={<LoginPage context={this.context} />} />
                <Route path="/loginSuccess" element={<LoginSuccessPage context={this.context} />} />
                <Route path="*" element={<NotFoundPage context={this.context} />} />
              </Routes>
            )
        }

      </BrowserRouter>
    );
  }
}

export default PagesRoot;
