import { message } from 'antd';
import { makeObservable, runInAction } from 'mobx';
import ChannelSetService from 'src/services/channelSet';
import BaseChannelList from './BaseChannelList';
import ChannelItem from './ChannelItem';

export default class LocalChannelList extends BaseChannelList {

  candidateIds = [];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  getChannels = async () => {
    if (this.candidateIds.length === 0) return;

    try {
      runInAction(() => { this.isLodaing = true; });
      const { list } = await ChannelSetService.queryChannelsByIds({
        ...this.filterCondition,
        ids: this.candidateIds,
        sort: this.sort
      });
      const newChannels = list.map((c) => new ChannelItem(c, this));

      runInAction(() => {
        this._list = [...this._list, ...newChannels];
        this.isLodaing = false;
      });

    } catch (err) {
      runInAction(() => { this.isLodaing = false; });
    }
  }


  add = async (ids) => {
    try {
      // filtered repeated ids
      const currentIdSet = new Set(this.candidateIds);
      const _ids = ids.filter((id) => !currentIdSet.has(id));

      runInAction(() => {
        this.candidateIds = [...this.candidateIds, ..._ids];
        this.isLodaing = true;
      });

      if (_ids.length > 0) {
        const { list } = await ChannelSetService.queryChannelsByIds({ ids: _ids });
        const newChannels = list.map((c) => new ChannelItem(c, this));
        runInAction(() => {
          this._list = [...this._list, ...newChannels];
        });
      }

    } finally {
      runInAction(() => { this.isLodaing = false; });
    }
  }

  applyFilter = async (newCondition) => {
    runInAction(() => {
      this.filterCondition = newCondition;
      this._list = [];
    });
    await this.getChannels();
  }

  deleteChannels = () => {
    if (this.noSeletedIds) {
      return message.warning('請勾選資料項目');
    }

    runInAction(() => {
      this._list = this._list.filter((channel) => !this.selectedIdSet.has(channel.id));
      this.candidateIds = this.candidateIds.filter((id) => !this.selectedIdSet.has(id));

      this.selectedIdSet.clear();
    });
    return null;
  }


  reset = () => {
    runInAction(() => {
      this.anchor = null;
      this.filterCondition = null;
      this.isSelectAll = false;
      this.isLodaing = false;
      this._list = [];
      this.candidateIds = [];
    });
  }

}
