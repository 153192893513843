import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const NewTag = (props) => {
  const [value, setValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);

  const handleInputConfirm = async (e) => {
    if (e.keyCode === 229) return;

    if (value === '') {
      setInputVisible(false);
      return;
    }

    try {
      await props.action(value);
    } catch {
      // ignore
    } finally {
      setInputVisible(false);
      setValue('');
    }
  };

  return (
    <div style={{ display: 'inline-block' }}>
      {inputVisible && (
      <Input
        type="text"
        size="small"
        style={{ width: 150 }}
        value={value}
        autoFocus
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
      />
      )}
      {!inputVisible && (
      <Tag
        style={{ borderStyle: 'dashed', background: '#fff' }}
        onClick={() => setInputVisible(true)}
      >
        <PlusOutlined />
        {' '}
        New Tag
      </Tag>
      )}
    </div>
  );
};

NewTag.propTypes = {
  action: PropTypes.func.isRequired
};

export default NewTag;
