import { makeObservable, observable, action, runInAction } from 'mobx';
import SearchSetService from 'src/services/searchSet';
import FeatureSetService from 'src/services/featureSet';
import { errorModal } from 'src/utils';
import { message, Modal } from 'antd';

export default class Query {
  projectId = '';
  data = {}

  @observable currentLevel = 'L1';
  @observable keywordMap = new Map();
  @observable L1Keywords = [];
  @observable L2Keywords = [];
  @observable L3Keywords = [];
  @observable selectedL1Keyword = null;
  @observable selectedL2Keyword = null;
  @observable selectedL3Keyword = null;
  @observable keywordsForUI = [];
  @observable features = [];
  @observable featureCategories = {
    data: [],
    level: 2,
    currentFeatureFile: ''
  };

  constructor({ projectId, data }) {
    makeObservable(this);
    this.projectId = projectId;
    this.data = data;
  }


  getFeatures = async () => {
    try {
      const { features } = await FeatureSetService.queryFeatureSet(this.projectId);
      runInAction(() => {
        this.features = features;
      });
    } catch {
      // ignore
    }
  }

  getFeatureCategories = async (fid) => {
    try {
      const level = this.features.find((feature) => feature.fid === fid).level;

      const categories = await FeatureSetService.mention(
        this.projectId,
        fid,
        {
          keywords: this.data.keywords,
          startDate: this.data.startDate.startOf('day').toISOString(),
          finishDate: this.data.endDate.endOf('day').toISOString()
        }
      );

      runInAction(() => {
        this.featureCategories.data = categories;
        this.featureCategories.level = level;
        this.featureCategories.currentFeatureFile = fid;
      });

    } catch (err) {
      console.log(err);
      // ignore
    }
  }

  getL1SearchKeywords = async () => {
    try {
      const res = await SearchSetService.getAllKeyword(this.projectId, { level: 1 });
      runInAction(() => {
        this.L1Keywords = res;

        res.forEach((keyword) => {
          if (!this.keywordMap.has(keyword._id)) {
            this.keywordMap.set(keyword._id, keyword);
          }
        });
      });

    } catch {
      // ignore
    }
  }

  getL2SearchKeywords = async () => {
    if (!this.selectedL1Keyword) return;

    try {
      const res = await SearchSetService.getAllKeyword(this.projectId, {
        level: 2,
        sid: this.selectedL1Keyword
      });
      runInAction(() => {
        this.L2Keywords = res;

        res.forEach((keyword) => {
          if (!this.keywordMap.has(keyword._id)) {
            this.keywordMap.set(keyword._id, keyword);
          }
        });
      });

    } catch {
      // ignore
    }
  }

  getL3SearchKeywords = async () => {
    if (!this.selectedL1Keyword) return;

    try {
      const res = await SearchSetService.getAllKeyword(this.projectId, {
        level: 3,
        sid: this.selectedL1Keyword,
        subSid: this.selectedL2Keyword
      });
      runInAction(() => {
        this.L3Keywords = res;

        res.forEach((keyword) => {
          if (!this.keywordMap.has(keyword._id)) {
            this.keywordMap.set(keyword._id, keyword);
          }
        });
      });

    } catch {
      // ignore
    }
  }

  @action
  addKeyword = () => {
    if (this.data.keywords.length >= 10) {
      return message.warn('主題範圍最多只能選擇 10 項');
    }

    let level;
    let keyword;

    if (this.selectedL3Keyword) {
      level = 'L3';
      keyword = this.selectedL3Keyword;
    } else if (this.selectedL2Keyword) {
      level = 'L2';
      keyword = this.selectedL2Keyword;
    } else if (this.selectedL1Keyword) {
      level = 'L1';
      keyword = this.selectedL1Keyword;
    } else {
      return null;
    }

    if (this.data.keywords.includes(keyword)) return null;

    if (this.keywordsForUI.length === 0) {
      this.currentLevel = level;
    } else if (this.currentLevel !== level) {
      return message.warn('所選層級不符');
    }

    const target = this.keywordMap.get(keyword);

    this.data.keywords.push(keyword);
    this.keywordsForUI.push({
      id: target._id,
      sid: target.level === 1 ? target._id : target.sid,
      subSid: target.level === 2 ? target._id : target.subSid,
      sub2Sid: target.level === 3 ? target._id : target.sub2Sid,
      keyword: target.keyword
    });

    this.selectedL1Keyword = null;
    this.selectedL2Keyword = null;
    this.selectedL3Keyword = null;
    this.L2Keywords = [];
    this.L3Keywords = [];

    return null;
  }

  @action
  deleteKeyword = (index) => {
    const keywordId = this.keywordsForUI[index].id;
    this.data.keywords = this.data.keywords.filter((keyword) => keyword !== keywordId);
    this.keywordsForUI.splice(index, 1);
  }

  @action
  copyLastKeyword = () => {
    const length = this.keywordsForUI.length;
    if (length === 0) return;

    const lastKeyword = this.keywordsForUI[length - 1];
    this.selectedL1Keyword = lastKeyword.sid;
    this.selectedL2Keyword = lastKeyword.subSid;
    this.selectedL3Keyword = lastKeyword.sub2Sid;

    this.getL2SearchKeywords();
    this.getL3SearchKeywords();
  }

  @action
  resetKeywords = () => {
    Modal.confirm({
      title: '重新選擇層級將清除目前所選主題範圍。是否清除？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        runInAction(() => {
          this.resetData(false);
        });
      }
    });
  }

  @action
  resetData = (isResetFile = true) => {
    this.keywordsForUI = [];
    this.selectedL1Keyword = null;
    this.selectedL2Keyword = null;
    this.selectedL3Keyword = null;
    this.currentLevel = 'L1';
    this.data.keywords = [];
    this.L2Keywords = [];
    this.L3Keywords = [];

    if (isResetFile) {
      this.data.featureFile = null;
    }
  }

  @action
  resetFeatureCategories = () => {
    this.featureCategories = {
      data: [],
      level: 2,
      currentFeatureFile: ''
    };
  }

  @action
  onDateChange = (date) => {
    this.data.startDate = date?.[0] ?? null;
    this.data.endDate = date?.[1] ?? null;
  }

  @action
  onL1KeywordChange = (e) => {
    this.selectedL1Keyword = e;
    this.selectedL2Keyword = null;
    this.selectedL3Keyword = null;
    this.getL2SearchKeywords();
    this.getL3SearchKeywords();
  }

  @action
  onL2KeywordChange = (e) => {
    this.selectedL2Keyword = e;
    this.selectedL3Keyword = null;
    this.getL3SearchKeywords();

    const target = this.keywordMap.get(e);
    this.selectedL1Keyword = target.sid;
  }

  @action
  onL3KeywordChange = (e) => {
    this.selectedL3Keyword = e;

    const target = this.keywordMap.get(e);
    this.selectedL1Keyword = target.sid;
    this.selectedL2Keyword = target.subSid;
  }

  @action
  onFileChange = (e) => {
    this.data.featureFile = e;
  }
}
