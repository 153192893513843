import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Row } from 'antd';

// 專案全品牌及產品排行

@observer
class TopicRankTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: <Row justify="center">排行</Row>,
        key: 'index',
        width: 200,
        render: (data, _, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: <Row justify="center">{props.title}</Row>,
        key: 'name',
        render: (data) => (<div>{data.name}</div>)
      },
      {
        title: <Row justify="center">UID</Row>,
        key: 'sid',
        render: (data) => (<div>{data.sid}</div>)
      },
      {
        title: <Row justify="center">聲量</Row>,
        key: 'count',
        width: 200,
        render: (data) => (<Row justify="center">{data.count}</Row>)
      }
    ];
  }

  render() {
    return (
      <Table
        rowKey="sid"
        dataSource={this.props.data}
        columns={this.columns}
        size="small"
        pagination={false}
        scroll={{ y: 380 }}
      />
    );
  }
}

TopicRankTable.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default TopicRankTable;
