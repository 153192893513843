import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import { Button, Dropdown, Menu } from 'antd';
import FeatureSetService from 'src/services/featureSet';

@observer
export default class ItemDropDown extends React.Component {

  @observable featureFiles = [];

  constructor() {
    super();
    makeObservable(this);
  }

  getFeatureFiles = async () => {
    const { features } = await FeatureSetService.queryFeatureSet(this.props.projectId);
    runInAction(() => {
      this.featureFiles = (features.length > 0) ? features : [{ fid: '', name: '尚無資料' }];
    });
  }

  render() {
    return (
      <Dropdown
        arrow
        trigger={['click']}
        placement="bottomLeft"
        overlay={(
          <Menu
            items={this.featureFiles.map((f) => ({
              key: f.fid,
              label: (
                <Button
                  type="text"
                  onClick={() => {
                    this.props.onRef(this.props.projectId, f.fid);
                  }}
                  disabled={f.level !== this.props.featureLevel}
                >
                  {f.name}
                </Button>
              )
            }))}
          />
      )}
        destroyPopupOnHide
      >
        <Button type="primary" onClick={this.getFeatureFiles}>
          引用此特性關鍵字
        </Button>
      </Dropdown>
    );
  }

}

ItemDropDown.propTypes = {
  projectId: PropTypes.string.isRequired,
  featureLevel: PropTypes.number.isRequired,
  onRef: PropTypes.func.isRequired
};

