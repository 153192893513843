import { getHost, request } from 'src/utils';
import AuthService from './auth';

export default class ProjectReport {

  /**
   * 專案全品牌及產品排行
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string} data.level
   * @param {string} data.startDate
   * @param {string} data.finishDate
   */
  static async ranking(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/report/tops`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   * 主題口碑數每日折線圖
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string[]} data.keywords
   * @param {string} data.startDate
   * @param {string} data.finishDate
   */
  static async chart(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/report/chart`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   * 主題 TOP 100 話題
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string[]} data.keywords
   * @param {string} data.startDate
   * @param {string} data.finishDate
   */
  static async topTopics(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/report/topics/tops`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    const { result, searchId } = res.data;
    return { result, searchId };
  }


  /**
   * 庫存頁面（主文內容）
   *
   * @param {boolean} isFeature
   * @param {Object} data
   * @param {string} data.projectId // required
   * @param {string} data.mainId    // required
   * @param {string} data.searchId  // required
   */
  static async topicsMainContent(data, isFeature = false) {
    const { projectId, mainId, searchId } = data;

    let apiUrl;
    if (isFeature) {
      apiUrl = `${getHost()}/api/v1/project/${projectId}/featureReport/topics/${mainId}`;
    } else {
      apiUrl = `${getHost()}/api/v1/project/${projectId}/report/topics/${mainId}`;
    }

    const options = {
      method: 'get',
      url: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        search_id: searchId
      }
    };

    const res = await request(options);
    return res.data.result;
  }


  /**
   * 庫存頁面（回文）
   *
   * @param {boolean} isFeature
   * @param {Object} data
   * @param {string} data.projectId // required
   * @param {string} data.mainId    // required
   * @param {string} data.searchId  // required
   * @param {number} data.page      // required
   * @param {number} data.limit     // required
   */
  static async topicsReplyContent(data, isFeature = false) {
    const { projectId, mainId, searchId, page, limit } = data;

    let url;
    if (isFeature) {
      url = `${getHost()}/api/v1/project/${projectId}/featureReport/topics/${mainId}/replies`;
    } else {
      url = `${getHost()}/api/v1/project/${projectId}/report/topics/${mainId}/replies`;
    }

    const options = {
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        search_id: searchId,
        page,
        limit
      }
    };

    const res = await request(options);
    return res.data.result;
  }


  /**
   * Top 100 特性關鍵字
   *
   * @param {string} id  required
   * @param {string} data
   * @param {string} data.fid required
   * @param {string} data.cid
   * @param {string} data.subCid
   * @param {string[]} data.keywords
   * @param {string} data.startDate
   * @param {string} data.finishDate
   */
  static async topFeatures(id, data) {
    const { fid, cid, subCid, ...otherDate } = data;

    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureReport/topics/topsKeyword`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: { fid, cid, subCid },
      data: otherDate
    };
    const res = await request(options);
    return res.data.result;
  }


  /**
   * 特性關鍵字話題列表
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string[]} data.feature
   * @param {string} data.limit
   * @param {number} data.limit
   * @param {string} data.startDate
   * @param {string} data.finishDate
   */
  static async featureTopics(id, data) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/project/${id}/featureReport/topics/tops`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      data
    };
    const res = await request(options);
    const { result, searchId, featureCategory, featureSubCategory, featureKeyword } = res.data;
    return { result, searchId, featureCategory, featureSubCategory, featureKeyword };
  }


  /**
   * 特性關鍵字話題列表 with pagination
   *
   * @param {string} id  required
   * @param {Object} data
   * @param {string} data.searchId
   * @param {number} data.page
   * @param {number} data.limit
   */
  static async featureTopicsPaging(id, data) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/project/${id}/featureReport/topics/tops`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        authorization: `Bearer ${AuthService.getToken()}`
      },
      params: {
        ...data,
        search_id: data.searchId
      }
    };
    const res = await request(options);
    return res.data.result;
  }
}
