const media = [
  {
    title: '綜合新聞',
    value: '綜合新聞'
  },
  {
    title: '公共時事評論',
    value: '公共時事評論'
  },
  {
    title: '流行時尚',
    value: '流行時尚'
  },
  {
    title: '旅遊',
    value: '旅遊'
  },
  {
    title: '運動體育',
    value: '運動體育'
  },
  {
    title: '動漫',
    value: '動漫'
  },
  {
    title: '電玩遊戲',
    value: '電玩遊戲'
  },
  {
    title: '影劇娛樂',
    value: '影劇娛樂'
  },
  {
    title: '星座命理玄學占卜',
    value: '星座命理玄學占卜'
  },
  {
    title: '美食料理',
    value: '美食料理'
  },
  {
    title: '攝影',
    value: '攝影'
  },
  {
    title: '工藝',
    value: '工藝'
  },
  {
    title: '園藝',
    value: '園藝'
  },
  {
    title: '文藝',
    value: '文藝'
  },
  {
    title: '表演藝術',
    value: '表演藝術'
  },
  {
    title: '美容保養',
    value: '美容保養'
  },
  {
    title: '酒',
    value: '酒'
  },
  {
    title: '3C',
    value: '3C'
  },
  {
    title: '汽機車',
    value: '汽機車'
  },
  {
    title: '視聽家電',
    value: '視聽家電'
  },
  {
    title: '公益',
    value: '公益'
  },
  {
    title: '健康醫療',
    value: '健康醫療'
  },
  {
    title: '趣味搞笑',
    value: '趣味搞笑'
  },
  {
    title: '寵物',
    value: '寵物'
  },
  {
    title: '科技',
    value: '科技'
  },
  {
    title: '家事家居',
    value: '家事家居'
  },
  {
    title: '兩性婚姻',
    value: '兩性婚姻'
  },
  {
    title: '母嬰親子',
    value: '母嬰親子'
  },
  {
    title: '購物消費',
    value: '購物消費'
  },
  {
    title: '房產',
    value: '房產'
  },
  {
    title: '財經',
    value: '財經'
  },
  {
    title: '教育進修',
    value: '教育進修'
  },
  {
    title: '職場',
    value: '職場'
  },
  {
    title: '氣象',
    value: '氣象'
  },
  {
    title: '宗教',
    value: '宗教'
  },
  {
    title: '軍事國防',
    value: '軍事國防'
  },
  {
    title: '國際情勢',
    value: '國際情勢'
  },
  {
    title: '政治',
    value: '政治'
  },
  {
    title: '社會',
    value: '社會'
  },
  {
    title: '內容農場',
    value: '內容農場'
  },
  {
    title: '地方新聞',
    value: '地方新聞'
  }];

const policy = [
  {
    title: '政治',
    value: '政治',
    sub: [
      {
        title: '泛藍',
        value: '泛藍'
      },
      {
        title: '泛綠',
        value: '泛綠'
      },
      {
        title: '泛白',
        value: '泛白'
      },
      {
        title: '其他',
        value: '其他'
      }
    ]
  }
];

const audience = [
  {
    title: '流行時尚',
    value: '流行時尚'
  },
  {
    title: '旅遊',
    value: '旅遊'
  },
  {
    title: '運動體育',
    value: '運動體育'
  },
  {
    title: '動漫',
    value: '動漫'
  },
  {
    title: '電玩遊戲',
    value: '電玩遊戲'
  },
  {
    title: '益智遊戲',
    value: '益智遊戲'
  },
  {
    title: '影劇娛樂',
    value: '影劇娛樂'
  },
  {
    title: '星座命理玄學占卜',
    value: '星座命理玄學占卜'
  },
  {
    title: '美食料理',
    value: '美食料理'
  },
  {
    title: '烘焙',
    value: '烘焙'
  },
  {
    title: '攝影',
    value: '攝影'
  },
  {
    title: '工藝',
    value: '工藝'
  },
  {
    title: '園藝',
    value: '園藝'
  },
  {
    title: '文藝',
    value: '文藝'
  },
  {
    title: '表演藝術',
    value: '表演藝術'
  },
  {
    title: '美容保養',
    value: '美容保養'
  },
  {
    title: '酒',
    value: '酒'
  },
  {
    title: '茶與咖啡',
    value: '茶與咖啡'
  },
  {
    title: '3C產品',
    value: '3C產品'
  },
  {
    title: '汽機車',
    value: '汽機車'
  },
  {
    title: '視聽家電',
    value: '視聽家電'
  },
  {
    title: '精品收藏',
    value: '精品收藏'
  },
  {
    title: '公益',
    value: '公益'
  },
  {
    title: '健康醫療',
    value: '健康醫療'
  },
  {
    title: '趣味搞笑',
    value: '趣味搞笑'
  },
  {
    title: '寵物',
    value: '寵物'
  },
  {
    title: '設計',
    value: '設計'
  },
  {
    title: '科技',
    value: '科技'
  },
  {
    title: '宗教',
    value: '宗教'
  },
  {
    title: '家事家居',
    value: '家事家居'
  },
  {
    title: '感性心情',
    value: '感性心情'
  },
  {
    title: '兩性婚姻',
    value: '兩性婚姻'
  },
  {
    title: '母嬰親子',
    value: '母嬰親子'
  },
  {
    title: '購物消費',
    value: '購物消費'
  },
  {
    title: '政治社會',
    value: '政治社會'
  },
  {
    title: '房產',
    value: '房產'
  },
  {
    title: '財經',
    value: '財經'
  },
  {
    title: '教育進修',
    value: '教育進修'
  },
  {
    title: '職場',
    value: '職場'
  },
  {
    title: '地方新聞',
    value: '地方新聞'
  },
  {
    title: '閒聊類型',
    value: '閒聊類型'
  },
  {
    title: '氣象',
    value: '氣象'
  },
  {
    title: '鐵道運輸',
    value: '鐵道運輸'
  },
  {
    title: '軍事國防',
    value: '軍事國防'
  },
  {
    title: '國際情勢',
    value: '國際情勢'
  }
];

export const OfficialMedia = [
  /// /////////////////////////////////// level 1
  { title: '消費品牌自營頻道',
    value: '消費品牌自營頻道',
    sub: [
      /// /////////////////////////// level 2
      {
        title: 'null',
        value: 'null',
        sub: [
          {
            title: '母嬰',
            value: '母嬰'
          },
          {
            title: '民生用品',
            value: '民生用品'
          },
          {
            title: '投信投顧',
            value: '投信投顧'
          },
          {
            title: '金融',
            value: '金融'
          },
          {
            title: '信用卡',
            value: '信用卡'
          },
          {
            title: '保健產業',
            value: '保健產業'
          },
          {
            title: '酒類',
            value: '酒類'
          },
          {
            title: '家電',
            value: '家電'
          },
          {
            title: '汽車',
            value: '汽車'
          },
          {
            title: '機車',
            value: '機車'
          },
          {
            title: '香水',
            value: '香水'
          },
          {
            title: '美妝',
            value: '美妝'
          },
          {
            title: '鞋包配飾',
            value: '鞋包配飾'
          },
          {
            title: '文創設計',
            value: '文創設計'
          },
          {
            title: '運動健身用品',
            value: '運動健身用品'
          },
          {
            title: '電信網路',
            value: '電信網路'
          },
          {
            title: '物流',
            value: '物流'
          },
          {
            title: '寵物',
            value: '寵物'
          },
          {
            title: '食品飲料',
            value: '食品飲料'
          },
          {
            title: '3C產品',
            value: '3C產品'
          },
          {
            title: '家具家飾',
            value: '家具家飾'
          },
          {
            title: '串流媒體',
            value: '串流媒體'
          }
        ]
      }
    ]
  },
  /// /////////////////////////////////// level 1
  { title: '通路自營頻道',
    value: '通路自營頻道',
    sub: [
      /// /////////////////////////// level 2
      { title: '電商通路',
        value: '電商通路',
        sub: [
          {
            title: '母嬰',
            value: '母嬰'
          },
          {
            title: '民生用品',
            value: '民生用品'
          },
          {
            title: '保健產業',
            value: '保健產業'
          },
          {
            title: '酒類',
            value: '酒類'
          },
          {
            title: '家電',
            value: '家電'
          },
          {
            title: '汽車',
            value: '汽車'
          },
          {
            title: '機車',
            value: '機車'
          },
          {
            title: '香水',
            value: '香水'
          },
          {
            title: '美妝',
            value: '美妝'
          },
          {
            title: '鞋包配飾',
            value: '鞋包配飾'
          },
          {
            title: '寵物',
            value: '寵物'
          },
          {
            title: '食品飲料',
            value: '食品飲料'
          },
          {
            title: '3C產品',
            value: '3C產品'
          },
          {
            title: '家具家飾',
            value: '家具家飾'
          },
          {
            title: '運動健身用品',
            value: '運動健身用品'
          },
          {
            title: '綜合電商',
            value: '綜合電商'
          },
          {
            title: '書與文具',
            value: '書與文具'
          },
          {
            title: '訂房平台',
            value: '訂房平台'
          },
          {
            title: '線上租車仲介平台',
            value: '線上租車仲介平台'
          },
          {
            title: '網路售票活動社交平台',
            value: '網路售票活動社交平台'
          },
          {
            title: '房屋租售比價平台',
            value: '房屋租售比價平台'
          },
          {
            title: '人才求職平台',
            value: '人才求職平台'
          },
          {
            title: '線上學習平台',
            value: '線上學習平台'
          },
          {
            title: '外送服務平台',
            value: '外送服務平台'
          }
        ]
      },
      /// /////////////////////////// level 2
      { title: '實體通路',
        value: '實體通路',
        sub: [
          {
            title: '母嬰',
            value: '母嬰'
          },
          {
            title: '購物百貨零售',
            value: '購物百貨零售'
          },
          {
            title: '超市賣場',
            value: '超市賣場'
          },
          {
            title: '3C賣場',
            value: '3C賣場'
          },
          {
            title: '玩具電玩',
            value: '玩具電玩'
          },
          {
            title: '便利商店',
            value: '便利商店'
          },
          {
            title: '眼鏡行',
            value: '眼鏡行'
          },
          {
            title: '藥妝雜貨',
            value: '藥妝雜貨'
          },
          {
            title: '藥局',
            value: '藥局'
          },
          {
            title: '運動健身用品',
            value: '運動健身用品'
          },
          {
            title: '書店/出版社',
            value: '書店/出版社'
          },
          {
            title: '鞋包配飾',
            value: '鞋包配飾'
          },
          {
            title: '汽車',
            value: '汽車'
          },
          {
            title: '餐飲',
            value: '餐飲'
          },
          {
            title: '烘焙甜點',
            value: '烘焙甜點'
          },
          {
            title: '茶與咖啡',
            value: '茶與咖啡'
          },
          {
            title: '手搖飲料',
            value: '手搖飲料'
          },
          {
            title: '教育進修',
            value: '教育進修'
          },
          {
            title: '才藝教室',
            value: '才藝教室'
          },
          {
            title: '運動健身',
            value: '運動健身'
          },
          {
            title: '戶外活動通路',
            value: '戶外活動通路'
          },
          {
            title: '樂園景點',
            value: '樂園景點'
          },
          {
            title: '藝文活動通路',
            value: '藝文活動通路'
          },
          {
            title: '娛樂場館',
            value: '娛樂場館'
          },
          {
            title: '旅遊住宿',
            value: '旅遊住宿'
          },
          {
            title: '大眾運輸',
            value: '大眾運輸'
          },
          {
            title: '計程車',
            value: '計程車'
          },
          {
            title: '租車公司',
            value: '租車公司'
          },
          {
            title: '航空公司',
            value: '航空公司'
          },
          {
            title: '醫療保健',
            value: '醫療保健'
          },
          {
            title: '寵物',
            value: '寵物'
          },
          {
            title: '美容保養',
            value: '美容保養'
          },
          {
            title: '家具家飾',
            value: '家具家飾'
          },
          {
            title: '房仲',
            value: '房仲'
          }
        ]
      }
    ]
  },
  /// /////////////////////////////////// level 1
  { title: '媒體自營頻道',
    value: '媒體自營頻道',
    sub: [
      /// /////////////////////////// level 2
      { title: '電視媒體',
        value: '電視媒體',
        sub: [...media]
      },
      /// /////////////////////////// level 2
      { title: '廣播媒體',
        value: '廣播媒體',
        sub: [...media]
      },
      /// /////////////////////////// level 2
      { title: '報紙媒體',
        value: '報紙媒體',
        sub: [...media]
      },
      /// /////////////////////////// level 2
      { title: '雜誌媒體',
        value: '雜誌媒體',
        sub: [...media]
      },
      /// /////////////////////////// level 2
      { title: '網路媒體',
        value: '網路媒體',
        sub: [...media]
      }
    ]
  },
  /// /////////////////////////////////// level 1
  { title: '人物自營頻道',
    value: '人物自營頻道',
    sub: [
      /// /////////////////////////// level 2
      { title: '網紅',
        value: '網紅',
        sub: [
          {
            title: '美容保養',
            value: '美容保養'
          },
          {
            title: '流行時尚',
            value: '流行時尚'
          },
          {
            title: '旅遊',
            value: '旅遊'
          },
          {
            title: '美食料理',
            value: '美食料理'
          },
          {
            title: '烘焙',
            value: '烘焙'
          },
          {
            title: '母嬰親子',
            value: '母嬰親子'
          },
          {
            title: '兩性婚姻',
            value: '兩性婚姻'
          },
          {
            title: '運動體育',
            value: '運動體育'
          },
          {
            title: '健康醫療',
            value: '健康醫療'
          },
          {
            title: '3C科技/視聽家電',
            value: '3C科技/視聽家電'
          },
          {
            title: '寵物',
            value: '寵物'
          },
          {
            title: '電玩遊戲',
            value: '電玩遊戲'
          },
          {
            title: '影劇娛樂',
            value: '影劇娛樂'
          },
          {
            title: '表演藝術',
            value: '表演藝術'
          },
          {
            title: '財經',
            value: '財經'
          },
          {
            title: '房產',
            value: '房產'
          },
          {
            title: '趣味搞笑',
            value: '趣味搞笑'
          },
          {
            title: '知識教育',
            value: '知識教育'
          },
          {
            title: '時事討論',
            value: '時事討論'
          },
          {
            title: '購物消費',
            value: '購物消費'
          },
          {
            title: '帶貨分潤',
            value: '帶貨分潤'
          },
          {
            title: '汽機車',
            value: '汽機車'
          },
          {
            title: '文學創作',
            value: '文學創作'
          },
          {
            title: '設計/插畫',
            value: '設計/插畫'
          },
          {
            title: '生活分享',
            value: '生活分享'
          },
          {
            title: '職場',
            value: '職場'
          },
          {
            title: '文具手作',
            value: '文具手作'
          },
          {
            title: '家事家居',
            value: '家事家居'
          },
          {
            title: '動漫',
            value: '動漫'
          },
          {
            title: '星座命理玄學占卜',
            value: '星座命理玄學占卜'
          },
          {
            title: '攝影',
            value: '攝影'
          },
          {
            title: '異國文化',
            value: '異國文化'
          }
        ]
      },
      /// /////////////////////////// level 2
      { title: '藝人明星',
        value: '藝人明星',
        sub: []
      },
      /// /////////////////////////// level 2
      { title: '高階經理人',
        value: '高階經理人',
        sub: []
      },
      /// /////////////////////////// level 2
      { title: '主播/主持人',
        value: '主播/主持人',
        sub: []
      },
      /// /////////////////////////// level 2
      { title: '模特兒/直播主',
        value: '模特兒/直播主',
        sub: []
      },
      /// /////////////////////////// level 2
      { title: '政治人物',
        value: '政治人物',
        sub: [...policy]
      },
      /// /////////////////////////// level 2
      { title: '名嘴',
        value: '名嘴',
        sub: [...policy]
      }
    ]
  },
  /// /////////////////////////////////// level 1
  { title: '組織自營頻道',
    value: '組織自營頻道',
    sub: [
      /// /////////////////////////// level 2
      { title: '政黨自營頻道',
        value: '政黨自營頻道',
        sub: [...policy]
      },
      /// /////////////////////////// level 2
      { title: '側翼粉專自營頻道',
        value: '側翼粉專自營頻道',
        sub: [...policy]
      },
      /// /////////////////////////// level 2
      { title: '政府機關自營頻道',
        value: '政府機關自營頻道',
        sub: [{
          title: '學校',
          value: '學校'
        }]
      },
      /// /////////////////////////// level 2
      { title: '非營利組織自營頻道',
        value: '非營利組織自營頻道',
        sub: []
      },
      /// /////////////////////////// level 2
      { title: '其他組織自營頻道',
        value: '其他組織自營頻道',
        sub: [
          /// /////////////////////////// level 3
          {
            title: '運動團體',
            value: '運動團體'
          },
          {
            title: '表演團體',
            value: '表演團體'
          },
          {
            title: '宗教團體',
            value: '宗教團體'
          }
        ]
      }
    ]
  }
];

export const Audience = [
  {
    title: '興趣受眾',
    value: '興趣受眾',
    sub: [
      {
        title: 'null',
        value: 'null',
        sub: [
          {
            title: '流行時尚',
            value: '流行時尚'
          },
          {
            title: '旅遊',
            value: '旅遊'
          },
          {
            title: '運動體育',
            value: '運動體育'
          },
          {
            title: '動漫',
            value: '動漫'
          },
          {
            title: '電玩遊戲',
            value: '電玩遊戲'
          },
          {
            title: '益智遊戲',
            value: '益智遊戲'
          },
          {
            title: '影劇娛樂',
            value: '影劇娛樂'
          },
          {
            title: '星座命理玄學占卜',
            value: '星座命理玄學占卜'
          },
          {
            title: '美食料理',
            value: '美食料理'
          },
          {
            title: '烘焙',
            value: '烘焙'
          },
          {
            title: '攝影',
            value: '攝影'
          },
          {
            title: '工藝',
            value: '工藝'
          },
          {
            title: '園藝',
            value: '園藝'
          },
          {
            title: '文藝',
            value: '文藝'
          },
          {
            title: '表演藝術',
            value: '表演藝術'
          }
        ]
      }
    ]
  },
  {
    title: '產品受眾(產業)',
    value: '產品受眾(產業)',
    sub: [
      {
        title: 'null',
        value: 'null',
        sub: [
          {
            title: '美容保養',
            value: '美容保養'
          },
          {
            title: '酒',
            value: '酒'
          },
          {
            title: '茶與咖啡',
            value: '茶與咖啡'
          },
          {
            title: '3C產品',
            value: '3C產品'
          },
          {
            title: '汽機車',
            value: '汽機車'
          },
          {
            title: '視聽家電',
            value: '視聽家電'
          },
          {
            title: '精品收藏',
            value: '精品收藏'
          }
        ]
      }
    ]
  },
  {
    title: '生活議題受眾',
    value: '生活議題受眾',
    sub: [
      {
        title: 'null',
        value: 'null',
        sub: [
          {
            title: '公益',
            value: '公益'
          },
          {
            title: '健康醫療',
            value: '健康醫療'
          },
          {
            title: '趣味搞笑',
            value: '趣味搞笑'
          },
          {
            title: '寵物',
            value: '寵物'
          },
          {
            title: '設計',
            value: '設計'
          },
          {
            title: '科技',
            value: '科技'
          },
          {
            title: '宗教',
            value: '宗教'
          },
          {
            title: '家事家居',
            value: '家事家居'
          },
          {
            title: '感性心情',
            value: '感性心情'
          },
          {
            title: '兩性婚姻',
            value: '兩性婚姻'
          },
          {
            title: '母嬰親子',
            value: '母嬰親子'
          },
          {
            title: '購物消費',
            value: '購物消費'
          },
          {
            title: '政治社會',
            value: '政治社會'
          },
          {
            title: '房產',
            value: '房產'
          },
          {
            title: '財經',
            value: '財經'
          },
          {
            title: '教育進修',
            value: '教育進修'
          },
          {
            title: '職場',
            value: '職場'
          },
          {
            title: '地方新聞',
            value: '地方新聞'
          },
          {
            title: '閒聊類型',
            value: '閒聊類型'
          },
          {
            title: '氣象',
            value: '氣象'
          },
          {
            title: '鐵道運輸',
            value: '鐵道運輸'
          },
          {
            title: '軍事國防',
            value: '軍事國防'
          },
          {
            title: '國際情勢',
            value: '國際情勢'
          }
        ]
      }
    ]
  }
];
