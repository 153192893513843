import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs, Row, Col, Spin } from 'antd';

import RootModal from 'src/components/modal/RootModal';
import ModalViewModel from 'src/viewModels/modal';
import CategoryTable from 'src/components/feature/CategoryTable';
import KeywordTable from 'src/components/feature/KeywordTable';
import KeywordModal from 'src/components/feature/KeywordModal';

import styles from './styles.module.css';

@observer
class SubTabs extends React.Component {
  constructor(props) {
    super(props);
    this.keywordVM = new ModalViewModel();
  }

  componentDidMount() {
    this.props.vm.didMount();
  }

  renderInfoBox = () => (
    <div className={styles.border}>
      <Row justify="center" align="middle" className={styles.borderBottom}>
        <div>特性關鍵字基本資料</div>
      </Row>
      <div className={styles.padding}>
        <Row justify="space-between" className={styles.marginBottom}>
          <Col flex={1}>
            <Row className={styles.row}>
              <div>檔案名稱：</div>
              <div>{this.props.vm.feature.name}</div>
            </Row>
          </Col>
          <Col>
            <Row className={styles.row}>
              <div>檔案群組階層數：</div>
              <div>{this.props.vm.feature.level - 1}</div>
            </Row>
          </Col>
        </Row>
        <Row className={styles.row}>
          <div>備註：</div>
          <div>{this.props.vm.feature.note}</div>
        </Row>
      </div>
    </div>
  )

  render() {
    return (
      <>
        <Tabs onTabClick={this.props.vm.resetFilter}>
          <Tabs.TabPane key={1} tab="群組與子群組">
            {this.renderInfoBox()}

            <CategoryTable
              vm={this.props.vm}
              canSelectRow={false}
              canAddTag={false}
              canEdit={false}
              scrollY="calc(100vh - 400px)"
            />
          </Tabs.TabPane>

          <Tabs.TabPane key={2} tab="關鍵字與語法">
            <KeywordTable
              vm={this.props.vm}
              showModal={this.keywordVM.show}
              canAdd={false}
              canEdit={false}
            />
          </Tabs.TabPane>
        </Tabs>


        <KeywordModal
          visible={this.keywordVM.visible}
          config={this.keywordVM.config}
          hide={this.keywordVM.hide}
          canEdit={false}
          onUpdate={() => {}}
        />

      </>
    );
  }
}

SubTabs.propTypes = {
  vm: PropTypes.object.isRequired
};

SubTabs.defaultProps = {};

export default SubTabs;
