import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import ChangeLogService from 'src/services/changeLog';
import { errorModal } from 'src/utils';

export default class LogItem {
  @observable note = '';

  constructor(log, projectId) {
    makeObservable(this);
    this.id = log.id;
    this.projectId = projectId;
    this.refProjectId = log.refProjectId;
    this.type = log.type;
    this.date = log.date;
    this.action = log.action;
    this.source = log.source;
    this.before = log.before;
    this.after = log.after;
    this.projectName = log.projectName;
    this.userName = log.userName;
    this.targetProjectId = log.projectId;


    runInAction(() => {
      this.note = log.note;
    });
  }

  updateNote = async (newNote) => {
    try {
      await ChangeLogService.updateChangeLog(this.projectId, this.id, newNote);
      runInAction(() => { this.note = newNote; });
    } catch {
      errorModal('發生錯誤，無法更新');
    }
  }

}
