import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { LOG_TYPE } from 'src/constants';
import SystemLoadingVM from 'src/viewModels/Search/SystemLoading';

import styles from './styles.module.css';

@observer
class SystemLoading extends React.Component {

  constructor() {
    super();
    this.vm = new SystemLoadingVM();
    this.columns = [
      {
        title: <Row justify="center">分析類型</Row>,
        key: 'type',
        render: (data) => (<Row justify="center">{LOG_TYPE[data.type]?.name ?? '-'}</Row>)
      },
      {
        title: <Row justify="center">分析設定</Row>,
        key: 'settings',
        width: 200,
        render: (data) => (
          <div style={{ width: 160 }}>
            <div>{`頻道組合：${data.channelCount ?? '-'}`}</div>
            <div>{`搜尋關鍵字：${data.keywordCount ?? '-'}`}</div>
            <div>{`特性關鍵字：${data.featureCount ?? '-'}`}</div>
          </div>
        )
      },
      {
        title: <Row justify="center">所屬專案</Row>,
        key: 'projectId',
        render: (data) => (
          <Col>
            <div>{data.name}</div>
            <div>{`(${data.projectId})`}</div>
          </Col>
        )
      },
      {
        title: <Row justify="center">分析建置時間</Row>,
        key: 'createdAt',
        render: (data) => (
          <Row justify="center">
            {data.createdAt
              ? dayjs(data.createdAt).format('YYYY/MM/DD HH:mm:ss')
              : '-'}
          </Row>
        )
      },
      {
        title: <Row justify="center">資料分析區間</Row>,
        key: 'date',
        render: (data) => {
          const startDate = dayjs(data.startDate).format('YYYY/MM/DD');
          const finishDate = dayjs(data.finishDate).format('YYYY/MM/DD');

          return (<Row justify="center">{`${startDate} - ${finishDate}`}</Row>);
        }
      },
      {
        title: <Row justify="center">開始分析時間</Row>,
        key: 'beginAt',
        render: (data) => {
          const beginAt = data.beginAt ? dayjs(data.beginAt).format('YYYY/MM/DD HH:mm:ss') : '-';

          return (<Row justify="center">{beginAt}</Row>);
        }
      },
      {
        title: <Row justify="center">分析進度</Row>,
        key: 'progress',
        render: (data) => (<Row justify="center">{`${data.progress}%`}</Row>)
      },
      {
        title: <Row justify="center">分析狀態</Row>,
        key: 'stageDesc',
        render: (data) => (
          <Row align="middle">
            <div style={{ margin: '0 5px' }}>{data.stageDesc}</div>
            {data.message && (
            <Tooltip
              trigger={['hover']}
              title={data.message}
            >
              <InfoCircleOutlined />
            </Tooltip>
            )}
          </Row>
        )
      }
    ];
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.tab}>
        <div className={styles.title}>目前系統順暢度</div>
        <Row align="middle" className={styles.mb}>
          {this.vm.indicator === 'light' && (
          <>
            <div className={clsx([styles.dot, styles.light])} />
            <div>綠燈，系統分析排程不多</div>
          </>
          )}
          {this.vm.indicator === 'medium' && (
          <>
            <div className={clsx([styles.dot, styles.medium])} />
            <div>黃燈，系統分析排程略多</div>
          </>
          )}
          {this.vm.indicator === 'heavy' && (
          <>
            <div className={clsx([styles.dot, styles.heavy])} />
            <div>紅燈，系統分析排程過多</div>
          </>
          )}
        </Row>


        <Row>
          <div className={styles.title}>目前排程狀況</div>
          <div className={styles.extra}>
            {`${this.vm.stats.running}筆資料正在分析中，尚有${this.vm.stats.pending}筆在佇列等待分析`}
          </div>
        </Row>


        <div className={styles.title}>正在分析中</div>
        <Table
          rowKey="jobId"
          pagination={false}
          columns={this.columns}
          dataSource={this.vm.running}
          className={styles.mb}
        />


        <div className={styles.title}>等待分析中</div>
        <Table
          rowKey="jobId"
          pagination={false}
          columns={this.columns}
          dataSource={this.vm.pending}
          className={styles.mb}
        />

      </div>
    );
  }
}

export default SystemLoading;
