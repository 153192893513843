import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';

export default class SearchTag {
  @observable parentId = '';
  @observable scope = '';
  @observable name = '';
  @observable id = '';
  @observable default = '';
  @observable subTags = null;

  constructor(params) {
    const {
      parentId,
      scope,
      name,
      _id
    } = params;

    makeObservable(this);

    runInAction(() => {
      this.parentId = parentId;
      this.scope = scope;
      this.name = name;
      this.id = _id;
      this.default = params.default;
      if (!parentId) this.subTags = [];
    });
  }

  static fromRes(data) {
    return new SearchTag(data);
  }

  @action
  update = async (name) => {
    this.name = name;
  }

  @action
  pushSubTags = (subTag) => {
    if (!this.subTags) return;
    this.subTags.push(subTag);
  }
}
