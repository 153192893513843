import React, { useState, useEffect } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';
import { Button, Typography, Row, Tag, Spin } from 'antd';
import SortButton from 'src/components/SortButton';
import CustomToolTip from 'src/components/DisableToolTip';
import ActionBar from 'src/components/search/L3ActionBar';
import { SEARCH_KEYWORD_TXT } from 'src/constants';

import styles from './styles.module.css';


const L3Pane = observer((props) => {
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    const ellipsisRows = (props.vm.L3tags.length > 2) ? props.vm.L3tags.length : 2;

    setColumns([
      {
        title: <Row justify="center">序號</Row>,
        width: 50,
        key: 'index',
        render: (data, __, i) => (
          <CustomToolTip data={data}>
            <Row justify="center">{i + 1}</Row>
          </CustomToolTip>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L1 ?? ''}名稱`}
          </Row>
        ),
        width: 200,
        key: 'l1',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={{ text: data.id, tooltips: [data.sid] }}
                  className={data.enable ? '' : styles.disable}
                  style={{ width: 180 }}
                  ellipsis={{ rows: ellipsisRows }}
                >
                  {props.vm.keywordPool.get(data.sid)?.name ?? '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Row justify="center">
            {`${SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L2}名稱`}
          </Row>
        ),
        width: 200,
        key: 'l2',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={
                    data.subSid
                      ? { text: data.subSid, tooltips: [data.subSid] }
                      : false
                  }
                  className={data.enable ? '' : styles.disable}
                  style={{ width: 180 }}
                  ellipsis={{ rows: ellipsisRows }}
                >
                  {props.vm.keywordPool.get(data.subSid)?.name ?? '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: (
          <Observer>
            {() => (
              <SortButton
                title={`${SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L3 ?? ''}名稱`}
                order={props.vm.L3.order}
                onOrderChange={props.vm.L3.onOrderChange}
              />
            )}
          </Observer>
        ),
        width: 200,
        key: 'l3',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <Typography.Paragraph
                  copyable={{ text: data.id, tooltips: [data.id] }}
                  className={data.enable ? '' : styles.disable}
                  style={{ width: 180 }}
                  ellipsis={{ rows: ellipsisRows }}
                >
                  {data.name || '-'}
                </Typography.Paragraph>
              </CustomToolTip>
            )}
          </Observer>

        )
      },
      {
        title: (
          <Row justify="center">
            {`${SEARCH_KEYWORD_TXT[props.vm.searchAngle]?.L3 ?? ''}搜尋關鍵字`}
          </Row>
        ),
        key: 'keyword',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt} style={{ WebkitLineClamp: props.vm.L3tags }}>
                  {data.keyword || '-'}
                </div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">標籤</Row>,
        width: 200,
        key: 'tag',
        render: (data) => {
          if (!data.tags) return null;
          return (
            <Observer>
              {() => (
                <CustomToolTip data={data}>
                  {data.tags.map((t) => (
                    <Row key={t.value}>
                      <div>{`${props.vm.tagPool.get(t.key)?.name ?? ''}：`}</div>
                      <Tag key={t.value}>{props.vm.tagPool.get(t.value)?.name ?? ''}</Tag>
                    </Row>
                  ))}
                </CustomToolTip>
              )}
            </Observer>
          );
        }
      },
      {
        title: <Row justify="center">同義詞</Row>,
        key: 'synonym',
        render: (data) => (
          <Observer>
            {() => (
              <CustomToolTip data={data}>
                <div className={styles.txt} style={{ WebkitLineClamp: props.vm.L3tags }}>
                  {data.synonym || '-'}
                </div>
              </CustomToolTip>
            )}
          </Observer>
        )
      },
      {
        title: <Row justify="center">執行動作</Row>,
        width: 110,
        key: 'action',
        render: (data) => (
          <Button
            type="link"
            className={styles.noPadding}
            onClick={() => props.vm.drawer.show(data)}
          >
            檢視
          </Button>
        )
      }
    ]);
  }, [props.vm, props.vm.searchAngle]);

  return (
    <>

      <ActionBar
        onQuery={props.vm.L3.onSearch}
        tags={props.vm.L3tags}
        L1KeywordOption={props.vm.L1KeywordOptions}
        L2KeywordOption={props.vm.L2KeywordOptions}
        searchAngle={props.vm.searchAngle}
        keywordPool={props.vm.keywordPool}
        tagPool={props.vm.tagPool}
      />


      {columns && (
      <ReactResizeDetector handleHeight>
        {({ height = 0 }) => (
          <Observer>
            {() => (
              <Spin
                tip="正在加載資料"
                spinning={props.vm.L3.isLoading}
              >
                <div
                  style={{ height: 'calc(100vh - 375px)' }}
                >
                  <Table
                    dataSource={props.vm.L3.list}
                    rowKey="id"
                    size="small"
                    rowClassName={(record) => {
                      if (record.enable) return '';
                      return styles.disable;
                    }}
                    columns={columns}
                    onFetch={props.vm.L3.onBottom}
                    scroll={{ y: height, x: 1600 }}
                    lineCount={props.vm.L3tags.length}
                  />
                </div>
              </Spin>
            )}
          </Observer>
        )}
      </ReactResizeDetector>

      )}


    </>
  );
});

L3Pane.propTypes = {
  vm: PropTypes.objectOf(PropTypes.any).isRequired
};

export default L3Pane;
