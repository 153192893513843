import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import SearchSetService from 'src/services/searchSet';
import { errorModal } from 'src/utils';

export default class KeywordItem {
  @observable sid = null;
  @observable subSid = null;
  @observable name = '';
  @observable keyword = '';
  @observable synonym = '';
  @observable enable = false;
  @observable disableDate = '';
  @observable id = '';
  @observable tags = [];
  @observable level = '';
  projectId = '';
  keywordPaneVM = null;

  constructor({ keywordPaneVM, projectId, params, tags }) {
    const {
      sid,
      subSid,
      name,
      keyword,
      synonym,
      enable,
      disableDate,
      _id,
      level
    } = params;

    makeObservable(this);

    this.projectId = projectId;
    this.keywordPaneVM = keywordPaneVM;

    runInAction(() => {
      this.sid = sid;
      this.subSid = subSid;
      this.name = name;
      this.keyword = keyword;
      this.synonym = synonym;
      this.enable = enable;
      this.disableDate = disableDate;
      this.id = _id;
      this.level = level;

      this.tags = tags ?? null;
    });

  }

  @computed
  get isSelected() {
    return this.keywordPaneVM.selectedIdSet.has(this.id);
  }

  @action
  toggleIsSelected = (bool = null) => {
    if (this.isSelected && bool !== true) {
      this.keywordPaneVM.selectedIdSet.delete(this.id);
    } else {
      this.keywordPaneVM.selectedIdSet.add(this.id);
    }
  }

  update = async (newData) => {
    try {
      const { sid, subSid, sub2Sid } = this._getIds();
      await SearchSetService.updateKeyword(newData, this.projectId, sid, subSid, sub2Sid, this.level);
      runInAction(() => {
        this.name = newData.name;
        this.keyword = newData.keyword;
        this.synonym = newData.synonym;
      });

    } catch (err) {
      errorModal('無法更新');
    }
  }

  @action
  attachTags = (array) => {
    this.tags = [...array];
  }

  updateTags = async (array) => {
    try {
      await Promise.all(array.map(async (tag) => {
        if (tag.hasChanged) {
          return SearchSetService.updateTagRelation(this.projectId, tag.tid, tag.subTid, [this.id]);
        }
        return null;
      }));
      const newTags = array.map((newTag) => ({ key: newTag.tid, value: newTag.subTid }));
      runInAction(() => { this.tags = newTags; });

    } catch {
      // ignore
    }
  }

  @action
  updateOneTag = (key, value) => {
    const newTags = this.tags.map((tag) => {
      if (tag.key !== key) return tag;
      return { key, value };
    });

    runInAction(() => { this.tags = newTags; });
  }

  @action
  disable = (date) => {
    this.disableDate = date;
    this.enable = false;
  }

  _getIds = () => {
    if (this.level === 1) {
      return { sid: this.id, subSid: null, sub2Sid: null };
    }
    if (this.level === 2) {
      return { sid: this.sid, subSid: this.id, sub2Sid: null };
    }
    return { sid: this.sid, subSid: this.subSid, sub2Sid: this.id };
  }
}
