import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';


import Sider from './components/Sider';

import styles from './styles.module.css';

/**
 *
 * @see https://ant.design/components/layout-cn/
 *
 * @param {object} props
 * @returns
 */
const MainLayout = (props) => (
  <Layout className={props.className} style={{ height: '100%' }}>
    <Sider />

    <Layout>
      <Layout.Content className={styles.content}>
        <Outlet className={styles.content}>
          { props.children }
        </Outlet>
      </Layout.Content>
    </Layout>
  </Layout>
);

MainLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

MainLayout.defaultProps = {
  className: null,
  children: null
};

export default MainLayout;
