import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import dayjs from 'dayjs';
import ProjectScheduleService from 'src/services/projectSchedule';
import { LIMIT } from 'src/constants';
import { errorModal } from 'src/utils';
import LogItem from './LogItem';

export default class Analysis {

  page = 1;
  hasNextPage = true;
  isLoading = false;

  @observable list = [];

  // default required conditions
  @observable queryCondition = {
    startDate: dayjs().subtract(1, 'day').startOf('day').toISOString(),
    endDate: dayjs().endOf('day').toISOString()
  };


  constructor() {
    makeObservable(this);
  }

  didMount = async () => {
    this.getList();
  }

  getList = async () => {
    try {
      if (this.isLoading) return;

      runInAction(() => {
        this.isLoading = true;
      });

      const res = await ProjectScheduleService.myProjectsScheduleLog({
        ...this.queryCondition,
        page: this.page,
        limit: LIMIT
      });

      if (res.length < LIMIT) {
        this.hasNextPage = false;
      }

      const logs = res.map((log) => new LogItem(log));

      runInAction(() => {
        this.list = [...this.list, ...logs];
        this.page += 1;
        this.isLoading = false;
      });

    } catch {
      runInAction(() => { this.isLoading = false; });
      errorModal('發生錯誤，無法取得分析列表');
    }
  }

  @action
  onQuery = (newCondition) => {
    this.queryCondition = newCondition;
    this.page = 1;
    this.list = [];
    this.getList();
  }

  @action
  onBottom = () => {
    if (this.hasNextPage) {
      this.getList();
    }
  }

}
