import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Row, Drawer, Button, Spin } from 'antd';
import dayjs from 'dayjs';
import Table from 'src/components/VirtualTable';
import ReactResizeDetector from 'react-resize-detector';

import styles from './styles.module.css';

// 特性關鍵字話題列表

@observer
class FeatureTopicsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: <Row justify="center">序號</Row>,
        key: 'index',
        width: 70,
        render: (data, _, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: <Row justify="center">Vockey</Row>,
        key: 'vocKey',
        width: 270,
        render: (data) => (
          <div>
            <Button
              type="link"
              onClick={() => this.props.showTopicModal(data.mainId, true)}
              style={{ lineHeight: 1.5, height: 'unset' }}
            >
              <div style={{ width: '100%', wordBreak: 'break-all' }}>{data.vocKey}</div>
            </Button>
          </div>
        )
      },
      {
        title: <Row justify="center">標題</Row>,
        key: 'title',
        render: (data) => (<div className={styles.txt}>{data.title}</div>)
      },
      {
        title: <Row justify="center">回文數</Row>,
        key: 'count',
        width: 130,
        render: (data) => (<Row justify="center">{data.count}</Row>)
      },
      {
        title: <Row justify="center">發表時間</Row>,
        key: 'date',
        width: 200,
        render: (data) => (
          <Row justify="center">
            {dayjs(data.date).format('YYYY/MM/DD HH:mm:ss')}
          </Row>
        )
      },
      {
        title: <Row justify="center">來源頻道</Row>,
        key: 'channel',
        width: 250,
        render: (data) => (
          <a
            className={styles.txt}
            href={data.channelUrl}
            disabled={!data.channelUrl}
            target="_blank"
            rel="noreferrer"
          >
            {data.channelDisplayName}
          </a>
        )
      }
    ];
  }

  render() {
    const meta = this.props.vm.meta;
    const categoryName = meta.categoryName;
    const subCategoryName = meta.subCategoryName ? ` - ${meta.subCategoryName}` : '';
    const keywordName = meta.keywordName ? ` - ${meta.keywordName}` : '';
    const keywordId = meta.keywordId;
    const startDate = this.props.startDate ? dayjs(this.props.startDate).format('YYYY/MM/DD') : '';
    const endDate = this.props.endDate ? dayjs(this.props.endDate).format('YYYY/MM/DD') : '';

    return (
      <Drawer
        visible={this.props.vm.isVisible}
        title="特性關鍵字話題列表"
        width="90%"
        onClose={this.props.hide}
        zIndex={100}
        maskClosable={false}
      >
        <h4>{`${categoryName}${subCategoryName}${keywordName} (${keywordId})`}</h4>
        <h4>{(startDate && endDate) && `(${startDate} - ${endDate})`}</h4>


        <ReactResizeDetector handleHeight>
          {({ height = 0 }) => (
            <div
              style={{ height: 'calc(100vh - 162px)' }}
            >
              <Observer>
                {() => (
                  <Spin
                    tip="正在加載資料"
                    spinning={this.props.vm.isLoading}
                  >
                    <Table
                      size="small"
                      rowKey="mainId"
                      onFetch={this.props.onBottom}
                      columns={this.columns}
                      scroll={{ y: height }}
                      dataSource={this.props.vm.data}
                      lineCount={3}
                    />
                  </Spin>
                )}

              </Observer>
            </div>
          )}
        </ReactResizeDetector>
      </Drawer>
    );
  }
}

FeatureTopicsDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  showTopicModal: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  onBottom: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

FeatureTopicsDrawer.defaultProps = {
  startDate: null,
  endDate: null
};

export default FeatureTopicsDrawer;
