import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Row, Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { NAME_ORDER } from 'src/constants';

const SortButton = observer((props) => {
  return (
    <>
      <Row align="middle" justify="center">
        <div>{props.title}</div>
        <Button
          type="text"
          style={{ padding: '0 8px' }}
          onClick={props.onOrderChange}
        >
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: 10 }}>
            <CaretUpOutlined style={{ color: props.order === NAME_ORDER.ASC ? '#1890ff' : 'rgba(0, 0, 0, 0.85)' }} />
            <CaretDownOutlined style={{ color: props.order === NAME_ORDER.DESC ? '#1890ff' : 'rgba(0, 0, 0, 0.85)' }} />
          </div>
        </Button>
      </Row>
    </>
  );
});

SortButton.propTypes = {
  title: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  onOrderChange: PropTypes.func.isRequired
};

export default SortButton;
