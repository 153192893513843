import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import { message } from 'antd';
import ProjectService from 'src/services/project';
import SearchSetService from 'src/services/searchSet';
import { errorModal, noSearchKeyword } from 'src/utils';
import KeywordPane from './KeywordPane';
import TagPane from './TagPane';
import ImportDrawer from './ImportDrawer';
import EditDrawer from './EditDrawer';
import DeleteSOP from './DeleteSOP';
import DisableSOP from './DisableSOP';

export default class SearchSetViewModel {

  id = null
  deleteModalContent = null;
  isRun = false;
  name = '';
  @observable searchAngle = null;
  @observable searchSetRefProject = null;
  @observable disableModal = {
    visible: false,
    onOk: () => {},
    onCancel: () => {}
  };

  @observable keywordPool = new Map();
  @observable tagPool = new Map();
  @observable L1 = new KeywordPane(this, 1);
  @observable L2 = new KeywordPane(this, 2);
  @observable L3 = new KeywordPane(this, 3);
  @observable tag = new TagPane(this);
  @observable importDrawer = null;
  @observable editDrawer = new EditDrawer(this);
  @observable isProcessingData = false;
  @observable refreshStamp = Date.now();
  deleteSOP = null;
  disableSOP = null;


  constructor(id, deleteModalContent) {
    makeObservable(this);
    this.id = id;
    this.deleteModalContent = deleteModalContent;
    this.deleteSOP = new DeleteSOP(id);
    this.disableSOP = new DisableSOP(id);
  }

  refresh = async () => {
    if (!this.id) return;

    try {
      this.tag.getAllTags();

      const {
        meta: { searchAngle, isRun, name },
        ref: { searchSetRefProject }
      } = await ProjectService.getProject(this.id);

      runInAction(() => {
        this.searchSetRefProject = searchSetRefProject;
        this.searchAngle = searchAngle; // brand | category
        this.isRun = isRun;
        this.name = name;
        this.importDrawer = new ImportDrawer(searchAngle);
      });

    } catch (err) {
      // TODO: error handeling
    }
  }

  refreshAll = () => {
    this.refresh();
    this.tag.refresh();
    this.L1.refresh();
    this.L2.refresh();
    this.L3.refresh();
  }

  clearAll = async () => {
    try {
      await SearchSetService.clearAll(this.id);

      // preserve name and clear reduceKeyword
      // const { name, reduceKeyword } = await SearchSetService.getMeta(this.id);
      await SearchSetService.updateMeta(this.id, { name: '', reduceKeyword: '' });

      runInAction(() => {
        this.keywordPool = new Map();
        this.tagPool = new Map();
        this.searchSetRefProject = null;
        this.refreshStamp = Date.now();
      });

      this.refreshAll();

    } catch {
      errorModal('發生錯誤，無法清空現有設定');
    }
  }

  onRef = async (data) => {
    try {
      const { refProjectId } = data;
      const refProjectTips = await SearchSetService.getTip(refProjectId);

      if (
        refProjectTips.level1.count === 0
        && refProjectTips.level2.count === 0
        && refProjectTips.level3.count === 0
      ) {
        errorModal('搜尋關鍵字內容為空，無法引用');
        return;
      }


      await SearchSetService.ref(this.id, refProjectId);
      this.refreshAll();

      runInAction(() => {
        this.searchSetRefProject = refProjectId;
        this.refreshStamp = Date.now();
      });

    } catch {
      errorModal('發生錯誤，無法引用公版專案');
    }
  }

  @action
  informDelete = (level, ids) => {
    ids.forEach((keywordId) => this.keywordPool.delete(keywordId));
    const set = new Set(ids);
    switch (level) {
      case 1:
        this.L2.keywords = this.L2.keywords.filter((keyword) => !set.has(keyword.sid));
        this.L3.keywords = this.L3.keywords.filter((keyword) => !set.has(keyword.sid));
        break;
      case 2:
        this.L3.keywords = this.L3.keywords.filter((keyword) => !set.has(keyword.subSid));
        break;
      default:
        // ignore;
    }
  }

  @action
  informDisable = (level, ids, date) => {
    const set = new Set(ids);
    switch (level) {
      case 1:
        this.L2.keywords.filter((keyword) => set.has(keyword.sid)).forEach((k) => k.disable(date));
        this.L3.keywords.filter((keyword) => set.has(keyword.sid)).forEach((k) => k.disable(date));
        break;
      case 2:
        this.L3.keywords.filter((keyword) => set.has(keyword.subSid)).forEach((k) => k.disable(date));
        break;
      default:
        // ignore;
    }
  }



  //  -------- import drawer --------

  @action
  onUpload = async (file) => {
    runInAction(() => { this.isProcessingData = true; });

    try {
      const json = await SearchSetService.readUploadedFile(file);

      runInAction(() => {
        this.importDrawer.visible = true;
        this.importDrawer.uploadData(json);
        this.isProcessingData = false;
      });
    } catch (e) {
      errorModal('匯入失敗', e.message);
      runInAction(() => {
        this.importDrawer.visible = false;
        this.isProcessingData = false;
      });
    }
  }

  onImport = async () => {
    try {
      runInAction(() => { this.isProcessingData = true; });
      let count = 0;

      const noSearchCheck = (keyword) => {
        if (
          keyword.includes('這裡不搜')
          || keyword.includes('不搜不搜')
          || keyword.includes('不搜部搜')
        ) {
          count += 1;
          return noSearchKeyword;
        }

        return keyword;
      };

      const name = this.importDrawer.name?.trim();
      const reduceKeyword = this.importDrawer.reduceKeyword?.trim();
      const rows = this.importDrawer.keywords.map((r) => ({
        level1: r.level1,
        level2: r.level2,
        level3: r.level3,
        keyword: noSearchCheck(r.keyword),
        synonym: r.synonym,
        tags: r.tags
      }));

      await SearchSetService.import(this.id, { rows, name, reduceKeyword });
      runInAction(() => {
        this.isProcessingData = false;
        this.refreshStamp = Date.now();
      });

      this.importDrawer.closeDrawer();
      this.refreshAll();

      const txt = (count > 0) ? `匯入成功，共有${count}個搜尋關鍵字欲留空` : '匯入成功！';
      message.success(txt);

    } catch (e) {
      errorModal('上傳失敗', e.message);
      runInAction(() => { this.isProcessingData = false; });
    }
  }

  @action
  onDrawerClose = () => {
    this.importDrawer.closeDrawer();
  }

  @computed
  get canRef() {
    const result = (this.L1.keywords?.length === 0)
      && (this.tag.tags.length === 0)
      && !this.searchSetRefProject;

    return !!result;
  }

}
