import {
  makeObservable, computed, observable, action, runInAction
} from 'mobx';
import { LIMIT } from 'src/constants';

export default class GetListVM {
  @observable list = [];
  @observable anchor = null;
  @observable isLoading = false;
  @observable searchConditions = null;

  callback = () => {}

  constructor(callback) {
    makeObservable(this);
    this.callback = callback;
  }

  getList = async () => {
    try {
      if (this.isLoading) return;
      runInAction(() => { this.isLoading = true; });

      const { list, anchor } = await this.callback({
        ...this.searchConditions,
        limit: LIMIT,
        anchor: this.anchor
      });

      runInAction(() => {
        this.list = [...this.list, ...list];
        this.anchor = anchor;
        this.isLoading = false;
      });

    } catch (err) {
      runInAction(() => { this.isLoading = false; });
      // TODO error message
    }
  }

  @action
  onSearch = (conditions) => {
    this.searchConditions = conditions;
    this.list = [];
    this.anchor = null;
    this.getList();
  }

  onBottom = () => {
    if (this.anchor) this.getList();
  }

}
