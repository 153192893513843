import React, { useCallback, useEffect, useState } from 'react';
import { observer, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Drawer, Modal, Spin } from 'antd';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import ProjectItem from 'src/components/project/ProjectItem';
import ActionBar from 'src/components/project/ActionBar';
import AllProjectsSettingsPage from 'src/pages/AllProjectsSettingsPage';

import ItemDropDown from './DrawerProjectItem';
import RefDrawerVM from './RefDrawerVM';

const RefDrawer = observer((props) => {

  const [vm] = useState(() => new RefDrawerVM());
  const { modalVM, onFeatureTabChange, currentRefFid } = vm;


  useEffect(() => {
    if (props.visible) {
      vm.getList();
    } else {
      vm.clear();
    }
  }, [props.visible, vm]);


  useEffect(() => {
    vm.setType(props.type);
  }, [props.type, vm]);


  const onRef = useCallback(async (projectId = null, fid = null) => {
    if (vm.isRunningRef) return;

    const refProjectId = projectId || modalVM.config?.projectId;
    const refFid = fid || currentRefFid;

    vm.setIsRunningRef(true);
    await props.onRef({ refProjectId, refFid });
    vm.setIsRunningRef(false);

    modalVM.hide();
    vm.clear();
    props.onClose();

  }, [currentRefFid, modalVM, props, vm]);


  return (
    <>
      <Drawer
        title="選擇要引用的公版專案"
        width="90%"
        visible={props.visible}
        onClose={props.onClose}
        destroyOnClose
        maskClosable={false}
      >
        <ActionBar
          onQuery={vm.onSearch}
          templateProjectsOnly
        />

        <Spin
          tip="正在加載資料"
          spinning={vm.isLoading || vm.isRunningRef}
        >
          <BottomScrollListener onBottom={vm.onBotton}>
            {(scrollRef) => (
              <div
                ref={scrollRef}
                style={{ overflowY: 'scroll', height: 'calc(100vh - 210px' }}
              >
                <Observer>
                  {() => (
                    <>
                      {vm.list.map((project) => (
                        <ProjectItem
                          key={project._id}
                          projectId={project._id}
                          project={project}
                          settingButton={(
                            <>
                              <Button
                                style={{ flex: 1, marginRight: 5 }}
                                type="primary"
                                onClick={() => {
                                  modalVM.show({ projectId: project._id });
                                  vm.getFeatureFiles(project._id);
                                }}
                              >
                                檢視
                              </Button>
                              {(props.type !== 'feature') && (
                                <Button
                                  style={{ flex: 1 }}
                                  type="primary"
                                  onClick={() => onRef(project._id)}
                                >
                                  {`引用此${vm.type}`}
                                </Button>
                              )}
                              {(props.type === 'feature') && (
                                <ItemDropDown
                                  projectId={project._id}
                                  featureLevel={props.featureLevel}
                                  onRef={onRef}
                                />
                              )}
                            </>
                          )}
                        />
                      ))}
                    </>
                  )}

                </Observer>
              </div>
            )}
          </BottomScrollListener>
        </Spin>
      </Drawer>

      <Modal
        visible={modalVM.visible}
        onCancel={() => {
          vm.clearFeatureFiles();
          modalVM.hide();
        }}
        bodyStyle={{ height: '90vh', overflow: 'scroll' }}
        width="95%"
        centered
        destroyOnClose
        footer={(
          <Button
            type="primary"
            onClick={() => onRef(null)}
            loading={vm.isRunningRef}
            disabled={
              (props.type === 'feature') && (vm.currentFeature?.level !== props.featureLevel)
            }
          >
            {props.type === 'feature'
              ? `引用${vm.currentFeature?.name ?? ''}特性關鍵字`
              : `引用此${vm.type}`}
          </Button>
        )}
      >
        {modalVM.config?.projectId && (
          <AllProjectsSettingsPage
            projectId={modalVM.config.projectId}
            defaultActiveKey={props.type}
            onFeatureTabChange={onFeatureTabChange}
          />
        )}
      </Modal>
    </>
  );
});

RefDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  featureLevel: PropTypes.number
};

RefDrawer.defaultProps = {
  featureLevel: null
};


export default RefDrawer;
